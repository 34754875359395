import React, { useState, useCallback } from "react"
import { Col, Row } from "reactstrap"
import MonthSelect from "./MonthSelect"
import YearSelect from "./YearSelect"
import { BsExclamationCircle } from "react-icons/bs"
import { Button, Input } from "antd"
// Import Helpers
import { get, post, put, del } from "helpers/api_helper"
import * as url from "helpers/url_helper"
import CardImage from "../../../assets/images/card/cardImage.png"
// Import React Query
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"

// Import Toast Notifications
import { toast } from "react-toastify"

const NewCard = ({ isChecked, handleClick, toggle, refetch }) => {
  const [cardNumberError, setCardNumberError] = useState("")
  const [bin, setBin] = useState(0)
  const [cardInfo, setCardInfo] = useState({
    name_on_card: "",
    number: "",
    expiry_month: "",
    expiry_year: "",
    cvc: "",
  })

  // Update individual fields
  const handleCardInfoChange = useCallback((fieldName, value) => {
    setCardInfo(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  })

  const handleCardNumberBlur = () => {
    const isValidCardNumber = cardInfo.number.length === 16
    setCardNumberError(
      isValidCardNumber ? "" : "Kart numarası 16 haneden oluşmak zorunda."
    )
  }

  const sendBinQuery = async () => {
    try {
      const response = await post(
        url.POST_QUERY_BIN,
        { bin: bin },
        { withCredentials: true }
      )
      return response
    } catch (error) {
      console.error("Error:", error)
      return { error: "An error occurred while fetching data." }
    }
  }
  const handleContinue = () => {
    console.log("Tüm bilgiler girildi, kart başarıyla kaydedildi!")
    addCard()
    toggle()
  }

  const {
    data: bankBinData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["bin", bin],
    queryFn: sendBinQuery,
    onError: error => {
      console.error("Error:", error)
    },
  })

  const handleCardNumberChange = e => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/\D/g, "").slice(0, 16)
    handleCardInfoChange("number", inputValue)
    if (inputValue.length >= 8) {
      const bin = inputValue.slice(0, 8)
      setBin(bin)
      sendBinQuery(bin)
    }
  }

  const handleCvcChange = e => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/\D/g, "").slice(0, 3)
    handleCardInfoChange("cvc", inputValue)
  }

  const isContinueVisible = () => {
    const { name_on_card, number, expiry_month, expiry_year, cvc } = cardInfo
    return (
      isChecked && name_on_card && number && expiry_month && expiry_year && cvc
    )
  }

  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 156) + 100
    const g = Math.floor(Math.random() * 156) + 100
    const b = Math.floor(Math.random() * 156) + 100
    return `rgb(${r}, ${g}, ${b})`
  }

  const postCard = useCallback(() => {
    return post(url.POST_ADD_CARD, cardInfo, { withCredentials: true })
  }, [cardInfo])

  const addCardMutation = useMutation({
    mutationFn: postCard,
    onSuccess: () => {
      console.log("added card")
      toast.success("Yeni Kart Eklendi.", { toastId: "cardAddSuccess" })
      refetch()
    },
    onError: error => {
      console.log(error)
      const errorMessage = error.response?.data?.detail?.[0]?.msg

      if (errorMessage) {
        // Additional error handling logic specific to transactions data
        if (errorMessage === "card number duplication") {
          toast.error("Sistemde Kayıtlı Olan Bir Kart Numarası Girdiniz.", {
            toastId: "cardDuplicationError",
          })
        }
      }

      if (error?.code === "ERR_NETWORK") {
        toast.error("Sistemden kaynaklı bir sorun var...", {
          toastId: "networkError",
        })
      } else {
        const errorTypes = error.response.data.detail.map(item => item.type)
        console.log(errorTypes)
        if (errorTypes.some(message => message.includes("string_too_short"))) {
          toast.warning("Lütfen tüm alanları doldurun.", {
            toastId: "emptyFieldError",
          })
        } else if (
          errorTypes.some(message => message.includes("int_parsing"))
        ) {
          toast.warning("Lütfen tarih değerlerini girmeyi unutmayın.", {
            toastId: "invalidInputError",
          })
        } else {
          toast.error("Bir hata oluştu...", { toastId: "someError" })
        }
      }
    },
  })

  const addCard = useCallback(() => {
    addCardMutation.mutate()
  }, [addCardMutation])

  const cardNumberStyle = { color: "#4F4F4F", marginTop: "30px" }
  const creditCard = {
    backgroundImage: `url(${CardImage})`,
    backgroundSize: "100% auto", // Resmin genişliğini 100% olarak ayarlar
    width: "500px",
    height: "250px",
    borderRadius: "10px",
  }

  return (
    <React.Fragment>
      <div style={creditCard}>
        <Row style={{ paddingTop: "18px" }}>
          <Col xs="7">
            <span
              style={{ color: "#FFF", fontSize: "20px", marginLeft: "35px" }}
            >
              {!isLoading && !isError && bankBinData && bankBinData.bank ? (
                bankBinData.bank
              ) : (
                <span style={{ fontSize: "10px", margin: "30px" }}></span>
              )}
            </span>
          </Col>
          <Col xs="5">
            {!isLoading && !isError && bankBinData && bankBinData.bank_media ? (
              <img
                src={bankBinData.bank_media}
                alt="Banka Logosu"
                style={{ marginLeft: "20px", height: "30px" }}
              />
            ) : (
              <span
                style={{
                  marginLeft: "20px",
                  fontSize: "20px",
                  color: "#FFF",
                }}
              ></span>
            )}
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2rem",
            marginLeft: "1rem",
            fontSize: "24px",
            color: "#000",
          }}
        >
          <Col>
            <span style={{ ...cardNumberStyle, color: "#FFF" }}>
              {!cardInfo.number && (
                <span style={{ fontSize: "20px" }}>Kart Numarası</span>
              )}
              {cardInfo.number &&
                cardInfo.number
                  .split("")
                  .map((char, index) =>
                    index % 4 === 0 && index > 0
                      ? "\u00A0\u00A0\u00A0\u00A0" + char
                      : "\u00A0" + char
                  )
                  .join("")}
            </span>
          </Col>
        </Row>
        <Row className="m-3 mb-4">
          <Col>
            <span>
              {!cardInfo.name_on_card && (
                <span
                  style={{
                    fontSize: "15px",
                    color: "#FFF",
                    marginBottom: "40px",
                  }}
                >
                  Sahip Adı
                </span>
              )}
              {cardInfo.name_on_card && (
                <span
                  style={{ color: "#FFF", marginLeft: "8px", fontSize: "15px" }}
                >
                  {cardInfo.name_on_card}
                </span>
              )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <span
              style={{ marginLeft: "35px", marginTop: "30px", color: "#ffff" }}
            >
              Son Kullanma Tarihi
              {cardInfo.expiry_month && cardInfo.expiry_year && (
                <>
                  {" "}
                  <br />{" "}
                  <span style={{ marginLeft: "35px", marginTop: "30px" }}>
                    {cardInfo.expiry_month}/{cardInfo.expiry_year}{" "}
                  </span>
                </>
              )}
            </span>
          </Col>
          <Col xs="3">
            <span style={{ color: "#ffff" }}>
              CVV <br /> {cardInfo.cvc}
            </span>
          </Col>
          <Col xs="3">
            <span>
              {!isLoading &&
              !isError &&
              bankBinData &&
              bankBinData.schema_media &&
              bankBinData.schema_media[0] ? (
                <img
                  src={bankBinData.schema_media[0]}
                  alt="Banka Logosu"
                  style={{
                    height: "50px",
                  }}
                />
              ) : (
                <span style={{ color: "#fff", fontSize: "15px" }}> </span>
              )}
            </span>
          </Col>
        </Row>
      </div>

      <Row className="d-flex align-items-center my-3">
        <Col xs="auto">
          <Input
            type="radio"
            checked={isChecked}
            onChange={() => {}}
            onClick={handleClick}
          />
        </Col>
        <Col className="align-self-center">
          <Row>
            <Col xxl="5" lg="5" sm="12" xs="12" className="mt-3">
              <Row>
                <span className="my-1 ms-1">Sahip Adı</span>
              </Row>
              <Row className="mx-1">
                <Input
                  placeholder="Sahibi adını giriniz."
                  disabled={!isChecked}
                  value={cardInfo.name_on_card}
                  onChange={e => {
                    let inputValue = e.target.value

                    inputValue = inputValue.replace(/[^A-Za-z\s]/g, "")

                    handleCardInfoChange("name_on_card", inputValue)
                  }}
                  type="text"
                  style={{ padding: "6px" }}
                />
              </Row>
            </Col>

            <Col xxl="3" lg="3" sm="6" xs="12" className="mt-3">
              <Row>
                <span className="my-1 ms-1">Ay</span>
              </Row>
              <Row className="mx-1">
                <MonthSelect
                  value={cardInfo.expiry_month}
                  onChange={e =>
                    handleCardInfoChange("expiry_month", e.target.value)
                  }
                  disabled={!isChecked}
                />
              </Row>
            </Col>
            <Col xxl="3" lg="3" sm="6" xs="12" className="mt-3">
              <Row>
                <span className="my-1 ms-1">Yıl</span>
              </Row>
              <Row className="mx-1">
                <YearSelect
                  value={cardInfo.expiry_year}
                  onChange={e =>
                    handleCardInfoChange("expiry_year", e.target.value)
                  }
                  disabled={!isChecked}
                />
              </Row>
            </Col>
            <Col xxl="8" lg="8" sm="9" xs="12" className="mt-3">
              <Row>
                <Col lg="6">
                  <span className="my-1 ms-1">Kart numarası</span>
                </Col>
              </Row>
              <Row className="mx-1">
                <Input
                  status={cardNumberError ? "error" : ""}
                  prefix={cardNumberError && <BsExclamationCircle />}
                  placeholder="Kart numaranızı giriniz."
                  disabled={!isChecked}
                  value={cardInfo.number}
                  onBlur={handleCardNumberBlur}
                  onChange={handleCardNumberChange}
                  type="text"
                  style={{ padding: "6px", marginTop: "6px" }}
                />
              </Row>
              <Row>
                <span className="text-danger mx-2">{cardNumberError}</span>
              </Row>
            </Col>
            <Col xxl="3" lg="3" sm="3" xs="12" className="mt-3">
              <Row>
                <span className="my-1 ms-1">CVV</span>
              </Row>
              <Row className="mx-1">
                <Input
                  disabled={!isChecked}
                  value={cardInfo.cvc}
                  onChange={handleCvcChange}
                  type="text"
                  style={{ padding: "6px" }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-end">
          {isContinueVisible() && (
            <Button
              style={{
                background: "#34C38F",
                marginTop: "2rem",
                color: "#ffff",
              }}
              onClick={handleContinue}
            >
              Devam Et
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default NewCard
