import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams, Link } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { IoIosCloseCircle } from "react-icons/io"
import EditProducts from "./EditProducts"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { IoArrowBackCircleSharp, IoCheckmarkCircle } from "react-icons/io5"
const EditCollections = () => {
  const history = useNavigate()
  const { collectionId } = useParams()
  const [editData, setEditData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [collectionName, setCollectionName] = useState("")
  const navigate = useNavigate();
  useEffect(() => {
    fetchCollectionData()
  }, [collectionId])

  const fetchCollectionData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/get-collection-data?collection_id=${collectionId}`,
        { withCredentials: true }
      )
      .then(response => {
        const data = response.data
        const products = JSON.parse(data.products)
        setEditData(products)
        setCollectionName(data.collection_name)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleDelete = id => {
    const newData = editData.filter(info => info.product_id !== id)
    setEditData(newData)
  }

  const handleAddProducts = selectedProducts => {
    setEditData([...editData, ...selectedProducts])
    toggleModal()
  }

  const updateCollection = () => {
    const productIds = editData.map(product => product.product_id)
    const requestData = {
      collection_name: collectionName,
      product_ids: productIds,
    }

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/update-collection?collection_id=${collectionId}`,
        requestData,
        { withCredentials: true }
      )
      .then(response => {
        console.log("Collection updated successfully:", response.data)
        toast.success("Koleksiyon başarıyla oluşturuldu.", {
          toastId: "descriptionMutation",
        })
        history("/collections")
      })
      .catch(error => {
        console.error("Error updating collection:", error)
        toast.error("Bilinmeyen Bir Hata Oluştu", {
          toastId: "descriptionMutationError",
        })
      })
  }

  return (
    <div style={{ backgroundColor: "white", margin: "10px" }}>
      <div className="page-content">
        <Container fluid>
        <Row className="mb-2">
          <Col lg="6"> <span className="title d-flex align-items-center">
            <IoArrowBackCircleSharp
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer", color: "##1A1A1D" }}
            />
          </span></Col>
           
          </Row>
          <Row>
            <Col lg={12}>
              <Row>
                <h5>Koleksiyon Bilgileri</h5>
              </Row>
              <Row>
                <p className="mt-3">Aşağıdaki tüm bilgileri doldurun.</p>
              </Row>
              <Row>
                <h6 className="">Koleksiyon Adı</h6>
              </Row>
              <Row>
                <input
                  className="edit-collection-input"
                  type="text"
                  placeholder="Type here..."
                  value={collectionName}
                  onChange={e => setCollectionName(e.target.value)}
                  style={{ marginLeft: "10px" }}
                />
                <p style={{ fontSize: "11px" }}>
                  Koleksiyon adını değiştirmek için tıklayınız !
                </p>
              </Row>
            </Col>
            <Col lg={12} className="pl-lg-5 mt-5 pt">
              <Row>
                <Col
                  className="d-flex justify-content-center  align-items-center"
                  xl={2}
                  lg={3}
                  md={3}
                  xs={6}
                  style={{
                    borderStyle: "dashed",
                    borderColor: "#CED4DA",
                    height: "209.6px",
                    marginTop: "25px",
                  }}
                >
                  <Button
                    type="button"
                    className="btn mt-2 me-1"
                    onClick={toggleModal}
                    style={{ backgroundColor: "white", border: "none" }}
                  >
                    <i
                      className="bx bx-plus fs-1"
                      style={{ color: "#888A8C" }}
                    />
                    <p style={{ color: "#495057" }}>Ürünü seç</p>
                  </Button>
                  <Modal
                    isOpen={modalOpen}
                    toggle={toggleModal}
                    style={{ maxWidth: "70%", width: "auto" }}
                  >
                    <ModalHeader toggle={toggleModal}>
                      Koleksiyona Ürün Ekle
                    </ModalHeader>
                    <ModalBody>
                      <EditProducts
                        onAddProducts={handleAddProducts}
                        editData={editData}
                      />
                    </ModalBody>
                  </Modal>
                </Col>
                {editData.map(product => (
                  <Col
                    key={product.product_id}
                    lg={3}
                    md={3}
                    xl={2}
                    xs={6}
                    className="mt-2"
                  >
                    <div
                      style={{
                        borderRadius: "8px",
                        padding: "10px",
                        margin: "5px 0",
                        position: "relative",
                      }}
                      key={product.product_id}
                    >
                      <img
                        src={product.variation_media[0]}
                        alt="Product"
                        style={{
                          width: "100%",
                          height: "210px",
                          position: "relative",
                          zIndex: 0,
                          borderRadius: "8px",
                          objectFit: "cover",
                          border: "1px solid #eee",
                        }}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "1px",
                          right: "3px",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: "0",
                          zIndex: 1,
                        }}
                        onClick={() => handleDelete(product.product_id)}
                      >
                        <i
                          className="bx bxs-x-circle"
                          style={{ fontSize: "25px", color: "#FA7E7E" }}
                        ></i>
                      </button>
                      <div>
                        <p className="text-truncate fw-bold text-center">
                          {product.product_name
                            ? product.product_name
                            : product.name}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-end">
              <Link to="/collections" className="btn btn-secondary me-2 my-4">
                Geri
              </Link>
              <button
                type="button"
                className="btn btn-success my-4"
                onClick={updateCollection}
              >
                Koleksiyonu Düzenle
              </button>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default EditCollections
