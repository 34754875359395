import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
// import { FaTimes } from "react-icons/fa"; // Çarpı ikonu için FontAwesome'dan gerekli bileşen
import NewCard from "../Components/NewCard";

const NewCardPopup = ({
  isOpen,
  toggle,
  isChecked,
  refetch
}) => {
  const handleClose = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "600px" }} centered>
      <ModalHeader toggle={handleClose}> Yeni Kart Ekle</ModalHeader>
      <ModalBody style={{ padding: "40px", position: "relative" }}>
        {/* <Button color="link" className="position-absolute top-0 end-0 m-3" onClick={handleClose}>
          <FaTimes />
        </Button> */}
        <NewCard
          toggle={toggle}
          isChecked={isChecked}
          refetch={refetch}
        />
      </ModalBody>
    </Modal>
  );
};

export default NewCardPopup;
