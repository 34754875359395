


const languages = {
  
  tr: { // Türkçe dilini ekleyin
    label: "Türkçe",

  },
}

export default languages;
