import React from "react";
import "./AddProduct.css"
const Tag = (props) => {
    const tagStyle = {
        display: "flex",
        alignItems: "center",
        margin: "4px", // Adjust these values as needed
        padding: "6px",    // Adjust these values as needed
        backgroundColor: "#FFF",
    };
    // console.log(props.color);
    const colorValue = props.color ?? "#000000";
    
    return (
        <React.Fragment>
            {props.type==="color" ?
                (
                    <div style={tagStyle}>
                        <span onClick={props.remove} style={{cursor: "pointer"}} className="px-2">x</span>
                        {/* {props.color && (
                            <div
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    backgroundColor: props.color,
                                    marginLeft: "8px",
                                }}
                            >
                            </div> */}
                            <input
                                type="color"
                                id="style2"
                                value={colorValue}
                                onChange={props.change}
                            />
                        {/* )} */}
                        <p className="m-0 px-2">{props.tag}</p>
                    </div>
                ) : (
                    <div style={tagStyle}>
                        <span onClick={props.remove} style={{cursor: "pointer"}} className="px-2">x</span>
                        {props.color && (
                            <div
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    backgroundColor: props.color,
                                    marginLeft: "8px",
                                    boxShadow: "2px 2px 3px 0px #cfcfcf "
                                }}
                            >

                            </div>
                        )}
                        <p className="m-0 px-2">{props.tag}</p>
                    </div>
                )}

        </React.Fragment>
    );
};


export default Tag;
