import React from "react";
import {
    Container,
    Label,
    Row,
    Col,
    Button,
    Input
} from "reactstrap";
import { useState, useMemo } from "react";
import AddressInput from "./AddressInput";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import "./style.css"
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
// import toastr from "toastr";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Skeleton } from 'antd';
import { DatePicker } from 'antd';
const PersonalInformation = () => {
    const history = useNavigate();

    const [count, setCount] = useState(0);
    const [editPersonalInformation, setEditPersonalInformation] = useState(false)
    const [editDOB, setEditDOB] = useState(false)
    const [DOB, setDOB] = useState("")
    const [gender, setGender] = useState("");
    const [DOBStatus, setDOBStatus] = useState("");
    const [isApproved, setIsApproved] = useState(true);
    function handleCountUpdate() {
        setCount(prevCount => prevCount + 1);
    }
    //start of update DOB
    const onChange = (date, dateString) => {
        console.log(dateString);
        setDOB(dateString)
    };
    const updateDOB = (data) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/update-dob`
        const finalData = { dob: DOB }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const updateDOBMutation = useMutation({
        mutationFn: updateDOB,
        onSuccess: (data) => {
            toast.success("Doğum tarihi güncellendi", { toastId: "date-of-birth-update" })
            setEditDOB(false)
            refetch()
        },
        onError: (error) => {
            toast.error("Doğum tarihi güncellenmedi", { toastId: "date-of-birth-update" })
            console.log(error);
        }
    })
    //end of update DOB

    //start of gender update api
    const updateGender = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/update-gender`
        const finalData = {
            gender: gender
        }
        return axios.put(finalUrl, finalData, { withCredentials: true })
    }
    const genderMutation = useMutation({
        mutationFn: updateGender,
        onSuccess: () => {
            toast.success("İşlem Tamamlandı", { toastId: "genderMutation" })
            refetch()
            setEditPersonalInformation(false)
        },
        onError: () => {
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "genderMutationError" });
        }
    })
    //end of gender update api

    const getPersonalInfo = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/personal-information`, { withCredentials: true })
    }
    const { isLoading, isError, data, error, isSuccess, refetch } = useQuery({ queryKey: ['get-personal-info', count], queryFn: getPersonalInfo, retry: 2 })
    useMemo(() => {
        setDOB(data?.data?.dob)
    }, [data])

    if (isError) {
        if (error?.response?.data?.detail[0]?.msg === "unathorized action" || error?.response?.data?.detail[0]?.msg === "invalid jwt") {
            history("/login")
        }
        console.log(error);
        if (error?.response?.data?.detail[0]?.msg === "seller is not approved") {
            // setIsApproved(false)
            return (
                <>
                    <h1>Satıcı onaylı değil</h1>
                </>
            )
        } else {
            return (
                <>
                    <h1>hata Oluştu</h1>
                </>
            )
        }
    }

    return (
        <>
            {isLoading ?
                (<Skeleton />) : 
                (
                    <Container>
                        

                        <Row>
                            <Col md="6" xs="12">
                                <Row className="d-flex align-items-center mb-3">
                                    <Col xs="12" sm="4">
                                        <Label>Ad </Label>
                                    </Col>
                                    <Col>
                                        <Input disabled type="text" placeholder={data?.data?.first_name} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" xs="12">
                                <Row className="d-flex align-items-center mb-3">
                                    <Col xs="12" sm="4">
                                        <Label>Soyadı </Label>
                                    </Col>
                                    <Col>
                                        <Input disabled type="text" placeholder={data?.data?.last_name} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" xs="12">
                                <Row className="d-flex align-items-center mb-3">
                                    <Col xs="12" sm="4">
                                        <Label>T.C </Label>
                                    </Col>
                                    <Col>
                                        <Input disabled type="text" placeholder={data?.data?.seller_TC_no} />
                                    </Col>
                                </Row>
                            </Col>
                            {editPersonalInformation ? (
                                <>
                                    <Col md="6" xs="12">
                                        <Row className="d-flex align-items-center mb-3">
                                            <Col xs="12" sm="4">
                                                <Label>Cinsiyet </Label>
                                            </Col>
                                            <Col>
                                                <Form.Select onChange={(e) => {
                                                    setGender(e.target.value)
                                                }} aria-label="Default select example">
                                                    <option >cinsiyet</option>
                                                    <option value="male" >Erkek</option>
                                                    <option value="female">Kadın</option>
                                                    <option value="rather-not-say">Söylememeyi Tercih Ederim</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                                <Button onClick={() => { setEditPersonalInformation(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                                <Button type="button" onClick={() => {
                                                    genderMutation.mutate()
                                                }} className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            ) : (
                                <Col md="6" xs="12">
                                    <Row className="d-flex align-items-center mb-3">
                                        <Col xs="12" sm="4">
                                            <Label>Cinsiyet </Label>
                                        </Col>
                                        <Col>
                                            <InputGroup className="">
                                                <Form.Control
                                                    placeholder={data?.data?.gender=="male"? "Erkek":data?.data?.gender=="female"?"Kadın":"Söylememeyi Tercih Ederim"}
                                                    disabled
                                                />
                                                <Button onClick={() => { setEditPersonalInformation(true) }} variant="outline-secondary" id="button-addon2">
                                                    Düzenle
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                </Col>
                            )}
                        </Row>
                        <Row>

                            {editDOB ? (
                                <>
                                    <Col md="6" xs="12">
                                        <Row className="d-flex align-items-center mb-3">
                                            <Col xs="12" sm="4">
                                                <Label>Doğum tarihi</Label>
                                            </Col>
                                            <Col>
                                                {/* <Input onChange={() => {
                                                    console.log(DOB);
                                                }} value={DOB} type="text" placeholder="date of birth" /> */}
                                                <DatePicker
                                                    onFocus={() => setDOBStatus("")}
                                                    status={DOBStatus}
                                                    placeholder="Doğum tarihi"
                                                    style={{ width: "100%", padding: "0.47rem 1.75rem 0.47rem 0.75rem" }}
                                                    onChange={onChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className="mb-4  d-flex justify-content-end  ">
                                                <Button
                                                    onClick={() => { setEditDOB(false) }}
                                                    style={{ marginRight: "8px" }}
                                                    type="button"
                                                    className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"
                                                >
                                                    <i className='bx bx-x bx-sm'></i>Geri
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        if (DOB && DOB !== data?.data?.dob) {
                                                            updateDOBMutation.mutate()
                                                        }
                                                        else {
                                                            setDOBStatus("error")
                                                        }
                                                    }}
                                                    className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"
                                                >
                                                    <i className='bx bx-check bx-sm'></i>Kaydet
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            ) : (
                                <Col md="6" xs="12">
                                    <Row className="d-flex align-items-center mb-3">
                                        <Col xs="12" sm="4">
                                            <Label>Doğum Tarihi</Label>
                                        </Col>
                                        <Col>
                                            <InputGroup className="">
                                                <Form.Control
                                                    placeholder="Doğum Tarihi"
                                                    value={DOB}
                                                    disabled
                                                    className={`${DOB===null && "border-danger"}`}
                                                />
                                                <Button
                                                    onClick={() => { setEditDOB(true) }}
                                                    variant="outline-secondary"
                                                    id="button-addon2"
                                                    className={`${DOB===null && "border-danger"}`}
                                                >
                                                    Düzenle
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                </Col>)}
                        </Row>

                        <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                        <Row>
                            <Col>
                                <AddressInput id={data?.data?.address_id} onUpdate={handleCountUpdate} type={"personal"} address={data?.data?.personal_address} />
                            </Col>
                        </Row>

                    </Container>
                )}
        </>
    )
}

export default PersonalInformation