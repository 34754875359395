import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DefinitionTable } from "./DefinitionTable";
function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    BUTİKO KULLANIM KOŞULLARI ve ÜYELİK SÖZLEŞMESİ
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "55vh", overflowY: "auto" }}>
                <h3>1. TARAFLAR</h3>
                <p className="lh-base">
                    İşbu BUTİKO Kullanım Koşulları ("Koşullar")
                    <br />
                    a. "Küçükbakkalköy Mah. Selvi̇li̇ Sk. Canan Business Plaza. No: 4/20 Ataşehi̇r/İstanbul" adresinde bulunan BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET ANONİM ŞİRKETİ ("BUTİKO") ile
                    <br />
                    b. BUTİKO'ya üye olmak isteyen kullanıcılar ("Kullanıcı")
                    <br />
                    arasında aşağıda belirtilen şartlar ve hükümler dâhilinde BUTİKO'nun herhangi bir yolla kullanılmaya başlanması halinde akdedilmiş sayılır ve yürürlüğe girer. BUTİKO ve Kullanıcı ayrı ayrı "Taraf" birlikte "Taraflar" olarak anılacaktır.
                    <br />
                    BUTİKO tedarikçilerin ürünlerini butiko.com.tr'de ve mobil uygulamasında listelemelerine ve satmalarına izin vermektedir. BUTİKO internet sitesinde satılan ürünlerin üreticisi değildir. Tedarikçilerin ürünlerinin alıcısı veya satıcısı da değildir.
                </p>
                <h3>2. TANIMLAR</h3>
                <DefinitionTable />
                <br />
                <h3>3. BAŞVURU VE ÜYELİK</h3>
                <p className="lh-base">
                    <strong>3.1.</strong> BUTİKO'nun akıllı cep telefonlarına indirilmesi kurulması ve üye olunması ücretsizdir.
                    <br />
                    <strong>3.2.</strong> Kullanıcı BUTİKO'ya üye olabilmek için 18 (on sekiz) yaşını doldurmuş ve BUTİKO tarafından herhangi bir sebep dolayısıyla üyelikten yasaklanmamış olmalıdır. 18 yaşından küçük kişiler ile ayırt etme gücünden yoksun veya kısıtlı erginler BUTİKO'ya üye olamaz. Kullanıcı bu sözleşmeyi elektronik ortamda onaylamakla 18 yaşından büyük ve tam ehliyetli olduğunu teyid etmiş olur.
                    <br />
                    <strong>3.3.</strong> Kullanıcılar BUTİKO'ya üye olmakla BUTİKO ile akdetmiş olduğu işbu Koşullar hükümlerine uyacağını kabul ve taahhüt eder.
                    <br />
                    <strong>3.4.</strong> Kullanıcı tarafından belirlenen şifre sadece Kullanıcı bilgisi dahilinde olacaktır. Kullanıcı şifresi unutulduğu takdirde BUTİKO talep üzerine Kullanıcı'nın BUTİKO'da kayıtlı e-posta adresine yeni şifre oluşturabilmek için bir bağlantı linki gönderecektir. Şifrenin belirlenmesi güvenliği korunması ve 3. kişilerin bilgisinden uzak tutulması tamamıyla Kullanıcı’nın sorumluluğundadır. Kayıt esnasında Kullanıcı tarafından belirlenen şifre ve kullanıcı adı münhasıran Kullanıcı'nın kullanımına tahsis edilmiş olup bu bilgiler 3. taraflarla paylaşılamaz. Kullanıcı söz konusu bilgilerin kendisi tarafından 3. kişilerle paylaşılmasından doğabilecek her türlü zarara karşı BUTİKO'yu tazmin etmeyi kabul ve taahhüt eder. BUTİKO şifre kullanımından doğacak problemlerden veya Kullanıcı'nın uğrayacağı olası zararlardan kesinlikle sorumlu değildir.
                    <br />
                    <strong>3.5.</strong> Kullanıcı BUTİKO'ya giriş esnasında vermiş olduğu bilgilerin eksiksiz gerçeğe uygun ve güncel bilgiler olduğunu ve söz konusu bilgilerin değişmesi halinde BUTİKO üzerinden bu bilgileri güncellemeyi kabul ve taahhüt eder. Aksi durumda meydana gelebilecek her türlü zarardan Kullanıcı sorumlu olacaktır. Bununla birlikte Kullanıcı BUTİKO'nun söz konusu bilgilerin güncel tutulmaması/olmaması sebebiyle uğrayacağı her türlü zarar ve 3. kişilere yapılacak ödemeler ile her türlü ceza ve benzeri mali yükümlülükler sebebi ile uğradığı zararı tazmin etmeyi kabul ve taahhüt eder.
                </p>
                <h3>4. BUTİKO’NUN KULLANIMI</h3>
                <p className="lh-base">
                    <strong>4.1.</strong> Kullanıcı, BUTİKO'ya kayıt olup, BUTİKO'da yer alan ve satın almak istediği Ürün(ler)’i seçerek satın alma butonuna tıkladığında, Mesafeli Satış Sözleşmesi'ni ve ilgili belgeleri onaylarak ödemeyi gerçekleştirdikten sonra Ürün’ü satın almış olur.
                    <br />
                    <strong>4.2.</strong> BUTİKO, teslimat süresinin gerçeği yansıtması adına elinden geleni azami çabayı gösterir ve bu doğrultuda bir öngörü sunar. Ancak hava şartları gibi Taraflar’ın kontrolü altında bulunmayan sebeplerle; bu bilgi ve öngörülerin doğruluğunu taahhüt etmez. Bu sebeple; bu gibi gecikme ve aksamalardan BUTİKO sorumlu tutulamaz. Teslimat tahminleri yalnızca tahmindir. Bunlar garanti edilen teslimat süreleri değildir ve bu yönde algılanmamalıdır. Siparişin hedeflenen tahmini süre içerisinde teslim edilememesinden BUTİKO sorumlu tutulamaz.
                    <br />
                    <strong>4.3.</strong> Kullanıcı sipariş verdiği Ürün’ü haklı bir sebep olmaksızın kuryeden teslim almazsa; Mesafeli Satış Sözleşmesi'nde yer alan haklar saklı kalmak kaydıyla, BUTİKO Kullanıcı'yı BUTİKO'dan çıkarma ve üyeliğini iptal etme hakkını saklı tutar.
                    <br />
                    <strong>4.4.</strong> Kullanıcı, sipariş verdiği Ürün’ün bedelini tamamen eksiksiz ve zamanında ödemekle yükümlüdür. Bu kapsamda, ödeme, BUTİKO'da yer alan ödeme sistemleri aracılığıyla gerçekleştirilir.
                    <br />
                    <strong>4.5.</strong> Kullanıcı, bir kereye mahsus olmak üzere kredi kartı bilgilerini ödeme sistemine kaydeder ve bu bilgiler daha sonraki kullanımlarda bir daha bilgi girmeyi gerektirmeden kullanılır. Kullanıcı isterse üç adete kadar farklı kartı sisteme tanıtabilir. Bu kartlar BUTİKO sisteminde değil, İyzico Ödeme Hizmetleri A.Ş. (bundan sonra "İyzico" olarak anılacaktır) sisteminde güvenli olarak saklanacaktır. İyzico hakkında detaylı bilgiye iyzico.com adresinden ulaşılabilir.
                    <br />
                    <strong>4.6.</strong> Sistemde o an kullanılan kredi kartından herhangi bir nedenle ödemenin yapılamaması halinde Kullanıcı sistemde daha önce tanımladığı başka bir kredi kartı varsa onu kullanarak veya yeni bir kredi kartı tanımlayarak tekrar ödeme yapabilir. Böyle bir durumda başka bir kartla ödeme yapma yükümlülüğü Kullanıcı'ya ait olup; ödemenin yapılmaması durumunda BUTİKO'nun sorumluluğu bulunmamaktadır.
                    <br />
                    <strong>4.7.</strong> Kullanıcı'nın kredi kartı bilgileri BUTİKO tarafından bilinmemekte, ödeme altyapısı İyzico tarafından sağlanmaktadır. İyzico vasıtasıyla gerçekleştirilecek ödemelerde Kullanıcı İyzico'nun sözleşmelerine riayet edeceğini ve İyzico ile arasındaki sözleşmenin işbu Sözleşme'den bağımsız olduğunu kabul ve taahhüt eder.
                    <br />
                    <strong>4.8.</strong> Kullanıcı, BUTİKO'ya giriş yaparken kullandığı bilgilerin (şifre ve kullanıcı adı gibi) güvenliğini sağlamaktan münhasıran sorumludur. Bu bilgilerin herhangi bir sebeple tehlikeye girmesi durumunda; Kullanıcı BUTİKO'ya bildirimde bulunarak bilgilerinin sistemlerden silinmesini talep edebilir. Aksi taktirde üçüncü şahıslar uygulamayı kullanarak sadece BUTİKO ödemesi yapmaya devam edebilirler. Böyle bir durumdan BUTİKO sorumlu tutulamaz.
                    <br />
                    <strong>4.9.</strong> Kullanıcı, BUTİKO Tedarikçi'sine ilişkin herhangi bir geri bildirim yapmak isterse; Uygulama içindeki şikayet bölümünden BUTİKO'ya bildirebilir. BUTİKO, Tedarikçi'nin şahsi davranış ve tutumlarından herhangi bir şekilde sorumlu tutulamaz. BUTİKO, Tedarikçiler ile Kullanıcıları bir araya getiren platformdur. BUTİKO, Tedarikçilere gerekli uyarıları yapar, gerekli gördüğü takdirde bu Tedarikçi'nin BUTİKO bünyesinde yer almasına son verir.
                    <br />
                    <strong>4.10.</strong> Kullanıcı, BUTİKO'nun zaman zaman promosyon kapsamında ücretsiz olarak sunduğu hizmetleri istismar edebilecek herhangi bir işlem ve/veya eylemde bulunmamayı kabul eder.
                    <br />
                    <strong>4.11.</strong> Kullanıcı, sipariş verdiği ürünün bedeli BUTİKO tarafından İyzico aracılığıyla tahsil edilmektedir.Kullanıcı geçerli bir nedeni olmak üzere siparişini iptal etmek için web sitesi veya uygulama üzerinden siparişinin iptalini talep edebilir.
                    <br />
                    <strong>4.12.</strong> Ürün kargolanmadan önce iptal edilen siparişlerin bedeli kredi kartına iade edilir. İade 2-8 iş günü içerisinde Kullanıcı'nın kredi kartına yansıtılır. Ürün teslim edildikten sonra Kullanıcının iade talep etmesine ilişkin detaylı bilgi Mesafeli Satış Sözleşmesinde yer alır.
                    <br />
                    <strong>4.13.</strong> Kullanıcı, bu Kullanım Sözleşmesini kabul etmekle birlikte; Site veya Uygulama içerisinde yer alan kullanım ve üyeliğe ilişkin olarak BUTİKO tarafından yapılacak her türlü bilgilendirme uyarıyı dikkate alacağını ve bu uyarı ve bilgilendirmelere uygun davranacağını kabul ve beyan eder.
                    <br />
                    <strong>4.14.</strong> Uygulama'ya veya Site'ye üye olabilmek için 18 yaşını doldurmuş bir gerçek kişi olmak ve Uygulama'da veya Site'de bulunan ilgili formları doldurmak gerekmektedir. BUTİKO yapacağı değerlendirme sonucu başvuru sahibini üyelik haklarından faydalandırabileceği gibi, hiçbir gerekçe göstermeksizin başvuruyu reddetme hakkına da sahiptir. İşbu Sözleşme'yi onaylamak ile Kullanıcı, 18 yaşından büyük olduğunu taahhüt etmektedir. 18 yaşını doldurmamış veya verdiği bilgilerde tutarsızlık olduğu tespit edilen Kullanıcının üyeliği iptal edilir. Kullanıcının vermiş olduğu yanlış beyandan dolayı BUTİKO'nun herhangi bir sorumluluğu bulunmamaktadır.
                    <br />
                    <strong>4.15.</strong> Kullanıcı, BUTİKO'nun yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapmakla yükümlü olduğu durumlar içerisinde, resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde üyelere ait gizli/özel/ticari bilgileri resmi makamlara açıklamaya yetkili olacağını kabul ve taahhüt eder.
                    <br />
                    <strong>4.16.</strong> Kullanıcının bu belirtilen Sözleşme'ye aykırı davrandığının tespiti halinde, BUTİKO Kullanıcının üyeliğini askıya alma veya BUTİKO'dan tamamen çıkarma hakkını saklı tutar.
                    <br />
                    <strong>4.17.</strong> BUTİKO'nun sunduğu hizmetten yararlanan Kullanıcı, Site'yi ve Uygulamayı hukuka ve amacına uygun şekilde kullanmayı ve Site ve Uygulama dahilinde yaptığı her işlem ve eylemdeki hukuki sorumluluğunun kendisine ait olduğunu kabul eder. Kullanıcının BUTİKO aracılığıyla ve/veya dahilinde, bu Sözleşme'ye ve hukuka aykırı olarak gerçekleştirdiği herhangi bir işlem, eylem ve/veya faaliyetleri nedeniyle BUTİKO'yı doğrudan veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.
                    <br />
                    <strong>4.18.</strong> BUTİKO Kullanıcı'dan kaynaklanan teknik aksaklıklar sebebiyle hizmetten faydalanılamaması durumunda işbu Sözleşme ile belirlenen yükümlülüklerinden herhangi birini ifa edememesi nedeniyle sorumlu tutulamayacak ve kendisinden herhangi bir nam altında herhangi bir talepte bulunulamayacaktır.
                    <br />
                    <strong>4.19.</strong> BUTİKO Kullanıcı’nın verdiği siparişi Tedarikçi’ye bildirmekle yükümlüdür. BUTİKO, tedarikçilerin ürünlerini listelemelerine ve satmalarına izin vermektedir. Bu durumların her birinde, bu durum, ilgili ürün ile ilgili bilgilerin bulunduğu sayfada belirtilmektedir. BUTİKO tedarikçinin ürünlerinin alıcısı veya satıcısı değildir ve tedarikçiler adına Kullanıcılardan satış bedelini tahsis etmekle yetkilendirilmiştir. Buna uygun olarak, bu üçüncü taraf ürünler için bir satışın tamamlanmasının ardından oluşturulan sözleşme, yalnızca tedarikçi ve Kullanıcı arasındadır. Tedarikçi ürünlerin satışından ve her türlü Kullanıcı hak talebi veya Kullanıcı ve tedarikçi arasındaki sözleşmeden veya bununla bağlantılı olarak doğan her türlü sorun ile ilgilenmekten kendisi sorumludur. BUTİKO sorumlu tutulamaz.
                    <br />
                    <strong>4.20.</strong> Kullanıcı, Uygulama aracılığıyla ve/veya Uygulama dahilinde, bu Sözleşme'ye ve hukuka aykırı olarak Tedarikçi ve/veya üçüncü kişiler tarafından gerçekleştirilen herhangi bir işlem, eylem ve/ veya faaliyetleri nedeniyle BUTİKO'nun doğrudan veya dolaylı olarak hiçbir şekilde sorumlu tutulamayacağını kabul eder.
                    <br />
                    <strong>4.21.</strong> Kullanıcı, BUTİKO'nun yazılı onayı olmadan bu Sözleşme veya Sözleşme kapsamındaki hak ve yükümlülüklerini veya Mesafeli Satış Sözleşmesi ile Mesafeli Satış Sözleşmesi'ndeki hak ve yükümlülüklerini kısmen veya tamamen herhangi bir üçüncü kişiye devredemez; üyeliğini kendisinden başka kişilerin kullanımına açılamaz.
                    <br />
                    <strong>4.22.</strong> BUTİKO, tedarikçilerin ürünlerini butiko.com.tr'de listelemelerine ve satmalarına izin vermektedir. Bu durumların her birinde, bu durum, ilgili ürün ile ilgili bilgilerin bulunduğu sayfada belirtilmektedir. BUTİKO tedarikçilerin sundukları Ürünlere ilişkin bir tekeffülde bulunmamaktadır ve bunları incelemek ve değerlendirmekten sorumlu değildir. BUTİKO, tüm bunların veya tedarikçinin eylemleri, ürünleri ve içerikleri için herhangi bir sorumluluk veya yükümlülük üstlenmemektedir.
                    <br />
                    <strong>4.23.</strong> Uygulama veya Site üzerindeki yönlendirmeler (link, banner) vasıtasıyla erişilen portallar, web siteleri, dosyalar ve içerikler, bu linkler vasıtasıyla erişilen portallar veya web sitelerinden sunulan hizmetler veya ürünler veya bunların içeriği hakkında BUTİKO'nun herhangi bir sorumluluğu yoktur.
                    <br />
                    <strong>4.24.</strong> BUTİKO'dan kaynaklanan ya da kaynaklanmayan herhangi bir hata, ihmal, kesinti, silinme, bozulma, iletimde gecikme veya iletişim ağı başarısızlığı gibi yazılım/ internet gibi teknik sorunlardan dolayı Uygulama/Site ve Kullanıcı arasındaki iletişimde kesintiler olabilir. Bu kesintilerden dolayı yaşanan iletişim ve Uygulama'ya/Site'ye ulaşmaya ilişkin kesintilerde Kullanıcı'nın yaşayacağı sorunlardan BUTİKO sorumlu tutulamaz.
                    <br />
                    <strong>4.25.</strong> BUTİKO'nun kullanımı için gerekli olan ağ verisini elde etmekten Kullanıcı sorumludur. BUTİKO'nun kullanımı için gerekli olan uyumlu donanım, aygıt veya güncellemelerin elde edilmesi Kullanıcının sorumluluğundadır. BUTİKO, Uygulama'nın veya Site'nin herhangi bir cihazda veya donanımda eksiksiz olarak işlev göreceğini garanti etmemektedir.
                    <br />
                    <strong>4.26.</strong> Kullanıcı, BUTİKO üyeliğini istediği anda sebep göstermeksizin, aşağıdaki yollarla iptal edebilir; info@butiko.com.tr adresine talebi belirten e-posta gönderebilir veya BUTİKO web sitesi veya mobil uygulaması üzerinden hesabını silebilir.
                </p>
                <h3>5. FİKRİ MÜLKİYET HAKLARI</h3>
                <p className="lh-base">
                    <strong>5.1.</strong> BUTİKO'nun; hizmetleri, fikri mülkiyet hakları, BUTİKO ticari markaları, BUTİKO ticari görünümü veya BUTİKO'nun tasarım, metin, imge, html kodu ve diğer kodlar ile veri tabanı da dahil fakat bunlarla sınırlı olmamak kaydıyla, tüm unsurları, her türlü ayni ve şahsi hakları, ticari bilgi ve know-how'a yönelik tüm hakları münhasıran BUTİKO'ya aittir.
                    <br />
                    <strong>5.2.</strong> Kullanıcı, fikri mülkiyet hukuku dahilinde BUTİKO içerisinde yer alan BUTİKO ve/veya başka 3. kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri dahil ancak bununla sınırlı olmamak üzere, BUTİKO'ya ait herhangi bir içeriği ve/veya ürün/yazılımı satamaz, otomatik program, robot gibi veri kazıma (data scraping) yazılımları veya sistemleri vasıtasıyla veya herhangi bir yöntemle kopyalayamaz, yayınlayamaz kullanarak türevlerini oluşturamaz, alt lisans veremez, başkasına devredemez, adi veya ticari şekilde 3. kişilere doğrudan veya dolaylı olarak kiralayamaz, ödünç veremez, izinsiz çoğaltamaz, elektronik veya optik olarak transfer edemez, herhangi bir ortama aktaramaz, kaynak kod halinde dönüştüremez, tersine mühendislik yapamaz, değiştiremez, söz konusu ürün/yazılımlardan esinlenerek başka programlar yapamaz ve 3. kişilerin de bunları yapmasına izin veremez. İşbu maddeye aykırılık halinde BUTİKO nezdinde doğan her türlü zarardan münhasıran Kullanıcı sorumlu olacaktır.
                </p>

                <h3>6. KİŞİSEL VERİLERİN KORUNMASI</h3>
                <p className="lh-base">
                    <strong>6.1.</strong> Kullanıcı, kendisine veya 3. bir kişiye ait veri tabanı, kayıt veya rehber yaratmak, kontrol etmek, güncellemek, değiştirmek amaçlarıyla BUTİKO'da yer alan kişisel verilere erişmeyeceğini ve kendi sistemlerine indirmeyeceğini kabul, beyan ve taahhüt eder.
                    <br />
                    <strong>6.2.</strong> Kullanıcı BUTİKO'ya üye olurken işbu Koşulları onaylamak ve üyelik sonrası BUTİKO içerisindeki hareketleri esnasında, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("Kanun") uyarınca veri sorumlusu olan BUTİKO ile paylaştıkları ad, soyad, elektronik posta adresi, Randevu adres/adresleri, telefon numarası başta olmak üzere kendisine sunulan aydınlatma metinlerinde yer verilen kişisel verilerinin ("Kişisel Veri/ler") Kanun'da belirlenmiş olan esaslar çerçevesinde ve uygun güvenlik düzeyinin temin edilmesine yönelik her türlü teknik ve idari tedbirin alınması suretiyle işleneceğini ve 3. kişilere aktarılabileceğini bildiğini, BUTİKO'ya üye olduğu esnada Kişisel Verileri'nin işlenmesine ilişkin detayları içeren aydınlatma metninin kendisine sunulduğunu ve kendisine sunulan aydınlatma metni ile BUTİKO Gizlilik Politikası'na BUTİKO üzerinden her zaman ulaşabileceğini ve hatta bu hususta BUTİKO'ya usulüne uygun bir başvuru yapması halinde kendisine BUTİKO tarafından gerekli desteğin sağlanacağını bildiğini beyan ve taahhüt eder.
                    <br />
                    <strong>6.3.</strong> BUTİKO, işbu Koşullar sebebiyle vakıf olduğu Kişisel Verileri, Kanun'da sayılan hallerde Kullanıcı'nın açık rızasından bağımsız olarak ve gerektiğinde Kullanıcı'nın açık rızasını almak suretiyle işleyebilecek, BUTİKO'nun sözleşmesel ilişkisi bulunan iş ortaklarına ve tedarikçilerine aktarabilecek ve BUTİKO'nun yurt dışında bulunan sunucularında muhafaza edebilecektir.
                    <br />
                    <strong>6.4.</strong> Kullanıcı'ya ait Kişisel Veriler, Kanun'a uygun şekilde maskelenmek veya anonimleştirilmek suretiyle araştırma, planlama ve istatistik amaçlarıyla her zaman kullanılabilir.
                    <br />
                    <strong>6.5.</strong> Kullanıcı, Kanun'un 11. maddesinde sayılan haklarına yönelik olarak BUTİKO'ya her zaman başvuruda bulunabilir. BUTİKO'ya yapılacak başvurular;
                    <br />
                    (i) Kullanıcı'nın kimliğini tevsik eden belgeler ile birlikte Uygulama üzerinden ulaşabileceği <em>“Veri Sorumlusuna Başvuru Formu”nu</em> doldurması suretiyle yazılı olarak <em>"Küçükbakkalköy Mah. Selvi̇li̇ Sk. Canan Business Plaza. No: 4/20 Ataşehi̇r/İstanbul"</em> adresinde bulunan şirket merkezine elden teslim etmesi veya kargo ile göndermesi suretiyle,
                    <br />
                    (ii) Kullanıcı’nın BUTİKO'ya ait sistemlerde kayıtlı e-posta adresi üzerinden, <em>“Veri Sorumlusuna Başvuru Formu”nu</em> doldurup <a href="mailto:info@butiko.com.tr">info@butiko.com.tr</a> adresine göndermesi suretiyle,
                    <br />
                    (iii) Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ'de öngörülen sair yöntemleri kullanması suretiyle BUTİKO'ya iletebilir.
                    <br />
                    <strong>6.6.</strong> Kullanıcı, BUTİKO üzerinde herhangi bir siber saldırı yahut yazılımsal hata dahil fakat bunlarla sınırlı olmamak üzere sebeplerle, erişmemesi gerekmesine karşın 3. kişilerin kişisel verilerine erişebilir duruma geldiği takdirde bu durumu gecikmeksizin BUTİKO'ya bildirir.
                    <br />
                    <strong>6.7.</strong> Koşullar'ın işbu maddesi, BUTİKO ile Kullanıcı arasında akdedilen sözleşmelerin herhangi bir sebeple sona ermesinden sonra da süresiz olarak yürürlükte kalmaya devam eder.
                </p>

                <h3>7. GİZLİLİK</h3>
                <p className="lh-base">
                    Kullanıcı, fikri mülkiyet hukuku dahilinde BUTİKO içerisinde yer alan BUTİKO ve/veya başka 3 kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri dahil ancak bununla sınırlı olmamak üzere, BUTİKO'ya ait herhangi bir içeriği ve/veya ürün/yazılımı satamaz, otomatik program, robot gibi veri kazıma (data scraping) yazılımları veya sistemleri vasıtasıyla veya herhangi bir yöntemle kopyalayamaz, yayınlayamaz kullanarak türevlerini oluşturamaz, alt lisans veremez, başkasına devredemez, adi veya ticari şekilde 3. kişilere doğrudan veya dolaylı olarak kiralayamaz, ödünç veremez, izinsiz çoğaltamaz, elektronik veya optik olarak transfer edemez, herhangi bir ortama aktaramaz, kaynak kod halinde dönüştüremez, tersine mühendislik yapamaz, değiştiremez, söz konusu ürün/yazılımlardan esinlenerek başka programlar yapamaz ve 3. kişilerin de bunları yapmasına izin veremez. İşbu maddeye aykırılık halinde BUTİKO nezdinde doğan her türlü zarardan münhasıran Kullanıcı sorumlu olacaktır.
                </p>
                <h3>5. FİKRİ MÜLKİYET HAKLARI</h3>
                <p className="lh-base">
                    <strong>5.1.</strong> BUTİKO'nun; hizmetleri, fikri mülkiyet hakları, BUTİKO ticari markaları, BUTİKO ticari görünümü veya BUTİKO'nun tasarım, metin, imge, html kodu ve diğer kodlar ile veri tabanı da dahil fakat bunlarla sınırlı olmamak kaydıyla, tüm unsurları, her türlü ayni ve şahsi hakları, ticari bilgi ve know-how'a yönelik tüm hakları münhasıran BUTİKO'ya aittir.
                    <br />
                    <strong>5.2.</strong> Kullanıcı, fikri mülkiyet hukuku dahilinde BUTİKO içerisinde yer alan BUTİKO ve/veya başka 3. kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri dahil ancak bununla sınırlı olmamak üzere, BUTİKO'ya ait herhangi bir içeriği ve/veya ürün/yazılımı satamaz, otomatik program, robot gibi veri kazıma (data scraping) yazılımları veya sistemleri vasıtasıyla veya herhangi bir yöntemle kopyalayamaz, yayınlayamaz kullanarak türevlerini oluşturamaz, alt lisans veremez, başkasına devredemez, adi veya ticari şekilde 3. kişilere doğrudan veya dolaylı olarak kiralayamaz, ödünç veremez, izinsiz çoğaltamaz, elektronik veya optik olarak transfer edemez, herhangi bir ortama aktaramaz, kaynak kod halinde dönüştüremez, tersine mühendislik yapamaz, değiştiremez, söz konusu ürün/yazılımlardan esinlenerek başka programlar yapamaz ve 3. kişilerin de bunları yapmasına izin veremez. İşbu maddeye aykırılık halinde BUTİKO nezdinde doğan her türlü zarardan münhasıran Kullanıcı sorumlu olacaktır.
                </p>

                <h3>6. KİŞİSEL VERİLERİN KORUNMASI</h3>
                <p className="lh-base">
                    <strong>6.1.</strong> Kullanıcı, kendisine veya 3. bir kişiye ait veri tabanı, kayıt veya rehber yaratmak, kontrol etmek, güncellemek, değiştirmek amaçlarıyla BUTİKO'da yer alan kişisel verilere erişmeyeceğini ve kendi sistemlerine indirmeyeceğini kabul, beyan ve taahhüt eder.
                    <br />
                    <strong>6.2.</strong> Kullanıcı BUTİKO'ya üye olurken işbu Koşulları onaylamak ve üyelik sonrası BUTİKO içerisindeki hareketleri esnasında, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("Kanun") uyarınca veri sorumlusu olan BUTİKO ile paylaştıkları ad, soyad, elektronik posta adresi, Randevu adres/adresleri, telefon numarası başta olmak üzere kendisine sunulan aydınlatma metinlerinde yer verilen kişisel verilerinin ("Kişisel Veri/ler") Kanun'da belirlenmiş olan esaslar çerçevesinde ve uygun güvenlik düzeyinin temin edilmesine yönelik her türlü teknik ve idari tedbirin alınması suretiyle işleneceğini ve 3. kişilere aktarılabileceğini bildiğini, BUTİKO'ya üye olduğu esnada Kişisel Verileri'nin işlenmesine ilişkin detayları içeren aydınlatma metninin kendisine sunulduğunu ve kendisine sunulan aydınlatma metni ile BUTİKO Gizlilik Politikası'na BUTİKO üzerinden her zaman ulaşabileceğini ve hatta bu hususta BUTİKO'ya usulüne uygun bir başvuru yapması halinde kendisine BUTİKO tarafından gerekli desteğin sağlanacağını bildiğini beyan ve taahhüt eder.
                    <br />
                    <strong>6.3.</strong> BUTİKO, işbu Koşullar sebebiyle vakıf olduğu Kişisel Verileri, Kanun'da sayılan hallerde Kullanıcı'nın açık rızasından bağımsız olarak ve gerektiğinde Kullanıcı'nın açık rızasını almak suretiyle işleyebilecek, BUTİKO'nun sözleşmesel ilişkisi bulunan iş ortaklarına ve tedarikçilerine aktarabilecek ve BUTİKO'nun yurt dışında bulunan sunucularında muhafaza edebilecektir.
                    <br />
                    <strong>6.4.</strong> Kullanıcı'ya ait Kişisel Veriler, Kanun'a uygun şekilde maskelenmek veya anonimleştirilmek suretiyle araştırma, planlama ve istatistik amaçlarıyla her zaman kullanılabilir.
                    <br />
                    <strong>6.5.</strong> Kullanıcı, Kanun'un 11. maddesinde sayılan haklarına yönelik olarak BUTİKO'ya her zaman başvuruda bulunabilir. BUTİKO'ya yapılacak başvurular;
                    <br />
                    (i) Kullanıcı'nın kimliğini tevsik eden belgeler ile birlikte Uygulama üzerinden ulaşabileceği <em>“Veri Sorumlusuna Başvuru Formu”nu</em> doldurması suretiyle yazılı olarak <em>"Küçükbakkalköy Mah. Selvi̇li̇ Sk. Canan Business Plaza. No: 4/20 Ataşehi̇r/İstanbul"</em> adresinde bulunan şirket merkezine elden teslim etmesi veya kargo ile göndermesi suretiyle,
                    <br />
                    (ii) Kullanıcı’nın BUTİKO'ya ait sistemlerde kayıtlı e-posta adresi üzerinden, <em>“Veri Sorumlusuna Başvuru Formu”nu</em> doldurup <a href="mailto:info@butiko.com.tr">info@butiko.com.tr</a> adresine göndermesi suretiyle,
                    <br />
                    (iii) Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ'de öngörülen sair yöntemleri kullanması suretiyle BUTİKO'ya iletebilir.
                    <br />
                    <strong>6.6.</strong> Kullanıcı, BUTİKO üzerinde herhangi bir siber saldırı yahut yazılımsal hata dahil fakat bunlarla sınırlı olmamak üzere sebeplerle, erişmemesi gerekmesine karşın 3. kişilerin kişisel verilerine erişebilir duruma geldiği takdirde bu durumu gecikmeksizin BUTİKO'ya bildirir.
                    <br />
                    <strong>6.7.</strong> Koşullar'ın işbu maddesi, BUTİKO ile Kullanıcı arasında akdedilen sözleşmelerin herhangi bir sebeple sona ermesinden sonra da süresiz olarak yürürlükte kalmaya devam eder.
                </p>

                <h3>7. GİZLİLİK</h3>
                <p className="lh-base">
                    Kullanıcı, fikri mülkiyet hukuku dahilinde BUTİKO içerisinde yer alan BUTİKO ve/veya başka 3 kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri dahil ancak bununla sınırlı olmamak üzere, BUTİKO'ya ait herhangi bir içeriği ve/veya ürün/yazılımı satamaz, otomatik program, robot gibi veri kazıma (data scraping) yazılımları veya sistemleri vasıtasıyla veya herhangi bir yöntemle kopyalayamaz, yayınlayamaz kullanarak türevlerini oluşturamaz, alt lisans veremez, başkasına devredemez, adi veya ticari şekilde 3. kişilere doğrudan veya dolaylı olarak kiralayamaz, ödünç veremez, izinsiz çoğaltamaz, elektronik veya optik olarak transfer edemez, herhangi bir ortama aktaramaz, kaynak kod halinde dönüştüremez, tersine mühendislik yapamaz, değiştiremez, söz konusu ürün/yazılımlardan esinlenerek başka programlar yapamaz ve 3. kişilerin de bunları yapmasına izin veremez. İşbu maddeye aykırılık halinde BUTİKO nezdinde doğan her türlü zarardan münhasıran Kullanıcı sorumlu olacaktır.
                </p>

                <h3>8. MÜCBİR SEBEPLER</h3>
                <p className="lh-base">
                    İşbu Koşullar kapsamında mücbir sebep (ulaşımı engelleyen hava muhalefetleri, ulaşım kesintisi, elektrik kesintisi, altyapı ve internet arızaları, iletişim sorunları, doğal afetler, isyan, yaygın şiddet hareketleri, savaş, grev, salgın hastalık gibi olağanüstü olaylar veya resmi makamların düzenlemeleri dahil ancak bunlarla sınırlı olmaksızın) sayılan ve BUTİKO'nun faaliyet ve operasyonlarını etkileyen tüm durumlarda BUTİKO, işbu Koşullar ile belirlenen edimlerden herhangi birini geç veya eksik ifa etme yahut ifa edememe nedeniyle sorumlu tutulamaz. Yukarıda belirtilen mücbir sebep halleri nedeniyle Kullanıcı, BUTİKO'dan herhangi bir nam ve isim altında tazminat talep edemez.
                </p>

                <h3>9. DEĞİŞİKLİKLER</h3>
                <p className="lh-base">
                    BUTİKO, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu Koşulları, uygun göreceği herhangi bir zamanda, BUTİKO üzerinden ilan ederek değiştirebilir.
                </p>

                <h3>10. SÜRE VE SONA ERME</h3>
                <p className="lh-base">
                    <strong>10.1.</strong> Kullanıcı, BUTİKO'yu kullanmayı dilediği zaman sonlandırma hakkına sahiptir. İşbu Koşullar, BUTİKO'nun haklı sebeple Kullanıcı'nın üyeliğine son verme hakkı saklı kalmak kaydıyla, Kullanıcı'nın BUTİKO üzerindeki üyeliği sona erinceye kadar yürürlükte kalacak ve Taraflar arasında hüküm ve sonuçlarını doğurmaya devam edecektir.
                    <br />
                    <strong>10.2.</strong> BUTİKO, Kullanıcı'nın işbu Koşullar dahilinde belirtilen hükümlere ve BUTİKO içinde yer alan kullanıma, üyeliğe ve BUTİKO hizmetlerine ilişkin benzeri kurallara aykırı hareket etmesi durumunda yahut BUTİKO tarafından işbu Koşullar üzerinde yapılan değişikliklerin Kullanıcı tarafından kabul edilmemesi durumunda; Koşulları tek taraflı olarak ve haklı sebeple feshedebilecektir.
                    <br />
                    <strong>10.3.</strong> Taraflar arasında akdedilen sözleşmelerin herhangi bir sebeple feshi durumunda, işbu Koşullar'daki kişisel verilerin korunması, gizlilik ve fikri mülkiyet hükümleri süresiz olarak yürürlükte kalmaya devam edecektir.
                </p>

                <h3>11. DEVİR YASAĞI</h3>
                <p className="lh-base">
                    Kullanıcı, işbu Koşullar'dan doğan haklarını, menfaatlerini veya yükümlülüklerini BUTİKO'nun yazılı izni olmaksızın, hiçbir şekilde 3. kişilere devir veya temlik edemez. BUTİKO'nun yazılı izni olmaksızın yapılan temlik veya devir işlemi geçersiz olacaktır.
                </p>

                <h3>12. UYGULANACAK HUKUK VE YETKİLİ MAHKEME</h3>
                <p className="lh-base">
                    <strong>12.1.</strong> Kullanıcı, BUTİKO'yu kullanmayı dilediği zaman sonlandırma hakkına sahiptir. İşbu Koşullar, BUTİKO'nun haklı sebeple Kullanıcı'nın üyeliğine son verme hakkı saklı kalmak kaydıyla, Kullanıcı'nın BUTİKO üzerindeki üyeliği sona erinceye kadar yürürlükte kalacak ve Taraflar arasında hüküm ve sonuçlarını doğurmaya devam edecektir.
                    <br />
                    <strong>12.2.</strong> Koşullar'dan doğan ihtilaflarda mevzuat çerçevesinde belirlenen parasal sınırlar dahilinde Kullanıcı ve BUTİKO'nun kayıtlı adresinin bulunduğu yerdeki tüketici hakem heyetlerinin, parasal sınırları aşan uyuşmazlıklarda Kullanıcı'nın ve BUTİKO'nun bulunduğu yerdeki tüketici mahkemelerinin yetkili olacağını kabul etmiştir. Uyuşmazlığın tüketici uyuşmazlığı olmaması halinde, İstanbul Anadolu Mahkemeleri ve İcra Daireleri yetkili olacaktır.
                </p>

                <h3>13. KAYITLARIN GEÇERLİLİĞİ</h3>
                <p className="lh-base">
                    Taraflar, işbu Koşullar'dan doğabilecek ihtilaflarda BUTİKO'nun resmi defter, ticari defter ve kayıtlarıyla, veri tabanında, sunucularında tuttuğu elektronik bilgilerin ve bilgisayar kayıtlarının ve elektronik ortamdaki yazışmaların bağlayıcı, kesin ve münhasır delil teşkil edeceğini ve bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.
                </p>

                <h3>14. BÜTÜNLÜK</h3>
                <p className="lh-base">
                    Koşulların herhangi bir hükmünün veya Koşullar'da yer alan herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve uygulanamaz olması, Koşulların geri kalan hükümlerinin yürüdüğünü ve geçerliliğini etkilemeyecektir.
                </p>

                <h3>15. YÜRÜRLÜK</h3>
                <p className="lh-base">
                    Kullanıcı, BUTİKO'yu cihazına yüklemesi, üyelik oluşturması ve kullanması esnasında işbu Koşullar'a tabi olacağını kabul ve taahhüt eder.
                </p>

                <h3>DEĞİŞİKLİK</h3>
                <p className="lh-base">
                    <strong>i. Değişiklik 1:</strong> Evrakta henüz değişiklik yapılmamıştır.
                    <br />
                    <strong>Değişiklik Detayı:</strong>
                </p>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Kapat</Button>
            </Modal.Footer>
        </Modal>
    );
}

function KullanimSozlesmesi({ modalShow, setModalShow }) {


    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default KullanimSozlesmesi;
