import React from 'react'

const Loading1 = () => {
    return (
        <React.Fragment>
            <svg width="38" height="358" viewBox="0 0 38 358" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect></rect>
                <rect x="23" width="358" height="7.99998" rx="3.99999" transform="rotate(90 23 0)" fill="#E4E4E4" />
                <rect x="23" width="69" height="8" rx="4" transform="rotate(90 23 0)" fill="#34C38F" />
                <circle cx="19" cy="63" r="19" transform="rotate(90 19 63)" fill="#34C38F" />
                <circle cx="19" cy="246" r="19" transform="rotate(90 19 246)" fill="#B5B5B5" />
                <text x="16" y="68" fontSize="18" fill="white">1</text>
                <text x="13" y="251" fontSize="18" fill="white">2</text>
            </svg>
        </React.Fragment>
    )
}

export default Loading1