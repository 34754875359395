import React from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import TokenCount from "components/CommonForBoth/TopbarDropdown/TokenCount";
import { Skeleton } from "antd";

const TokensAndTransactionsCard = ({
    transactionsLoading,
    transactionsError,
    transactionsData
}) => {
    const renderTransactions = () => {
        if (transactionsLoading) {
            return (
                <span>
                    <Skeleton active />
                </span>
            );
        }

        if (transactionsData?.length === 0) {
            return <span>Yapmış olduğunuz işlem yok.</span>;
        } else if (!transactionsData || transactionsError) {
            return <span>Bir Hata Oluştu.</span>;
        }

        // En son yapılan işlemin üstte olması için transactionsData dizisini tersine çeviriyoruz
        const sortedTransactionsData = transactionsData.slice().reverse();

        return (
            <Table responsive cellPadding={20} bordered>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: "white" }}>Miktar</th>
                        <th style={{ backgroundColor: "white" }}>İşlem</th>
                        <th style={{ backgroundColor: "white" }}>Fiyat</th>
                        <th style={{ backgroundColor: "white" }}>Tarih</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTransactionsData.map((transaction, transactionIndex) => (
                        <tr key={transactionIndex}>
                            <td
                                style={{
                                    color: transaction.action === "token_purchase"
                                        ? "#0DA52E"
                                        : transaction.action === "promotion"
                                            ? "#EB4375"
                                            : "red",
                                    backgroundColor: "white",
                                    textAlign: "center"
                                }}
                            >
                                {transaction.amount ? `${transaction.amount} TKN` : "0 TKN"}
                            </td>
                            <td style={{ backgroundColor: "white" }}>{transaction.action === "promotion" ? "Promosyon" : "Token"}</td>
                            <td style={{ backgroundColor: "white", textAlign: "center" }}>{transaction.price ? `${transaction.price} TL` : "-"}</td>
                            <td style={{ backgroundColor: "white" }}>
                                {new Date(transaction.date).toLocaleString("en-GB")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <Card className="p-3 px-5">
            <CardBody>
                <Row>
                    <Col lg="6" xs="12">
                        <h6 className="my-1" style={{ fontSize: "19px", fontWeight: "600" }}>
                            Geçmiş
                        </h6>
                    </Col>
                    <Col lg="6" xs="12">
                        <div className="d-flex justify-content-end">
                            <TokenCount />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: "50px" }}>
                    {/* <h6 style={{ fontSize: "11px", fontWeight: "600" }}>Geçmiş</h6> */}
                    <div style={{ overflowY: "auto", maxHeight: "662px" }}>
                        {renderTransactions()}
                    </div>
                </Row>
            </CardBody>
        </Card>
    );
};

export default TokensAndTransactionsCard;
