import React from "react";
import { Container, Input } from "reactstrap";
import { Col, Row } from "reactstrap";

import ListGroup from "react-bootstrap/ListGroup";

//Import Breadcrumb
import {  useState } from "react";
import "../AboutStore.css";

import DatePicker from "react-datepicker";
import "../AboutStore.css"
import "react-datepicker/dist/react-datepicker.css"
import { FaClock } from "react-icons/fa";
function getBrowserInfo() {
  const userAgent = navigator.userAgent;
  let browser;

  // Check for popular browsers
  if (/firefox/i.test(userAgent)) {
    browser = "Firefox";
  } else if (/chrome/i.test(userAgent)) {
    browser = "Chrome";
  } else if (/safari/i.test(userAgent)) {
    browser = "Safari";
  } else if (/edge/i.test(userAgent)) {
    browser = "Edge";
  } else if (/trident/i.test(userAgent)) {
    browser = "Internet Explorer";
  } else {
    browser = "Unknown";
  }

  return browser;
}
const EditOpenColseTime = (props) => {


  const [wednesday, setWednesday] = useState(props.wednesday)
  const [thursday, setThursday] = useState(props.thursday)
  const [saturday, setSaturday] = useState(props.saturday)
  const [tuesday, setTuesday] = useState(props.tuesday)
  const [sunday, setSunday] = useState(props.sunday)
  const [friday, setFriday] = useState(props.friday)
  const [monday, setMonday] = useState(props.monday)
  const [startDate, setStartDate] = useState(new Date());
  const inputFieldStyle = {
    borderRadius: "4px",
    border: "1px solid #CED4DA",
    background: "#F3F3F9",
    height: "36px",
  };
  const greyBackG = {
    backgroundColor: "#A6A6A6",
    border: "none"
  }

  const userBrowser = getBrowserInfo();
  console.log("User Browser:", userBrowser);
  
  return (
    <>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className=" d-flex justify-content-center align-items-center">
              <Col className=" d-flex  align-items-center" xl="4" md="12" xs="4">
                <div className="form-check form-switch ">
                  <Input
                    type="checkbox"
                    className="form-check-input "
                    id="mondaySwitch"
                    style={monday?.is_enabled === false ? greyBackG : null}
                    checked={monday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      
                      const newState = { ...monday }
                      newState.is_enabled = !monday.is_enabled
                      setMonday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="mondaySwitch">
                    Pazartesi
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">

                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={monday?.start_date}
                          // defaultValue="00:00"
                          id="mondayOpen"
                          onChange={(e) => {
                            const newState = { ...monday }
                            newState.start_date = e.target.value
                            setMonday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...monday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setMonday(newState)
                        
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="mondayOpen"
                      className="form-control-sm time-picker-style"
                      value={monday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>

              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={monday?.end_date}
                          id="mondayClose"
                          onChange={(e) => {
                            const newState = { ...monday }
                            newState.end_date = e.target.value
                            setMonday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...monday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setMonday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="mondayClose"
                      className="form-control-sm time-picker-style"
                      value={monday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className=" justify-content-between  align-items-center">
              <Col xl="4" md="12" xs="4">
                <div className="form-check form-switch ">
                  <Input
                    type="checkbox"
                    style={tuesday?.is_enabled === false ? greyBackG : null}
                    className="form-check-input"
                    id="tuesdaySwitch"
                    checked={tuesday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      const newState = { ...tuesday }
                      newState.is_enabled = !tuesday.is_enabled
                      setTuesday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="tuesdaySwitch">
                    Salı
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={tuesday?.start_date}
                          id="tuesdayOpen"
                          onChange={(e) => {
                            const newState = { ...tuesday }
                            newState.start_date = e.target.value
                            setTuesday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...tuesday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setTuesday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="tuesdayOpen"
                      className="form-control-sm time-picker-style"
                      value={tuesday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>

              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={tuesday?.end_date}
                          id="tuesdayClose"
                          onChange={(e) => {
                            const newState = { ...tuesday }
                            newState.end_date = e.target.value
                            setTuesday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...tuesday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setTuesday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="tuesdayClose"
                      className="form-control-sm time-picker-style"
                      value={tuesday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className=" justify-content-between  align-items-center">
              <Col xl="4" md="12" xs="4">
                <div className="form-check form-switch ">
                  <input
                    type="checkbox"
                    style={wednesday?.is_enabled === false ? greyBackG : null}
                    className="form-check-input"
                    id="wednesdaySwitch"
                    checked={wednesday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      const newState = { ...wednesday }
                      newState.is_enabled = !wednesday.is_enabled
                      setWednesday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="wednesdaySwitch">
                    Çarşamba
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={wednesday?.start_date}
                          id="wednesdayOpen"
                          onChange={(e) => {
                            const newState = { ...wednesday }
                            newState.start_date = e.target.value
                            setWednesday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...wednesday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setWednesday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="wednesdayOpen"
                      className="form-control-sm time-picker-style"
                      value={wednesday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>

              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={wednesday?.end_date}
                          
                          id="wednesdayClose"
                          onChange={(e) => {
                            const newState = { ...wednesday }
                            newState.end_date = e.target.value
                            setWednesday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...wednesday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setWednesday(newState)
                        
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="wednesdayClose"
                      className="form-control-sm time-picker-style"
                      value={wednesday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className=" justify-content-between  align-items-center">
              <Col xl="4" md="12" xs="4">
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={thursday?.is_enabled === false ? greyBackG : null}
                    id="thursdaySwitch"
                    checked={thursday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      const newState = { ...thursday }
                      newState.is_enabled = !thursday.is_enabled
                      setThursday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="thursdaySwitch">
                    Perşembe
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={thursday?.start_date}
                          
                          id="thursdayOpen"
                          onChange={(e) => {
                            const newState = { ...thursday }
                            newState.start_date = e.target.value
                            setThursday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...thursday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setThursday(newState)
                        
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="thursdayOpen"
                      className="form-control-sm time-picker-style"
                      value={thursday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>

              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={thursday?.end_date}
                          // defaultValue="00:00"
                          id="thursdayClose"
                          onChange={(e) => {
                            const newState = { ...thursday }
                            newState.end_date = e.target.value
                            setThursday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...thursday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setThursday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="thursdayClose"
                      className="form-control-sm time-picker-style"
                      value={thursday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className=" justify-content-between  align-items-center">
              <Col xl="4" md="12" xs="4">
                <div className="form-check form-switch ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={friday?.is_enabled === false ? greyBackG : null}
                    id="fridaySwitch"
                    checked={friday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      const newState = { ...friday }
                      newState.is_enabled = !friday.is_enabled
                      setFriday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="fridaySwitch">
                    Cuma
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={friday?.start_date}
                          id="fridayOpen"
                          onChange={(e) => {
                            const newState = { ...friday }
                            newState.start_date = e.target.value
                            setFriday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...friday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setFriday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="fridayOpen"
                      className="form-control-sm time-picker-style"
                      value={friday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>

              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={friday?.end_date}
                          id="fridayClose"
                          onChange={(e) => {
                            const newState = { ...friday }
                            newState.end_date = e.target.value
                            setFriday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...friday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setFriday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="fridayClose"
                      className="form-control-sm time-picker-style"
                      value={friday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className="justify-content-between  align-items-center">
              <Col xl="4" md="12" xs="4" >
                <div className="form-check form-switch ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={saturday?.is_enabled === false ? greyBackG : null}
                    id="saturdaySwitch"
                    checked={saturday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      const newState = { ...saturday }
                      newState.is_enabled = !saturday.is_enabled
                      setSaturday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="saturdaySwitch">
                    Cumartesi
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                 
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={saturday?.start_date}
                          // defaultValue="00:00"
                          id="saturdayOpen"
                          onChange={(e) => {
                            const newState = { ...saturday }
                            newState.start_date = e.target.value
                            setSaturday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...saturday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setSaturday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="saturdayOpen"
                      className="form-control-sm time-picker-style"
                      value={saturday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={saturday?.end_date}
                          id="saturdayClose"
                          onChange={(e) => {
                            const newState = { ...saturday }
                            newState.end_date = e.target.value
                            setSaturday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...saturday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setSaturday(newState)
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="saturdayClose"
                      className="form-control-sm time-picker-style"
                      value={saturday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
      <ListGroup.Item>
        <div className="item-content">
          <Container>
            <Row className="justify-content-between  align-items-center">
              <Col xl="4" md="12" xs="4">
                <div className="form-check form-switch ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={sunday?.is_enabled === false ? greyBackG : null}
                    id="sundaySwitch"
                    checked={sunday?.is_enabled}
                    readOnly
                    onClick={(e) => {
                      const newState = { ...sunday }
                      newState.is_enabled = !sunday.is_enabled
                      setSunday(newState)
                    }}
                  />
                  <label className="form-check-label" htmlFor="sundaySwitch">
                    Pazar
                  </label>
                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  {userBrowser != "Safari" ? (
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={sunday?.start_date?.replace('.', ':')}
                          id="sundayOpen"
                          onChange={(e) => {
                            const newState = { ...sunday }
                            newState.start_date = e.target.value
                            setSunday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...sunday }
                        newState.start_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setSunday(newState)
                        console.log(sunday);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="sundayOpen"
                      className="form-control-sm time-picker-style"
                      value={sunday?.start_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}

                </div>
              </Col>
              <Col xl="4" md="12" xs="4">
                <div className="">
                  {userBrowser != "Safari" ? (

                    
                    <>
                      <div style={{ position: 'relative', width: "100%", display: 'inline-block' }}>
                        <Input
                          className="form-control-sm"
                          style={inputFieldStyle}
                          type="time"
                          value={sunday?.end_date}
                          // defaultValue="00:00"
                          id="sundayClose"
                          onChange={(e) => {
                            const newState = { ...sunday }
                            newState.end_date = e.target.value
                            setSunday(newState)
                          }}
                        />
                        <FaClock style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }} />
                      </div>
                    </>
                  ) : (

                    <DatePicker
                      selected={startDate}
                      onChange={(e) => {
                        const newState = { ...sunday }
                        newState.end_date = e.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
                        setSunday(newState)
                        console.log(sunday);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      portalId="my-popper"
                      id="sundayClose"
                      className="form-control-sm time-picker-style"
                      value={sunday?.end_date}
                      icon={<FaClock style={{ position: "absolute", right: "0px", marginTop: "3px" }} />}
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ListGroup.Item>
    </>
  );
};
export default EditOpenColseTime;