import { useMutation } from "@tanstack/react-query";
import { Input } from "antd";
import axios from "axios";
import React, { useState, useRef } from "react"
import { toast } from "react-toastify";
import {
    Button,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"

const OrderDetailReturnedPopup = ({ modelIsOpen, setModelIsOpen, orderItemToCancel, reFetch }) => {
    const [cancelTitle, setCancelTitle] = useState("");
    const [cancelDescription, setCancelDescription] = useState("");
    const formRef = useRef(null)
    const { TextArea } = Input;
    const toggleModal = () => {
        setModelIsOpen(!modelIsOpen)
    }
    // console.log(orderItemToCancel);

    const cancelReturn = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/cancel-return`
        const finalData = {
            order_item_id: orderItemToCancel.order_item_id,
            cancel_title: cancelTitle,
            cancel_description: cancelDescription
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const cancelReturnMutation = useMutation({
        mutationFn: cancelReturn,
        onSuccess: (data) => {
            // console.log(data);
            setCancelDescription("")
            setCancelTitle("")
            toast.success("İade reddedildi")
            toggleModal()
            reFetch()
        },
        onError: (error) => {
            if (error?.response?.data?.detail[0]?.msg == "String should have at most 250 characters") {
                toast.error("Dize en fazla 250 karakterden oluşmalıdır")
            } else {
                toast.error("Böyle bir sipariş yok")
            }
        }
    })

    return (
        <Modal
            style={{
                maxWidth: "1000px",
                maxHeight: "1000px",
                marginTop: "80px",
            }}
            isOpen={modelIsOpen}
            toggle={toggleModal}
        >
            <ModalHeader toggle={toggleModal}>
                İade reddi formu{" "}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={6}>
                        <h4 className="ms-3 fw-bold">
                            {orderItemToCancel.product_name}
                        </h4>

                        <div className="ms-3 mt-3 d-flex align-items-center">
                            <h6 className="text-secondary fw-semibold me-3 col-6 ">Beden:</h6>
                            <p className="mb-0 text-secondary col-6 ">{orderItemToCancel.size}</p>
                        </div>

                        <div className="ms-3 mt-3 d-flex align-items-center">
                            <h6 className="text-secondary fw-semibold me-3 col-6 ">İade Tarihi:</h6>
                            <p className="mb-0 text-secondary col-6 ">{orderItemToCancel.date}</p>
                        </div>

                        <div className="ms-3 mt-3 d-flex align-items-start">
                            <h6 className="text-secondary fw-semibold me-3 col-6 ">İade nedeni:</h6>
                            <p className="mb-0 text-secondary col-6 " style={{ maxHeight: "150px", overflowY: "auto" }}>
                                {orderItemToCancel.return_reason}
                            </p>
                        </div>

                        <div className="ms-3 mt-3 d-flex align-items-start">
                            <h6 className="text-secondary fw-semibold me-3 col-6 ">İade detayı:</h6>
                            <p className="mb-0 text-secondary col-6 " style={{ maxHeight: "250px", overflowY: "auto" }}>
                                {orderItemToCancel.return_description}
                            </p>
                        </div>
                    </Col>
                    <Col style={{ overflowX: "auto" }} lg={6}>
                        <form id="form" onSubmit={(e) => {
                            e.preventDefault();
                            cancelReturnMutation.mutate()
                        }}>


                            <h6 className="">Reddetme başlığı</h6>
                            <Input
                                value={cancelTitle}
                                onChange={(e) => setCancelTitle(e.target.value)}
                                style={{
                                    // width: "400px",
                                    // flexShrink: 0,
                                    borderRadius: "8px",
                                    border: "1px solid #CED4DA",
                                    background: "var(--inputs-bg, #F3F3F9)",
                                }}
                                type="text"
                                className="form-control mt-3"
                                required
                            />
                            <h6 className=" mt-3">Açıklama</h6>
                            <TextArea
                                value={cancelDescription}

                                rows={4}
                                onChange={(e) => setCancelDescription(e.target.value)}
                                style={{
                                    
                                    borderRadius: "8px",
                                    border: "1px solid #CED4DA",
                                    background: "var(--inputs-bg, #F3F3F9)",
                                }}
                                type="text"
                                className="form-control mt-3"
                                required
                            />
                        </form>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                    iptal
                </Button>
                <Button type="submit" form="form" color="primary">Onayla</Button>
            </ModalFooter>
        </Modal>
    )
}

export default OrderDetailReturnedPopup