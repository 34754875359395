import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./products.css"
import { useParams } from "react-router-dom"
import axios from "axios"
import accessToken from "helpers/jwt-token-access/accessToken";
import ColorCircle from "./ColorCircle"

import Rating from "react-rating"

const FullStar = () => {
  return <i className="fa fa-solid fa-star" style={{ color: "#F1B44C" }}></i>;
}

const EmptyStar = () => {
  return <i className="fa fa-regular fa-star" style={{ color: "#74788D" }}></i>
}

const ReviewDetails = () => {
  // Set meta title
  document.title = "Ads Detail | Skote - React Admin & Dashboard Template"

  const { ad_id } = useParams();
  const parsedAdId = parseInt(ad_id, 10);
  const imageStyle = { width: "100%", height: "auto", maxWidth: "100%" };
  const [menuData, setMenuData] = useState({});
  const [colorData, setColorData] = useState([]);

  const [visitCount, setVisitCount] = useState([
    {
      name: "AD Time",
      data: [],
    },
    {
      name: "Without AD",
      data: [],
    },
  ]);

  const [sales, setSales] = useState([
    {
      name: "AD Time",
      data: [],
    },
    {
      name: "Without AD",
      data: [],
    },
  ]);
  const [salesByLoc, setSalesByLoc] = useState([]);

  useEffect(() => {
    const headers = {
      Authorization: accessToken,
    };

    const config = {
      headers: headers,
    };

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/detail?promotion_id=${ad_id}`,
        { withCredentials: true }
      )
      .then(response => {
        // Use response.data directly as an object
        // console.log(response.data);
        setMenuData(response.data);
        let jsonArray;
        try {
          // Attempt to parse the JSON string
          jsonArray = JSON.parse(response.data.color_and_media);

          // Ensure that jsonArray is an array
          if (!Array.isArray(jsonArray)) {
            throw new Error('Parsed result is not an array');
          }
          setColorData(jsonArray)
        } catch (error) {
          // If parsing fails, log the error and handle appropriately
          console.error('Error parsing JSON:', error);
          // You can set jsonArray to an empty array or handle the error in your specific way
          jsonArray = [];
        }


        // Initialize arrays to store data
        const visitCountDataWithAd = [];
        const visitCountDataWithoutAd = [];
        const salesWithAdData = [];
        const salesWithoutAdData = [];

        // Map the monthStats array to populate visitCount and sales arrays
        response.data.visit_count.forEach(item => {
          visitCountDataWithAd.push(item.views_with_promotion);
          visitCountDataWithoutAd.push(item.views_without_promotion);

        });
        JSON.parse(response.data.sales).forEach(item => {

          salesWithAdData.push(item.sales_with_promotion);
          salesWithoutAdData.push(item.sales_without_promotion);
        });

        // Populate the visitCount and sales arrays with the accumulated data
        setVisitCount([
          {
            name: "AD Time",
            data: visitCountDataWithAd,
          },
          {
            name: "Without AD",
            data: visitCountDataWithoutAd,
          }
        ]);

        setSales([
          {
            name: "AD Time",
            data: salesWithAdData,
          },
          {
            name: "Without AD",
            data: salesWithoutAdData,
          }
        ]);
        setSalesByLoc(JSON.parse(response.data.sales_by_location))

      })
      .catch(error => {
        if (error && error.response) {
          // console.log(error.response);
        }
      });
  }, [parsedAdId]);



  // useEffect(() => {
  //   console.log(menuData);
  //   console.log(menuData.hashtags);
  // }, [menuData])

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Ads Detail" />
          <button className="btn btn-info " onClick={() => {
            console.log(visitCount);
          }}>sdfsf</button>
          <Row>
            <Col lg="6">
              <Card className="p-2">
                <CardBody>
                  <Row className="d-flex align-items-center">
                    <Col
                      xs="9"
                      sm="6"
                      md="3"
                      className="d-flex flex-column align-items-center"
                    >
                      <img src={menuData.color_and_media && JSON.parse(menuData.color_and_media)[0].variation_media[0]} style={imageStyle} />
                    </Col>

                    <Col md="9">
                      <Row>
                        <Col xl="11">
                          <Row>
                            <Col>
                              <h6 className="brand">{menuData?.category}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col >
                              <h6 className="productTitle">
                                {menuData?.name}
                              </h6>
                            </Col>
                          </Row>
                          <Row className="no-gutters">
                            {menuData?.total_reviews ? (
                              <Rating readonly={true} initialRating={rating} fullSymbol={<FullStar />} emptySymbol={<EmptyStar />} />
                            ) : (
                              <span>No Reviews Yet</span>
                            )}
                            <span>({reviewCount} Customers Review)</span>
                          </Row>

                          <Row>
                            <Col >
                              <p style={{ fontWeight: "400" }}>
                                {menuData?.description}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col lg="3">
                              <p>Hashtags:</p>
                            </Col>

                            {menuData && menuData.hashtags && JSON.parse(menuData.hashtags).map((tag, index) => (
                              <Col
                                lg="3"
                                className="hashtags"
                                key={"hashtag_" + index}
                              >
                                <p>{tag}</p>
                              </Col>
                            ))}

                            {menuData && menuData.hashtags && JSON.parse(menuData.hashtags).length > 3 && (
                              <Col lg="3" className="hashtags">
                                <p>+ {hashtags.length - 3} more</p>
                              </Col>
                            )}
                          </Row>

                          <Row className="mt-4">
                            <Col lg="2">
                              <p>Colors:</p>
                            </Col>
                            <Col lg="5">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                {colorData?.map((item, i) => { return <ColorCircle key={i} color={item?.color?.color_hex} /> })}
                                {/* <ColorCircle color={menuData.color && menuData.color} /> */}

                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center">
                    <h6 className="speciTitle">Specifications :</h6>
                  </Row>
                  <Row className="d-flex align-items-center">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th scope="row">Category</th>
                          <td>{menuData.category && menuData.category}</td>
                        </tr>
                        <tr>
                          <th scope="row">Brand</th>
                          <td>{menuData.product_brand && menuData.product_brand}</td>
                        </tr>
                        <tr>
                          <th scope="row">Features</th>
                          <td>{menuData.features && menuData.features}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  )
}

export default ReviewDetails
