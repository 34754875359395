import React from "react";

const WaitIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#FFEED1" />
            <path d="M5 3.33333V5H4M8 5C8 6.65687 6.65687 8 5 8C3.34315 8 2 6.65687 2 5C2 3.34315 3.34315 2 5 2C6.65687 2 8 3.34315 8 5Z" stroke="#F1B44C" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default WaitIcon;