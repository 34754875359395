import React from 'react';
import { Table } from 'reactstrap';

const ProductTable = ({ products }) => {


    const sizeStyle = { fontWeight: "600" };

    const tableStyle = {
        border: "1px solid #EFF2F7", borderCollapse: "collapse", width: "100%",
    }

    const tableHeadStyle = { fontWeight: 600 };

    const tableRowStyle = {
        border: "1px solid #EFF2F7", // Add this line to apply border to each cell in the row
    };

    const tableHeadCellStyle = {
        border: "1px solid #EFF2F7",
        padding: "1rem",
        backgroundColor: "#FFFFFF"
    }

    const tableCellStyle = {
        border: "1px solid #EFF2F7",
        padding: "0.5rem 1rem",
        backgroundColor: "#FFFFFF"
    };

    return (
        <React.Fragment>
            <Table responsive cellPadding={10} style={tableStyle} className='my-2 mb-5 mt-3'>
                <thead style={tableHeadStyle}>
                    <tr style={tableRowStyle}>
                        <th style={tableHeadCellStyle}>Beden</th>
                        <th style={tableHeadCellStyle}>KDV</th>
                        <th style={tableHeadCellStyle}>Barkod</th>
                        <th style={tableHeadCellStyle}>Miktar</th>
                        <th style={tableHeadCellStyle}>ButikO Fiyatı</th>
                        <th style={tableHeadCellStyle}>Dışarıdaki Fiyat</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => (
                        <tr key={index} style={tableRowStyle}>
                            <td style={{ ...tableCellStyle, ...sizeStyle }}>{product.size}</td>
                            <td style={tableCellStyle}>{product.tax_rate}</td>
                            <td style={tableCellStyle}>{product.barcode}</td>
                            <td style={tableCellStyle}>{product.stock_quantity}</td>
                            <td style={tableCellStyle}>{product.price_in_butiko}</td>
                            <td style={tableCellStyle}>{product.price_outside_butiko}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
}

export default ProductTable;