import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import ColorCircle from "./ColorCircle"
import SelectedProductBox from "./SelectedProductBox"
import withRouter from "components/Common/withRouter"
import "../collection.css"
import { toast } from "react-toastify"
import { PiWarningCircleFill } from "react-icons/pi"
const AddCollections = ({ refreshCollections, toggleModal, modalOpen }) => {
  const [selectedProductIds, setSelectedProductIds] = useState([])
  const [collectionName, setCollectionName] = useState("")
  const [products, setProducts] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [collectionNameError, setCollectionNameError] = useState("")
  const sizeBoxStyle = {
    border: "1px solid #a2a2a2",
    padding: "4px 7px",
    margin: "0 3px 5px 3px",
    display: "inline-block",
    borderRadius: "10px",
  }

  const overflowStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
  useEffect(() => {
    fetchProductsWithSearch()
  }, [searchValue])

  const fetchProductsWithSearch = () => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/product-search`
    if (searchValue.trim() !== "") {
      url += `?search=${searchValue}`
    }

    axios
      .get(url, { withCredentials: true })
      .then(response => {
        const data = response.data
        const products = data.map(product => ({
          ...product,
          variation_media: JSON.parse(product.variation_media),
          colors: JSON.parse(product.colors),
        }))
        setProducts(products)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
        console.log("Response data:", error.response.data)
      })
  }

  const handleItemClick = productId => {
    const updatedSelection = [...selectedProductIds]
    const index = updatedSelection.indexOf(productId)
    if (index > -1) {
      updatedSelection.splice(index, 1)
    } else {
      updatedSelection.push(productId)
    }
    setSelectedProductIds(updatedSelection)
  }

  const handleRemoveProduct = productId => {
    setSelectedProductIds(selectedProductIds.filter(id => id !== productId))
  }

  const handleAddCollection = async () => {
    if (collectionName.trim() === "") {
      setCollectionNameError("Lütfen koleksiyon adını giriniz.")
      return
    }

    try {
      const collectionData = {
        collection_name: collectionName,
        product_ids: selectedProductIds,
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/create-collection`,
        collectionData,
        { withCredentials: true }
      )

      console.log("Koleksiyon başarıyla oluşturuldu.", response.data)
      setSelectedProductIds([])
      setCollectionName("")
      setCollectionNameError("")
      toast.success("Koleksiyon başarıyla oluşturuldu", {
        toastId: "descriptionMutation",
      })
      // Popup'ı kapat
      toggleModal()
      // Collections sayfasını yeniden yükle
      refreshCollections()
    } catch (error) {
      console.error("Error creating collection:", error)
      if (
        error.response &&
        error.response.data &&
        error.response.data.detail &&
        error.response.data.detail[0] &&
        error.response.data.detail[0].msg === "collection_name duplication"
      ) {
        setCollectionNameError("Bu isimde bir koleksiyon zaten var.")
      } else {
        toast.error("Bilinmeyen bir hata oluştu", {
          toastId: "descriptionMutationError",
        })
      }
    }
  }

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      style={{ maxWidth: "1370px" }}
    >
      <ModalHeader toggle={toggleModal}>Yeni Koleksiyon Ekle</ModalHeader>
      <ModalBody>
        <div style={{ padding: "10px" }}>
          <Container fluid>
            <Row>
              <Col lg={6}>
                <div
                  className="table-responsive"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <table>
                    <thead className="add-collection-table">
                      <tr>
                        <th className="px-3 py-3 border-0 "></th>
                        <th className="px-3 py-3">Ürünler</th>
                        <th className="px-3 py-3">Ürün Açıklaması</th>
                        <th className="px-3 py-3">Renkler</th>
                        <th className="px-3 py-3 me-2">Fiyat</th>
                      </tr>
                    </thead>
                    <tbody className="add-collection-table">
                      {products.map((product, index) => (
                        <tr
                          key={product.product_id}
                          className="pt-2"
                          onClick={() => handleItemClick(product.product_id)}
                        >
                          <td className=" text-end  p-0 m-0">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                style={{ borderColor: "#767676" }}
                                id="flexCheckDefault"
                                checked={selectedProductIds.includes(
                                  product.product_id
                                )}
                                readOnly
                              />
                            </div>
                          </td>
                          <td className="text-nowrap px-3">
                            <img
                              src={product.variation_media[0]}
                              alt="Product"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "8px",
                                objectFit: "cover",
                              }}
                              className="img-fluid mx-auto d-block"
                            />
                          </td>
                          <td className="  px-3">
                            <p
                              className="mt-2  text-truncate fw-medium "
                              style={{ width: "150px" }}
                            >
                              {product.name}
                            </p>
                          </td>
                          <td
                            className="text-center align-middle"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {product?.colors
                                ?.slice(0, 3)
                                .map((color, index) => (
                                  <ColorCircle
                                    key={index}
                                    color={color.color_hex}
                                  />
                                ))}
                              {product?.colors?.length &&
                                product.colors.length > 3 && (
                                  <div
                                    style={{
                                      ...sizeBoxStyle,
                                      ...overflowStyle,
                                      marginBottom: 0,
                                    }}
                                  >
                                    <span style={{ fontSize: "16px" }}>
                                      + {product.colors.length - 3}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </td>
                          <td>
                            <p className="text-center fw-medium  ">
                              {product.price}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col lg={6}>
                <Row style={{ marginBottom: "30px" }}>
                  <h5 style={{ marginBottom: "15px" }}>Koleksiyon İsmi</h5>
                  <Col lg={6}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Koleksiyon ismi girin"
                      value={collectionName}
                      onChange={e => setCollectionName(e.target.value)}
                    />
                    {collectionNameError && (
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "2px",
                          marginTop: "2px",
                        }}
                      >
                        <PiWarningCircleFill
                          style={{ color: "red", marginTop: "3px" }}
                        />
                        <p style={{ color: "red", marginLeft: "1px" }}>
                          {collectionNameError}
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>
                <h5>Seçilen Ürünler</h5>
                <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
                  {selectedProductIds.length === 0 ? (
                    <Col xs={12} style={{ marginTop: "10px" }}>
                      <p style={{ color: "#F46A6A" }}>
                        Seçili ürün yok, lütfen ürün seçiniz.
                      </p>
                    </Col>
                  ) : (
                    selectedProductIds.map(productId => (
                      <Col
                        key={productId}
                        xs={6}
                        md={3}
                        style={{ marginBottom: "15px" }}
                      >
                        <SelectedProductBox
                          product={products.find(
                            p => p.product_id === productId
                          )}
                          onRemove={handleRemoveProduct}
                        />
                      </Col>
                    ))
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Kapat
        </Button>
        <Button color="primary" onClick={handleAddCollection}>
          Koleksiyon Oluştur
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default withRouter(AddCollections)
