import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

function MyVerticallyCenteredModal(props) {
  const styles = {
    body: {
      fontFamily: 'Arial, sans-serif',
      color: 'rgb(87, 87, 87)',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
      marginBottom: '20px',
    },
    li: {
      listStyle: 'none',
    },
    th: {
      border: '1px solid black',
      padding: '10px',
      textAlign: 'left',
      backgroundColor: '#f2f2f2',
    },
    td: {
      border: '1px solid black',
      padding: '10px',
      textAlign: 'left',
    },
    firstTd: {
      backgroundColor: '#d9e1f2',
      border: '1px solid black',
      padding: "10px",
      width: '20%',
      fontWeight: 'bold',
    },
    h3: {
      marginTop: '20px',
    },
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET ANONİM ŞİRKETİ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "55vh", overflowY: "auto", paddingLeft: "40px", paddingRight: "40px" }}>
        <h4>
          6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA
          METNİ
        </h4>
        <h5 style={{ textDecoration: "underLine" }}>İÇİNDEKİLER:</h5>
        <ol>
          <li>
            <b>BİLGİLENDİRME</b>
          </li>
          <li>
            <b>TANIMLAR</b>
          </li>
          <li>
            <b>VERİ SORUMLUSUNUN KİMLİĞİ VE İLETİŞİM BİLGİLERİ</b>
          </li>
          <li>
            <b>İŞLENEN KİŞİSEL VERİLERİNİZ</b>
            <ul>
              <li>
                <b>ÜYELERİMİZ</b>
              </li>
              <li>
                <b>SATICILARIMIZ</b>
              </li>
            </ul>
          </li>
          <li>
            <b>KİŞİSEL VERİLERİNİZ HANGİ AMAÇLA İŞLENMEKTEDİR?</b>
          </li>
          <li>
            <b>KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</b>
          </li>
          <li>
            <b>KİŞİSEL VERİLERİNİZİ KİMLERE VE HANGİ AMAÇLA AKTARIYORUZ?</b>
          </li>
          <li>
            <b>
              KİŞİSEL VERİSİ İŞLENEN “İLGİLİ KİŞİ” OLARAK KANUN NEZDİNDEKİ
              HAKLARINIZ NELERDİR?
            </b>
          </li>
        </ol>
        <h5>1-) BİLGİLENDİRME</h5>
        <p>
          BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET ANONİM ŞİRKETİ (“BUTİKO” veya
          “Şirket”), butiko.com.tr internet sitesi ve mobil uygulamasının
          sahibidir. BUTİKO, kurduğu elektronik ticaret platformu üzerinden
          tedarikçileri, üreticileri ve tüketicileri bir araya getirir.{" "}
        </p>
        <p>
          BUTİKO, Websitesi’nde yer alan herhangi bir ürün veya hizmetin
          satıcısı konumunda değildir ve 6563 sayılı Elektronik Ticaretin
          Düzenlenmesi Hakkında Kanun uyarınca aracı hizmet sağlayıcıdır.
        </p>
        <p>
          BUTİKO tarafından gizliliğinize saygı duyulmakta ve veri güvenliğinize
          önem verilmektedir. Bu kapsamda 6698 sayılı Kişisel Verilerin
          Korunması Kanunu (“Kanun”, “KVKK”) ve ilgili diğer mevzuatlar
          dâhilinde sizleri bilgilendirmek ve aydınlatmak amacıyla işbu
          aydınlatma metni hazırlanmıştır.
        </p>
        <p>
          6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) 7 Nisan 2016
          tarihli Resmi Gazete’de yayınlanmak suretiyle ihdas edilmiş ve
          yürürlüğe girmiştir. Kanun; Kişisel verilerin işlenmesinde başta özel
          hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini
          korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin
          yükümlülükleri ile uyacakları usul ve esasları düzenlemek amacıyla
          kabul edilmiştir.
        </p>
        <p>
          Şirketimiz KVKK kapsamında kişisel verilerinizi hukuka uygun olarak
          işlemektedir.
        </p>
        <p>
          İşbu Aydınlatma Metni, yürürlükteki mevzuatta yapılabilecek
          değişiklikler çerçevesinde veri sorumlusu Şirketimiz tarafından her
          zaman güncellenebilir. Veri sorumlusu olarak kişisel verilerin
          işlenmesi ve aktarılması amaçlarında ve toplanma yöntemlerinde
          gerçekleşecek değişiklikler çerçevesinde ilgili metinleri güncelleme
          hakkımızı her zaman saklı tutuyoruz.
        </p>
        <p>
          Kişisel verilerinizin tarafımızca işlenmesine ilişkin diğer detaylı
          bilgilere VERBİS (Veri Sorumluları Sicil Bilgi Sistemi) bildirim
          kaydımızdan ulaşabilirsiniz. Faaliyetlerimizde ve çalışma yapımızda
          oluşabilecek değişikliklere göre kişisel veri işleme faaliyetlerimizde
          de değişiklik olabilir. Yapılan değişikliklere ilişkin güncellemelerin
          takibini internet sitemiz ve sosyal medya hesaplarımızdan
          yapabilirsiniz. Yine güncellemeler doğrultusunda VERBİS kayıt
          bilgilerimizi de düzenli olarak kontrol etmekte ve değiştirmekteyiz.{" "}
        </p>
        <h5>2-) TANIMLAR</h5>
        <table style={styles.table}>
          <tbody>
            <tr>
              <td style={styles.firstTd}>Açık Rıza:</td>
              <td style={styles.td}>
                Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Üye:</td>
              <td style={styles.td}>
                Üyelik koşullarını kabul ederek web sitesine giriş yapan ve/veya ürün satın alan kişileri ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Satıcı:</td>
              <td style={styles.td}>
                BUTİKO ile yaptığı sözleşme kapsamında butiko.com.tr'ye üye olan ve oluşturduğu hesap üzerinden yayınladığı ilanlar aracılığıyla çeşitli Ürün ve/veya hizmetleri satışa arz eden tüzel/gerçek kişi üyeyi ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Şirket:</td>
              <td style={styles.td}>
                “Küçükbakkalköy Mah. Selvi̇li̇ Sk. Canan Business Plaza. No: 4/20 Ataşehi̇r/İstanbul” adresinde mukim BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET ANONİM ŞİRKETİ’ni ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Çerez (Cookie):</td>
              <td style={styles.td}>
                Kullanıcıların bilgisayarlarına yahut mobil cihazlarına kaydedilen ve ziyaret ettikleri web sayfalarındaki tercihleri ve diğer bilgileri depolamaya yardımcı olan küçük dosyaları ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>İrtibat Kişisi:</td>
              <td style={styles.td}>
                Türkiye’de yerleşik olan tüzel kişiler ile Türkiye’de yerleşik olmayan tüzel kişi veri sorumlusu temsilcisinin Kanun ve bu Kanuna dayalı olarak çıkarılacak ikincil düzenlemeler kapsamındaki yükümlülükleriyle ilgili olarak, Kurum ile kurulacak iletişim için veri sorumlusu tarafından Sicile kayıt esnasında bildirilen gerçek kişiyi ifade eder. (İrtibat kişisi Veri Sorumlusunu temsile yetkili değildir. Adından anlaşılacağı üzere yalnızca veri sorumlusu ile ilgili kişilerin ve Kurumun iletişimini “irtibatı” sağlamak üzere görevlendirilen kişidir.)
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Kanun/KVKK:</td>
              <td style={styles.td}>
                7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanan, 24 Mart 2016 tarihli ve 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Kişisel Veri:</td>
              <td style={styles.td}>
                Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Kişisel Verilerin İşlenmesi:</td>
              <td style={styles.td}>
                Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Kurul:</td>
              <td style={styles.td}>
                Kişisel Verileri Koruma Kurulu’nu ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Veri Sahibi/İlgili Kişi:</td>
              <td style={styles.td}>
                Kişisel verisi işlenen gerçek kişiyi ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Veri Sorumlusu:</td>
              <td style={styles.td}>
                Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.
              </td>
            </tr>
            <tr>
              <td style={styles.firstTd}>Kaynak:</td>
              <td style={styles.td}>
                6698 sayılı Kişisel Verilerin Korunması Kanunu- Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik - Veri Sorumluları Sicili Hakkında Yönetmelik - Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ - Veri Sorumlusuna Başvuru ve Usul Esasları Hakkında Tebliğ Veri sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ.
              </td>
            </tr>
          </tbody>
        </table>
        <h5>3-) VERİ SORUMLUSUNUN KİMLİĞİ VE İLETİŞİM BİLGİLERİ</h5>
        <p>
          İşbu Aydınlatma Metninde ifade edilmiş olduğu üzere ŞİRKET, KVKK
          gereğince veri sorumlusudur.
        </p>
        <b>i.Veri Sorumlusu Bilgisi</b>
        <ul>
          <li>
            <strong>Açık Adı:</strong> BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET
            ANONİM ŞİRKETİ
          </li>
          <li>
            <strong>Kısa Adı:</strong> BUTİKO
          </li>
          <li>
            <strong>MERSİS No:</strong> 0191132111300001
          </li>
          <li>
            <strong>Adres:</strong> Küçükbakkalköy Mah. Selvi̇li̇ Sk. Canan
            Business Plaza. No: 4/20 Ataşehir/İstanbul
          </li>
          <li>
            <strong>İnternet Sitesi:</strong>{" "}
            <a href="https://butiko.com.tr/">https://butiko.com.tr/</a>
          </li>
          <li>
            <strong>E-posta:</strong>{" "}
            <a href="mailto:info@butiko.com.tr">info@butiko.com.tr</a>
          </li>
        </ul>
        <b>ii.İrtibat Kişisi</b>
        <ul>
          <li>
            <strong>Adı Soyadı:</strong> Av. Hande Karaman
          </li>
          <li>
            <strong>E-posta:</strong>{" "}
            <a href="mailto:av.handekaraman@gmail.com">
              av.handekaraman@gmail.com
            </a>
          </li>
          <li>
            <strong>Telefon:</strong> 08503094219
          </li>
        </ul>
        <h5>4-) İŞLENEN KİŞİSEL VERİLERİNİZ</h5>
        <h6>A. ÜYELERİMİZ</h6>
        <p>
          Üyelik koşullarını kabul ederek web sitesine giriş yapan ve/veya ürün
          satın alan kişiler “Üye” olarak tanımlanmaktadır. Sitemize üye olmanız
          halinde aşağıdaki kişisel verileriniz işlenecektir.
        </p>
        <ul>
          <li>
            <strong>Kimlik Bilgileriniz:</strong> adınız, soyadınız
          </li>{" "}
          <br />
          <li>
            <strong>İletişim Bilgileriniz:</strong> cep telefonu numaranız,
            teslimat adresiniz, e-posta adresiniz,
          </li>
          <br />
          <li>
            <strong>Lokasyon Bilgileriniz:</strong> bulunduğunuz yerin konum
            bilgileri,
          </li>
          <br />
          <li>
            <strong>Müşteri İşlem Bilgileriniz:</strong> sipariş ve fatura
            bilgileriniz, teslimat işlem bilgileriniz, alışverişlerinizle ilgili
            gerçekleşen işlem geçmişi bilgisi, talep ve şikayet bilgileriniz,
            butiko.com.tr'de yer alan ürünlere ilişkin yorum yapmanız ve/veya
            soru sormanız halinde yorum ve sorularınız içerisinde bizzat
            paylaştığınız bilgiler,
          </li>
          <br />
          <li>
            <strong>Ödeme Bilgileriniz:</strong>vergi dairesi, fatura bilgileri,
            ödeme yöntemi bilgileriniz,
          </li>
          <br />
          <li>
            <strong>İşlem Güvenliği Bilgileriniz:</strong>IP adresi bilgisi,
            şifre ve parola bilgileriniz, çerez bilgileriniz,{" "}
          </li>
          <br />
          <li>
            <strong>Pazarlama Bilgileriniz:</strong>verdiğiniz ticari elektronik
            ileti iznine istinaden gönderilen pazarlama amaçlı sms, e-posta
            mesajları veya çağrı merkezi tarafından yapılan aramalar, çerez
            kayıtları, hedefleme bilgileri, alışkanlık ve beğenileri gösteren
            değerlendirmeler,
          </li>
          <br />
        </ul>
        <h6>B. SATICILARIMIZ(Üye İşyeri Yetkilisi, Çalışanı, Hissedarları)</h6>
        <p>
          BUTİKO ile yaptığı sözleşme kapsamında butiko.com.tr'ye üye olan ve
          oluşturduğu hesap üzerinden yayınladığı ilanlar aracılığıyla çeşitli
          Ürün ve/veya hizmetleri satışa arz eden tüzel/gerçek kişi “Satıcı”
          olarak tanımlanmaktadır.
        </p>
        <p>
          Kanunda “kişisel verileri işlenen gerçek kişiler” ifadesi
          kullanıldığından, verileri işlenen tüzel kişiler bu Kanunun kapsamı
          dışında tutulmuştur. Bu sebeple işbu Aydınlatma Metninde bahsedilen
          tüzel kişiliğin kendisi değildir. Tüzel kişiliğin yetkilisi, çalışanı
          ve/veya hissedarlarıdır. Sitemizi ürünleri satışa sunmak ve satmak
          amacıyla kullanmanız halinde aşağıdaki kişisel verileriniz
          işlenecektir.{" "}
        </p>
        <ul>
          <li>
            <strong>Kimlik Bilgileriniz:</strong> adınız, soyadınız, TCKN
          </li>{" "}
          <br />
          <li>
            <strong>İletişim Bilgileriniz:</strong> adresiniz, cep telefonu
            numaranız, sabit telefon numaranız, e-posta adresiniz,
          </li>
          <br />
          <li>
            <strong>Müşteri İşlem Bilgileriniz:</strong> sipariş ve fatura
            bilgileriniz, teslimat işlem bilgileriniz, alışverişlerinizle ilgili
            gerçekleşen işlem geçmişi bilgisi, talep ve şikayet bilgileriniz,
            Ürünlerinize yapılan yorumlar ve verdiğiniz cevaplar, yorum ve
            sorularınız içerisinde bizzat paylaştığınız bilgiler,
          </li>
          <br />
          <li>
            <strong>İşlem Güvenliği Bilgileriniz:</strong>IP adresi bilgisi,
            şifre ve parola bilgileri, çerez bilgileri,
          </li>
          <br />
          <li>
            <strong>Finansal Bilgileriniz:</strong>Vergi dairesi, vergi
            numarası, vergi levhası, fatura bilgileri,
          </li>
          <br />
        </ul>
        <h5>5-) KİŞİSEL VERİLERİNİZ HANGİ AMAÇLA İŞLENMEKTEDİR?</h5>
        <p>
          Şirket, yukarıda sayılan kişisel verilerinizi Kanun ile işbu
          Aydınlatma Metni’nde yer verilen şartlar kapsamında ve aşağıdaki
          amaçlarla işlemektedir:
        </p>
        <ul>
          <li>
            <strong>Hukuk İşlerinin Takibi ve Yürütülmesi:</strong> Kimlik
            Verisi, İşlem Güvenliği Verisi, Finansal Veri, Ödeme Verileri.
          </li>
          <li>
            <strong>Bilgi Güvenliği Süreçlerinin Yürütülmesi:</strong> Kimlik
            Verisi, İşlem Güvenliği Verisi.
          </li>
          <li>
            <strong>Sözleşme Süreçlerinin Yürütülmesi:</strong> Kimlik Verisi,
            İletişim Verisi, Lokasyon Verisi, Müşteri İşlem Verisi, Finansal
            Veri, Ödeme Verileri.
          </li>
          <li>
            <strong>İletişim Faaliyetlerinin Yürütülmesi:</strong> Kimlik
            Verisi, İletişim Verisi, Müşteri İşlem Verisi.
          </li>
          <li>
            <strong>Mal / Hizmet Satış Süreçlerinin Yürütülmesi:</strong> Kimlik
            Verisi, İletişim Verisi, Lokasyon Verisi, Finansal Veri, Ödeme
            Verileri.
          </li>
          <li>
            <strong>Reklam & Pazarlama Süreçlerinin Yürütülmesi:</strong> Kimlik
            Verisi, İletişim Verisi, Lokasyon Verisi, İşlem Güvenliği Verileri.
          </li>
          <li>
            <strong>Saklama ve Arşiv Faaliyetlerinin Yürütülmesi:</strong>{" "}
            Kimlik Verisi, İletişim Verisi, Lokasyon Verisi, İşlem Güvenliği
            Verileri, Finansal Veri, Ödeme Verileri.
          </li>
          <li>
            <strong>
              Uygulamaya Giriş Çıkış Kayıtlarınız ile Uygulama Üzerinden
              Gerçekleştirdiğiniz İşlemlerin Takibi ve Saklanması:
            </strong>{" "}
            Kimlik Verisi, Müşteri İşlem Verisi, İşlem Güvenliği Verileri.
          </li>
          <li>
            <strong>İş Faaliyetlerinin Yürütülmesi / Denetimi:</strong> Kimlik
            Verisi, Müşteri İşlem Verisi, İşlem Güvenliği Verileri.
          </li>
          <li>
            <strong>Hesap Güvenliğinin Sağlanması:</strong> Kimlik Verisi,
            Müşteri İşlem Verisi, İşlem Güvenliği Verileri.
          </li>
          <li>
            <strong>Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi:</strong>{" "}
            Kimlik Verisi, İletişim Verisi, Müşteri İşlem Verisi, İşlem
            Güvenliği Verileri, Lokasyon Verisi, Finansal Veri, Ödeme Verileri.
          </li>
        </ul>
        <h5>6-) KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</h5>
        <p>
          Kişisel verileriniz butiko.com.tr web sitesi üzerinden dijital
          ortamlarda otomatik ya da kısmen otomatik yöntemlerle elde
          edilmektedir. Bu elde etme işlemleri, web sitesine girmeniz, formları
          doldurmanız veya bazı bilgilerinizin çerezler aracılığı ile toplanması
          gibi yöntemlerle gerçekleşir. <br />
          <br />
          Otomatik veri işleme, bilgisayar, telefon gibi işlemci sahibi cihazlar
          tarafından yerine getirilen, yazılım veya donanım özellikleri
          aracılığıyla önceden hazırlanan algoritmalar kapsamında insan
          müdahalesi olmadan kendiliğinden gerçekleşen işleme faaliyetidir.
          Kısmen otomatik veri işleme ise insan etkisi bulunan ancak yine
          bilgisayar gibi işlemci sahibi cihazlar vasıtası ile kişisel veri
          işleme faaliyetidir.
          <br />
          <br />
          Kimlik ve iletişim verileriniz ancak açık rızanızın varlığı halinde
          reklam ve pazarlama süreçlerinin yürütülmesi amacıyla ve bu kapsamda
          sizinle iletişime geçilmek üzere işlenebilecektir. Kişisel verileriniz
          ancak açık rızanızın varlığı halinde yurt dışında bulunan sunucularda
          saklanabilecektir. <br />
          <br />
          Şirket, işbu Aydınlatma Metninde yer verilen kişisel verilerinizi,
          Kanun'un 5. maddesinde yer alan hukuki sebeplere dayalı olarak
          toplanmakta ve işlemektedir. Aşağıda verileriniz işlenirken dayanılan
          hukuki sebepler belirtilmiştir.
          <br />
          <br />
          <span style={{ textDecoration: "underLine" }}>
            İlgili kişinin açık rızasının bulunması (KVKK md. 5/1):
          </span>{" "}
          butiko.com.tr'de yer alan ürünlere yönelik sorularınızın ve/veya
          yorumlarınızın ad-soyad bilginiz ile birlikte yayınlanması, çeşitli
          pazarlama ve reklam faaliyetlerinde kullanabilmesi, tercihlerinize
          uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha
          iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin
          arttırılmasına yönelik faaliyetlerin yürütülmesi, butiko.com.tr
          nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza
          bilgi verilmesi, ticari elektronik iletiler gönderilmesi ve yurtdışına
          kişisel veri aktarımı yapılması.
          <br />
          <br />
          <span style={{ textDecoration: "underLine" }}>
            Kişisel veri işlemenin kanunlarda açıkça öngörülmüş olması (KVKK md.
            5/2-a):
          </span>{" "}
          şirketin işlettiği butiko.com.tr'de yer alan operasyonların
          güvenliğinin sağlanması, bilgi güvenliği süreçlerinin yürütülmesi,
          faaliyetlerin mevzuata uygun yürütülmesinin sağlanmasına yönelik
          faaliyetler başta olmak üzere mevzuattan kaynaklanan
          yükümlülüklerimizin yerine getirilmesi,
          <br />
          <br />
          <span style={{ textDecoration: "underLine" }}>
            Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
            olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
            işlenmesinin gerekli olması (KVKK md. 5/2-c):
          </span>{" "}
          üyelik sözleşmesinin kurulması, mesafeli satış sözleşmesi gibi
          Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri tahtında
          butiko.com.tr üzerinden akdedilen sözleşmelerin kurulması
          faaliyetlerinin yürütülmesi, akdettiğimiz sözleşmelerin ifasına
          yönelik faaliyetlerin yürütülmesi, satın alım işlemlerinizin
          gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi, şirketin iş
          faaliyetlerinin yürütülmesi ve denetimi, teslimat süreçlerinin
          takibinin yapılması, ürün ve hizmetlerimize ilişkin talep, şikayet ve
          önerilerinizin değerlendirilmesi, finans ve muhasebe süreçlerinin
          yürütülmesi ve denetimi, iletişim faaliyetlerinin yürütülmesi,
          <br />
          <br />
          <span style={{ textDecoration: "underLine" }}>
            Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
            veri işlemenin zorunlu olması (KVKK md. 5/2-ç):
          </span>{" "}
          Mesafeli Sözleşmeler Yönetmeliği ve Elektronik Ticarette Hizmet
          Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik başta olmak
          üzere Şirketimizin tabii olduğu ikincil mevzuatta yer alan ve/veya
          yetkili merciiler tarafından yayınlanan karar, kılavuz ve rehberlerde
          belirtilen hukuki yükümlülüklerinin yerine getirilmesi, yetkili kişi,
          kurum ve kuruluşlara bilgi verilmesi, faaliyetlerin mevzuata uygun
          yürütülmesinin sağlanması, hukuk işlerinin takibi ve yürütülmesi,
          finans ve muhasebe işlerinin yürütülmesi,
          <br />
          <br />
          <span style={{ textDecoration: "underLine" }}>
            Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
            zorunlu olması (KVKK md. 5/2-e):
          </span>{" "}
          hukuk ve dava işlerinin yürütülmesi,
          <br />
          <br />
          <span style={{ textDecoration: "underLine" }}>
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması (KVKK md. 5/2-f):
          </span>{" "}
          şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve
          iyileştirilmesine yönelik faaliyetlerin yürütülmesi ve sizlere daha
          iyi bir alışveriş deneyiminin sağlanması,
          <br />
          <br />
          <h5>7-) KİŞİSEL VERİLERİNİZİ KİMLERE VE HANGİ AMAÇLA AKTARIYORUZ?</h5>
          <p>
            Toplanan kişisel verileriniz; Şirketimiz ve Şirketimizle iş ilişkisi
            içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini;
            Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri
            faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından
            yapılması; Şirketimiz tarafından sunulan ürün ve hizmetlerin
            sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre
            özelleştirilerek sizlere önerilmesi Şirketimizin ticari ve iş
            stratejilerinin belirlenmesi ve uygulanması ile Şirketimizin insan
            kaynakları politikalarının yürütülmesinin temini amaçlarıyla iş
            ortaklarımıza, tedarikçilerimize, hissedarlarımıza, kanunen yetkili
            kamu kurumları ve özel kişilere, KVKK’nın 8. ve 9. maddelerinde
            belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde
            aktarılabilecektir.
            <br />
            <br />
            Kişisel verileriniz KVKK Md.28/1 çerçevesinde talep edilmesi halinde
            aydınlatma yükümlülüğü olmadan ve açık rızanız aranmadan ilgili
            makamlara aktarılabilir.
            <br />
            <br />
            Bunun dışında öngörülemeyen durumlarda da kişisel verileriniz
            kanunlarda açıkça belirtilmiş durumlarda talep edilmesi halinde
            kanunlarda belirtilen kamu kurumlarına (Bakanlıklar gibi idari
            makamlara) kanunda öngörülen amaç ve sınırlamalar dahilinde
            aktarılabilir.
            <br />
            <br />
            Aydınlatma yükümlülüğü olmayan ve açık rızanızı gerektirmeyen yasal
            yükümlülüklerimizin dışında kişisel verilerinizi kimlerle, hangi
            amaçlarla paylaştığımıza ilişkin bilgilere aşağıda yer verdik;
            <br />
            <br />
            <strong>Yetkili Kurum ve Kuruluşlar:</strong> Yetkili kurum veya
            kuruluşların Şirketimizden talepte bulunması ya da bu kurumlara
            bildirim yapmamızın öngörüldüğü durumlarda, yasal
            yükümlülüklerimizin yerine getirilmesi. <br />
            <br />
            <strong>Tedarikçiler:</strong> Siparişe konu ürünün hazırlanması,
            teslim edilebilmesi ve sipariş yönetiminin sağlanması amacıyla
            Şirketimiz ile iş birliği yapan satıcılara, tedarikçilere ve kargo
            şirketlerine
            <br />
            <br />
            <strong>Ürün ve Hizmet Aldığımız Şirketler:</strong> Üçüncü
            taraflardan mal veya hizmet tedariki kapsamında destek alınması
            (örneğin, SMS gönderimleri için bir tedarikçiden altyapı desteği
            alınması), finans ve muhasebe süreçlerinin yürütülmesi.
            <br />
            <br />
            <strong>Anlaşmalı Olduğumuz Kargo Şirketleri:</strong> Siparişinizin
            belirtmiş olduğunuz adrese teslim edilebilmesi. <br />
            <br />
            <strong>Yetkili Ödeme Kuruluşları:</strong> Ödemelerin
            gerçekleşebilmesi amacıyla ödeme bilgileriniz banka veya ödeme
            kuruluşu olan iş ortaklarımıza.(Kredi kartını bilgilerinizi daha
            sonra kullanmak amacı ile kaydetmek istemeniz halinde kart
            bilgileriniz, kart saklama hizmeti veren iş ortaklarımıza
            aktarılacak ve güvenli olarak burada açık rızanıza istinaden
            saklanacaktır.)
            <br />
            <br />
          </p>
        </p>
        <h5>
          8-) KİŞİSEL VERİSİ İŞLENEN "İLGİLİ KİŞİ" OLARAK KANUN NEZDİNDEKİ
          HAKLARINIZ NELERDİR?
        </h5>
        <p>
          İlgili kişi olarak Kanun'un 11. maddesi uyarınca aşağıdaki haklara
          sahip olduğunuzu bildiririz:
        </p>
        <ol>
          <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
          <br />
          <li>İşlenen kişisel verilerinize ilişkin bilgi talep etme,</li>
          <br />
          <li>
            Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
          </li>
          <br />
          <li>Kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
          <br />
          <li>
            Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
            bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
            kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
            isteme,
          </li>
          <br />
          <li>
            İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
            edilmesi suretiyle aleyhinize bir sonucun ortaya çıkması durumunda
            buna itiraz etme,
          </li>
          <br />
          <li>
            Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
            zarara uğramanız halinde zararın giderilmesini talep etme.
          </li>
          <br />
        </ol>
        <p>Yukarıda sıralanan haklarınıza yönelik başvurularınızı,</p>
        <ol type="i">
          <li>
            Kimliğinizi tevsik eden belgeler ile birlikte Uygulama üzerinden
            ulaşabileceğiniz Veri Sorumlusuna Başvuru Formu'nu doldurmak
            suretiyle yazılı olarak “Küçükbakkalköy Mah. Selvi̇li̇ Sk. Canan
            Business Plaza. No: 4/20 Ataşehi̇r/İstanbul” adresine elden teslim
            etmek veya kargo ile göndermek suretiyle,
          </li>
          <br />
          <li>
            Şirket sistemlerinde kayıtlı e-postanız aracılığıyla, Veri
            Sorumlusuna Başvuru Formu'nu doldurup “info@butiko.com.tr” adresine
            göndererek veya
          </li>
          <br />
          <li>
            Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'de
            öngörülen sair yöntemleri kullanmak suretiyle Şirketimize
            iletebilirsiniz.
          </li>
          <br />
        </ol>
        <p>
          Talebiniz, mümkün olan en kısa sürede ve en geç 30 (otuz) gün içinde
          ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet
          gerektirmesi halinde Veri Sorumlusuna Başvuru Usul ve Esasları
          Hakkında Tebliğ ile belirlenen tarifeye göre tarafınızdan ücret talep
          edilebilecektir.
        </p>
        <br />
        <h4>DEĞİŞİKLİK </h4>
        <strong style={{ textDecoration: "underLine" }}>
          i. Değişiklik 1 :
        </strong>
        Evrakta henüz değişiklik yapılmamıştır.
        <p style={{ textDecoration: "underLine" }}>
          <strong>Değişiklik Detayı :</strong>{" "}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  )
}

function Kvkk({ modalShow, setModalShow }) {

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

export default Kvkk
