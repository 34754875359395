import React from "react";

const ColorCircle = ({ color }) => {
  return (
    <div
      style={{
        width: "20px", // Yuvarlak rengin genişliği
        height: "20px", // Yuvarlak rengin yüksekliği
        borderRadius: "50%", // Yuvarlak yapmak için border-radius
        backgroundColor: color, // Renk
        margin: "6px",
        boxShadow: "2px 2px 3px 0px #cfcfcf"
      }}
    ></div>
  );
};

export default ColorCircle;
