import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { get, post } from "helpers/api_helper";
import * as url from "helpers/url_helper";
import useUserStore from "./routeStore";

const Authmiddleware = (props) => {
  const history = useNavigate()
  // const setApprovalStatus = useUserStore(state => state.setApprovalStatus)
  
  let location = useLocation();
  const isAuth = () => {
    return get(url.GET_IS_AUTH)
  }
  const { isLoading, isError, error, data } = useQuery({ queryKey: ["is-auth"], queryFn: isAuth });
  let status
  if (isLoading) {
    status = "Checking your authentication..."
  } else if (isError) {
    console.error("Error checking authentication:", error);
  } else {

    status = data;
    // console.log(status);
    // setApprovalStatus(status?.payload?.approval_status)

    if (
      (location.pathname !== "/profile" && location.pathname !== "/dashboard") &&
      status?.payload?.approval_status === "pending"
    ) {
      history("/profile", { state: "redirect" });
      // alert("fldsk");
      toast.info("Kullanıcı kaydınız tamamlanmadan işlem yapamazsınız",{toastId: "warning"});
    } else if (!status?.is_authenticated) {
      history("/")
    }
  }

  return (
    <React.Fragment>

      {props.children}
      <ToastContainer />
    </React.Fragment>
  );
};

export default Authmiddleware;