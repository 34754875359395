import React from "react";
import { useState } from "react";
import {

    Row,
    Col,
    Button,
    Input,
    Label
} from "reactstrap";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import accessToken from "helpers/jwt-token-access/accessToken";
// import toastr from "toastr";
import { toast } from 'react-toastify';

import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { Skeleton } from 'antd';



const AddressInput = ({ id, type, address, onUpdate }) => {
    const history = useNavigate();

    //for notification

    const [city, setCity] = useState("")
    const [isCity, setIsCity] = useState(false)
    const [district, setDistrict] = useState("")
    const [isDistrict, setIsDistrict] = useState(false)
    const [changeAddress, setChangeAddress] = useState(false)
    //for up date
    const [districtForUpdate, setDistrictForUpdate] = useState(address?.district || "");
    const [cityForUpdate, setCityForUpdate] = useState(address?.city || "");
    const [neighborhoodForUpdate, setNeighborhoodForUpdate] = useState(address?.neighborhood || "");
    const [streetForUpdate, setStreetForUpdate] = useState(address?.street || "");
    const [apartmentNoForUpdate, setApartmentNoForUpdate] = useState(address?.apartment_no || "");
    const [houseNoForUpdate, setHouseNoForUpdate] = useState(address?.house_no || "");
    const [addressForUpdate, setAddressForUpdate] = useState(address?.address || "");



    //end for notification
    const linkStyle = {
        color: address === null ? "red" : "#556EE6"
    };
    //start api call

    const updateAdrress = () => {
        // const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/addresses/update-address/${id}`
        const finalData = {
            // address: {
            city: cityForUpdate || null,
            street: streetForUpdate || null,
            address: addressForUpdate || null,
            district: districtForUpdate || null,
            house_no: String(houseNoForUpdate) || null,
            apartment_no: String(apartmentNoForUpdate) || null,
            neighborhood: neighborhoodForUpdate || null
            // }
        }
        if (id) {
            const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/addresses/update-address/${id}`
            return axios.put(finalUrl, finalData, { withCredentials: true })
        } else {
            const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/addresses/create-address`
            return axios.post(finalUrl, finalData, { withCredentials: true })

        }

    }
    const addressMutation = useMutation({
        mutationFn: updateAdrress,
        onSuccess: () => {
            toast.success("İşlem Tamamlandı", { toastId: "addressMutation" })
            setChangeAddress(false)
            onUpdate()
        },
        onError: (error) => {
            if (error.response.data.detail[0].type === "missing" || error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "addressMutationErr" });
            console.log(error);
        }
    })

    const getCity = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/il`, { withCredentials: true })
    }
    const getDistrict = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/ilce/${city}`, { withCredentials: true })
    }
    const getNeigborhood = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/mahalle/${district}`, { withCredentials: true })
    }


    const { isPending, isError, data, error } = useQuery({ queryKey: ['get-city'], queryFn: getCity })
    const { data: districtData } = useQuery({ queryKey: ['get-district', city], queryFn: getDistrict, enabled: isCity })
    const { data: neighborhoodData } = useQuery({ queryKey: ['get-neighborhood', district], queryFn: getNeigborhood, enabled: isDistrict })

    // if (isPending) {
    //     return (<Skeleton />)
    // }
    if (isError) {
        if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
            history("/login")
        }
        toast.error("error", { toastId: "fetchError" })
    }
    //end api call
    const changeButton = address === null ? "Adres gir" : "Adres değiştir"



    return (
        <Row className="my-2 ">
            <Col xs="2">
                <span>{type === "personal" ? "Kişisel " : (type)} Adres </span>
                <br></br>
                {type === "office" ? (null) : (<a style={linkStyle} onClick={() => { setChangeAddress(!changeAddress) }}>{changeButton}</a>)}
            </Col>
            {changeAddress ? (
                <Col>
                    <Row>
                        <Col xs="12" md="6" xl="4">
                            <Label>İL</Label>
                            <Form.Select onChange={(e) => {
                                setCity(e.target.value)
                                setIsCity(true)
                                setCityForUpdate(e.target.options[e.target.selectedIndex].text)
                            }} aria-label="Default select example">
                                <option>il</option>
                                {data.data.map((city) => {
                                    return <option key={city.il_id} value={city.il_id}>{city.il}</option>
                                })}
                            </Form.Select>
                        </Col>
                        <Col xs="12" md="6" xl="4">
                            <Label>İLÇE</Label>
                            <Form.Select className="" onChange={(e) => {
                                setDistrict(e.target.value)
                                setIsDistrict(true)
                                setDistrictForUpdate(e.target.options[e.target.selectedIndex].text);
                            }} aria-label="Default select example">
                                <option>ilce</option>
                                {districtData?.data.map((dist) => {
                                    return <option id={dist.ilce_id} key={dist.ilce_id} value={dist.ilce_id} >{dist.ilce}</option>
                                })}
                            </Form.Select>
                        </Col>
                        <Col xs="12" md="6" xl="4">
                            <Label>MAHALLE</Label>
                            <Form.Select onChange={(e) => {
                                setNeighborhoodForUpdate(e.target.options[e.target.selectedIndex].text)
                            }} aria-label="Default select example">
                                <option>mahalle</option>
                                {neighborhoodData?.data.map((neighborhood) => {
                                    return <option key={neighborhood.mahalle_id} value={neighborhood.mahalle_id} >{neighborhood.mahalle}</option>
                                })}
                            </Form.Select>
                        </Col>
                        <Col className="mt-2" xs="12" md="6" xl="4">
                            <Label>SOKAK</Label>
                            <Input value={streetForUpdate} onChange={(e) => {
                                setStreetForUpdate(e.target.value)

                            }} type="text" placeholder="sokak" />

                        </Col>
                        <Col className="mt-2" xs="12" md="6" xl="4">
                            <Label>DIŞ KAPI NO</Label>
                            <Input onChange={(e) => {
                                setApartmentNoForUpdate(e.target.value)
                            }} type="text" placeholder="dish No" value={apartmentNoForUpdate} />
                        </Col>
                        <Col className="mt-2" xs="12" md="6" xl="4">
                            <Label>İÇ KAPI NO</Label>
                            <Input onChange={(e) => {
                                setHouseNoForUpdate(e.target.value)
                            }} type="text" placeholder="Ic No" value={houseNoForUpdate} />
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <Row>
                        <Col className="mt-2">
                            <Label>ADRES TARİFİ</Label>
                            <Input onChange={(e) => {
                                setAddressForUpdate(e.target.value)
                            }} type="textarea" placeholder="address" value={addressForUpdate} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                            <Button onClick={() => { setChangeAddress(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                            <Button onClick={() => {
                                addressMutation.mutate()
                            }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                        </Col>
                    </Row>
                </Col>) : (//if change address is false this part gonna be rendered
                <>
                    <Col>
                        <Row>
                            <Col xs="12" md="6" xl="4">
                                <Label>İL</Label>
                                <Form.Select className={`${address === null && "border-danger"}`} disabled onChange={(e) => {
                                    setCity(e.target.value)
                                    setIsCity(true)
                                }} aria-label="Default select example">
                                    <option >{address?.city ? address.city : "boş"}</option>

                                </Form.Select>
                            </Col>
                            <Col xs="12" md="6" xl="4">
                                <Label>İLÇE</Label>
                                <Form.Select className={`${address === null && "border-danger"}`} disabled onChange={(e) => {
                                    setDistrict(e.target.value)
                                    setIsDistrict(true)
                                }} aria-label="Default select example">
                                    <option>{address?.district ? address.district : "boş"}</option>

                                </Form.Select>
                            </Col>
                            <Col xs="12" md="6" xl="4">
                                <Label>MAHALLE</Label>
                                <Form.Select className={`${address === null && "border-danger"}`} disabled aria-label="Default select example">
                                    <option>{address?.neighborhood ? address.neighborhood : "boş"}</option>

                                </Form.Select>
                            </Col>
                            <Col className="mt-2" xs="12" md="6" xl="4">
                                <Label>SOKAK</Label>
                                <Input className={`${address === null && "border-danger"}`} disabled type="text" placeholder={address?.street ? address.street : "boş"}>

                                </Input>
                            </Col>
                            <Col className="mt-2" xs="12" md="6" xl="4">
                                <Label>DIŞ KAPI NO</Label>
                                <Input className={`${address === null && "border-danger"}`} disabled type="text" placeholder={address?.apartment_no ? address.apartment_no : "boş"} />
                            </Col>
                            <Col className="mt-2" xs="12" md="6" xl="4">
                                <Label>İÇ KAPI NO</Label>
                                <Input className={`${address === null && "border-danger"}`} disabled type="text" placeholder={address?.house_no ? address.house_no : "boş"} />
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                        <Row>
                            <Col className="mt-2">
                                <Label>ADRES TARİFİ</Label>
                                <Input className={`${address === null && "border-danger"}`} disabled type="textarea" placeholder={address?.address ? address.address : "boş"} />
                            </Col>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default AddressInput;

