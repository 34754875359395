// SelectComponent.jsx
import React from 'react';

// ! Şirket türü seçmeyi kolaylaştıran Select componentı.
const SelectComponent = ({ value, onChange, options, placeholder }) => (
    <select
        value={value}
        onChange={onChange}
        className="form-select form-text"
        aria-label="Default select example"
        style={{ padding: "20px" }}
    >
        <option defaultValue>{placeholder}</option>
        {options.map((option) => (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        ))}
    </select>
);

export default SelectComponent;
