import {create} from 'zustand';

const useReviewStore = create((set, get) => ({
    // States from Reviews page
    currentPage: 0,
    errorMessage: "",
    selectedReviewId: 0,
    isAnswered: "",
    isNextButtonDisabled: false,

    // States from ReviewDetails page
    reviewDetails: null,
    reviewReply: null,
    message: "",
    error: "",

    // Additional States from previous store
    reviews: [],
    filteredData: [],

    // Actions for Reviews page
    setCurrentPage: (page) => set({ currentPage: page }),
    setErrorMessage: (message) => set({ errorMessage: message }),
    setSelectedReviewId: (id) => set({ selectedReviewId: id }),
    setIsAnswered: (value) => set({ isAnswered: value }),
    setIsNextButtonDisabled: (value) => set({ isNextButtonDisabled: value }),

    // Actions for ReviewDetails page
    setReviewDetails: (details) => set({ reviewDetails: details }),
    setReviewReply: (reply) => set({ reviewReply: reply }),
    setMessage: (message) => set({ message }),
    setError: (error) => set({ error }),

    // Additional Actions from previous store
    setReviews: (reviews) => set({ reviews }),
    setFilteredData: (data) => set({ filteredData: data }),
}));

export default useReviewStore;
