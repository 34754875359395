// FormContext.js
import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

// !  createContext ve useContext kullanarak tüm kayıt olma verilerini formData içinde topluyor. Önce 1. sayfa sonra 2. sayfa.
// ! Bu kısım ana veriler için gerekli ama hataların state management'ından ayırmak için bu kısmı Zustang'da önce "slice" olarak alıp store'da birleştirebiliriz.
export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({});

    const updateFormData = (newData) => {
        setFormData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    return (
        <FormContext.Provider value={{ formData, updateFormData }}>
            {children}
        </FormContext.Provider>
    );
};

export const useForm = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useForm must be used within a FormProvider');
    }
    return context;
};
