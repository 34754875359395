// PaginationButtons.jsx
import React from "react";
import { Button, Col, Row } from "reactstrap";

const PaginationButtons = ({ currentPage, handlePreviousPage, handleNextPage, nextButtonDisabled }) => (
    <Row>
        <Col className="d-flex justify-content-end">
            {currentPage > 0 && (
                <Button className="prevButton" onClick={handlePreviousPage}>
                    Geri
                </Button>
            )}
            <Button className="nextButton" onClick={handleNextPage} disabled={nextButtonDisabled}>
                İleri
            </Button>
        </Col>
    </Row>
);


export default PaginationButtons;
