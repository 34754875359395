import {create} from 'zustand';
import axios from 'axios';

const useOrderStore = create((set) => ({
    // orders: [],
    page: 0,
    sortBy: 'order_date',
    order: 'DESC',
    name: '',
    showAll: true,
    loading: false,
    error: null,

    setNextPage: () => set((state)=>({ page:state.page + 1 })),
    setPrevPage: () => set((state)=>({ page:state.page - 1 })),
    setPage: (page) => set({ page:page }),
    setSortBy: (sortBy) => set({ sortBy:sortBy }),
    setOrder: (order) => set({ order:order }),
    setName: (name) => set({ name:name }),
    setShowAll: (showAll) => set({ showAll:showAll }),

    
}));

export default useOrderStore;
