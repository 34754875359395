import React from "react";
import ColorCircle from "../ColorCircle";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";


export const SortableItem = ({ product, index, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id })
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: "none",

  }
  return (
    <>
      <tr style={style} ref={setNodeRef} {...attributes} {...listeners} className="pt-2">
        <td>
          <i className="bx bx-menu ms-4"></i>
          {index + 1}
        </td>
        <td>
          {product && product.variations_info ? (
            <img
              src={JSON.parse(product.variations_info)[0].variation_media[0]}
              style={{
                width: "80px",
                height: "90px",
                borderRadius: "8px",
                objectFit: "cover"
              }}
              className="img-fluid mx-auto d-block"
              alt="Product"
            />
          ) : (
            <img
              src={product1}
              style={{
                width: "80px",
                height: "90px",
                borderRadius: "8px",
                objectFit: "cover"
              }}
              className="img-fluid mx-auto d-block"
              alt="Product Placeholder"
            />
          )}
        </td>
        <td>
          {product?.name && (
            <p className="mt-2" style={{ fontWeight: 500 }}>
              {product.name}
            </p>
          )}
          {product?.product_id && (
            <p style={{ fontWeight: 400 }}>
              Code: {product.product_id}
            </p>
          )}
          {product.colors && (
            JSON.parse(product.colors).length > 3 ? (
              // Render something else if the length is more than 3
              <div style={{ display: "flex", alignItems: "center" }}>
                {JSON.parse(product.colors).slice(0, 3).map((color, index) => (
                  <ColorCircle
                    key={index}
                    color={color.color_hex}
                  />
                ))}
                <div
                  style={{
                    width: "20px", // Yuvarlak rengin genişliği
                    height: "20px", // Yuvarlak rengin yüksekliği
                    borderRadius: "50%", // Yuvarlak yapmak için border-radius
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "6px",
                    marginBottom: "8px",

                  }}
                >
                  +{JSON.parse(product.colors).length - 3}
                </div>
              </div>
            ) : (
              // Render ColorCircle components if the length is not more than 3
              JSON.parse(product.colors).map((color, index) => (
                <ColorCircle
                  key={index}
                  color={color.color_hex}
                />
              ))
            )
          )}
        </td>
        <td style={{ display: "flex", alignItems: "center" }}>
        </td>
      </tr>
    </>
  )
}