import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import ColorCircle from "./ColorCircle";
import Rating from "./Rating";

const ReviewCard = ({ review, onClicked }) => {
    return (
        <Card style={{cursor: "pointer"}} onClick={onClicked}>
            <CardBody>
                <CardTitle>
                    <Row>
                        <Col>
                            {review.username ?? "Kullanıcı Adı"}
                        </Col>
                        <Col className="d-flex flex-column align-items-end">
                            <Row>
                                <Col>
                                    <Rating rating={review.rating ?? 0} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardTitle>
                <Row className="mb-2">
                    <Col xs="12" lg="12" className="d-flex flex-column align-items-start">
                        <Row className="d-flex align-items-center">
                            <Col className="d-flex flex-column ">
                                <div className="d-flex justify-content-between gap-1">
                                    <span>
                                        <span style={{ opacity: "0.5" }}>
                                            Boy:
                                        </span>
                                        &nbsp;{(review?.height ?? 0 + "cm") ?? "Boy"}
                                    </span>
                                </div>
                            </Col>
                            <Col >
                                <span>
                                    <span style={{ opacity: "0.5" }}>
                                        Kilo:
                                    </span>
                                    &nbsp;{(review?.weight ?? 0 + "kg") ?? "Kilo"}
                                </span>
                            </Col>
                            <Col >
                                <div className="d-flex align-items-center gap-1">
                                    <span style={{ opacity: "0.5" }}>
                                        Ürün:
                                    </span>
                                    <div style={{
                                        border: "1px solid #DCDCDC",
                                        borderRadius: "12px", // Yuvarlak yapmak için border-radius
                                        padding: "4px 8px"
                                    }}>
                                        {review?.size ?? "Beden"}
                                    </div>
                                    <ColorCircle color={review?.color?.color_hex ?? "#FFF"} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <span>{review?.comment ?? "Yorum"}</span>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ReviewCard