import React from "react";
import { Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import ColorCircle from "./ColorCircle";
import Rating from "./Rating";

const SelectedReviewCard = ({ review }) => {
    const imageStyle = { width: "200px", height: "100%", maxWidth: "100%", };

    return (
        <Card className="m-0 py-0 pe-2 ps-0">
            <Row className='py-0 pe-2 ps-0 d-flex align-items-center'>
                <Col xs="12" md="2" className='d-flex flex-column justify-content-sm-center justify-content-md-start'>
                    <CardImg
                        src={review?.variation_media ?? productImage}
                        style={imageStyle}
                        alt="Ürün resmi"
                        className='pb-0'
                    />
                </Col>
                <Col xs="12" md="10">
                    <CardBody className="p-0 pe-1">
                        <CardTitle className="my-1">
                            <Row>
                                <Col>
                                    <span className='d-flex' style={{ color: "#343A40", fontSize: "14px", fontWeight: "500" }}>
                                        {review?.product_name ?? "Ürün Adı"}
                                    </span>
                                </Col>
                                <Col className="d-flex flex-column align-items-end">
                                    <Rating rating={review?.rating ?? 0} />
                                </Col>
                            </Row>
                        </CardTitle>
                        <CardSubtitle className="my-1">
                            <Row>
                                <Col>
                                    {review?.category_name ?? "Kategori"}
                                </Col>
                                <Col className="d-flex flex-column align-items-end">
                                    <div className="d-flex justify-content-between">
                                        <span>
                                            <span style={{ opacity: "0.5" }}>
                                                Boy:
                                            </span>
                                            &nbsp;{(review?.height + "cm") ?? "Boy"}
                                        </span>
                                        &nbsp;
                                        <span>
                                            <span style={{ opacity: "0.5" }}>
                                                Kilo:
                                            </span>
                                            &nbsp;{(review?.weight + "kg") ?? "Kilo"}
                                        </span>
                                    </div>
                                </Col>
                            </Row>

                        </CardSubtitle>
                        <Row className="d-flex align-items-center my-1">
                            <Col>
                                <span style={{ color: "#74788D", fontSize: "12px", fontWeight: "400" }}>
                                    {review?.reviewed_at ?? "Tarih Belirtilmemiş..."}
                                </span>
                            </Col>
                            <Col className="d-flex flex-column align-items-end">
                                <div className="d-flex align-items-center">
                                    <ColorCircle color={JSON.parse(review?.color)?.color_hex ?? "#FFF"} />
                                    <span>
                                        {JSON.parse(review?.color)?.color_name ?? "Ürün Rengi"}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-1">
                            <Col>
                                <span>Alınan Fiyat: {review?.price ?? 0}</span>
                            </Col>
                            <Col className="d-flex flex-column align-items-end">
                                <div style={{
                                    border: "1px solid #DCDCDC",
                                    borderRadius: "12px", // Yuvarlak yapmak için border-radius
                                    padding: "4px 8px"
                                }}>
                                    {review?.size ?? "Beden"}
                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                </Col>
            </Row>

        </Card>
    )
}

export default SelectedReviewCard