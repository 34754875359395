import React from "react";

import Placeholder from 'react-bootstrap/Placeholder';

const StackedColumnChartError = ({ dataColors, productSalesData }) => {


    
    return (
        <React.Fragment>
            <h4>Bir şeyler ters gitti </h4>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={4} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={6} />
            </Placeholder>

        </React.Fragment>
    );
};

export default StackedColumnChartError;
