// login
export const POST_AUTH_LOGIN = `${process.env.REACT_APP_API_URL}/api/v1/auth/login`
export const GET_IS_AUTH = `${process.env.REACT_APP_API_URL}/api/v1/auth/is-authenticated`
export const GET_REFRESH_TOKEN = `${process.env.REACT_APP_API_URL}/api/v1/auth/refresh-token`
export const POST_FORGOT_PASSWORD = `${process.env.REACT_APP_API_URL}/api/v1/auth/forgot-password`
export const POST_VERIFY_CODE = `${process.env.REACT_APP_API_URL}/api/v1/auth/verify-code`
export const POST_CHANGE_PASSWORD = `${process.env.REACT_APP_API_URL}/api/v1/auth/change-password`

// DASHBOARD
export const GET_AUTH_DASHBOARD = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard`

// City & District & Neighborhood
export const GET_TRANSACTIONS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/token/get-transactions`
export const GET_OFFERS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/token/get-offers`
export const GET_CREDIT_CARDS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/token/get-credit-cards`
export const GET_ABOUT_STORE = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/`
export const GET_PRODUCT_DETAILS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/detail`
export const GET_LATEST_TRANSACTION_PRODUCT = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/latest-transactions?page=0`
// export const GET_AUTH_TOKEN = "${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/token";
export const GET_AUTH_TOKEN = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/token/`
export const GET_TOP_SELLING_PRODUCT = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/top-selling-product`
export const GET_AUTH_CITY = `${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/il`
export const GET_AUTH_DISTRICT = `${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/ilce`
export const GET_AUTH_NEIGBORHOOD = `${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/mahalle`

export const GET_ADS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads`;
export const GET_ADS_DETAIL = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/detail`;
// REGISTER
export const POST_AUTH_REGISTER = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/register/`

// REVIEWS
export const GET_REVIEWS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/reviews`;
export const GET_REVIEW_DETAILS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/reviews/detail`;
export const GET_REVIEW_REPLY = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/reviews/get-reply`
export const POST_REVIEW_REPLY = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/reviews/reply`;
export const POST_REVIEW_SEARCH = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/reviews/search`;
export const POST_DUPLICATE_INFORMER = `${process.env.REACT_APP_API_URL}/api/v1/auth/duplication-check`;

// INVENTORY
export const POST_PRODUCT_SEARCH = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/search`;
export const DELETE_PRODUCT = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/delete-product`;
export const GET_DISCOUNT_ITEMS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/get-discount-items`;
export const POST_DISCOUNT_ITEMS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/apply-discount/`;
export const GET_OUT_OF_STOCK_ITEMS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/info-out-of-stock`
export const POST_OUT_OF_STOCK_ITEMS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/apply-out-of-stock`
export const POST_NEW_PRODUCT_PART_1 = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/new-product/part-1/`
export const POST_NEW_PRODUCT_PART_2 = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/new-product/part-2/`;

// SMS
export const GET_GENERATE_SMS_CODE = `${process.env.REACT_APP_API_URL}/api/v1/verification/sms/generate-code`
export const POST_VERIFY_SMS_CODE = `${process.env.REACT_APP_API_URL}/api/v1/verification/sms/verify-code`

// PAYMENTS and TOKENS
export const GET_CARDS = `${process.env.REACT_APP_API_URL}/api/v1/payment/get_card_details`
export const POST_ADD_CARD = `${process.env.REACT_APP_API_URL}/api/v1/payment/add_card`
export const PUT_UPDATE_CARD = `${process.env.REACT_APP_API_URL}/api/v1/payment/update_card`
export const DELETE_CARD = `${process.env.REACT_APP_API_URL}/api/v1/payment/delete_card`
export const POST_QUERY_BIN = `${process.env.REACT_APP_API_URL}/api/v1/payment/query-BIN/`
export const GET_HIDDEN_FIELDS_SELLER = `${process.env.REACT_APP_API_URL}/api/v1/paytr/payment/get-hidden-fields-seller`

// PRODUCT DETAILS
export const GET_TOTAL_PURCHASES = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/detail/total-purchases`
export const GET_TOTAL_REFUNDS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/detail/total-refunds`
export const GET_SIZE_AND_VARIATIONS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/detail/size-and-variations`
export const GET_SPECIFICATIONS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/detail/specifications`
export const GET_REVIEWS_WITH_ID = `${process.env.REACT_APP_API_URL}/api/v1/review/get_reviews`

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";


//PROFILE
export const POST_EDIT_JWT_PROFILE = `/post-jwt-profile`;
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/?page=0`;
export const GET_PRODUCTS_DETAIL = "/product";


//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
// export const GET_QUESTION_ASKERS = "/questionAskers";
// export const GET_QUESTIONS = "/questions";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";


//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";





