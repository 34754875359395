import React from 'react';

const PriceTable = () => {
  const data = [
    { ds: '0 ', min: 0, max: 2, price: 71.40 },
    { ds: '3', min: 3, max: 5, price: 82.80 },
    { ds: '6 ', min: 6, max: 10, price: 97.20 },
    { ds: '11', min: 11, max: 15, price: 118.80 },
    { ds: '16 ', min: 16, max: 20, price: 151.20 },
    { ds: '21', min: 21, max: 25, price: 171.60 },
    { ds: '26', min: 26, max: 30, price: 202.80 },
    { ds: 'Artan', min: 'Artan', max: '', price: 7.56 }
  ];

  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
    marginTop: '20px'
  };

  const thStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center'
  };

  const tdStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center'
  };

  const priceStyle = {
    color: 'red',
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center'
  };

  const noteStyle = {
    marginTop: '10px',
    fontSize: '14px'
  };

  return (
    <div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th colSpan="2" style={thStyle}>DS/KG</th>
            <th rowSpan="2" style={thStyle}>TR Tek Fiyat (TL)</th>
          </tr>
          <tr>
            <th style={thStyle}>Min</th>
            <th style={thStyle}>Max</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td style={tdStyle} colSpan={row.ds === 'Artan' ? 2 : 1}>{row.ds}</td>
              {row.ds !== 'Artan' && <td style={tdStyle}>{row.max}</td>}
              <td style={priceStyle}>{row.price} ₺</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p style={noteStyle}>* KDV ve Eph hariçtir</p>
      <p style={noteStyle}>* Fiyatlar parça başıdır</p>
    </div>
  );
};

export default PriceTable;
