import React from 'react';
import { Col, Button} from 'reactstrap';
import ErrorDisplay from '../hataGöster/ErrorDisplay';

// ! Register'ın 2. sayfasında kullanılan işaretleme kutusu, açıklaması ve bilgilendirme popupı.
export const CheckBoxSection = ({ checked, onChange, buttonText, onClick, ModalComponent, modalShow, setModalShow, error }) => {


    const buttonStyle = {
        backgroundColor: "#F8F8FB",
        border: "none",
        color: "blue",
        textDecoration: "underline",
        marginBottom:"5px"
    };

    return (
        <Col lg="12">
            <div className="form-check">
                <input
                    type="checkbox"
                    checked={checked}
                    onClick={onChange}
                />
                <Button style={buttonStyle} onClick={onClick}>
                    {buttonText}
                </Button>
            </div>
            <ModalComponent modalShow={modalShow} setModalShow={setModalShow} />
            <ErrorDisplay error={error} />
        </Col>
    );
};
