import React from "react";

const DeclinedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#FFCECE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7 3.40286L6.59714 3L5 4.59714L3.40286 3L3 3.40286L4.59714 5L3 6.59714L3.40286 7L5 5.40286L6.59714 7L7 6.59714L5.40286 5L7 3.40286Z" fill="#BF1616" />
        </svg>
    )
}

export default DeclinedIcon;