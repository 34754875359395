import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const AveragePriceBox = ({ averagePriceData }) => {
    // const { value, previous_period_percentage } = averagePriceData;
    let dataToDisplay
    if (averagePriceData === 0) {
        dataToDisplay = "Lütfen Ürünü yükleyin"
    }else{
        dataToDisplay = averagePriceData
    }

    return (
        <Col md="4">
            <Card className="mini-stats-wid">
                <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                <i className="bx bx-purchase-tag-alt" />
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Ortalama Fiyat</h5>
                    </div>
                    <div className="text-muted mt-4">
                        <h4 style={dataToDisplay === "Lütfen Ürünü yükleyin" ? {color: "#F1B44C"}:{}}>
                            {dataToDisplay} 
                        </h4>
                        {/* <div className="d-flex">
                            <span className="badge badge-soft-warning font-size-12">
                                {previous_period_percentage}
                            </span>{" "}
                            <span className="ms-2 text-truncate">Önceki dönemden</span>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default AveragePriceBox;
