import React, { useEffect, useState, useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { IoAlert } from "react-icons/io5";

import { Link } from "react-router-dom";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import avatar1 from "../../assets/images/order/avatar-1.jpg"
import "./chat.css"

import { toast } from 'react-toastify';

import {
  UncontrolledTooltip
} from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";
import LightBox from "./LightBox";

const Question = () => {


  document.title = "Teknik Destek | Butiko";


  // const { chats, messages } = useSelector(state => ({
  //   chats: state.chat.chats,
  //   messages: state.chat.messages,
  // }));
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [messageBox, setMessageBox] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(1);

  const [currentUser, setCurrentUser] = useState({
    name: "Henry Wells",
    isActive: true,
  });


  const [Chat_Box_Username, setChat_Box_Username] = useState("");

  const [curMessage, setcurMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [recipientId, setRecipientId] = useState(0);
  const [messageHistory, setMessageHistory] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newMsg, setNewMsg] = useState([]);
  const [imgSrc, setImgSrc] = useState("");
  const [shouldStartNewChatFlag, setShouldStartNewChatFlag] = useState(false);
  const [chat, setChat] = useState({});

  const options = { hour: 'numeric', minute: 'numeric', hour12: false };

  // useEffect(() => {
  //   if (!isEmpty(messages)) scrollToBottom();
  // }, [messages]);
  const getChatHistory = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/crm_chat_history/${currentRoom}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const {
    isError: chatHistoriIsError,
    isPending: chatHistoryIsPending,
    error: chatHistoryError,
    data: chatHistoriData,
    refetch: chatHistoryRefetch,
  } = useQuery({
    queryKey: ["get-chat-history"],
    queryFn: getChatHistory,
    enabled: false,
    retry: false,

  })
  let chatHistoriDataToShow = []
  if (chatHistoryIsPending) {
  } else if (chatHistoriIsError) {
    console.log(chatHistoryError);
  } else {
    chatHistoriDataToShow = chatHistoriData.data
    // console.log(chatHistoriData.data);
  }
  //Use For Chat Box
  const userChatOpen = (id, name, recipient, chat) => {
    if (name === "null null") {
      setChat_Box_Username("Müşteri Temsilcisi Bekleniyor")
    } else {

      setChat_Box_Username(name);
    }
    setChat(chat)
    // console.log(chat);
    setCurrentRoom(id);
    setRecipientId(recipient)
    if (newMsg.includes(recipient)) {
      setNewMsg(prev => prev.filter(number => number !== recipient))
    }
    // console.log(recipient);
  };
  const getSellerSessions = useCallback(() => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/crm_user_sessions/`
    return axios.get(finalUrl, { withCredentials: true })
  })
  const { isError, isPending, error, data, refetch: sessionsRefetch } = useQuery({
    queryKey: ["get-sessions"],
    queryFn: getSellerSessions
  })
  let sessionData
  let isOpenSession = []
  if (isPending) {
    sessionData = "loading"
  } else if (isError) {
    console.log(error);
  } else {
    isOpenSession = data?.data?.sessions.filter((item) => item.status != 'closed')
    sessionData = data?.data?.sessions
    // sessionData.forEach((data) => {
    //   if (data.status != "closed") {
    //     userChatOpen(
    //       data._id,
    //       `${data.crm_first_name} ${data.crm_last_name}`,
    //       data.crm_id,
    //       data
    //     );
    //   }
    // })
    // console.log(data.data);
  }
  const [sellerId, setSellerId] = useState(0);
  const [sellerUserName, setSellerUserName] = useState("");
  //when using react query we can not use setstate but by using usememo we can do it 
  useMemo(() => {
    setUserId(data?.data?.user_id);
    if (data?.data?.sessions) {

      setSellerId(data?.data?.sessions[0]?.user_id)
      setSellerUserName(data?.data?.sessions[0]?.user_last_name)
      sessionData.forEach((data) => {
        // console.log(data);
        if (data.status != "closed") {
          userChatOpen(
            data._id,
            `${data.crm_first_name} ${data.crm_last_name}`,
            data.crm_id,
            data
          );
        }
      })
    }
  }, [data?.data])


  // ------------------------------web socket---------------------------
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `wss://butikoseller.com/chat_api/sellersocket/${userId}`,
    {
      onMessage: (e) => {
        console.log('Received message:', JSON.parse(e.data));
        if (JSON.parse(e.data).type === "session_closed") {
          console.log("close");
          setShouldStartNewChatFlag(true)
          sessionsRefetch()
          // chatHistoriData = [];
          chatHistoriDataToShow = []
          setCurrentRoom(1)
          chatHistoryRefetch()
          setChat_Box_Username("")
          setChat({})
          setRecipientId(0)
          
        } else {
          chatHistoryRefetch();
          sessionsRefetch()
          toast.info("Crm'den yeni mesaj", {
            icon: <IoAlert color="#556EE6" size={24} />,
          });
          scrollToBottom()
        }
      },

    })

  useEffect(() => {
    if (lastMessage !== null) {
      console.log(lastMessage);
      chatHistoryRefetch()
      sessionsRefetch()

    }
  }, [lastMessage, setMessageHistory]);
  // --------------------------end web socket-------------------------


  const [percent, setPercent] = useState(0);
  const uploadMedia = (img) => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/media/upload_files`
    const finalData = new FormData()
    finalData.append('files', img)
    return axios.post(finalUrl, finalData, {
      withCredentials: true, onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded} b of ${total} b | ${percent}%`);
        if (percent < 100) {
          setPercent(percent)
        }
      }
    })
  }
  const [imgUrl, setImgUrl] = useState("");
  const uploadMediaMutation = useMutation({
    mutationFn: uploadMedia,
    onSuccess: (data) => {
      setImgUrl(data.data.file_name[0])
      setPercent(100)
      setTimeout(() => {
        setPercent(0)
      }, 3000);
    },
    onError: (error) => { console.log(error); }
  })
  const [imgToUpload, setImgToUpload] = useState("");
  const handleImageChange = (event) => {
    if (event.target.files[0].size > 6000) {

      setImgToUpload(event.target.files[0])
      // const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 3000));
      toast.promise(
        uploadMediaMutation.mutateAsync(event.target.files[0]),
        {
          pending: 'Yükleniyor',
          success: 'Yüklendi👌',
          error: 'Yüklenmedi'
        }
      )
      uploadMediaMutation.mutate(event.target.files[0])
      event.preventDefault();
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Minimum Dosya Boyutu 5 Kilobyte'dır.")
    }
  };
  const shouldStartNewChat = (chatId) => {
    // console.log(chatId);
    if (Array.isArray(sessionData)) {

      for (let i = 0; i < sessionData.length; i++) {
        const element = sessionData[i];
        if (element._id === chatId && element.status === "closed") {
          setShouldStartNewChatFlag(true);
          break; // Exit the loop when condition is met
        } else {
          setShouldStartNewChatFlag(false)
        }
      }
    }
    if (isOpenSession.length == 0 && chatId == 1) {
      setShouldStartNewChatFlag(true)
    }
    console.log(shouldStartNewChatFlag);
  }
  useEffect(() => {
    shouldStartNewChat(currentRoom)
    console.log(shouldStartNewChatFlag);
  }, [currentRoom, chat])
  useEffect(() => {
    shouldStartNewChat(currentRoom)
    console.log(currentRoom);
  }, [])


  useEffect(() => {
    // This will run after the state has been updated
    chatHistoryRefetch();
    setTimeout(() => {
      scrollToBottom()
    }, 1000);
  }, [currentRoom]);

  const addMessage = (roomId, sender) => {

    if (curMessage !== "" && recipientId !== null) {
      const test = {
        recipient: recipientId,
        recipient_type: "crm",
        message: {
          from_seller_name: sellerUserName,
          from_seller_id: sellerId,
          text: curMessage,
          ...(imgUrl && { media: imgUrl }),
        }
      }
      console.log(test);
      sendMessage(JSON.stringify(test), []);
      // chatHistoriData.data.push(test)
      chatHistoryRefetch()
      setImgUrl("")
      setcurMessage("");
      setSelectedImage(null);
      setImgToUpload(null)
    } else {
      alert("no img")
    }
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  };
  const startNewChat = () => {
    console.log("start new chat");
    const chat = {
      recipient: 1,
      recipient_type: "crm",
      message: {
        from_seller_name: "start chat",
        from_seller_id: 0,
        text: ""
      }
    }
    sendMessage(JSON.stringify(chat), []);
    console.log(chat);
    sessionsRefetch()
  }




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Messages" breadcrumbItem="Crm" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div >
                    <div className="search-box chat-search-box pb-4">
                      <div className="position-relative">
                        <Input
                          // onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Ara..."

                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <div>
                        <ul className="list-unstyled chat-list" id="recent-list">

                          <PerfectScrollbar style={{ height: "70vh" }}>
                            {Array.isArray(sessionData) && sessionData?.map(chat => {

                              return (
                                <li
                                  key={chat._id + chat.status}
                                  // className={
                                  //   currentRoom === chat._id
                                  //     ? "active"
                                  //     : "bg-danger"
                                  // }
                                  className={`${currentRoom === chat._id ? "active" : ""} ${chat.status === "closed" ? " opacity-50 " : ""}`}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {

                                      userChatOpen(
                                        chat._id,
                                        `${chat.crm_first_name} ${chat.crm_last_name}`,
                                        chat.crm_id,
                                        chat
                                      );
                                    }}
                                  >
                                    <div className="d-flex">

                                      <div className="align-self-center me-3">
                                        <i
                                          className={newMsg.includes(chat.user_id) ? "mdi mdi-circle text-success font-size-10" : ""}
                                        />
                                      </div>
                                      <div className="align-self-center me-3">
                                        <img
                                          src={chat.crm_profile_picture === "null" || chat.crm_profile_picture === null ? avatar1 : chat.crm_profile_picture}
                                          className="rounded-circle avatar-xs"
                                          alt=""
                                        />
                                      </div>

                                      <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-14 mb-1">
                                          {!chat.crm_id ? ("Müşteri Temsilcisi Bekleniyor") : `${chat.crm_first_name} ${chat.crm_last_name}`}

                                        </h5>
                                        <p style={{ maxWidth: "80px" }} className="text-truncate mb-0">
                                          {/* {currentRoom === chat._id && chatHistoriData?.data[chatHistoriData?.data.length - 1]?.message?.text} */}
                                          {chat?.recent_messages[0]?.message?.text}
                                        </p>
                                      </div>
                                      <div className="font-size-11">
                                        {/* {currentRoom === chat._id && new Date(chatHistoriData?.data[chatHistoriData?.data.length - 1]?.timestamp).toLocaleTimeString("en-US", options)} */}
                                        {chat?.recent_messages[0]?.timestamp ? new Date(chat.recent_messages[0].timestamp).toLocaleString("en-US", options) : ""}
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              )
                            }
                            )}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <Row>
                            <Col md="2" className="me-1">
                              <div className="avatar-xs align-self-center me-3 ">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                  {Chat_Box_Username[0]}
                                </span>
                              </div>
                            </Col>
                            <Col className="align-self-center">
                              <h5 className="font-size-15 mb-1">
                                {Chat_Box_Username}
                              </h5>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">

                        <ul className="list-unstyled">
                          <PerfectScrollbar
                            style={{ height: "470px" }}
                            containerRef={ref => setMessageBox(ref)}
                          >
                            {currentRoom == 1 && (
                              <h1 className=" align-items-center d-flex justify-content-center ">Lütfen Eski Bir Sohbet Seçin Veya,<br></br> Yeni Bir Sohbet Başlatın</h1>
                            )}

                            {Array.isArray(chatHistoriDataToShow) &&
                              chatHistoriDataToShow?.map((message, index) => {
                                const date = new Date(chatHistoriDataToShow[index]?.timestamp).toLocaleDateString('en-GB');
                                const prvDate = new Date(chatHistoriDataToShow[index - 1]?.timestamp).toLocaleDateString('en-GB');

                                return (
                                  <li
                                    key={"test_k" + message._id}
                                    className={
                                      message?.message?.from_seller_id
                                        ? "right"
                                        : ""
                                    }
                                  >

                                    <p style={{ textAlign: "center" }}>{date > prvDate && date}</p>
                                    <p style={{ textAlign: "center" }}>{index === 0 && date}</p>
                                    <div className="conversation-list">
                                      <div className="ctext-wrap">
                                        <div className="conversation-name">
                                          {message?.message?.from_user_name}
                                        </div>
                                        <p style={{ textAlign: "start" }}>{message?.message?.text}</p>
                                        {message?.message?.media &&
                                          <img
                                            className="chat-img"
                                            onClick={() => {
                                              handleShow()
                                              setImgSrc(message?.message?.media)
                                            }}
                                            src={message?.message?.media}
                                            alt=""
                                            width="150px"
                                          />
                                        }
                                        <p className="chat-time mb-0"> {message.timestamp ? new Date(message.timestamp).toLocaleTimeString('en-US', options) : new Date().toLocaleTimeString('en-US', options)}</p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              }
                              )
                            }
                          </PerfectScrollbar>
                        </ul>
                      </div>
                      {
                        selectedImage &&
                        <div className="replymessage-block mb-0 d-flex align-items-start ">
                          <div className="flex-grow-1">
                            <img src={selectedImage} alt="select img" style={{ width: "150px", height: "auto" }} />
                            {/* {percent > 0 && <ProgressBar animated now={percent} label={`${percent}%`} />} */}
                          </div>
                          <div className="flex-shrink-0">
                            <button type="button" id="close_toggle" className="btn btn-sm btn-link mt-n2 me-n3 fs-18" onClick={() => setSelectedImage(null)}>
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      }
                      {/* {chat && chat.status !== "closed" && ( */}
                      <div className="p-3 chat-input-section ">
                        <Row>
                          {!shouldStartNewChatFlag ? (
                            <>

                              <Col  >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    value={curMessage}
                                    onChange={e => setcurMessage(e.target.value)}
                                    className="form-control chat-input"
                                    placeholder={sessionData[0].crm_id === null ? "crm'i bekliyorum" : "Mesaj Girin..."}
                                    disabled={sessionData[0].crm_id === null}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        console.log("enter pressed");
                                        addMessage(currentRoom, currentUser.name)
                                      }
                                    }}
                                  />
                                </div>
                                <div className="chat-input-links">
                                  <ul className="list-inline mb-0">

                                    <li className="list-inline-item">
                                      <label htmlFor="imageInput" style={{ color: "#556ee6", fontSize: 16 }}>
                                        <i className="mdi mdi-file-image-outline me-1" id="Imagetooltip" />
                                        <UncontrolledTooltip placement="top" target="Imagetooltip">
                                          Images
                                        </UncontrolledTooltip>
                                      </label>
                                      <input disabled={currentRoom === 1} type="file" id="imageInput" className="d-none" onChange={handleImageChange} />
                                    </li>

                                  </ul>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={() =>
                                    addMessage(currentRoom, currentUser.name)
                                  }
                                  className="btn btn-primary btn-rounded chat-send w-md "
                                  disabled={currentRoom === 1 || curMessage === ""}
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    Gönder
                                  </span>{" "}
                                  <i className="mdi mdi-send" />
                                </Button>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Button onClick={startNewChat} className={`${isOpenSession.length > 0 ? ("d-none ") : ("") } ${currentRoom == 1 && isOpenSession.length > 0 ? ("d-none ") : ("")} btn btn-success`}>
                                Sohbeti Başlat
                              </Button>
                            </>
                          )}
                        </Row>
                        <LightBox modalOpen={show} setModalOpen={setShow} src={imgSrc} />
                      </div>
                      {/* )} */}
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
}

export default Question;