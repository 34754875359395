import React, { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap"

import { get } from "helpers/api_helper"

import { useNavigate } from "react-router-dom"

const AdDetailModal = ({ modalOpen, setModalOpen, id }) => {
  const history = useNavigate()

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const getPromotionDetail = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/detail?promotion_id=${id}`
    return get(finalUrl, { withCredentials: true })
  }
  const { isLoading, isError, error, data, refetch } = useQuery({
    queryKey: ["promotion-detail", id],
    queryFn: getPromotionDetail,
    enabled: false,
  })
  let detail
  let totalProducts
  let totalViews
  let mostVisitedProduct
  if (isLoading) {
    detail = (
      <tr>
        <td colSpan="4">Yükleniyor...</td>
      </tr>
    )
  } else if (isError) {
    if (

      error?.response?.data?.detail[0]?.msg === "unathorized action" ||
      error?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    detail = (
      <tr>
        <td colSpan="4">hata</td>
      </tr>
    )
    // console.log(error);
  } else {
    // console.log(data?.data);
    totalProducts = data?.length
    totalViews = data?.reduce(
      (sum, product) => sum + product.total_views,
      0
    )
    mostVisitedProduct = data?.reduce((maxProduct, product) => {
      return maxProduct.total_views > product.total_views ? maxProduct : product
    }, data[0])

    detail = data?.map((item, index) => {
      return (
        <tr key={index}>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt=""
              src={item.variation_media && JSON.parse(item.variation_media)[0]}
              style={{
                width: "80px",
                height: "110px",
                marginTop: "8px",
                objectFit: "cover",
                border: "1px solid #eee",
                borderRadius: "8px",
              }}
            />
          </td>
          <td style={{ textAlign: "center" }}>{item.product_name}</td>
          <td style={{ textAlign: "center" }}>{item.total_views}</td>
        </tr>
      )
    })
    // console.log(data);
  }
  useEffect(() => {
    if (modalOpen && id) {
      // Enable the query and trigger the API request
      refetch()
    }
  }, [modalOpen, id])

  return (
    <>
      <Container fluid={true}>
        <Modal
          style={{
            maxWidth: "35rem",
            maxHeight: "1200px",
            marginTop: "80px",
          }}
          isOpen={modalOpen}
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}>Reklam Ayrıntıları </ModalHeader>
          <ModalBody className="mx-3 my-3">
            <Row>
              <Col lg={12}>
                <Row>
                  <Col className="col-4 d-flex flex-column justify-content-center">
                    <h6
                      style={{
                        color: "black",
                        fontSize: "600",
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Toplam Ürünler
                    </h6>
                    <h1
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {totalProducts}
                    </h1>
                  </Col>
                  <Col className="col-4 d-flex flex-column justify-content-center">
                    <h6
                      style={{
                        color: "black",
                        fontSize: "600",
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Toplam görüntülenme
                    </h6>
                    <h1
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {totalViews}
                    </h1>
                  </Col>
                  <Col className="col-4 d-flex flex-column justify-content-center">
                    <h6
                      style={{
                        color: "black",
                        fontSize: "600",
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Çok ziyaret edilen
                    </h6>
                    <h1
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {mostVisitedProduct?.total_views}
                    </h1>
                  </Col>
                </Row>
                <div className="d-flex justify-content-start ">
                  <div className="position-relative col-12 col-sm-7 col-md-5"></div>
                </div>
                <div
                  style={{ maxHeight: "500px", overflow: "auto" }}
                  className="table-responsive mt-3"
                >
                  <table>
                    <thead>
                      <tr>
                        <th className="px-3 py-3">Ürünler</th>
                        <th className="px-3 py-3">Ürün Adi</th>
                        <th className="px-3 py-3">Ziyareti</th>
                      </tr>
                    </thead>
                    <tbody className="my-3">{detail}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Kapat
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}

export default withRouter(AdDetailModal)
