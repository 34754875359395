// CreditCardsComponent.js

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import SavedCard from './SavedCard';
// import Spinner from 'react-bootstrap/Spinner';
import { Skeleton } from "antd";
import EditCardModal from '../Popups/EditCardModal';

const CreditCards = ({
    creditCardsLoading,
    creditCardsError,
    creditCardsData,
    selectedCard,
    handleCardSelection,
    handleDeleteCard,
    refetch
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cardInformation, setCardInformation] = useState({});
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <Row>
            {creditCardsLoading ? (
                <span>
                    {/* Yükleniyor<Spinner className="ms-1" size="sm" animation="border" /> */}
                    <Skeleton active />
                </span>
            ) : creditCardsError ? (
                <span>Bir Hata Oluştu.</span>
            ) : (
                creditCardsData && creditCardsData.length === 0 ? (
                    <span className='text-danger'>Kayıtlı kart bulunamamıştır. Sağ alttaki butondan yeni kart ekleyebilirsiniz.</span>
                ) : (
                    creditCardsData.map((card, index) => (
                        <Col lg="12" key={index}>
                            <SavedCard
                                card={card}
                                isChecked={selectedCard === card.detail_id}
                                handleClick={() => handleCardSelection("saved", card)}
                                handleDelete={() => handleDeleteCard(card)}
                                isOpen={isOpen}
                                toggle={toggle}
                                setCardInformation={setCardInformation}
                            />
                        </Col>
                    ))
                )
            )}
            <EditCardModal cardInformation={cardInformation} isOpen={isOpen} toggle={toggle} refetch={refetch}/>
        </Row>
    );
};


export default CreditCards;
