import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Collapse,
  ModalFooter,
} from "reactstrap"
import { useState } from "react"

const HesapAyarlari = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [isOpen3, setIsOpen3] = useState(false)
  const [isOpen4, setIsOpen4] = useState(false)
  const [isOpen5, setIsOpen5] = useState(false)
  const [isOpen6, setIsOpen6] = useState(false)
  const [isOpen7, setIsOpen7] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggle2 = () => {
    setIsOpen2(!isOpen2)
  }
  const toggle3 = () => {
    setIsOpen3(!isOpen3)
  }
  const toggle4 = () => {
    setIsOpen4(!isOpen4)
  }
  const toggle5 = () => {
    setIsOpen5(!isOpen5)
  }
  const toggle6 = () => {
    setIsOpen6(!isOpen6)
  }
  const toggle7 = () => {
    setIsOpen7(!isOpen7)
  }
  const fontSize18 = { fontSize: "15px" }

  return (
    <div>
      <span onClick={toggleModal} style={{ ...fontSize18, cursor: "pointer",textDecoration:"underLine" }}>
        Soruları görmek için tıklayınız
      </span>
      <Modal
        style={{ maxWidth: "1000px", maxHeight: "1000px", marginTop: "80px" }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>
          HESAP AYARLARI İLE İLGİLİ SIKÇA SORULAN SORULAR
        </ModalHeader>
        <ModalBody
          className="mx-3 my-3"
          style={{ overflowY: "auto", minHeight: "55vh" }}
        >
          <Card style={{ backgroundColor: "white" }}>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Hesabımı nasıl askıya alabilirim?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen}>
                    <p>
                    Kısa bir süreliğine tatile mi gidiyorsunuz? Yada hesabınızı özel sebeplerden dolayı bir
süre kullanamyacak mısınız? Bu süre zarfında ürün satışınız gerçekleşir ise satın alan
kullancıları mağdur etmemek ve hesabınızında sipariş iptalinden olumsuz
etkilenmemesi adına bizimle iletişime geçerek sizden gelecek talebe kadar hesabınızı
askıya alınmasını talep edebilirsiniz.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle2}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen2 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Hesabımı nasıl silebilirim?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen2}>
                    <p>
                    Herhangi bir olumsuzluk yaşadığınızda destek ekibimiz ile iletişime geçmeniz
durumunda elimizden gelen tüm desteği size sağlayacağımızı bilmenizi isteriz. Ama
yinede artık Butiko ailesine dahil olmak istemiyorsanız bununla ilgili bizimle iletişime
geçerek hesabınızın silinmesi hususunda destek alabilirsiniz.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle3}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen3 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Hesabım neden engellendi?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen3}>
                    <p>
                    Butiko kullanıcı sözleşmesi yada kullanım koşullarına aykırı hareket edecek bir
davranışta bulunduysanız, hesabınıza erişiminiz kısa süreliğine yada süresiz olarak
engellenmiş olabilir. Bununla destek ekibimiz ile iletişime geçtiğinizde tarafınıza
gerekli açıklamalar sağlanacaktır.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default HesapAyarlari
