import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const StackedColumnChart = ({ dataColors, productSalesData }) => {

  const stackedColumnChartColors = getChartColorsArray(dataColors);
  // console.log(Array.isArray(productSalesData));
  // throw new Error("chart")

  const seriesData = Array.isArray(productSalesData) 
    ? [
      {
        name: "Satış",
        data: productSalesData.map((item) => item.sales),
      },
      {
        name: "İadeler",
        data: productSalesData.map((item) => item.returns),
      },
      {
        name: "Reklam Satışları",
        data: productSalesData.map((item) => item.ad_sales),
      },
    ]
    : [];

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 0,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: [
        "Oca",
        "Şub",
        "Mar",
        "Nis",
        "May",
        "Haz",
        "Tem",
        "Ağu",
        "Eyl",
        "Eki",
        "Kas",
        "Ara",
      ],
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={seriesData}
        type="bar"
        height="500"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default StackedColumnChart;
