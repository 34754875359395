import React, { useState, useEffect } from "react"
import axios from "axios"
import ColorCircle from "./ColorCircle"
import { Container, Col, Row, Button } from "reactstrap"
import SelectedProductBox from "./SelectedProductBox"
const EditProducts = ({ onAddProducts, editData }) => {
  const [selectedProductIds, setSelectedProductIds] = useState([])
  const [products, setProducts] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const sizeBoxStyle = {
    border: "1px solid #a2a2a2",
    padding: "4px 7px",
    margin: "0 3px 5px 3px",
    display: "inline-block",
    borderRadius: "10px",
  }

  const overflowStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
  useEffect(() => {
    fetchProducts()
  }, [searchValue])

  const fetchProducts = () => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/product-search`
    if (searchValue.trim() !== "") {
      url += `?search=${searchValue}`
    }

    axios
      .get(url, { withCredentials: true })
      .then(response => {
        const data = response.data
        const products = data.map(product => ({
          ...product,
          variation_media: JSON.parse(product.variation_media),
          colors: JSON.parse(product.colors),
        }))
        setProducts(products)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  const handleItemClick = productId => {
    let updatedSelection = []

    if (!selectedProductIds.includes(productId)) {
      updatedSelection = [...selectedProductIds, productId]
    } else {
      updatedSelection = selectedProductIds.filter(id => id !== productId)
    }

    setSelectedProductIds(updatedSelection)
  }

  const filterProducts = (productList, editData) => {
    const editDataProductIds = editData.map(product => product.product_id)

    return productList.filter(
      product => !editDataProductIds.includes(product.product_id)
    )
  }

  const handleRemoveProduct = productId => {
    setSelectedProductIds(selectedProductIds.filter(id => id !== productId))
  }

  const handleAddToCollection = () => {
    const editDataProductIds = editData.map(product => product.product_id)

    const selectedProductsFiltered = products.filter(
      product =>
        selectedProductIds.includes(product.product_id) &&
        !editDataProductIds.includes(product.product_id)
    )
    onAddProducts(selectedProductsFiltered)
  }

  return (
    <div style={{ padding: "10px" }}>
      <Row>
        <Col lg={6}>
          <div
            className="table-responsive mt-3"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <table>
              <thead>
                <tr>
                  <th className="px-3 py-3 border-0 "></th>
                  <th className="px-3 py-3">Ürünler</th>
                  <th className="px-3 py-3">Ürün Açıklaması</th>
                  <th className="px-3 py-3">Renkler</th>
                  <th className="px-3 py-3 me-2">Fiyat</th>
                </tr>
              </thead>
              <tbody style={{ cursor: "pointer" }}>
                {filterProducts(products, editData).map(product => (
                  <tr
                    key={product.product_id}
                    className="pt-2"
                    onClick={() => handleItemClick(product.product_id)}
                  >
                    <td>
                      <div className="form-check ms-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          style={{ border: "1px solid #a2a2a2" }}
                          checked={selectedProductIds.includes(
                            product.product_id
                          )}
                          readOnly
                        />
                      </div>
                    </td>
                    <td className="text-nowrap px-3">
                      <img
                        src={product.variation_media[0]}
                        alt="Product"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                        className="img-fluid mx-auto d-block"
                      />
                    </td>
                    <td className="text-nowrap px-3">
                      <p className="mt-2" style={{ fontWeight: 500 }}>
                        {product.name}
                      </p>
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {product?.colors?.slice(0, 3).map((color, index) => (
                          <ColorCircle key={index} color={color.color_hex} />
                        ))}
                        {product?.colors?.length &&
                          product.colors.length > 3 && (
                            <div
                              style={{
                                ...sizeBoxStyle,
                                ...overflowStyle,
                                marginBottom: 0,
                              }}
                            >
                              <span style={{ fontSize: "16px" }}>
                                + {product.colors.length - 3}
                              </span>
                            </div>
                          )}
                      </div>
                    </td>
                    <td>
                      <p style={{ fontWeight: 400 }} className="text-center">
                        {product.price} ₺
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col lg={6}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div>
              <h5>Koleksiyona Ekli Ürünler</h5>
              <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
                {editData.map(product => (
                  <Col
                    key={product.product_id}
                    lg={3}
                    md={3}
                    xl={2}
                    xs={6}
                    className="mt-2"
                  >
                    <div
                      className="d-flex justify-content-end"
                      style={{
                        position: "relative",
                        height: "100px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={product.variation_media[0]}
                        alt={product.product_name}
                        style={{
                          width: "100px",
                          height: "100px",
                          position: "relative",
                          zIndex: 0,
                          borderRadius: "8px",
                          objectFit: "cover",
                          border: "1px solid #eee",
                        }}
                      />
                    </div>
                    <p className="text-truncate fw-bold text-center mt-2">
                      {product.product_name}
                    </p>
                  </Col>
                ))}
              </Row>
              <h5 style={{ marginTop: "15px" }}>
                Koleksiyona Yeni Eklenecek Ürünler
              </h5>
              <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
                {selectedProductIds.length === 0 ? (
                  <Col xs={12}>
                    <p style={{ color: "#F46A6A" }}>
                      Seçili ürün yok, lütfen ürün seçiniz.
                    </p>
                  </Col>
                ) : (
                  selectedProductIds.map(productId => (
                    <Col
                      key={productId}
                      xs={6}
                      md={3}
                      style={{ marginBottom: "15px" }}
                    >
                      <SelectedProductBox
                        product={products.find(p => p.product_id === productId)}
                        onRemove={handleRemoveProduct}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </div>
            <div style={{ marginTop: "auto", marginLeft: "auto" }}>
              <Button color="primary" onClick={handleAddToCollection}>
                Ürünleri Ekle
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EditProducts
