// AddressInputComponent.jsx
import React, { useEffect, useState, useCallback, useRef, useMemo } from "react"
import Selector from "./Selector" // Import your Selector component
import { Button, Col, Row } from "reactstrap"
import MapModal from "../map/MapModal"
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api"
import icon from "../iconButiko/11Asset 12.png"

// ! Çok ama ÇOK fazla prop alması gerekiyor. Bunların HEPSİNİ Zustand'da addressSlice tarzı bir değişkene alalım.
const AddressInputComponent = ({
  city,
  setCity,
  district,
  setDistrict,
  neighborhood,
  setNeighborhood,
  street,
  setStreet,
  apartmentNo,
  setApartmentNo,
  houseNo,
  setHouseNo,
  address,
  setAddress,
  inputTitle,
  setLatitude,
  setLongitude,
  latt,
  lngg,
  isMultipleAddress,
  cityError,
  districtError,
  neighborhoodError,
  streetError,
  apartmentNoError,
  houseNoError,
  addressError,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const onLoad = useCallback(map => (mapRef.current = map), [])

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  // * Harita için kurulum kodları
  const mapRef = useRef()
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: "AIzaSyCjLPBtaQ4LReqxlwdjuIr9wFF_8wHeGwQ",
    googleMapsApiKey: "AIzaSyBv0r0C6360TDA3uM9iaPwQrTl4z8inMvw",
  })
  const option = useMemo(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  )
  const [center, setCenter] = useState({ lat: 41.0082, lng: 28.9784 })
  let position = {
    lat: latt,
    lng: lngg,
  }
  useEffect(() => {
    if (latt !== 0) {
      setCenter({
        lat: latt,
        lng: lngg,
      })
    }
  }, [latt])
  if (!isLoaded) return <div>loading...</div>
  return (
    <>
      <Row>
        <Col lg="10">
          {/* İl, ilçe mahalle ve sokak seçmek için bir component.
                    Aynı şekilde AddressInputComponent'a gelen TÜM proplar bu Selector bileşenine aktarılıyor.
                    Sonradan bu Component'ı Zustand ile basitleştirmemiz gerekiyor */}
          <Selector
            city={city}
            setCity={setCity}
            district={district}
            setDistrict={setDistrict}
            neighborhood={neighborhood}
            setNeighborhood={setNeighborhood}
            street={street}
            setStreet={setStreet}
            cityError={cityError}
            districtError={districtError}
            neighborhoodError={neighborhoodError}
            streetError={streetError}
            apartmentNo={apartmentNo}
            setApartmentNo={setApartmentNo}
            apartmentNoError={apartmentNoError}
            houseNo={houseNo}
            setHouseNo={setHouseNo}
            houseNoError={houseNoError}
            address={address}
            setAddress={setAddress}
            addressError={addressError}
          />
        </Col>
      </Row>

      {/* Seçilen adres mağaza ise ve isMultipleAddress işaretliyse en altta konum seçme kısmı için harita popupı görünüyor. */}
      {inputTitle === "office" && (
        <Row style={{ marginTop: "20px" }}>
          <Col className="d-flex justify-content-end  " lg="8">
            <GoogleMap
              zoom={10}
              center={center}
              mapContainerClassName="register-map"
              options={option}
              onLoad={onLoad}

              // onClick={handleDBClick}
            >
              {position.lat !== 0 && <Marker position={position} icon={icon} />}
            </GoogleMap>
          </Col>
          <Col
            className=" d-flex align-items-center justify-content-center "
            lg="2"
          >
            {/* <Button className='btn btn-info' style={{}}>fsudjoifu</Button> */}
            <Button
              onClick={() => {
                toggleModal()
              }}
              className="btn"
              style={{background:"#556EE6", border:"1px solid #CED4DA",}}
            >
              {latt === 0 ? "Konum Seçiniz" : "Konumu Düzenle"}
            </Button>
          </Col>
          <MapModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
          />
        </Row>
      )}
    </>
  )
}

export default AddressInputComponent
