import React, { useEffect, useState } from 'react';
import { Container, Button, Card, CardBody, Row, Col, CardImg, CardTitle } from "reactstrap";

import avatarImage from "../../assets/images/order/avatar-1.jpg";

import { formatDistanceToNow } from 'date-fns';
import { tr } from 'date-fns/locale'; // Import Turkish locale
import { useNavigate } from 'react-router-dom'; // Import useParams

import { get, post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { useQuery, QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";

// import toastr from 'toastr';
import { toast } from 'react-toastify';
import SelectedReviewCard from './Components/SelectedReviewCard';
import useReviewStore from './store';


const queryClient = new QueryClient();

const ReviewDetailQuery = () => {

    const navigate = useNavigate();

    const selectedReviewId = useReviewStore((state) => state.selectedReviewId);
    const reviewDetails = useReviewStore((state) => state.reviewDetails);
    const reviewReply = useReviewStore((state) => state.reviewReply);
    const message = useReviewStore((state) => state.message);
    const error = useReviewStore((state) => state.error);

    const setReviewDetails = useReviewStore((state) => state.setReviewDetails);
    const setReviewReply = useReviewStore((state) => state.setReviewReply);
    const setMessage = useReviewStore((state) => state.setMessage);
    const setError = useReviewStore((state) => state.setError);

    const fetchReviewDetails = async () => {
        try {
            if (!selectedReviewId || selectedReviewId === 0) {
                throw new Error("Review ID is not defined");
            }
            const response = await get(`${url.GET_REVIEW_DETAILS}?review_id=${selectedReviewId}`, { withCredentials: true });
            return response;
        } catch (error) {
            handleError(error);
        }
    };

    const fetchReviewReply = async () => {
        try {
            if (!selectedReviewId || selectedReviewId === 0) {
                throw new Error("Review ID is not defined");
            }
            const response = await get(`${url.GET_REVIEW_REPLY}?review_id=${selectedReviewId}`, { withCredentials: true });
            return response;
        } catch (error) {
            handleError(error);
        }
    };

    const handleError = (error) => {
        if (error.response?.status === 404) {
            return null;
        }
        if (["unathorized action", "invalid jwt"].includes(error.response?.data?.detail?.[0]?.msg)) {
            navigate("/login");
            return;
        }
        console.log(error);
        return null;
    };

    const { data, isLoading, isError, refetch: reviewDetailsRefetch } = useQuery({
        queryKey: ['reviewDetails', selectedReviewId],
        queryFn: fetchReviewDetails,
        onError: (error) => {
            console.log(error);
        },
        enabled: selectedReviewId !== 0,
    });

    useEffect(() => {
        setReviewDetails(data);
    }, [data, setReviewDetails])

    const { data: replyData, refetch: reviewReplyRefetch } = useQuery({
        queryKey: ['reviewReply', selectedReviewId],
        queryFn: fetchReviewReply,
        enabled: !isLoading && !isError && selectedReviewId !== 0,
    });

    useEffect(() => {
        setReviewReply(replyData);
    }, [replyData, setReviewReply])

    useEffect(() => {
        if (isError) {
            setError("Error fetching review details");
        } else {
            setError("");
        }
    }, [isError]);

    const sendMessageMutation = useMutation({
        mutationFn: async () => {
            const messageSent = {
                review_id: selectedReviewId,
                reply: message,
            };
            await post(url.POST_REVIEW_REPLY, messageSent, { withCredentials: true });
        },
        onSuccess: async () => {
            toast.success("Mesaj başarıyla gönderildi", { toastId: "sentSuccess" });
            await reviewDetailsRefetch();
            await reviewReplyRefetch();
        },
        onError: (error) => {
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "someError" });
            if (["unauthorized action", "invalid jwt"].includes(error.response?.data?.detail?.[0]?.msg)) {
                navigate("/login");
            }
            console.log(error);
        }
    });

    const handleSendMessage = async () => {
        try {
            await sendMessageMutation.mutateAsync();
        } catch (error) {
            console.error(error);
        }
    };

    const imageStyle = { width: "200px", height: "100%", maxWidth: "100%", borderRadius: "8px", };

    return (
        <Container fluid={true}>
            {reviewDetails ? (
                <>
                    <Row>
                        <Col>
                            <SelectedReviewCard review={reviewDetails} />
                        </Col>
                    </Row>
                    <hr
                        style={{
                            color: "#0000001A",
                            backgroundColor: "#0000001A",
                            border: "1px solid #0000001A",
                            margin: "0"
                        }}
                    />
                    <Row>
                        <Col>
                            <Card className='py-0 pe-2 ps-0'>
                                <Row>
                                    <Col xs="12" md="12">
                                        <CardBody className='p-3'>
                                            <Row className='mb-3'>
                                                <Col className='d-flex align-items-center gap-3'>
                                                    <div className="align-self-center">
                                                        <img
                                                            src={reviewDetails?.user_profile_picture ?? avatarImage}
                                                            className="rounded-circle avatar-xs"
                                                            alt="Profile Image"
                                                        />
                                                    </div>
                                                    <span style={{ fontSize: "14px", fontWeight: 500, lineHeight: "21px" }}>
                                                        {reviewDetails?.username ?? "Kullanıcı Adı"}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span style={{ fontWeight: "400", lineHeight: "19.5px" }}>
                                                        {reviewDetails?.comment ?? "Yorum"}
                                                    </span>
                                                </Col>
                                            </Row>
                                            {
                                                reviewDetails.review_medias && reviewDetails.review_medias.length !== 0 && (
                                                    <hr
                                                        style={{
                                                            color: "#0000001A",
                                                            backgroundColor: "#0000001A",
                                                            border: "1px solid #0000001A",
                                                        }}
                                                    />
                                                )
                                            }

                                            <Row className='d-flex align-items-start'>
                                                {
                                                    reviewDetails?.review_medias && (
                                                        reviewDetails.review_medias.map((review_media, index) => (
                                                            <Col xs="12" sm="6" md="3" className='py-2' key={index}>
                                                                <div className="align-self-center me-3">
                                                                    <img
                                                                        src={review_media}
                                                                        style={imageStyle}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </Col>
                                                        ))
                                                    )
                                                }
                                            </Row>
                                        </CardBody>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {!error && (
                                reviewReply ? (
                                    <Card className='p-2'>
                                        <CardBody>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <span className='d-flex my-1' style={{ fontSize: "15px", fontWeight: "500" }}>
                                                        Yorum
                                                    </span>
                                                    <div >
                                                        <i className='bx bx-calendar text-primary font-size-20' ></i>
                                                        <span style={{ color: "#74788D", fontSize: "12px", fontWeight: "400" }}>
                                                            {reviewReply.created_at &&
                                                                formatDistanceToNow(new Date(reviewReply.created_at), { addSuffix: true, locale: tr })
                                                            }
                                                        </span>
                                                    </div>
                                                </div>

                                                <span className='d-flex my-1' style={{ fontSize: "15px", fontWeight: "500" }}>
                                                    Yazan : Satıcı
                                                </span>
                                                <p className='mt-3'>{reviewReply.reply_content}</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ) : (
                                    <Card className='p-2'>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control chat-input"
                                                            placeholder="Mesaj yazın..."
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}

                                                        />
                                                    </div>
                                                    {error && (
                                                        <div className="text-danger">{error}</div> // Render the error message
                                                    )}
                                                </Col>
                                                <Col className="col-auto">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        className="btn btn-primary btn-rounded chat-send w-md"
                                                        onClick={handleSendMessage}
                                                        disabled={message.trim() === "" ? true : false}
                                                    >
                                                        <span className="d-none d-sm-inline-block me-2">Gönder</span>{" "}
                                                        <i className="mdi mdi-send" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                )
                            )}

                        </Col>
                    </Row>
                </>
            ) : (
                <Card>
                    <CardTitle className='d-flex justify-content-center align-items-center'>
                        Yorum detaylarını görüntülemek için sol taraftaki bir yoruma tıklayın.
                    </CardTitle>
                </Card>
            )}
        </Container>
    );
}

const ReviewDetails = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ReviewDetailQuery />
        </QueryClientProvider>
    )
}

export default ReviewDetails;
