import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import Placeholder from 'react-bootstrap/Placeholder';

const OrdersBoxError = ({ error, resetErrorBoundary }) => {
    // const { value, previous_period_percentage } = ordersData;

    return (
        <Col md="4">
            <Card className="mini-stats-wid">
                <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                <i className="bx bx-copy-alt" />
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Siparişler</h5>
                    </div>
                    <div className="text-muted mt-4">
                        {/* {error.message} */}

                        Bir şeyler ters gitti
                        <Placeholder as="h4" animation="glow">
                            <Placeholder xs={6} />
                        </Placeholder>


                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default OrdersBoxError;
