
import React from "react";
import PropTypes from "prop-types";
import AdDetailModal from "../Popups/AdDetailModal";

const AdRow = ({ modalOpen, setModalOpen, setAdDetailId, ad, index,  }) => {

    const toggleModal = () => {
        setAdDetailId(ad.promotion_id)
        setModalOpen(!modalOpen)
    }

    const tableDataStyle = {
        backgroundColor: "white",
        textAlign: "center",
    };

    return (<>
        <tr>
            <td style={tableDataStyle}>{index + 1}</td>
            <td style={tableDataStyle}>{ad.semt}</td>
            <td style={tableDataStyle}>{ad.ranking}</td>
            <td style={tableDataStyle}>{ad.start_date}</td>
            <td style={tableDataStyle}>{ad.end_date}</td>
            <td style={tableDataStyle}>{ad.token_cost} TKN</td>
            <td style={tableDataStyle}>{ad.total_views}</td>
            <td style={tableDataStyle}>
                <p style={{ cursor: "pointer", textDecoration: "underline", color: "#ADB8C2" }} onClick={toggleModal}>
                    Ayrıntıları gör
                </p>
            </td>

        </tr>
        {/* {modalOpen && (
            <AdDetailModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                id={ad.promotion_id}
            />
        )} */}
    </>
    );
};

AdRow.propTypes = {
    ad: PropTypes.object.isRequired,
};

export default AdRow;
