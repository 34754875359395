import React,{useEffect} from "react"
import { Card } from "reactstrap"
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Progress } from 'antd';
import { Row, Col, ListGroup, Container } from 'react-bootstrap';
import useGetIsAuth from "routes/hooks/useGetIsAuth";
import { Link } from "react-router-dom";

const calcProgres = (type, len) => {
  const denominator = type === "sahis" ? 5 : 7;
  let numerator = denominator - len
  let progress = (numerator / denominator) * 100
  return Math.round(progress)
}


function CardWelcome(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const { auth,shouldOpen } = useGetIsAuth()
  useEffect(() => {
    // Check if shouldOpen changes and set state accordingly
    if (shouldOpen !== undefined) {
      setShow(shouldOpen);
    }
  }, [shouldOpen]); // Only runs when shouldOpen changes
  let type = ""
  if (auth) {
    if (auth?.payload?.is_sahis) {
      type = "sahis"
    } else {
      type = "other"
    }
  }
  const translations = {
    status_tax_board: "Vergi levhası",
    status_activity_certificate: "Faaliyet belgesi",
    status_trade_registry_gazette: "Ticaret sicil gazetesi",
    status_authorized_signatures: "Imza sirküleri",
    status_smart_ewallet_contract: "AKILLI E-CÜZDAN SÖZLEŞMESİ",
    status_seller_contract: "SATICI İŞ ORTAKLIĞI ve İLAN SÖZLEŞME",
    status_TC_photo: "T.C. Kimlik"
  };
  let translatedDocuments
  const getDocInfo = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/documents-information`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { isLoading, isError, error, data } = useQuery({ queryKey: ["document-information"], queryFn: getDocInfo })
  let docInfo
  if (isLoading) {
    docInfo = "loading"
    return
  } else if (isError) {
    console.log(error);
    docInfo = "An error occurred"
  } else {
    docInfo = data?.data
    let missingDocs = []
    // const dataArray = Object.entries(docInfo).map(([key, value]) => ({ key, value }));
    const dataArray = docInfo 
  ? Object.entries(docInfo).map(([key, value]) => ({ key, value }))
  : [];
    dataArray.forEach((item, index) => {
      if (item.value !== "approved" && item.key.startsWith("status")) {
        missingDocs.push(item.key)
      }
    })
    translatedDocuments = missingDocs.map(key => translations[key]);
   

    if (translatedDocuments?.length === 0) {
      return
    }

  }
  return (
    <React.Fragment>
      <Col xl="12">
        <Card className="bg-primary bg-soft">
          <div>
            <Row>
              <Col xs="12">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Hoş Geldiniz!</h5>
                  <p>
                    Profil durumu, Butiko hizmetini kullanmaya devam etmek için
                    aşağıdaki maddeleri tamamlayın!
                  </p>


                  <ul className="ps-3 mb-0 list">
                    {Array.isArray(translatedDocuments) && translatedDocuments.map((item, index) => {
                      if (item) {

                        return (
                          <li key={index} className="py-1"> {`${item}  `} </li>
                        )
                      }
                    })}
                  
                  </ul>
                </div>
              </Col>
            </Row>
          </div>


          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container className="mt-1">
                <Row className="justify-content-center mb-4">
                  <Col md={12} className="text-center">
                    <Progress type="circle" percent={calcProgres(type, translatedDocuments?.length)} />
                  </Col>
                </Row>

                <Row className="justify-content-center mb-3">
                  <Col md={12}>
                    <div className="p-3 border rounded bg-light text-center">
                      <p className="mb-2 fs-4">Satış yapabilmeniz için aşağıdaki belgeleri yüklemeniz gerekmektedir:</p>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={12}>
                    <ListGroup variant="flush">
                      {Array.isArray(translatedDocuments) && translatedDocuments.map((item, index) => (
                        item && <ListGroup.Item key={index} className="py-2"> {item} </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col md={12}>
                    <Link to="/profile?section=docs">
                      <Button className="w-100 mt-4">Belgeleri Yüklemek İçin Profiline Git</Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </Card>

      </Col>
    </React.Fragment>
  )
}

export default CardWelcome
