import React from "react";

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#98FFDA" />
            <path d="M2.77734 5.43209L4.14487 6.66666L7.22179 3.88889" stroke="#34C38F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckIcon;