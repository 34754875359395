import React from 'react';
import { Row, Col } from 'reactstrap';
import PriceCard from './PriceCard';
// import Spinner from 'react-bootstrap/Spinner';
import { Skeleton } from "antd";

const Offers = ({ offersLoading, offersError, offersData, clickedPackage, handleRadioChange }) => (
    <Row>
        {offersLoading ? (
            <span>
                {/* Yükleniyor<Spinner className="ms-1" size="sm" animation="border" /> */}
                <Skeleton active />
            </span>
        ) : offersError ? (
            <span>Bir Hata Oluştu.</span>
        ) : (
            offersData.map((offer, index) => (
                <Col key={index} xs="12" sm="6" xl="4">
                    <PriceCard
                        offer={offer}
                        selectedPackage={clickedPackage}
                        isSelected={clickedPackage === offer.offer_id}
                        onRadioChange={() => handleRadioChange(offer)}
                    />
                </Col>
            ))
        )}
    </Row>
);

export default Offers;
