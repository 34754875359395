import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css"
import store from "./store";
import { FormProvider } from "pages/Register/FormContext";
import { ErrorProvider } from "pages/Register/ErrorContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <FormProvider>
            <ErrorProvider>
              <App />
            </ErrorProvider>
          </FormProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.Fragment>
  </Provider>
);

serviceWorker.unregister();
