import React from "react";

// ! Basit hata gösterme bileşeni
const ErrorDisplay = ({ error }) => {
    return (
        error && (
            <div className="error-message mt-1">
                <i className="fas fa-exclamation-circle" />
                <span>{" "}{error}</span>
            </div>
        )
    );
};

export default ErrorDisplay;
