import React, { useState } from "react";
import withRouter from "components/Common/withRouter";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IoClose } from "react-icons/io5";
const NotificationDataInfo = ({ data }) => {
  const [notificateData, setNotificateData] = useState(data);

  const getNotification = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/notification/get-notifications`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const updateDismiss = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/notification/update-last-dismiss`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const {
    data: dismissData,
    isLoading: dismissIsLoading,
    isError: dismissIsError,
    error: dismissError,
    refetch: dismissRefetch
  } = useQuery({ queryKey: ["dismiss"], queryFn: updateDismiss, enabled: false })
  if (dismissIsLoading) {

  } else if (dismissIsError) {
    console.log(dismissError);
  } else {
    console.log(dismissData);
  }
  const { data: notificationData, isError, error, isLoading, refetch: notificationRefetch } = useQuery({ queryKey: ["notification"], queryFn: getNotification })
  let notification
  let notificationNotReverse
  if (isLoading) {
    notification = "loading"
  } else if (isError) {
    console.log(error);
    notification = "error"
  } else {
    notificationNotReverse = notificationData.data
    notification = [...notificationNotReverse].reverse()
    // NData = notification
    // console.log(notificationData);
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    return date.toLocaleString('en-US', options);
  };

  return (
    <CardBody className="pt-0 mb-1">

      <div className="table-responsive">
        <table className="my-3">
          <thead style={{ background: "#F8F9FA" }}>
            <tr>
              <th className="">#</th>
              <th className="px-3">Başlık</th>
              <th className="px-3">İçerik</th>
              <th className="px-3">Tarih</th>
              <th className="">
                {/* <p>Hepsini sil</p> */}
                <IoClose style={{cursor: "pointer"}} onClick={() => {
                  dismissRefetch()
                  notificationRefetch()
                }} size="22" />
              </th>
            </tr>
          </thead>
          <tbody style={{ marginTop: "10px" }}>
            {Array.isArray(notification) && notification.length > 0 ?
              (notification.map((info, index) => (
                <tr style={{ borderBottom: "1px solid #EFF2F7" }} key={`${info.title}--${index}`}>

                  <td style={{ padding: "10px", textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ padding: "10px" }}>{info.title}</td>
                  <td style={{ padding: "10px" }}>{info.content}</td>
                  <td style={{ padding: "10px" }}>{formatDate(info.date)}</td>


                </tr>
              ))) : (
                <tr style={{ borderBottom: "1px solid #EFF2F7" }}>
                  <td colSpan={5} style={{ padding: "10px", textAlign: 'center' }}>
                    <div>Hiçbir bildirim mevcut değil</div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </CardBody>
  )
}
const Notifications = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Profile" breadcrumbItem="Notificaitons" />
          <Row className="px-2">
            
            <Col lg={12}>
              <Card className="px-2" style={{ borderRadius: "4px", background: "#FFF", boxShadow: "0px 10px 20px 0px rgba(18, 38, 63, 0.03)" }}>
                <NotificationDataInfo  />
              </Card>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
}

export default withRouter(Notifications);