import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Collapse,
  ModalFooter,
} from "reactstrap"
import { useState } from "react"

const UrunEkleme = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [isOpen3, setIsOpen3] = useState(false)
  const [isOpen4, setIsOpen4] = useState(false)
  const [isOpen5, setIsOpen5] = useState(false)
  const [isOpen6, setIsOpen6] = useState(false)
  const [isOpen7, setIsOpen7] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggle2 = () => {
    setIsOpen2(!isOpen2)
  }
  const toggle3 = () => {
    setIsOpen3(!isOpen3)
  }
  const toggle4 = () => {
    setIsOpen4(!isOpen4)
  }
  const toggle5 = () => {
    setIsOpen5(!isOpen5)
  }
  const toggle6 = () => {
    setIsOpen6(!isOpen6)
  }
  const toggle7 = () => {
    setIsOpen7(!isOpen7)
  }
  const fontSize18 = { fontSize: "15px" }

  return (
    <div>
      <span onClick={toggleModal} style={{ ...fontSize18, cursor: "pointer",textDecoration:"underLine" }}>
        Soruları görmek için tıklayınız
      </span>
      <Modal
        style={{ maxWidth: "1000px", maxHeight: "1000px", marginTop: "80px" }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>
          URUN SATIŞI İLE İLGİLİ SIKÇA SORULAN SORULAR
        </ModalHeader>
        <ModalBody
          className="mx-3 my-3"
          style={{ overflowY: "auto", minHeight: "55vh" }}
        >
          <Card style={{ backgroundColor: "white" }}>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Ürünümü nasıl silebilir / düzenleyebilirim?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen}>
                    <p>
                    Butiko’da aktif satışta olan bir ürünü düzenlemek yada silmek istediğinizde ilgili ürün
ilanına girerek düzenlemeyi sağladıktan sonra Düzenlemeyi Kaydet yada Bu
paylaşımı Sil seçenekleri ile işlem sağlayabilirsiniz.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle2}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen2 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Ürünlerim neden reddedildi / silindi?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen2}>
                    <p>
                    Taraftar ürünleri, Ev dekaorasyon ürünleri, Hijyen kurallarına aykırı ürünler ( Tırnak
makası, törpü, törpü seti, lens ve lens solüsyonu, ped, diş fırçası, lazer ve epilasyon
malzemeleri vs. ), Mutfak malzemeleri, Nevresim takımları, yorgan, battaniye, çeyiz
setleri, lifler, etiketsiz bornoz setleri vs. yetişkin erkek giyim ve ayakkabı ürünleri,
makyaj malzemesi organizatörleri, yapay çiçekler, süt sağma aletleri, nişan tepsileri,
kokulu taşlar, kına malzemeleri, ev temizleme ürünleri, değerli ziynet eşyaları
ürünlerini kargolama sürecinde sorun çıkarabileceği için kabul edilmemektedir.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle3}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen3 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Yasaklı ürünler nelerdir?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen3}>
                    <p>
                    Taraftar ürünleri, Ev dekaorasyon ürünleri, Hijyen kurallarına aykırı ürünler ( Tırnak
makası, törpü, törpü seti, lens ve lens solüsyonu, ped, diş fırçası, lazer ve epilasyon
malzemeleri vs. ), Mutfak malzemeleri, Nevresim takımları, yorgan, battaniye, çeyiz
setleri, lifler, etiketsiz bornoz setleri vs. yetişkin erkek giyim ve ayakkabı ürünleri,
makyaj malzemesi organizatörleri, yapay çiçekler, süt sağma aletleri, nişan tepsileri,
kokulu taşlar, kına malzemeleri, ev temizleme ürünleri, değerli ziynet eşyaları
ürünlerini kargolama sürecinde sorun çıkarabileceği için kabul edilmemektedir.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default UrunEkleme
