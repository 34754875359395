import React from "react"
import {
    CardBody,
} from "reactstrap"


const PriceTable = props => {
    const shipping = props.shipping
    const sub_total = props.sub_total
    return (
        <CardBody className="pt-0 hidden-print">
            <table className="w-100">
                <tbody>

                <tr className="d-flex justify-content-between align-items-center  w-100 py-1">
                    <td>Alt Toplam</td>
                    <td>{sub_total}₺</td>
                </tr>
                <tr className="d-flex justify-content-between align-items-center  w-100 py-1">
                    <th>Kargo</th>
                    <td>{shipping}₺</td>
                </tr>
                <tr className="d-flex justify-content-between align-items-center  w-100 py-1">
                    <th>Toplam</th>
                    <td className="">{shipping + sub_total}₺</td>
                </tr>
                </tbody>
            </table>
        </CardBody>
    )
}

export default PriceTable