import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames"

import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";


import PersonalInformation from "./Components/PersonalInformation";
import CompanyInformation from "./Components/CompanyInformation";
import Documents from "./Components/Documents";
import Settings from "./Components/Settings";
import Account from "./Components/Account";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUserStore from "routes/routeStore";

const UserProfile = () => {
  const [activeTab, setactiveTab] = useState("1")
  const history = useNavigate();
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  const getApprovalStatus = useUserStore(state => state.getStatus)


  let [searchParams, setSearchParams] = useSearchParams();

  let section = searchParams.get("section")
  // console.log(section)
  if (section == "account") {
    toggleTab("1")
  } else if (section == "personal") {
    toggleTab("2")
  } else if (section == "company") {
    toggleTab("3")
  } else if (section == "docs") {
    toggleTab("4")
  } else {
  }
  //meta title
  document.title = "Profil | Butiko";
  const getCompanyInfo = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/company-information`, { withCredentials: true })
  }
  const { isLoading, isError, data, error, isSuccess, refetch } = useQuery({ queryKey: ['get-company-info'], queryFn: getCompanyInfo })
  if (isError) {
    if (error?.response?.data?.detail[0]?.msg === "unathorized action" || error?.response?.data?.detail[0]?.msg === "invalid jwt") {
      history("/login")
    } else if (error?.response?.data?.detail[0]?.msg === "seller is not approved") {
      toggleTab("4")
      console.log("sfdsdfsdf");
    }
    console.log(error);
  }

  useMemo(() => {
    getApprovalStatus()
      .then(status => {
        if (status == "pending") {
          toggleTab("4")
        }
      })
      .catch(error => {
        console.error('Error fetching status:', error);
      });
  }, [data])
  let bredCrumb = ""
  if (activeTab === "1") {
    bredCrumb = "Hesap"
  } else if (activeTab === "2") {
    bredCrumb = "Kişisel Bilgiler"
  } else if (activeTab === "3") {
    bredCrumb = "Şirket Bilgileri"
  } else if (activeTab === "4") {
    bredCrumb = "Belgeler"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Hesap bilgilerim" breadcrumbItem={bredCrumb} />

          <Row>
            <Col xl="9">
              <Card className="p-3">
                <CardTitle>Kayıt Verileri</CardTitle>
                <CardBody>
                  <Row>
                    <Col xs="12" xl="3">
                      <Nav pills vertical>
                        <NavItem className="mb-2" style={{ border: "1px solid #ced4da", borderRadius: "8px" }}>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                              setSearchParams({ section: "account" })
                            }}
                          >
                            Hesap
                          </NavLink>
                        </NavItem>
                        <NavItem className="mb-2" style={{ border: "1px solid #ced4da", borderRadius: "8px" }}>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                              setSearchParams({ section: "personal" })
                            }}
                          >
                            Kişisel Bilgiler
                          </NavLink>
                        </NavItem>
                        <NavItem className="mb-2" style={{ border: "1px solid #ced4da", borderRadius: "8px", }}>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                              setSearchParams({ section: "company" })
                            }}
                          >
                            Şirket Bilgisi
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ border: "1px solid #ced4da", borderRadius: "8px" }}>
                          <NavLink
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              toggleTab("4")
                              setSearchParams({ section: "docs" })
                            }}
                          >
                            Belgeler
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col className="mt-3" xs="12" xl="9">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Account />
                        </TabPane>
                        <TabPane tabId="2">
                          <PersonalInformation />
                        </TabPane>
                        <TabPane tabId="3">
                          <CompanyInformation type={data?.data?.company_type === "sahis" ? "sahis" : "company"} />
                        </TabPane>
                        <TabPane tabId="4">
                          <ErrorBoundary
                            onError={(e) => console.log(e.message)}
                            fallback={
                              <>
                                <Container className="">
                                  <Row>
                                    <div className="">
                                      Bir şeyler ters gitti
                                    </div>

                                  </Row>
                                  <button onClick={() => window.location.reload()} className="btn btn-info px-4">Yenile</button>
                                </Container>
                              </>

                            }>
                            <Documents type={data?.data?.company_type === "sahis" ? "sahis" : "company"} />

                          </ErrorBoundary>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>


                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Settings />
            </Col>
            {/* <ToastContainer />   */}
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
