import React, { useState, useCallback } from "react";
import {  FaPen } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { Col, Input, Row } from "reactstrap";
// import YearSelect from "./YearSelect";
// import MonthSelect from "./MonthSelect";
import { Popconfirm } from 'antd';
// Import Helpers
import {put } from "helpers/api_helper"
import * as url from "helpers/url_helper"

// Import React Query
import { useMutation } from "@tanstack/react-query"

// Import Toast Notifications
import { toast } from "react-toastify"

const SavedCard = ({ card, isChecked, handleClick, handleDelete, isOpen, toggle, setCardInformation }) => {

  const [isEditMode, setIsEditMode] = useState(false);

  const [cardInfo, setCardInfo] = useState({
    detail_id: card?.detail_id ?? 0,
    card_owner_name: "",
    card_number: "",
    expiry_month: "",
    expiry_year: "",
    cvc: ""
  });

  const updateCardInfo = useCallback((fieldName, value) => {
    setCardInfo(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  }, [cardInfo]);

  // const handleCardNumberChange = useCallback((e) => {
  //   let inputValue = e.target.value.replace(/\D/g, '').slice(0, 16);
  //   updateCardInfo("card_number", inputValue);
  // }, [cardInfo]);

  // const handleCardOwnerNameChange = useCallback((e) => {
  //   let inputValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
  //   updateCardInfo("card_owner_name", inputValue);
  // }, [cardInfo]);

  // const handleExpiryMonthChange = useCallback((e) => updateCardInfo("expiry_month", e.target.value), [cardInfo])

  // const handleExpiryYearChange = useCallback((e) => updateCardInfo("expiry_year", e.target.value), [cardInfo])

  // const handleCvvChange = useCallback((e) => {
  //   let inputValue = e.target.value.replace(/\D/g, '').slice(0, 3);
  //   updateCardInfo("cvc", inputValue)
  // }, [cardInfo])

  // Function to format the card number
  const formatCardNumber = (cardNumber) => {
    // Ensure the card number is a string
    const cardString = String(cardNumber);

    // Get the last 2 digits
    const lastTwo = cardString.slice(-2);

    // Calculate the number of asterisks needed
    const asterisksCount = cardString.length - 2;

    // Create groups of 4 asterisks
    const fullGroups = Math.floor(asterisksCount / 4);
    const remainingAsterisks = asterisksCount % 4;

    // Create the masked part with dashes
    let maskedPart = ('****-').repeat(fullGroups) + '*'.repeat(remainingAsterisks);

    // Remove trailing dash if present
    maskedPart = maskedPart.replace(/-$/, '');

    // Combine the masked part with the last two digits
    return maskedPart + lastTwo;
  };

  // Edit Credit Cards
  const editCreditCard = useCallback(async () => {
    const response = await put(url.PUT_UPDATE_CARD, cardInfo, { withCredentials: true, })

    console.log(response)

    return response
  }, [cardInfo])

  const editCreditCardMutation = useMutation({
    mutationFn: editCreditCard,
    onSuccess: () => {
      console.log("Card edited")
      toast.success("Kart bilgileriniz başarıyla düzenlendi.", { toastId: "cardEditSuccess" })
      refetch()
    },
    onError: error => {
      console.log(error)

      if (error?.code === "ERR_NETWORK") {
        toast.error("Sistemden kaynaklı bir sorun var...", { toastId: "networkError" })
      } else {
        const errorTypes = error.response.data.detail.map(item => (item.type))
        if (errorTypes.some(message => message.includes("string_too_short"))) {
          toast.warning("Lütfen tüm alanları doldurun.", { toastId: "emptyFieldError" });
        } else if (errorTypes.some(message => message.includes("int_parsing"))) {
          toast.warning("Lütfen tarih değerlerini girmeyi unutmayın.", { toastId: "invalidInputError" });
        } else {
          toast.error("Bir hata oluştu...", { toastId: "someError" });
        }
      }

    },
  })


  // const handleSubmit = useCallback(() => {
  //   setIsEditMode(false);
  //   editCreditCardMutation.mutate();
  // }, []);


  return (
    <React.Fragment>
      <Row className="my-2 d-flex align-items-center">
        <Col xs="auto">
          <Input type="radio"
            checked={isChecked}
            onChange={() => { }}
            onClick={handleClick}
            style={{ borderColor: "#767676" }} />
        </Col>
        <Col>
          <Row>
            {/* {isEditMode ? (
              <>
                <Col xxl="3" lg="6" sm="6" xs="12" className="my-1">
                  <Input
                    type="text"
                    value={cardInfo.card_number}
                    onChange={handleCardNumberChange}
                    placeholder="Kart Numarası" />
                </Col>
                <Col xxl="3" lg="6" sm="6" xs="12" className="my-1">
                  <Input
                    type="text"
                    value={cardInfo.card_owner_name}
                    onChange={handleCardOwnerNameChange}
                    placeholder="Kart Sahibi" />
                </Col>
                <Col xxl="2" lg="6" sm="6" xs="12" className=" my-1">
                  <MonthSelect
                    value={cardInfo.expiry_month}
                    onChange={handleExpiryMonthChange}
                    disabled={false}
                  />
                </Col>
                <Col xxl="2" lg="6" sm="6" xs="12" className=" my-1">
                  <YearSelect
                    value={cardInfo.expiry_year}
                    onChange={handleExpiryYearChange}
                    disabled={false}
                  />
                </Col>
                <Col xxl="2" lg="6" sm="6" xs="12" className="d-flex flex-column gap-3 my-1" >
                  <Input
                    type="text"
                    value={cardInfo.cvc}
                    onChange={handleCvvChange}
                    placeholder="cvc" />
                </Col>

               
                <Col xxl="2" lg="6" sm="6" xs="12" className="d-flex flex-column justify-content-center my-2" >
                  <span onClick={handleSubmit} className="d-flex gap-2 justify-content-center align-items-center text-success" style={{ cursor: "pointer" }}>
                    <FaCheck />Kaydet
                  </span>
                </Col>
              </>
            ) : (<> */}

            <Col xxl="3" lg="6" sm="6" xs="12" className="my-1">
              <span>{formatCardNumber(card.card_number)}</span>
              <br />
              <span>{card.card_owner_name}</span>
            </Col>
            <Col xxl="2" lg="6" sm="6" xs="12" className="my-1 d-flex flex-column justify-content-center">
              <span>
                {card.expiry_year.toString().slice(-2)}/
                {card.expiry_month}
              </span>
            </Col>
            <Col xxl="2" lg="6" sm="6" xs="12" className="my-1 d-flex flex-column justify-content-center" >
              <span>{card.cvc}</span>
            </Col>

            {/* </>)} */}

            {!isEditMode && <Col xxl="2" lg="6" sm="6" xs="12" className="my-2 d-flex d-xxl-none d-xs-none" />}


            <Col xxl="2" lg="6" sm="6" xs="12" className="my-2">
              <span onClick={() => {
                // setIsEditMode(prevState => !prevState)
                setCardInformation(card)
                toggle()
              }} className="d-flex gap-2 justify-content-center align-items-center my-1" style={{ color: "#556EE6", cursor: "pointer" }}>
                <FaPen />
                {isEditMode ? ("İptal") : ("Düzenle")}
              </span>
            </Col>
            <Popconfirm
              // title="Delete the task"
              description="Silmek istediğine emin misin"
              onConfirm={handleDelete}
              // onCancel={cancel}
              okText="Evet"
              cancelText="Hayır"
            >
              {/* <Button danger>Delete</Button> */}
              <Col xxl="2" lg="6" sm="6" xs="12" className="my-2" >
                <span className="d-flex gap-2 justify-content-center align-items-center my-1" style={{ color: "#F46A6A", cursor: "pointer" }}>
                  <FaRegTrashCan />
                  Sil
                </span>
              </Col>
            </Popconfirm>
          </Row>
        </Col>
      </Row >
    </React.Fragment >
  );
};

export default SavedCard;
