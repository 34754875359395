import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, CardBody, Table, Button } from "reactstrap";
import axios from "axios";
import toastr from "toastr";
import EcommerceOrdersModal from "../../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import accessToken from "helpers/jwt-token-access/accessToken";
import { GET_AUTH_DASHBOARD } from "helpers/url_helper";
import { useNavigate, Link } from "react-router-dom";
import "./dashboard.css"
const LatestTransaction = ({ latestTransactionData }) => {
  const [modal1, setModal1] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);

  const navigate = useNavigate();

  const defaultTransactionData = [
    {
      order_id: "",
      billing_name: "",
      date: "",
      total_amount: "",
      status: "Veri yok",
      payment_method: "",
      products: ""
    }
  ]

  const openOrderDetailsModal = (order) => {
    setSelectedOrder(order);
    setSelectedOrderProducts(JSON.parse(order.products));
    toggleViewModal();
  };

  const toggleViewModal = () => setModal1(!modal1);






  const tableCellStyle = {
    // border: "1px solid #EFF2F7",
    padding: "0.5rem 0.5rem",
    textAlign: "center",
    backgroundColor: "#ffffff"
  };

  const GET_LATEST_TRANSACTION_PRODUCT =
    `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/latest-transactions?page=0`



  // React Query for fetching latest transaction data
  const { isLoading, isError, data: transactionData, error, refetch } = useQuery({
    queryKey: ["get-transaction-data"],
    queryFn: () => axios.get(GET_LATEST_TRANSACTION_PRODUCT, { withCredentials: true }),
    // onError: (error) => {
    //   toastr.error("Lütfen Giriş Yapın");
    //   console.log(error);
    //   if (
    //     error.response?.data?.detail?.[0]?.msg === "unathorized action" ||
    //     error.response?.data?.detail?.[0]?.msg === "invalid jwt"
    //   ) {
    //     navigate("/login");
    //   } else if (
    //     error.response?.data?.detail?.[0]?.is_verified_phone_number === 0 ||
    //     error.response?.data?.detail?.[0]?.msg === "seller is not approved"
    //   ) {
    //     setIsPhoneVerificationModalOpen(true)
    //   } else if (error.response?.data?.detail?.[0]?.msg === "not found") {
    //     console.log("you");
    //   } else {
    //     setIsPhoneVerificationModalOpen(false)
    //   }
    // }
  });
  let transaction_data
  if (isLoading) {

  } else if (isError) {
    console.log(error);
    if (
      error.response?.data?.detail?.[0]?.msg === "unathorized action" ||
      error.response?.data?.detail?.[0]?.msg === "invalid jwt"
    ) {
      navigate("/login");
    } else if (
      error.response?.data?.detail?.[0]?.is_verified_phone_number === 0 ||
      error.response?.data?.detail?.[0]?.msg === "seller is not approved"
    ) {
      // setIsPhoneVerificationModalOpen(true)
    } else if (error.response?.data?.detail?.[0]?.msg === "not found") {
      // console.log("you");
    } else {
      setIsPhoneVerificationModalOpen(false)
    }
  } else {
    transaction_data = transactionData?.data
  }
  // const transaction_data = transactionData?.data //?? defaultTransactionData;
  return (
    <>
      {/* <EcommerceOrdersModal
        isOpen={modal1}
        toggle={toggleViewModal}
        selectedOrder={selectedOrder}
        selectedOrderProducts={selectedOrderProducts}
      /> */}
      <Card>
        <CardBody style={{ overflowX: "auto" }}>
          <div className="mb-4 h4 card-title">Son İşlemler</div>
          <Table className="table-responsive mb-4 tableStyle" >
            <thead className="tableHeadStyle">
              <tr className="tableRowStyle">
                <th className="tableHeadCellStyle">Sipariş ID</th>
                <th className="tableHeadCellStyle">Fatura Adı</th>
                <th className="tableHeadCellStyle">Tarih</th>
                <th className="tableHeadCellStyle">Toplam</th>
                {/* <th className="tableHeadCellStyle">Ödeme Durumu</th> */}
                {/* <th style={tableHeadCellStyle}>Ödeme Yöntemi</th> */}
                <th className="tableHeadCellStyle">Detayları Görüntüle</th>
              </tr>
            </thead>
            <tbody>

              {isLoading ? (
                <tr className="tableCellStyle">
                  <td colSpan="7">Veriler yükleniyor...</td>
                </tr>
              ) : error?.response?.data?.detail?.[0]?.msg === "not found" ? (
                <tr className="tableCellStyle">
                  <td colSpan="7">Şu anda görüntülenecek işlem bulunmamaktadır.</td>
                </tr>
              ) : transaction_data?.length > 0 ? (
                transaction_data.map((transaction, key) => (
                  <tr key={key}>
                    <td className="tableCellStyle">{transaction.order_id}</td>
                    <td className="tableCellStyle">{transaction.billing_name}</td>
                    <td className="tableCellStyle">{transaction.date}</td>
                    <td className="tableCellStyle">{transaction.total} ₺</td>
                    {/* <td className="tableCellStyle"> <span className={transaction.status === "ordered" ? ("accept-status") : ("cancel-status")} >{transaction.status}</span></td> */}
                    <td className="tableCellStyle">
                      <Link to={`/ecommerce-orders-detail?order_id=${transaction.order_id}`}>

                        <Button
                          type="button"
                          color="primary"
                          className="btn-sm btn-rounded"
                        // onClick={() => openOrderDetailsModal(transaction)}
                        >
                          Detayları göster
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={tableCellStyle}>
                  <td colSpan="7">Bir Hata Oluştu...</td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default LatestTransaction;
