import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import Placeholder from 'react-bootstrap/Placeholder';
//Import Breadcrumb
import axios from "axios";
//i18n
import { withTranslation } from "react-i18next";
//redux

export const TopSellingProductErrorFallBack = ({ error, resetErrorBoundary }) => {

    return (
        <>
            <Col xl="4">
                <Card style={{ padding: "15px" }}>
                    <CardBody>
                        <div className="clearfix">
                            <div className="float-end">
                                <div className="input-group input-group-sm">
                                    <select
                                        className="form-select form-select-sm"
                                        disabled
                                    >
                                        <option value="1">Ocak</option>
                                    </select>

                                    <label className="input-group-text">Ay</label>
                                </div>
                            </div>
                            <h4 className="card-title mb-4">En Çok Satan Ürün</h4>
                        </div>
                        <div className="text-muted ">

                            <p className="mb-2 fs-3 ">Bir şeyler ters gitti</p>
                            <Placeholder />
                            <Placeholder as="p" animation="wave">
                                <Placeholder xs={4} />
                            </Placeholder>
                            <Placeholder as="p" animation="wave">
                                <Placeholder xs={8} />
                            </Placeholder>
                            <Placeholder as="p" animation="wave">
                                <Placeholder xs={12} />
                            </Placeholder>
                            {/* <Placeholder xs={6} />
                            <Placeholder xs={6} />
                            <Placeholder className="w-75" />
                            <Placeholder style={{ width: '25%' }} /> */}

                            <div className=" mt-4  ">
                                <Table className="table align-middle table-responsive  mb-0">
                                    <tbody >

                                    </tbody>
                                </Table>
                            </div>

                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}