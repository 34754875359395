import React, { useState, useCallback } from "react"
// Import Reactstrap
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
// Import React Router DOM
import { useNavigate } from "react-router-dom"

// Import Breadcrumbs
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Helpers
import { get, post, put, del } from "helpers/api_helper"
import * as url from "helpers/url_helper"

// Import React Query
import { useQuery, useMutation, QueryClient, QueryClientProvider } from "@tanstack/react-query"

// Import Toast Notifications
import { toast, ToastContainer } from "react-toastify"

// Import Popup
import NewCardPopup from "./Popups/NewCardPopup"

// Import Components
import TokensAndTransactionsCard from "./Components/TokensAndTransactionsCard"
import Offers from "./Components/Offers"
import CreditCards from "./Components/CreditCards"
import PaymentForm from "./Components/PaymentForm"
import ECuzdanSozlesmesi from "pages/sozlesmeler/ecuzdanSozlesmesi.js"
const queryClient = new QueryClient()
const TokenQuery = () => {

  // Meta başlığı
  document.title = "Token-Cuzdan | ButikO"

  const navigate = useNavigate()
  const [clickedPackage, setClickedPackage] = useState(0)
  const [tokenCount, setTokenCount] = useState(0)
  const [tokenPrice, setTokenPrice] = useState(0)
  const [cardInfo, setCardInfo] = useState({
    card_owner_name: "",
    card_number: "",
    expiry_month: "",
    expiry_year: "",
    // cvv for cardInfo state because paytr API accepts cvv instead of cvc
    cvv: ""
  });
  const [selectedCardType, setSelectedCardType] = useState("")
  const [selectedCard, setSelectedCard] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [ecuzdanOpened, setEcuzdanOpened] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(false)


  const handleEcuzdanOpen = () => {
    setEcuzdanOpened(true);
  };
  const toggleModal = () => {
    setIsCardOpen(!isCardOpen)
  }

  // Update individual fields
  const handleCardInfoChange = useCallback((fieldName, value) => {
    setCardInfo(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  })

  // Define a reusable error handling function
  const handleCommonErrors = (error) => {
    const errorMessage = error.response?.data?.detail?.[0]?.msg;
    if (
      errorMessage &&
      (errorMessage === "unathorized action" ||
        errorMessage === "invalid jwt")
    ) {
      navigate("/login");
    }

    console.error(error);
  };


  const fetchTransactionsData = useCallback(async () => {
    return await get(url.GET_TRANSACTIONS, { withCredentials: true })
  }, []);

  const fetchOffersData = useCallback(async () => {
    return await get(url.GET_OFFERS, { withCredentials: true })
  }, []);

  const fetchCreditCardsData = useCallback(async () => {

    const response = await get(url.GET_CARDS, { withCredentials: true })

    // console.log(response);

    return response
  }, []);

  const {
    data: transactionsData,
    isLoading: transactionsLoading,
    isError: transactionsError,
  } = useQuery({
    queryKey: ["transactionsData"],
    queryFn: fetchTransactionsData,
    // ...transactionsQueryOptions
  })

  const {
    data: offersData,
    isLoading: offersLoading,
    isError: offersError,
  } = useQuery({
    queryKey: ["offersData"],
    queryFn: fetchOffersData,
    // ...queryOptions
  })

  // PAYMENTS
  // Get Credit Cards
  const {
    data: creditCardsData,
    isLoading: creditCardsLoading,
    isError: creditCardsError,
    refetch,
  } = useQuery({
    queryKey: ["creditCardsData"],
    queryFn: fetchCreditCardsData,
    // ...queryOptions
  })

  // Delete Credit Cards
  const deleteCreditCard = useCallback(async (card) => {
    const { detail_id } = card // Extract detail_id from the card object
    const data = { detail_id }
    const response = await del(url.DELETE_CARD, { data, withCredentials: true })

    console.log(response)

    return response
  })

  const deleteCreditCardMutation = useMutation({
    mutationFn: deleteCreditCard,
    onSuccess: () => {
      console.log("card deleted")
      toast.success("Kartınız başarıyla kaldırıldı.", { toastId: "cardDeleteSuccess" })
      refetch()
    },
    onError: error => {
      console.log(error)
      if (error?.code === "ERR_NETWORK") {
        toast.error("Sistemden kaynaklı bir sorun var...", { toastId: "networkError" })
      } else {
        toast.error("Bir hata oluştu...", { toastId: "someError" })
      }

    },
  })

  // Inside your component where you're calling deleteCreditCard
  const handleDeleteCard = card => {
    deleteCreditCardMutation.mutate(card)
  }

  const handleCardSelection = (cardType, card = null) => {
    if (cardType === "new") {
      setSelectedCard(cardType)
      setSelectedCardType("new")
    } else if (cardType === "saved" && card) {
      setSelectedCardType("saved")
      // Select the first saved card by default, you can modify this logic based on your requirements
      setSelectedCard(card.detail_id)
      handleCardInfoChange("card_owner_name", card.card_owner_name)
      handleCardInfoChange("card_number", card.card_number)
      handleCardInfoChange("expiry_month", card.expiry_month)
      handleCardInfoChange("expiry_year", card.expiry_year)
      // cvc for card but cvv for cardInfo state because paytr API accepts cvv instead of cvc
      handleCardInfoChange("cvv", card.cvc)
      // set_cc_owner(card.name_on_card)
    }
  }

  const handleCheckboxChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const handleRadioChange = offer => {
    setClickedPackage(offer.offer_id)
    setTokenCount(offer.token_amount)
    setTokenPrice(offer.token_price)
  }

  const handleFinishButtonClick = () => {
    navigate("/bank", { state: { tokenCount, tokenPrice } })
  }

  const handleButtonClick = useCallback(() => {
    setIsCardOpen(!isCardOpen)
    handleCardSelection("new")
    handleCardSelection(isCardOpen ? "new" : "saved");
  }, [isCardOpen, handleCardSelection]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ButikO" breadcrumbItem="Tokenlar" />
          <ToastContainer />
          <Row>

            <Col xs="12" lg="6">
              <Card className="p-3">
                <CardTitle>Token paketleri</CardTitle>
                <CardBody>
                  <Offers
                    offersLoading={offersLoading}
                    offersError={offersError}
                    offersData={offersData}
                    clickedPackage={clickedPackage}
                    handleRadioChange={handleRadioChange}
                  />
                  <CardSubtitle className="mb-2" >
                    Kayıtlı Kart Bilgisi
                  </CardSubtitle>
                  <CreditCards
                    creditCardsLoading={creditCardsLoading}
                    creditCardsError={creditCardsError}
                    creditCardsData={creditCardsData}
                    selectedCard={selectedCard}
                    handleCardSelection={handleCardSelection}
                    handleDeleteCard={handleDeleteCard}
                    refetch={refetch}
                  />
                  <Row className="d-flex justify-content-center align-items-center mt-2">
                    <Col className="d-flex flex-column justify-content-center align-items-end">
                      <button
                        onClick={handleButtonClick}
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          textDecoration: "underline",
                          fontSize: "12px",
                          color: "#556EE6",
                        }}
                      >
                        Başka bir Kart ile Ödeme Yap
                      </button>
                    </Col>
                  </Row>

                  <NewCardPopup
                    isOpen={isCardOpen}
                    toggle={toggleModal}
                    isChecked={selectedCard === "new"}
                    selectedCard={selectedCard}
                    refetch={refetch}
                  />
                  <Row className="mt-4">
                    <Col
                      xs="12"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div className="d-flex me-3">
                        {/* Checkbox input */}
                        <Input
                          type="checkbox"
                          onChange={() => { }}
                          checked={isChecked}
                          onClick={handleCheckboxChange} // Ensure this is correctly bound
                          style={{
                            backgroundColor: isChecked ? "#F46A6A" : "",
                            marginTop: "12px"
                          }}
                        />
                        {/* Checkbox label */}
                        <ECuzdanSozlesmesi backgroundColor="white" />

                      </div>
                      {/* <form> */}
                      <PaymentForm
                        cardInfo={cardInfo}
                        selectedCardType={selectedCardType}
                        clickedPackage={clickedPackage}
                        isChecked={isChecked}
                        selectedCard={selectedCard}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" lg="6">
              <TokensAndTransactionsCard
                transactionsData={transactionsData}
                transactionsLoading={transactionsLoading}
                transactionsError={transactionsError}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const Tokens = () => (
  // <QueryClientProvider client={queryClient}>
  <TokenQuery />
  // </QueryClientProvider>
)

export default Tokens
