import React, { useState } from "react"
import {
  Button,
  Col,
  Row,
  CardTitle,
  Card,
  CardBody,
} from "reactstrap"
import OrderDetailReturnedPopup from "../Popups/OrderDetailReturnedPopup";
import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { tr } from 'date-fns/locale'; // Import Turkish locale
import { formatDistanceToNow } from 'date-fns';



const ReturningInfoTableWaiting = ({
  order_items,
  reFetch,
  return_tracking_no,
  return_tracking_url,
  // return_cancelled_barcode,
  return_cancelled_tracking_no,
  return_cancelled_tracking_url,
  customer_name,
  customer_address,
  order_date
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const [orderItemToCancel, setOrderItemToCancel] = useState({});
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  console.log(order_items);
  const orderStatusTable = {
    "ordered": "Sipariş verildi",
    "preparing": "hazırlanıyor",
    "shipped": "kargoya verildi",
    "customer-cancelled": "müşteri tarafından iptal edildi",
    "seller-cancelled": "satıcı tarafından iptal edildi",
    "customer-delivered": "teslim edildi",
    "success": "tamamlandı",
    "returning": "iade ediliyor",
    "return-shipped": "iade kargoya verildi",
    "returned": "iade satıcıya ulaştı",
    "return-accepted": "iade onaylandı",
    "return-cancelled": "iade iptal edildi",
    "return-cancelled-shipped": "iade müşteri için kargoya verildi",
    "return-cancelled-delivered": "iade müşteriye ulaştı"
  }

  const areAllItemsReturnedOrCanceled = order_items.every(item => {
    const status = item.status;
    return status === "return-accepted" || status === "return-cancelled";
  });
  const areSomeItemsReturnedCanceled = order_items.some(item => {
    const status = item.status;
    return status === "return-cancelled";
  });



  const completeReturn = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/complete-return-cancel?order_id=${order_items[0]?.order_id}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const completeReturnMutation = useMutation({
    mutationFn: completeReturn,
    onSuccess: (data) => {
      // console.log(data);
      toast.success("Gönderim kodu oluşturuldu")
      reFetch()
    },
    onError: (error) => {
      console.log(error)
      if (error?.response?.data?.detail[0]?.msg === "invalid order_id or no 'return-cancelled' order_item with no shipping_code") {
        toast.error("geçersiz sipariş kodu veya kargo kudu")
      } else if (error?.response?.data?.detail[0]?.msg === "there is at least one order_item with the status of 'returned' in this order") {
        toast.error("En az bir karar verilmemiş iadeniz mevcut")
      } else {
        toast.error("Bir şeyler yanlış gitti")
      }
    }
  })
  const acceptReturn = (id) => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/accept-return?order_item_id=${id}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const acceptReturnMutation = useMutation({
    mutationFn: acceptReturn,
    onSuccess: (data) => {
      // console.log(data);
      reFetch();
      toast.success("İade kabul edildi")
    },
    onError: (error) => {
      console.log(error);
      if (error?.response?.data?.detail[0]?.msg === "accepting return failed") {
        toast.error("İade kabul edilemedi")
      } else {
        toast.error("Böyle bir sipariş yok")
      }
      reFetch();
    }
  })
  const getBarcode = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-barcode?order_item_id=${order_items[0].order_item_id}&option=return_cancelled_reference_no`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const getBarcodeMutaion = useMutation({ mutationFn: getBarcode })
  const handleGetBarcode = async () => {
    // console.log(return_cancelled_barcode);


    try {
      const res = await getBarcodeMutaion.mutateAsync()
      // console.log(res);
      const base64Pdf = `${res.data.base64_barcode}`;
      const byteCharacters = atob(base64Pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element and click it to download the file
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${return_cancelled_tracking_no}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    // if (return_cancelled_barcode) {
    //   const formData = new FormData();
    //   formData.append("file", return_cancelled_barcode);

    //   axios.post(
    //     "https://api.labelary.com/v1/printers/8dpmm/labels/6x6/0/",
    //     formData,
    //     {
    //       headers: {
    //         Accept: "application/pdf" // Include the Accept header here
    //       },
    //       responseType: 'blob' // Specify the response type as blob
    //     }
    //   )
    //     .then((res) => {
    //       try {
    //         // console.log(res);
    //         const url = URL.createObjectURL(new Blob([res.data]));

    //         // Create a link element
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `${return_cancelled_tracking_no}.pdf`); // Set the download attribute
    //         document.body.appendChild(link);

    //         // Trigger the click event to initiate the download
    //         link.click();

    //         // Cleanup: Remove the link element and revoke the object URL
    //         link.parentNode.removeChild(link);
    //         URL.revokeObjectURL(url);
    //       } catch (err) {
    //         toast.error("Bir şeyler ters gitti")
    //         console.log(err);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //       toast.error("Bir şeyler ters gitti")
    //     });
    // } else {
    //   alert("jsdoifisdjfo")
    // }
  };

  return (
    <>
      <Col className="col-6">
        <Card>
          <CardTitle>
            Siparişi İade
          </CardTitle>
          <CardBody>

            <table
              className="text-dark opacity-75 fs-6 pb-4"
              style={{ fontWeight: "500" }}
            >
              <tbody>
                {customer_name && (<tr>
                  <td className="p-2 pe-4 ps-0">Ad</td>
                  <td> {customer_name}</td>
                </tr>)}
                {customer_address && (<tr>
                  <td className="p-2 pe-4 ps-0">Adres</td>
                  <td> {customer_address}</td>
                </tr>)}
                {order_date && (<tr>
                  <td className="p-2 pe-4 ps-0">Adres</td>
                  <td> {order_date}</td>
                </tr>)}
                {return_tracking_no && (<tr>
                  <td className="p-2 pe-4 ps-0">Satın Alma Tarihi</td>
                  <td>{return_tracking_no}</td>
                </tr>)}

                {return_tracking_url && (<tr>
                  <td className="p-2 pe-4 ps-0">İade takip linki</td>
                  <td><a href={return_tracking_url}>link</a></td>
                </tr>)}

                {return_cancelled_tracking_no && (<tr>
                  <td className="p-2 pe-4 ps-0">İade iptal takip numarası</td>
                  <td>{return_cancelled_tracking_no}</td>
                </tr>)}
                {return_cancelled_tracking_url && (<tr>
                  <td className="p-2 pe-4 ps-0">İade iptal takip linki</td>
                  <td> <a href={return_cancelled_tracking_url}>link</a></td>
                </tr>)}


              </tbody>
            </table>
            <div className="row">
              {order_items.map((info, index) => (
                <div key={index} className="col-12 col-xxl-12 p-1 m-0">
                  <Card className="w-100 d-flex position-relative flex-row border rounded p-0 overflow-hidden">

                    <img
                      src={info?.cover}
                      className="border"
                      style={{ width: "20%", objectFit: "cover", borderRadius: "8px" }}
                    />
                    <div className="w-75 column p-3 py-auto">

                      <CardTitle className="fs-6" style={{ fontWeight: "500" }}>
                        {info?.product_name}
                      </CardTitle>
                      {info?.status === "returned" ? (
                        <Alert
                          banner
                          message={
                            <Marquee pauseOnHover gradient={false}>
                              {`Onay için son ${formatDistanceToNow(new Date(info.returned_expires_at), { addSuffix: false, locale: tr })}`}
                            </Marquee>
                          }
                        />
                      ) :
                        (null)
                      }
                      <Row>

                        <Col>
                          <p className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              Durum:
                            </small>
                            {orderStatusTable[info.status]}
                          </p>
                        </Col>
                        <Col>
                          <p className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              Beden:
                            </small>
                            {info.size}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              Beden:
                            </small>
                            {info.quantity}
                          </p>
                        </Col>
                        <Col>
                          <p className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              Tarih:
                            </small>
                            {info.date}
                          </p>
                        </Col>
                      </Row>
                      {info.return_cancelled_shipping_code && (
                        <Row>
                          <Col>
                            <p className="p-0 m-0">
                              <small className="text-secondary opacity-75 me-1">
                                İade gönderim kodunu:
                              </small>
                              {info.return_cancelled_shipping_code}
                            </p>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <p style={{ maxHeight: "200px", overflowY: "auto" }} className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              İade nedeni:
                            </small>
                            {info.return_reason}
                          </p>
                        </Col>
                        <Col>
                          <p style={{ maxHeight: "200px", overflowY: "auto" }} className="p-0 m-0">
                            <small className="text-secondary opacity-75 me-1">
                              İade açıklaması:
                            </small>
                            {info.return_description}
                          </p>
                        </Col>
                      </Row>

                      {info?.status === "returned" && (
                        <Row>
                          <Col className="text-end mt-2">
                            <Button onClick={() => {
                              setOrderItemToCancel(info)
                              toggleModal()
                            }} color="danger">İade iptal</Button>
                            <Button
                              onClick={() => acceptReturnMutation.mutate(info.order_item_id)}
                              color="success"
                              className="ms-2">
                              İadeyi kabul et
                            </Button>
                          </Col>
                        </Row>
                      )}


                    </div>
                    <div
                      className="position-absolute rounded-circle"
                      style={{
                        backgroundColor: info.color_hex,
                        border: "2px solid #C1BDBD",
                        width: "20px",
                        height: "20px",
                        bottom: "0",
                        left: "20%",
                        transform: "translate(-50%)",
                      }}
                    ></div>
                  </Card>
                  {Array.isArray(info.return_medias) && info.return_medias.length > 0 && (
                    <div className="d-flex flex-column">
                      <h6 className="mb-0">Eklentiler</h6>
                      <div className="row">
                        {info.return_medias.map((image, index) => (
                          <div key={index} className="col-12 col-md-6 col-lg-3 p-2">
                            <img className="w-100" style={{ objectFit: "cover", borderRadius: "8px" }} src={image} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <OrderDetailReturnedPopup
              reFetch={reFetch}
              orderItemToCancel={orderItemToCancel}
              modelIsOpen={modalOpen}
              setModelIsOpen={setModalOpen}
            />
            {areSomeItemsReturnedCanceled && areAllItemsReturnedOrCanceled && return_cancelled_tracking_no && (
              <Button color="success" onClick={handleGetBarcode}>Kargo barkodu al</Button>
            )}
            {areAllItemsReturnedOrCanceled && !return_cancelled_tracking_no && (
              <div className="d-flex justify-content-end gap-2">
                <Button
                  onClick={() => completeReturnMutation.mutate()}
                  color="success"
                >
                  İade iptal işlemini tamamla
                </Button>


              </div>
            )}


          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default ReturningInfoTableWaiting