import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationTr from './locales/tr/translation.json'; 

const resources = {

  tr: {
    translation: translationTr
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'tr', 
    fallbackLng: 'tr',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
