import React, { useRef, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import withRouter from "components/Common/withRouter"
import {
    Button,
    Col,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
} from "reactstrap"
import { message } from 'antd';
import Image from 'react-bootstrap/Image';

import axios from "axios"
import "./AddProduct.css"

import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { IoImageOutline } from "react-icons/io5";
import "./AddProduct.css"
import { TiDelete } from "react-icons/ti";


const UploadMediaModal = ({ modalOpen, setModalOpen, products }) => {
    const [count, setCount] = useState(0);
    const toastRef = useRef(null)
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    products.map(product => ({
        ...product,
        okImg: [],
        notOkImg: []
    }));
    const history = useNavigate()


    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }
    //for drop zone

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };


    const handleDrop = (e, index, product_id, variation_id) => {
        e.preventDefault();
        e.stopPropagation();

        const files = Array.from(e.dataTransfer.files);
        const validFiles = files.filter(
            (file) =>
                file.size > 500 &&
                (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")
        );

        if (validFiles.length === 0) {
            toast.info("Dosyalar 5 kb'den büyük olmalıdır", { toastId: "dropError" });
            return;
        }

        const promises = validFiles.map((file) =>
            variationMediaMutation.mutateAsync({
                media: file,
                productId: product_id,
                variationId: variation_id,
            })
        );

        messageApi.open({
            key,
            type: 'loading',
            content: 'Resim yükleniyor...',
            duration: 0,
        });

        Promise.all(promises)
            .then(() => {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Tüm resimler başarıyla yüklendi.',
                    duration: 2,
                });
            })
            .catch((error) => {

                messageApi.open({
                    key,
                    type: 'error',
                    content: 'Bazı resimler yüklenemedi',
                    duration: 2,
                });
                console.error("Error uploading media:", error);
            });
    };

    const handleClick = (index) => {//because input is display none this fuc trigers click event for uploading photo

        const photoInput = document.getElementById(`input_${index}`)

        photoInput.click()
    };

    const handleInputChange = (e, index, product_id, variation_id) => {
        const files = Array.from(e.target.files);
        const key = "loading";
        const validFiles = files.filter(
            (file) =>
                file.size > 500 &&
                (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")
        );

        if (validFiles.length === 0) {
            messageApi.open({
                key,
                type: 'error',
                content: 'Dosyalar 5 kb\'den büyük ve resim formatında olmalıdır',
                duration: 2,
            });
            return;
        }

        messageApi.open({
            key,
            type: 'loading',
            content: 'Resimler yükleniyor...',
            duration: 0,
        });

        const promises = validFiles.map((file) =>
            variationMediaMutation.mutateAsync({
                media: file,
                productId: product_id,
                variationId: variation_id,
            })
        );

        Promise.all(promises)
            .then(() => {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Tüm resimler başarıyla yüklendi.',
                    duration: 2,
                });
            })
            .catch((error) => {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'Bazı resimler yüklenemedi',
                    duration: 2,
                });
                console.error("Error uploading media:", error);
            });
    };


    const uploadVariationMedia = (props) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/upload-variation-media`
        const formData = new FormData();
        formData.append("file", props.media);
        formData.append("product_id", parseInt(props.productId));
        formData.append("variation_id", parseInt(props.variationId));


        return axios.post(finalUrl, formData, { withCredentials: true })
    }
    const variationMediaMutation = useMutation({
        mutationFn: uploadVariationMedia,
        onSuccess: (data, variables) => {
            // console.log(data.data.media_url);
            setTimeout(() => {

                setCount(count + 1)
            }, 800);
            // toast.update(toastRef.current, { render: "Yüklendi👌", type: "success", isLoading: false, autoClose: 1000 })
            // console.log(variables);
            products.forEach((productt) => {
                if (productt.variation_id === variables.variationId) {
                    productt.okImg = productt.okImg || [];
                    // productt.okImg.push(variables.media.name);
                    productt.okImg.push(data.data.media_url);

                }
            });
            // toast.success('Medya Başarıyla yüklendi!', { toastId: "done" })
        },
        onError: (error, variables, context) => {
            toast.update(toastRef.current, { render: "Yüklenmedi", type: "error", isLoading: false, autoClose: 1000 })
            if (error.response.data.detail[0].msg === "file size above maxiumum") {

                toast.error("Dosya boyutu 5MB üzerinde", { toastId: "sizeErr" })
            } else {
                toast.error("Desteklenmeyen ortam türü", { toastId: "formatError" })
            }
            console.log(error);
            products.forEach((productt) => {
                if (productt.variation_id === variables.variationId) {
                    productt.notOkImg = productt.notOkImg || [];
                    productt.notOkImg.push(variables.media.name);

                }
            });
        },


    })
    // if (variationMediaMutation.isPending) {
    //     console.log("pending");
    //     toast.warning('Yükleniyor!', { toastId: "waiting" });
    // }
    const stageProduct = (id) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/stage-product`
        const finalData={
            product_id: parseInt(id)
        }
        return axios.post(finalUrl,finalData, { withCredentials: true })
    }
    const stageMutation = useMutation({
        mutationFn: stageProduct,
        onSuccess: (data) => {
            toast.success("Ürün onay için admin'e gönderildi")
            console.log(data);
            products.map((product) => {
                product.okImg = []
                product.notOkImg = []
            })
            products = []
            setTimeout(() => {
                history("/ecommerce-products")

            }, 1500);
        },
        onError: (e) => {
            console.log(e)
            if (e?.response?.data?.detail[0].msg?.startsWith("variation_media doesn't exist")) {
                toast.error("Varyasyon Medyası Yok Lütfen Medya Yükleyin")
            }else if(e?.response?.data?.detail[0]?.msg ==="invalid product_id, no product found"){
                toast.error("Geçersiz Ürün")
            } else if(e?.response?.data?.detail[0]?.msg=="invalid product_id, no variation found"){
                toast.error("Geçersiz Ürün Varyasyonu")
            }else if(e?.response?.data?.detail[0]?.msg=="invalid product_id, no size found"){
                toast.error("Geçersiz Ürün Bedeni")
            }
        }
    })
    const removeVariation = (props) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/inventory/remove-orphan-variation-media`
        const data = {
            variation_id: props.id,
            media_url: props.url
        }
        return axios.post(finalUrl, data, { withCredentials: true })
    }
    const removeVariationMutation = useMutation({
        mutationFn: removeVariation,
        onSuccess: (data, variables) => {
            console.log(data);
            console.log(variables);
            products.forEach((product) => {
                if (product.variation_id === variables.id) {
                    product.okImg = product.okImg.filter((img) => img !== variables.url)
                }
            })
            setCount(count + 1)
            toast.success("Kaldırıldı")
        },
        onError: (error) => {
            console.log(error);
            toast.error("Kaldırılmadı")
        }
    })


    return (
        <>
            <Container fluid={true}>
                <Modal
                    style={{
                        maxWidth: "75rem",
                        maxHeight: "1200px",
                        marginTop: "3rem",
                    }}
                    isOpen={modalOpen}
                    toggle={toggleModal}
                    backdrop="static"
                >
                    <ModalHeader toggle={toggleModal}>Ürününüzü Seçin </ModalHeader>
                    <ModalBody className="mx-3 my-3">
                        <Row>
                            {products.map((product, index) => {
                                return (
                                    <>
                                        <Row className=" mb-2 " key={`index${index}`} style={{ borderBottom: "1px solid #eeeeee" }}>
                                            <Col style={{ maxWidth: "12rem", width: "100%" }} className="col-4 p-0">
                                                <div

                                                    className="dropzoneStyles"
                                                    onClick={() => handleClick(index)}
                                                    onDragOver={handleDragOver}
                                                    onDrop={(e) => handleDrop(e, index, product.product_id, product.variation_id)}
                                                >
                                                    <input
                                                        multiple
                                                        type="file"
                                                        id={"input_" + index}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleInputChange(e, index, product.product_id, product.variation_id)}
                                                        accept="image/jpeg, image/png"
                                                    />

                                                    <IoImageOutline size={64} />

                                                    <span style={{ fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Tıklayın veya Sürükleyin</span>
                                                </div>
                                            </Col>
                                            <Col className="col-2 d-flex align-items-center ">

                                                <div style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    width: "16px",
                                                    height: "16px",
                                                    borderRadius: "50%",
                                                    backgroundColor: product.color.color_hex,
                                                    boxShadow: "2px 2px 3px 0px #cfcfcf"
                                                }}></div>
                                                <span className="ms-2" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                                    {product.color.color_name}
                                                </span>
                                            </Col>
                                            <Col className="col-6" style={{ overflowX: "auto", whiteSpace: "nowrap", position: "relative" }}>
                                                {product?.okImg?.map((item, index) => (
                                                    <div style={{ position: "relative", display: "inline-block", marginRight: "8px" }} key={`${index}-ok`}>
                                                        <Image
                                                            className="m-1"
                                                            height={160}
                                                            width={140}
                                                            style={{ borderRadius: "8px", objectFit:"cover"}}
                                                            src={item}
                                                        // onClick={(e) => {
                                                        //     console.log(e.target.src);
                                                        // }}
                                                        />
                                                        <button
                                                            style={{ position: "absolute", top: "-8px", right: "-10px", backgroundColor: "transparent", border: "none", cursor: "pointer", fontSize: "18px" }}
                                                            onClick={() => removeVariationMutation.mutate({ url: item, id: product.variation_id })}
                                                        >
                                                            <TiDelete size={20} color="rgb(250, 126, 126)" />
                                                        </button>
                                                    </div>
                                                ))}
                                                {/* <div style={{ marginTop: '14px' }}></div>
                                                {product?.notOkImg?.map((item, index) => (
                                                    <span className="not-ok-img" key={`${index}-NotOk`}>
                                                        {item}
                                                    </span>
                                                ))} */}
                                            </Col>

                                        </Row>
                                    </>
                                )
                            })}


                        </Row>
                        {contextHolder}
                    </ModalBody>
                    <ModalFooter>

                        <Button color="secondary" onClick={() => {
                            toggleModal()
                            products.map((product) => {
                                product.okImg = []
                                product.notOkImg = []
                            })
                        }}>
                            Kapat
                        </Button>
                        <Button onClick={() => {
                            console.log(products[0].product_id)
                            stageMutation.mutate(products[0].product_id)
                        }} color="success">
                            Ürün ekle
                        </Button>

                    </ModalFooter>
                </Modal>
            </Container>
        </>
    )
}

export default withRouter(UploadMediaModal)
