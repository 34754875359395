import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IoIosNotifications } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
//i18n
import { withTranslation } from "react-i18next";
import addNotification from 'react-push-notification';
import logo from "../../../assets/images/logo/Logo.png"
const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    return date.toLocaleString('en-US', options);
  };
  const updateFetch = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/notification/update-last-fetch`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { data: updateFetchData,
    isLoading: updateFetchIsLoading,
    isError: updateFetchIsError,
    error: updateFetchError,
    refetch: updateFetchRefetch
  } = useQuery({
    queryKey: ["update-last-fetch"],
    queryFn: updateFetch,
    enabled: false
  })
  if (updateFetchIsLoading) {

  } else if (updateFetchIsError) {
    console.log(updateFetchError);
  } else {
    // console.log(updateFetchData);
  }

  const getNotification = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/notification/get-notifications`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { data: notificationData, isError, error, isLoading, refetch } = useQuery({ queryKey: ["notification"], queryFn: getNotification })
  let notification
  let notificationNotReverse
  let toPushNotifications
  let number
  if (isLoading) {
    notification = "loading"
  } else if (isError) {
    console.log(error);
    notification = "error"
  } else {

    notificationNotReverse = notificationData.data
    notification = [...notificationNotReverse].reverse()
    toPushNotifications = notificationData.data.filter(
      (notification) => notification.first_time === 1
    );
    number = toPushNotifications.length;
    toPushNotifications.forEach(element => {
      addNotification({
        title: element.title,
        // subtitle: 'This is a subtitle',
        message: element.content,
        // theme: 'darkblue',
        native: true,
        icon: logo
      });
    });
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
          onClick={() => {
            // console.log("hi");
            updateFetchRefetch()
            setTimeout(() => {
              refetch()
            }, 7000);
          }}
        >
          <i className={`bx bx-bell ${number > 0 && "bx-tada"}`} />
          <span className="badge bg-danger rounded-pill">{number > 0 && number}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to={"/notifications"}>
                  Hepsini Gör
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {Array.isArray(notification) && notification.length > 0 ? (
              notification.map((item, index) => {
                return (
                  <Link key={`${item.title}-- ${index}`} to="/notifications" className="text-reset notification-item">
                    <div className={`d-flex ${item.first_time === 1 ? "bg-info-subtle " : ""}`}>
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          {/* <i className="bx bx-cart" /> */}
                          <IoIosNotifications />
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">
                          {/* {props.t("Your order is placed")} */}
                          {item.title}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className={`mb-0 texend d-inline-block float-end ${item.first_time === 0 ? "d-none  " : ""}`}>
                            <GoDotFill color="#556EE6" />
                          </p>
                          <p style={{ width: "150px" }} className="mb-1 text-truncate ">
                            {/* {props.t(
                              "If several languages coalesce the grammar"
                            )} */}
                            {item.content}
                          </p>
                          <p className="mb-0 align-items-center ">
                            {/* <i className="mdi mdi-clock-outline" />{" "} */}
                            <MdOutlineAccessTimeFilled style={{ marginBottom: "3px" }} />{" "}
                            {/* {props.t("3 min ago")}{" "} */}
                            {formatDate(item.date)}
                          </p>
                        </div>


                      </div>
                    </div>
                  </Link>
                )
              })
            ) : (
              <div >

                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1 text-center ">
                    Bildirim yok
                  </h6>
                </div>
              </div>
            )}
            {/* <Link to="" className="text-reset notification-item  ">
              <div className={`d-flex   `}>
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-cart" />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your order is placed")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "If several languages coalesce the grammar"
                      )}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar3}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") +
                        "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "If several languages coalesce the grammar"
                      )}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/notifications">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("Daha fazla göster..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};