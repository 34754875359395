import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
//Import Star Ratings
import StarRatings from "react-star-ratings";

const Specifications = ({ specifications, isLoading, isError }) => {

    // Loading state
    if (isLoading) {
        return <div>Yükleniyor...</div>;
    }

    // Error state
    if (isError) {
        return <div>Veriler alınırken hata oluştu.</div>;
    }

    return (
        <>
            <span style={{color:"blue"}}>
                {specifications?.category_name}
                </span>
            <h4 className="mt-1 mb-3">{specifications?.name}</h4>

            {
                specifications?.total_reviews != 0 && specifications?.rating && (
                    <div className="text-muted float-start me-3">
                        <StarRatings
                            rating={specifications.rating}
                            starRatedColor="#F1B44C"
                            starEmptyColor="#74788d"
                            numberOfStars={5}
                            name="rating"
                            starDimension="14px"
                            starSpacing="3px"
                        />
                    </div>
                )
            }

            <p className="text-muted mb-4">
                ( {specifications?.total_reviews} Müşteri Değerlendirmesi )
            </p>

            <div
                style={{ lineHeight: "24px", color: "#74788D" }}
                dangerouslySetInnerHTML={{ __html: specifications?.description }}
            />
            <div className="mt-5">
                <h5 className="mb-3">Özellikler :</h5>

                <div className="table-responsive">
                    <Table className="table mb-0 table-bordered">
                        <tbody>
                            <tr>
                                <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                >
                                    Marka
                                </th>
                                <td>
                                    {specifications?.product_brand ? (
                                        <span>{specifications.product_brand}</span>
                                    ) : (
                                        <span>Marka belirtilmemiş.</span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                >
                                    Renk
                                </th>
                                <td>
                                    <div className="d-flex">
                                        {specifications?.color &&
                                            JSON.parse(specifications.color).map(
                                                (color, index, array) => (
                                                    <React.Fragment key={index}>
                                                        <span>{color.color_name}</span>
                                                        {index !== array.length - 1 && <span>,&nbsp;</span>}
                                                    </React.Fragment>
                                                )
                                            )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                >
                                    Kategori
                                </th>
                                <td>{specifications?.category_name}</td>
                            </tr>
                            <tr>
                                <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                >
                                    Özellikler
                                </th>
                                <td>{specifications?.features}</td>
                            </tr>
                            <tr>
                                <th
                                    scope="row"
                                    style={{ width: "200px" }}
                                    className={"text-capitalize"}
                                >
                                    Hashtagler
                                </th>
                                <td>
                                    <div className="d-flex">
                                        {specifications?.hashtags ? (
                                            JSON.parse(specifications.hashtags).map(
                                                (hashtag, index, array) => (
                                                    <React.Fragment key={index}>
                                                        <p>{hashtag}</p>
                                                        {index !== array.length - 1 && <span>,&nbsp;</span>}
                                                    </React.Fragment>
                                                )
                                            )
                                        ) : (
                                            <span>Hashtag yok.</span>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </>

    );
};

Specifications.propTypes = {
    specifications: PropTypes.shape({
        product_brand: PropTypes.string,
        color: PropTypes.string,
        category_name: PropTypes.string,
        features: PropTypes.string,
        hashtags: PropTypes.string,
        total_reviews: PropTypes.number, // Add PropTypes for total_reviews
        rating: PropTypes.number, // Add PropTypes for rating
        description: PropTypes.string, // Add PropTypes for description
    }),
    isLoading: PropTypes.bool.isRequired, // PropTypes for isLoading
    isError: PropTypes.bool.isRequired, // PropTypes for isError
};

export default Specifications;
