import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
const useGetCards = () => {
    const getCards = ()=>{
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/payment/get_card_details`
        return axios.get(finalUrl,{withCredentials:true})
    }
    const {data,isLoading, isError,error,refetch } = useQuery({queryKey:["getCardData"],queryFn:getCards})
    const cards = data?.data
    return {cards, isLoading, isError,error,refetch}
}
export default useGetCards