import React from 'react';
import ErrorDisplay from '../hataGöster/ErrorDisplay';
import { Col } from 'reactstrap';

// ! Register'ın 2. sayfasında her sırada bir input kutusu olduğu için yazdığım component.
// * En dışta Col var. Ve bir Row'un içinde lg prop'unu ayarlayarak istediğimiz kadar input kutusunu aynı sıraya yerleştirebiliriz.
const FormInput = ({ title, value, onChange, placeholder, error, type = "text", optional = false, isEmail = false, lg = 10, onBlur }) => (
    <Col lg={lg} style={{ marginTop: "20px" }}>
        <span className="inputTitle">
            {title}{" "}
            {optional && <span style={{ opacity: "0.5" }}>(isteğe bağlı)</span>}
        </span>
        {isEmail ? (
            <input
                type="email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className="form-control form-text"
                placeholder={placeholder}
                style={{ padding: "20px" }}
            />
        ) : (
            <div>
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className="form-control form-text"
                    placeholder={placeholder}
                    style={{ padding: "20px" }}
                />
            </div>
        )}
        <ErrorDisplay error={error} />
    </Col>
);

export default FormInput;
