import React from "react";

const ColorCircle = (props) => {
  return (
    <div
    // key={props.key}
      style={{
        width: "20px", // Yuvarlak rengin genişliği
        height: "20px", // Yuvarlak rengin yüksekliği
        borderRadius: "50%", // Yuvarlak yapmak için border-radius
        backgroundColor: props.color, // Renk
        display: "inline-block",
        margin: "6px",
        marginBottom: "8px",
        boxShadow: "3px 3px 4px 1px #cfcfcf"
      }}
    ></div>
  );
};

export default ColorCircle;
