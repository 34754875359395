import React from 'react';
import { Form } from 'react-bootstrap';

const MonthSelect = ({ value, onChange, disabled }) => {
    return (
        <Form.Select
            value={value < 9 ? `0${value}`:value}
            onChange={onChange}
            disabled={disabled}
            aria-label="Month Select"
        >
            <option>Ay</option>
            {[...Array(12)].map((_, index) => (
                <option key={index + 1} value={index < 9 ? `0${index + 1}` : `${index + 1}`}>
                    {index + 1}
                </option>
            ))}
        </Form.Select>
    );
};

export default MonthSelect;
