import React, { useState } from "react"
import CarouselPage from "./CarouselPage"

// import images
import logodark from "../../assets/images/logo/logo-dark.png"
import logolight from "../../assets/images/logo/logo-light.png"
import { Button, Col, Input, Label, List, Row } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { useMutation } from "@tanstack/react-query"
import toastr from "toastr"
import { BsEyeFill } from "react-icons/bs"
import { BsEyeSlashFill } from "react-icons/bs"
const ChangePassword = () => {
  //meta title
  document.title = "Şifre Değiştir | ButikO"

  const location = useLocation()
  const navigate = useNavigate()
  const uuid = location?.state?.uuid
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [error, setError] = useState("")

  const [isMoreThan8Chars, setIsMoreThan8Chars] = useState(false)
  const [includesUpperCase, setIncludesUpperCase] = useState(false)
  const [includesLowerCase, setIncludesLowerCase] = useState(false)
  const [includesNumber, setIncludesNumber] = useState(false)
  const [includesSpecialChar, setIncludesSpecialChar] = useState(false)
  const [isClickedOnPassword, setIsClickedOnPassword] = useState(false)

  const handlePasswordChange = e => {
    const newPassword = event.target.value
    setPassword(newPassword)
    validatePassword(newPassword)
    setError("")
    if (e.target.value !== confirmPassword) {
      setPasswordsMatch(false)
    } else {
      setPasswordsMatch(true)
    }
  }
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handlePasswordClick = () => {
    setIsClickedOnPassword(true)
  }

  const handlePasswordBlur = () => {
    setIsClickedOnPassword(false)
  }

  const validatePassword = password => {
    setIsMoreThan8Chars(password.length >= 8)
    setIncludesUpperCase(/[A-Z]/.test(password))
    setIncludesLowerCase(/[a-z]/.test(password))
    setIncludesNumber(/\d/.test(password))
    setIncludesSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password))
  }

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value)
    setError("")
    if (e.target.value !== password) {
      setPasswordsMatch(false)
    } else {
      setPasswordsMatch(true)
    }
  }

  const changePassword = async () => {
    const data = {
      uuid: uuid,
      new_password: password,
    }

    const response = await post(url.POST_CHANGE_PASSWORD, data)

    console.log(response)

    return response
  }

  const changePasswordMutation = useMutation({
    mutationFn: changePassword,
    onError: error => {
      console.log(error)

      if (
        error?.response?.data?.detail?.[0]?.msg ===
        "String should have at least 1 characters"
      ) {
        toastr.error("Yeni şifreniz en az 1 karakter içermeli.")
      } else if (
        error?.response?.data?.detail?.[0]?.msg ===
        "password length must be at least 8"
      ) {
        toastr.error("Yeni şifreniz en az 8 karakterden oluşmalı.")
      } else if (
        error?.response?.data?.detail?.[0]?.msg ===
        "password must have at least one capital character"
      ) {
        toastr.error("Yeni şifreniz en az 1 büyük harf içermeli.")
      } else if (
        error?.response?.data?.detail?.[0]?.msg ===
        "password must have at least one number"
      ) {
        toastr.error("Yeni şifreniz en az 1 sayı içermeli.")
      } else if (
        error?.response?.data?.detail?.[0]?.msg ===
        "password must have at least one special character"
      ) {
        toastr.error("Yeni şifreniz en az 1 özel karakter içermeli.")
      }
    },
    onSuccess: () => {
      toastr.success(
        "Şifreniz başarıyla değiştirildi. Giriş sayfasına yönlendiriliyorsunuz."
      )
      navigate("/login")
    },
  })

  const handleButtonClick = () => {
    if (!passwordsMatch) {
      setError("Şifrelerin eşleştiğinden lütfen emin olunuz.")
      return
    }

    changePasswordMutation.mutate()
  }

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid p-0">
          <div className="row g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-lock-alt h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Şifrenizi Değiştirin</h4>
                          <p>Lütfen yeni şifrenizi girin.</p>

                          <div className="mt-4 text-start">
                            <Label>Yeni şifre</Label>
                            <div style={{ position: "relative" }}>
                              <Input
                                type={showPassword ? "text" : "password"}
                                className="mb-2"
                                value={password}
                                onChange={handlePasswordChange}
                                onClick={handlePasswordClick}
                                onBlur={handlePasswordBlur}
                              />
                              <div
                                onClick={handleShowPassword}
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "20px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  fontSize: "15px",
                                }}
                              >
                                {showPassword ? (
                                  <BsEyeSlashFill />
                                ) : (
                                  <BsEyeFill />
                                )}
                              </div>
                            </div>

                            <Label>Şifrenizi onaylayınız</Label>
                            <div style={{ position: "relative" }} >
                            <Input
                              type={showConfirmPassword ? "text" : "password"}
                              className="mb-2"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                            />
                        <div
                          onClick={handleShowConfirmPassword}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "20px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        >
                          {showConfirmPassword ? (
                            <BsEyeSlashFill />
                          ) : (
                            <BsEyeFill />
                          )}
                        </div>
                      </div>

                            {isClickedOnPassword && (
                              <Row>
                                <Col lg="12">
                                  <List>
                                    <li
                                      style={{
                                        color: isMoreThan8Chars
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      Şifreniz en az 8 haneden oluşmalı.
                                    </li>
                                    <li
                                      style={{
                                        color: includesUpperCase
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      Şifreniz en az 1 büyük harf içermeli.
                                    </li>
                                    <li
                                      style={{
                                        color: includesLowerCase
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      Şifreniz en az 1 küçük harf içermeli.
                                    </li>
                                    <li
                                      style={{
                                        color: includesNumber ? "green" : "red",
                                      }}
                                    >
                                      Şifreniz en az 1 sayı içermeli.
                                    </li>
                                    <li
                                      style={{
                                        color: includesSpecialChar
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      Şifreniz en az 1 sembol içermeli.
                                    </li>
                                  </List>
                                </Col>
                              </Row>
                            )}

                            {error && (
                              <span className="text-danger mb-2">{error}</span>
                            )}

                            <div className="d-flex flex-column justify-content-center align-items-stretch mt-2">
                              <Button
                                style={{ backgroundColor: "#E35E5E", border:"1px solid #CED4DA"}}
                                onClick={handleButtonClick}
                              >
                                Şifreyi Yenile
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} ButikO{" "}
                        <i className="mdi mdi-heart text-danger"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword
