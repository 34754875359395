import {create} from 'zustand';
import axios from 'axios';
const useUserStore = create((set) => ({
    approvalStatus: "",
    setApprovalStatus: (status) => set({ approvalStatus: status }),
    getStatus: async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/is-authenticated`,{withCredentials:true});
            if (response.status === 200) {
                console.log(response?.data);
                if(response?.data?.is_authenticated){
                    return response?.data?.payload?.approval_status
                }
                // set({ approvalStatus: newStatus });
                // return newStatus;
            } else {
                console.error('Failed to get approval status from server');
                return null;
            }
        } catch (error) {
            console.error('Error getting approval status from server:', error);
            return null;
        }
    }

}));

export default useUserStore;