import React, { useState } from "react"
import { Table, Card, CardBody } from "reactstrap"
import ProductRow from "./ProductRow"
import OutOfStockPopup from "pages/Ecommerce/EcommerceProducts/Popups/OutOfStockPopup"
import DeleteConfirmationPopup from "pages/Ecommerce/EcommerceProducts/Popups/DeleteConfirmationPopup"

const ProductList = ({
  errorOccurred,
  isLoading,
  products,
  setProducts,
  deleteProduct,
  setCurrentPage
}) => {

  const [outOfStockModalIsOpen, setOutOfStockModalIsOpen] = useState(false)
  const [discountModalIsOpen, setDiscountModalIsOpen] = useState(false)
  const [deleteConfirmModalIsOpen, setDeleteConfirmModalIsOpen] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState(0)
  const [productName, setProductName] = useState("");

  const isProductsEmpty = !products || products.length === 0;
  const showNoProductsAlert = isProductsEmpty || errorOccurred === true;

  const openDiscountModal = () => {
    setDiscountModalIsOpen(true)
  }
  const openOutOfStockModal = () => {
    setOutOfStockModalIsOpen(true)
  }
  const openDeleteConfirmModal = () => {
    setDeleteConfirmModalIsOpen(true)
  }

  return (
    <Card>
      <CardBody style={{ overflowX: "auto" }}>
        {
          showNoProductsAlert ? (
            <div className="alert" style={{backgroundColor:"#F8F8FB"}}>
              Ürün bulunamadı. "Yeni Ürün Ekle" butonundan yeni ürün ekleyebilirsiniz.
            </div>
          ) : (
            <Table className="table-bordered productTable" >
              <thead >
                <tr>
                  <th>Ürünler</th>
                  <th>Ürün Açıklaması</th>
                  <th>Satışlar</th>
                  <th>Miktar</th>
                  <th>Fiyat</th>
                  <th>İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {errorOccurred ? (
                  <tr style={{ backgroundColor: "#fff" }}>
                    <td colSpan="8">Ürün bulunamadı. Filtreleri temizleyip tekrar deneyiniz</td>
                  </tr>
                ) : isLoading ? (
                  <tr style={{ backgroundColor: "#fff" }}>
                    <td colSpan="8">Yükleniyor...</td>
                  </tr>
                ) : (products && products.length > 0 &&
                  products
                    .slice(0, 6)
                    .map((product, key) => (
                      <ProductRow
                        key={`_row_${key}`}
                        product={product}
                        setCurrentPage={setCurrentPage}
                        deleteProduct={deleteProduct}
                        setSelectedProductId={setSelectedProductId}
                        setProductName={setProductName}
                        openOutOfStockModal={openOutOfStockModal}
                        openDeleteConfirmModal={openDeleteConfirmModal}
                      />
                    )))}
                <OutOfStockPopup
                  modalIsOpen={outOfStockModalIsOpen}
                  setIsOpen={setOutOfStockModalIsOpen}
                  productId={selectedProductId}
                  productName={productName}
                />
                <DeleteConfirmationPopup
                  modalIsOpen={deleteConfirmModalIsOpen}
                  setIsOpen={setDeleteConfirmModalIsOpen}
                  productId={selectedProductId}
                  products={products}
                  setProducts={setProducts}
                />
                
              </tbody>
            </Table>
          )
        }

      </CardBody>
    </Card>

  )
}

export default ProductList
