import React from "react"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useMutation } from "@tanstack/react-query"
// Import Helpers
import * as url from "helpers/url_helper"
import { del } from "helpers/api_helper"


const DeleteConfirmationPopup = ({
  modalIsOpen,
  setIsOpen,
  productId,
  products,
  setProducts,
}) => {
  function closeModal() {
    setIsOpen(false)
  }

  const deleteProductMutation = useMutation({
    mutationKey: ["deleteProduct"],
    mutationFn: ({ productId }) => {
      return del(`${url.DELETE_PRODUCT}?product_id=${productId}`, {
        withCredentials: true,
      })
    },
    onError: error => {
      if (error?.response && error.response.status === 404) {
        toast.error("Ürün bulunamadı.", { toastId: "noProductError" })
      } else if (
        error?.response?.data?.detail?.[0]?.msg &&
        error?.response?.data?.detail?.[0]?.msg ===
          "there is an active order regarding this product_id, cannot be removed"
      ) {
        toast.error(
          "Şu anda ürünün aktif siparişi var. Bu yüzden bu ürün silinemez.",
          { toastId: "activeOrderError" }
        )
      } else {
        console.error("Error deleting product: ", error)
        toast.error("Ürün silinemedi.", { toastId: "notDeletedError" })
      }
    },
    onSuccess: (data, variables) => {
      // Filter out the deleted product from the products array
      const updatedProducts = products.filter(
        product => product.product_id !== variables.productId
      )

      setProducts(updatedProducts)

      toast.success("Ürün başarıyla silindi.", { toastId: "deleteSuccessful" })

      // // Update the query data with the filtered products
      // queryClient.setQueryData(['getProducts', currentPage], updatedProducts);
      // refetch()
    },
  })

  const deleteProduct = () => {
    deleteProductMutation.mutate({ productId })
  }

  const handleDelete = () => {
    deleteProduct()
    closeModal()
  }

  const modalStyles = {
    maxWidth: "500px",
    margin: "auto",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  }

  const headerStyles = {
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ddd",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    padding: "16px",
  }

  const bodyStyles = {
    padding: "40px",
    fontSize: "15px",
    textAlign: "center",
  }

  const buttonGroupStyles = {
    display: "flex",
    justifyContent: "end",
    gap: "20px",
    marginTop: "45px",
  }

  const cancelButtonStyles = {
    backgroundColor: "#f5f5f5",
    borderColor: "#6c757d",
    border: "1px solid #CED4DA",
    color: "black",
  }

  const deleteButtonStyles = {
    backgroundColor: "#FF5043",
    border: "1px solid #CED4DA",
    color: "white",
    padding: "10px 20px",
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={closeModal}
      // contentLabel="Example Modal"
      toggle={closeModal}
      style={{
        ...modalStyles,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >

      <ModalBody style={bodyStyles}>
        <Row className="justify-content-center align-items-center text-center">
          <Col>
            <Row style={{marginBottom:"20px"}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
              viewBox="0 0 512 512"
              width="150" height="150"
            >
              <path
                fill="#A82C1F"
                fillRule="nonzero"
                d="M256 0c70.686 0 134.69 28.658 181.016 74.984C483.342 121.31 512 185.314 512 256c0 70.686-28.658 134.69-74.984 181.016C390.69 483.342 326.686 512 256 512c-70.686 0-134.69-28.658-181.016-74.984C28.658 390.69 0 326.686 0 256c0-70.686 28.658-134.69 74.984-181.016C121.31 28.658 185.314 0 256 0z"
              />
              <circle fill="#FF5043" cx="256" cy="256" r="226.536" />
              <path
                fill="#fff"
                fillRule="nonzero"
                d="M275.546 302.281c-.88 22.063-38.246 22.092-39.099-.007-3.779-37.804-13.444-127.553-13.136-163.074.312-10.946 9.383-17.426 20.99-19.898 3.578-.765 7.512-1.136 11.476-1.132 3.987.007 7.932.4 11.514 1.165 11.989 2.554 21.402 9.301 21.398 20.444l-.044 1.117-13.099 161.385zm-19.55 39.211c14.453 0 26.168 11.717 26.168 26.171 0 14.453-11.715 26.167-26.168 26.167s-26.171-11.714-26.171-26.167c0-14.454 11.718-26.171 26.171-26.171z"
              />
            </svg>
            </Row>

            <span style={{fontWeight:"bold"}}>
              Ürünü Silmek İstediğinize Emin Misiniz ?
            </span>
            <div style={buttonGroupStyles}>
              <Button
                style={cancelButtonStyles}
                onClick={closeModal}
                className="px-3"
              >
                İptal Et
              </Button>
              <Button
                style={deleteButtonStyles}
                onClick={handleDelete}
                className="px-4"
              >
                Kaldır
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default DeleteConfirmationPopup
