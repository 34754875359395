import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
export const useProformaInvoice = (id) => {
    const getProformaInvoice = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-proforma-invoice?order_id=${id}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { isLoading, isError, error, data, refetch } = useQuery({
        queryKey: ['get-proforma-invoice', id],
        queryFn: getProformaInvoice,
    });

    return { isLoading, isError, error, data, refetch };
};