import React from "react"
import { Col } from "reactstrap"
import "./log.css"
// import image1 from "../../assets/login/66px.png"
// import image2 from "../../assets/login/discover2.jpg"
// import image3 from "../../assets/login/discover3.jpg"
// import image4 from "../../assets/login/discover4.jpg"
// import image5 from "../../assets/login/rectangle-31@2x.jpg"
// import image6 from "../../assets/login/rectangle-261@2x.jpg"
// import image12 from "../../assets/login/discover5.jpg"
// import image7 from "../../assets/login/discover6.jpg"
// import image8 from "../../assets/login/rectangle-31@2x.jpg"
// import image9 from "../../assets/login/rectangle-261@2x.jpg"
// import image10 from "../../assets/login/rectangle-301@2x.png" // yok
// import image11 from "../../assets/login/rectangle-311@2x.png" // yok
// import image13 from "../../assets/login/rectangle-301@2x.png"
// import image14 from "../../assets/login/discover5.jpg"
// import image15 from "../../assets/login/discover6.jpg"
// import image16 from "../../assets/login/shirts.png"
// import image17 from "../../assets/login/discover1.png"
// import { useQuery } from "@apol
import Logo from "../../assets/images/logo/Logo.png"
import "./masonry.css"

// const images = [
//   image1,
//   image2,
//   image3,
//   image4,
//   image5,
//   image6,
//   image6,
//   image7,
//   image7,
// ]

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl="9" sm="3" md="3" xs="12">
        <div className="leftSide">
          <div className="container-bg">
            <div className="bg-column">
              {/* <img className="bg-image" src={image1} alt="Image 1" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/4142841a-0e5a-45ff-377c-9ea855030f00/cloudflareimage" alt="Image 1" />
              {/* <img className="bg-image" src={image2} alt="Image 2" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/85e88e0b-60d1-4545-166e-20079fb4be00/cloudflareimage" alt="Image 2" />
              {/* <img className="bg-image" src={image3} alt="Image 3" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/26ef2285-7e35-44e1-65f8-393c4b80b500/cloudflareimage" alt="Image 3" />
              {/* <img className="bg-image" src={image4} alt="Image 4" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/38c220f1-9889-4b5d-6663-9af0f1cbd300/cloudflareimage" alt="Image 4" />
              {/* <img className="bg-image" src={image5} alt="Image 7" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/f7c51fe1-eb6e-4769-0521-f3e292008c00/cloudflareimage" alt="Image 7" />
              {/* <img className="bg-image" src={image6} alt="Image 8" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/3c291041-8729-4da3-21e6-379bfbb0dc00/cloudflareimage" alt="Image 8" />
            </div>
            <div className="bg-column">
            {/* <img className="bg-image" src={image13} alt="Image 9" /> */}
            <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/4e673fdd-4507-4db0-e3e5-5bc63e683a00/cloudflareimage" alt="Image 9" />
              {/* <img className="bg-image" src={image14} alt="Image 10" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/36bda6ed-05e2-4b69-346b-214d20903600/cloudflareimage" alt="Image 10" />
              {/* <img className="bg-image" src={image15} alt="Image 5" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/51a147d8-255c-48ff-e68e-0ed080c9e100/cloudflareimage" alt="Image 5" />
              {/* <img className="bg-image" src={image16} alt="Image 6" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/f0d1eecd-1a05-4690-af7a-bbd0f1528200/cloudflareimage" alt="Image 6" />
              {/* <img className="bg-image" src={image17} alt="Image 11" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/3c32fbc1-ae83-4bbf-930c-d2a2b7801800/cloudflareimage" alt="Image 11" />
              {/* <img className="bg-image" src={image1} alt="Image 12" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/4142841a-0e5a-45ff-377c-9ea855030f00/cloudflareimage" alt="Image 1" />

             
            </div>
            <div className="bg-column">
               {/* <img className="bg-image" src={image12} alt="Image 5" /> */}
               <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/36bda6ed-05e2-4b69-346b-214d20903600/cloudflareimage" alt="Image 5" />
              {/* <img className="bg-image" src={image7} alt="Image 6" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/51a147d8-255c-48ff-e68e-0ed080c9e100/cloudflareimage" alt="Image 6" />
              {/* <img className="bg-image" src={image8} alt="Image 7" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/f7c51fe1-eb6e-4769-0521-f3e292008c00/cloudflareimage" alt="Image 7" />
              {/* <img className="bg-image" src={image9} alt="Image 8" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/3c291041-8729-4da3-21e6-379bfbb0dc00/cloudflareimage" alt="Image 8" />
              {/* <img className="bg-image" src={image10} alt="Image 9" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/4e673fdd-4507-4db0-e3e5-5bc63e683a00/cloudflareimage" alt="Image 9" />
              {/* <img className="bg-image" src={image11} alt="Image 10" /> */}
              <img className="bg-image" src="https://imagedelivery.net/PYdy8-FWM_sp47JFgI0O9A/971e8dfe-eada-4d65-3ec9-e9d12de23b00/cloudflareimage"alt="Image 10" />
            </div>
          </div>
          <img className={"logoLogin"} src={Logo} alt="Logo" />
          <p className="firstPara">Your fashion style brought close to you</p>
          <p className="secondPara">
            Each women has her own style, <br /> <b>Butiko</b> helps you to find
            your unique style.{" "}
          </p>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default CarouselPage
