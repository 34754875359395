import React from "react"
import { Modal } from "antd"
import regi1 from "../regi1.jpg"

const HelpModal = ({ isVisible, onClose }) => {
  return (
    <Modal
      title="Kayıt Yardım"
      visible={isVisible}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
      bodyStyle={{ maxHeight: 710, overflowY: 'auto' }} 
      footer={null}
    >
      <img src={regi1} alt="" width="98%" height="360"/>

      <div style={{ marginTop: "20px" }}>
        <span>
          Açılan başvuru formunda ilgili alanları doldurmanız gerekmektedir.
        </span>
        <ul>
          <li>
            {" "}
            <b>Adınız- Soyadınız : </b> Şirket yetkilisinin adı ve soyadı
            bilgisini giriniz.
          </li>
          <li>
            {" "}
            <b>E-Posta Adresiniz: </b> Girilen e-posta adresine gönderilecek
            olan e-posta ile doğrulama yapmanız gerekmektedir.
          </li>
          <li>
            {" "}
            <b>TCKN : </b> Girdiğiniz TCKN bilgisine sahip şirket kaydı
            bulunmalıdır.
          </li>
          <li>
            {" "}
            <b>Şifre : </b> Satıcı paneline giriş yapmak için bir şifre
            belirlemeniz gerekmektedir.
            <br />
            <ul>
              <li>Şifreniz 12 Karakterden oluşmalı</li>
              <li>Şifreniz en az bir büyük harf içermelidir.</li>
              <li>Şifreniz en az bir küçük harf içermelidir.</li>
              <li>Şifreniz en az bir sayı içermelidir.</li>
              <li>Şifreniz en az bir sembol içermelidir.</li>
            </ul>
          </li>
          <li>
            {" "}
            <b>Cep Telefonunuz : </b> ButikO tarafından SMS gönderilerinin yapılacağı cep telefonu bilgisini giriniz. <br/> Girilen telefon numarasına gönderilecek olan kodu doğrulamanız istenecektir.
          </li>
        </ul>
        <span style={{marginBottom:"5px"}}><b>İstenen bilgileri doğru bir şekilde girdikten sonra ileri butonuna tıklayınız.</b></span>
      </div>
    </Modal>
  )
}

export default HelpModal
