import React, { useState, useEffect } from "react"
import { Col, Container, Form, Input, Row } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Import Helpers
import * as url from 'helpers/url_helper';
import { get, post, put, del } from "helpers/api_helper"

// Import Toast Notifications
import { toast, ToastContainer } from "react-toastify";

// Import Query
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// Import Components
import FilterSection from "./Components/PageComponents/Products/FilterSection"
import ProductList from "./Components/PageComponents/Products/ProductList"
import PaginationButtons from "./Components/PageComponents/Products/PaginationButtons"

const Products = () => {

  // const queryClient = useQueryClient();

  //meta title
  document.title = "Ürün Listesi | ButikO"

  const navigate = useNavigate();

  const [products, setProducts] = useState([]);

  const linkStyle = {
    backgroundColor: "#34C38F",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "20px",
    fontSize: "16px",
    float: "left",
    display: "flex",
    alignItems: "center",
  }

  const [currentPage, setCurrentPage] = useState(0) // Sayfa numarası 0'dan başlıyor
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  const handlePageChange = (page) => setCurrentPage(page);

  const handleNextPage = () => {
    setCurrentPage(currentPage => currentPage + 1);
  };


  const handlePreviousPage = () => {
    if (currentPage > 0) {
      // Sayfa 0'dan önceye gitmeyi engeller
      setCurrentPage(currentPage => currentPage - 1)
    }
  }

  const fetchProducts = async (currentPage) => {
    const postData = {
      page: currentPage,
    };

    const response = await post(
      url.POST_PRODUCT_SEARCH,
      postData,
      { withCredentials: true }
    );

    const responseData = response.map(item => ({
      ...item,
      media_links: JSON.parse(item.media_links),
      colors: JSON.parse(item.colors),
      sizes: JSON.parse(item.sizes),
    }));

    // console.log(response);

    setProducts(responseData);

    return responseData;
  };

  const {
    data: fetchedProducts,
    isError: isProductsError,
    isLoading: isProductsLoading,
    refetch,
  } = useQuery({
    queryKey: ['getProducts', currentPage],
    queryFn: () => fetchProducts(currentPage),
    onSuccess: () => {
      setFilterApplied(false);
      setErrorOccurred(false);
      setNextButtonDisabled(false)

    },
    onError: (error) => {
      console.error("Veri alınırken bir hata oluştu: ", error);
      setErrorOccurred(true)

      if (error.response?.data?.detail[0]?.msg === "unathorized action" || error.response?.data?.detail[0]?.msg === "invalid jwt") {
        navigate.push("/login");
      }

      if (error.response?.data?.detail[0]?.msg === "product not found") {
        setNextButtonDisabled(true);
      } else {
        setNextButtonDisabled(false);
      }
    },
  })

  useEffect(() => {
    if (products && products.length < 7) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
  }, [products])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ButikO" breadcrumbItem="Ürün Listesi" />
          <ToastContainer />
          <Row>
            <Col lg="12 ">
              <Row className="mb-3 d-flex justify-content-end">
                <Col className=" d-flex justify-content-end" xl={12} sm={12}>
                  <div className="mt-2 ">
                    <Link
                      to={"/ecommerce-add-product"}
                      style={linkStyle}
                    >
                      <i className="bx bx-plus m-1"></i>
                      Yeni Ürün Ekle
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
           
            <Col lg="12">
              <Row>
                <Col xs="12" md="12" sm="12" lg="3">
                  <FilterSection
                    setNextButtonDisabled={setNextButtonDisabled}
                    setErrorOccurred={setErrorOccurred}
                    currentPage={currentPage}
                    setProducts={setProducts}
                    refetch={refetch}
                  />

                </Col>
                <Col xs="12" md="12" sm="12" lg="9" className="cardContent">
                  <ProductList
                    errorOccurred={errorOccurred}
                    isLoading={isProductsLoading}
                    products={products}
                    setProducts={setProducts}
                    setCurrentPage={handlePageChange}
                  />

                  <PaginationButtons
                    currentPage={currentPage}
                    handlePreviousPage={handlePreviousPage}
                    handleNextPage={handleNextPage}
                    nextButtonDisabled={nextButtonDisabled}
                  />
                </Col>
              </Row >
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Products)
