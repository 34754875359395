// ErrorContext.js
import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

// !  createContext ve useContext kullanarak Register 1 ve 2. sayfalarındaki tüm hataları yönetmeyi sağlıyor.
// ! Bu kısım için de ayrı bir Zustang "slice" oluşturulup store'a eklenebilir.

export const useErrorContext = () => {
    return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
    const [errors, setErrors] = useState({});

    const showError = (location, message) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [location]: message,
        }));
    };

    const hideError = (location) => {
        setErrors((prevErrors) => ({ ...prevErrors, [location]: undefined }));
    };

    const clearErrors = () => {
        setErrors({});
    };

    return (
        <ErrorContext.Provider value={{ errors, showError, hideError, clearErrors }}>
            {children}
        </ErrorContext.Provider>
    );
};

export default ErrorContext;
