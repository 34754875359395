import React, { useState } from "react"
import CarouselPage from "./CarouselPage"

// import images
import logodark from "../../assets/images/logo/logo-dark.png"
import logolight from "../../assets/images/logo/logo-light.png"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { useMutation } from "@tanstack/react-query"
import toastr from "toastr"
import OTPInput from "react-otp-input"

const TwostepVerification2 = () => {

  //meta title
  document.title = "2 Aşamalı Doğrulama | ButikO";

  const [code, setCode] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state.email;

  const otpInputStyle = {
    width: '43px', // Adjust width as needed
    height: '47px', // Adjust height as needed
    fontSize: '20px', // Adjust font size as needed
    borderRadius: '10px', // Add border radius
    border: '1px solid #CED4DA', // Add border style
    margin: '6px', // Add margin between inputs
    textAlign: 'center', // Center text horizontally
  }

  const handleChange = (value) => {
    // Update the code state with the new value
    setCode(value);
  };

  const verifyCode = async () => {
    const data = {
      email: email,
      verification_code: code,
      role:"seller"
    }

    const response = await post(url.POST_VERIFY_CODE, data)

    console.log(response);

    return response
  }

  const verifyCodeMutation = useMutation({
    mutationFn: verifyCode,
    onError: error => {
      console.log(error);

      if (error?.response?.data?.detail?.[0]?.msg === "invalid code") {
        toastr.error("Geçersiz Kod.")
      } else if (error?.response?.data?.detail?.[0]?.msg === "code expired") {
        toastr.error("Kodun Süresi geçti, tekrar deneyiniz.")
        setTimeout(() => {
          navigate(-1);
        }, 4000);
      } else {
        toastr.error("Beklenmedik bir hata oluştu.")
      }

    },
    onSuccess: response => {
      toastr.success("Kod doğru! Yönlendiriliyorsunuz...")
      navigate("/change-password", { state: { uuid: response?.uuid } })

    }
  })

  return (
    <React.Fragment>
      <div>
        <div className="container-fluid p-0">
          <div className="row g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>E-postanızı Doğrulayın</h4>
                          <p>
                            Lütfen e-postanıza gönderilen 6 haneli kodu giriniz.
                          </p>

                          <Row className="my-2 mb-4">
                            <Col className="d-flex flex-column justify-content-center align-items-center">
                              <OTPInput
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={otpInputStyle}
                                inputType="number"
                              />
                            </Col>
                          </Row>

                          <div className="mt-4">
                            <Button
                              style={{ backgroundColor: "#E35E5E" }}
                              onClick={() => {
                                verifyCodeMutation.mutate()
                              }}
                            >
                              Onayla
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} ButikO {" "}
                        <i className="mdi mdi-heart text-danger"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TwostepVerification2
