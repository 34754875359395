import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Modal,
  Col,
  ModalBody,
  ModalHeader,
  Row,
  ModalFooter,
  Button,
} from "reactstrap"

const RegisterSuccessPopup = ({ modalIsOpen, setIsOpen }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [modalIsOpen])

  const closeModal = () => {
    setIsOpen(false)
    goToLoginPage()
  }

  const goToLoginPage = () => {
    navigate("/login")
  }

  return (
    <Modal isOpen={modalIsOpen} toggle={closeModal} size="lg" centered={true}>
      <ModalHeader toggle={closeModal}>
        <span style={{ fontSize: "16.2px", fontWeight: "500" }}>
          Kayıt Olma İşlemi Başarılı
        </span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="150"
              height="150"
              viewBox="0 0 256 256"
              xmlSpace="preserve"
            >
              <defs />
              <g
                style={{
                  stroke: "none",
                  strokeWidth: 0,
                  strokeDasharray: "none",
                  strokeLinecap: "butt",
                  strokeLinejoin: "miter",
                  strokeMiterlimit: 10,
                  fill: "none",
                  fillRule: "nonzero",
                  opacity: 1,
                }}
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <path
                  d="M 89.328 2.625 L 89.328 2.625 c -1.701 -2.859 -5.728 -3.151 -7.824 -0.568 L 46.532 45.173 c -0.856 1.055 -2.483 0.997 -3.262 -0.115 l -8.382 -11.97 c -2.852 -4.073 -8.789 -4.335 -11.989 -0.531 l 0 0 c -2.207 2.624 -2.374 6.403 -0.408 9.211 l 17.157 24.502 c 2.088 2.982 6.507 2.977 8.588 -0.011 l 4.925 -7.07 L 89.135 7.813 C 90.214 6.272 90.289 4.242 89.328 2.625 z"
                  style={{
                    stroke: "none",
                    strokeWidth: 1,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "rgb(111,178,6)",
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
                <path
                  d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 6.072 0 11.967 1.19 17.518 3.538 c 2.034 0.861 2.986 3.208 2.125 5.242 c -0.859 2.035 -3.207 2.987 -5.242 2.126 C 54.842 8.978 49.996 8 45 8 C 24.598 8 8 24.598 8 45 c 0 20.402 16.598 37 37 37 c 20.402 0 37 -16.598 37 -37 c 0 -3.248 -0.42 -6.469 -1.249 -9.573 c -0.57 -2.134 0.698 -4.327 2.832 -4.897 c 2.133 -0.571 4.326 0.698 4.896 2.833 C 89.488 37.14 90 41.055 90 45 C 90 69.813 69.813 90 45 90 z"
                  style={{
                    stroke: "none",
                    strokeWidth: 1,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "rgb(111,178,6)",
                    fillRule: "nonzero",
                    opacity: 1,
                  }}
                  transform="matrix(1 0 0 1 0 0)"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center mt-3">
            <h3>ButikO'ya Hoşgeldiniz !</h3>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center text-center">
            <p style={{fontSize:"15px"}}>
            E-postanıza bir doğrulama e-postası gönderdik.  <br/> Lütfen gelen e-postayı kontrol edin ve doğrulama bağlantısına tıklayarak hesabınızı etkinleştirin.
            </p>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ backgroundColor: "#556EE6",border:"none",padding:"10px 15px" }}
          onClick={goToLoginPage}
        >
          Giriş Yap
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RegisterSuccessPopup
