import React from "react"
// Import Helpers
import { get } from "helpers/api_helper"
import * as url from "helpers/url_helper"

// Import React Query
import { toast } from "react-toastify"

const HiddenInput = ({ name, value }) => {
  return <input type="hidden" name={name} id={name} value={value} />
}

const PaymentForm = ({
  cardInfo,
  selectedCardType,
  clickedPackage,
  isChecked,
  selectedCard,
}) => {

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Form girişlerini doğrula
    if (!clickedPackage) {
      toast.warning("Lütfen bir paket seçin.")
      return
    }
    if (!isChecked) {
      toast.warning("Lütfen şartlar ve koşulları kabul edin.")
      return
    }
    if (!selectedCardType) {
      toast.warning("Lütfen bir kart seçin.")
      return
    }


    if (isChecked) {
      try {
        const hiddenData = await get(
          `${url.GET_HIDDEN_FIELDS_SELLER}?offer_id=${clickedPackage}`,
          { withCredentials: true }
        )
        const form = e.target
        Object.entries(hiddenData).forEach(([name, value]) => {
          const hiddenInput = document.createElement("input")
          hiddenInput.type = "hidden"
          hiddenInput.name = name
          hiddenInput.value = value
          form.appendChild(hiddenInput)
        })

        form.submit()
      } catch (error) {
        toast.error("Gizli alanları alırken bir hata oluştu.")
        console.error("Error fetching hidden fields:", error)
      }
    } else {
      toast.warning("Lütfen şartlar ve koşulları kabul edin.")
    }
  }

  return (
    <form
      method="post"
      action="https://www.paytr.com/odeme"
      onSubmit={handleSubmit}
    >
      {cardInfo &&
        Object.entries(cardInfo).map(([name, value]) => (
          <HiddenInput
            key={name}
            name={name === "card_owner_name" ? "cc_owner" : name}
            value={value}
          />
        ))}
      <button
        value="Ödeme Yap"
        type="submit"
        className="btn btn-success "
        color="success"
        style={{ width: "180px" }}
      >
        Satın Al
      </button>
    </form>
  )
}

export default PaymentForm



//  // onClick={addCardDetail}
//         // disabled={
//         //     selectedCardType === "new"
//         //         ? !clickedPackage ||
//         //         !isChecked ||
//         //         !selectedCard ||
//         //         !card_owner_name ||
//         //         !card_number ||
//         //         !expiry_month ||
//         //         !expiry_year ||
//         //         !cvv
//         //         : selectedCardType === "saved"
//         //             ? !clickedPackage || !isChecked || !selectedCard
//         //             : true
//         // }