import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react"
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Row,
    Input,
    Label
} from "reactstrap"
import Form from 'react-bootstrap/Form';

const AddAddress = ({ addressRefetch, isAddAddress, setIsAddAddress }) => {
    const [city, setCity] = useState("")
    const [isCity, setIsCity] = useState(false)
    const [district, setDistrict] = useState("")
    const [isDistrict, setIsDistrict] = useState(false)
    const [districtForUpdate, setDistrictForUpdate] = useState("");
    const [cityForUpdate, setCityForUpdate] = useState("");
    const [neighborhoodForUpdate, setNeighborhoodForUpdate] = useState("");
    const [streetForUpdate, setStreetForUpdate] = useState("");
    const [apartmentNoForUpdate, setApartmentNoForUpdate] = useState("");
    const [houseNoForUpdate, setHouseNoForUpdate] = useState("");
    const [addressForUpdate, setAddressForUpdate] = useState("");
    const [name, setName] = useState("");
    const getCity = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/il`, { withCredentials: true })
    }
    const getDistrict = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/ilce/${city}`, { withCredentials: true })
    }
    const getNeigborhood = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/mahalle/${district}`, { withCredentials: true })
    }
    function convertAndCapitalize(str) {
        
        return str.toUpperCase();
    }
    function convertAndCapitalizeDistrict(str) {
        str = str.replace(/I/gi, "ı");
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const postAddAddress = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/addresses/create-address`
        const finalData = {
            city: convertAndCapitalize(cityForUpdate),
            district: convertAndCapitalizeDistrict(districtForUpdate),
            neighborhood: neighborhoodForUpdate,
            street: streetForUpdate,
            apartment_no: apartmentNoForUpdate,
            house_no: houseNoForUpdate,
            address: addressForUpdate,
            name: name,
            is_default: false
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const addAddressMutation = useMutation({
        mutationFn: postAddAddress,
        onSuccess: () => {
            setIsAddAddress(false)
            addressRefetch()
        },
        onError: (error) => {
            console.log(error);
            if (error.response.data.detail[0].msg == "invalid city or district value") {
                toast.error("Geçersiz şehir veya bölge değeri")
            } else {
                toast.error("Bir şeyler yanlış gitti")
            }
        }
    })


    const { isPending, isError, data, error } = useQuery({ queryKey: ['get-city'], queryFn: getCity })
    const { data: districtData } = useQuery({ queryKey: ['get-district', city], queryFn: getDistrict, enabled: isCity })
    const { data: neighborhoodData } = useQuery({ queryKey: ['get-neighborhood', district], queryFn: getNeigborhood, enabled: isDistrict })

    if (isPending) {
        return (<h1>Yükleniyor..</h1>)
    }
    if (isError) {
        if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
            history("/login")
        }
        toast.error("error", { toastId: "fetchError" })
    }

    return (
        <>
            <div>
                <Row className="d-flex justify-content-between align-items-center">
                    <Col className="col-6 d-flex justify-content-start align-items-center">
                        <h2 className="">Adres Ekle</h2>
                    </Col>
                    <Col className="col-6 text-end">
                        <Button onClick={() => setIsAddAddress(!isAddAddress)} color="link" >{isAddAddress ? "Geri" : "Adres Ekle"}</Button>
                    </Col>
                </Row>
                <Row>
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        addAddressMutation.mutate()
                    }}>
                        <Col>
                            <Row>
                                <Col xs="12" md="6" xl="4">
                                    <Label>İL</Label>
                                    <Form.Select
                                        required
                                        onChange={(e) => {
                                            setCity(e.target.value)
                                            setIsCity(true)
                                            setCityForUpdate(e.target.options[e.target.selectedIndex].text)
                                        }}
                                    // aria-label="Default select example"
                                    >
                                        <option>il</option>
                                        {data.data.map((city) => {
                                            return <option key={city.il_id} value={city.il_id}>{city.il}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col xs="12" md="6" xl="4">
                                    <Label>İLÇE</Label>
                                    <Form.Select
                                        required
                                        onChange={(e) => {
                                            setDistrict(e.target.value)
                                            setIsDistrict(true)
                                            setDistrictForUpdate(e.target.options[e.target.selectedIndex].text);

                                        }}
                                    // aria-label="Default select example"
                                    >
                                        <option>ilce</option>
                                        {districtData?.data.map((dist) => {
                                            return <option id={dist.ilce_id} key={dist.ilce_id} value={dist.ilce_id} >{dist.ilce}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col xs="12" md="6" xl="4">
                                    <Label>MAHALLE</Label>
                                    <Form.Select
                                        required
                                        onChange={(e) => {
                                            setNeighborhoodForUpdate(e.target.options[e.target.selectedIndex].text)
                                        }} aria-label="Default select example"
                                    >
                                        <option>mahalle</option>
                                        {neighborhoodData?.data.map((neighborhood) => {
                                            return <option key={neighborhood.mahalle_id} value={neighborhood.mahalle_id} >{neighborhood.mahalle}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col className="mt-2" xs="12" md="6" xl="4">
                                    <Label>SOKAK</Label>
                                    <Input
                                        required
                                        value={streetForUpdate}
                                        onChange={(e) => {
                                            setStreetForUpdate(e.target.value)

                                        }}
                                        type="text" placeholder="sokak" />

                                </Col>
                                <Col className="mt-2" xs="12" md="6" xl="4">
                                    <Label>DIŞ KAPI NO</Label>
                                    <Input
                                        required
                                        onChange={(e) => {
                                            setApartmentNoForUpdate(e.target.value)
                                        }}
                                        type="text"
                                        placeholder="dish No"
                                        value={apartmentNoForUpdate}
                                    />
                                </Col>
                                <Col className="mt-2" xs="12" md="6" xl="4">
                                    <Label>İÇ KAPI NO</Label>
                                    <Input
                                        required
                                        onChange={(e) => {
                                            setHouseNoForUpdate(e.target.value)
                                        }}
                                        type="text"
                                        placeholder="Ic No"
                                        value={houseNoForUpdate}
                                    />
                                </Col>

                            </Row>
                            <Row>

                            </Row>
                            <Row>
                                <Col className="mt-2">
                                    <Label>ADRES TARİFİ</Label>
                                    <Input
                                        required
                                        onChange={(e) => {
                                            setAddressForUpdate(e.target.value)
                                        }}
                                        type="textarea"
                                        placeholder="address"
                                        value={addressForUpdate}
                                    />

                                </Col>
                                <Col className="mt-2" xs="12" md="6" xl="4">
                                    <Label>Adres kayit adi</Label>
                                    <Input
                                        required
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                        type="text" placeholder="Adres kayit adi" />

                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button
                                        // onClick={postAddAddress}

                                        type="submit"
                                        className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light">
                                        Ekle
                                    </Button>

                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Row>


            </div>
        </>
    )
}
export default AddAddress