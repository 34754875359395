import React, { useRef } from "react";
import { useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Input,
    Label,

} from "reactstrap";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import AuthCode from 'react-auth-code-input';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup'
// import toastr from "toastr";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Skeleton } from 'antd';
const Account = ({ type }) => {
    const [changePass, setChangePass] = useState(false)
    const [changeEmail, setChangeEmail] = useState(false)
    const [changePhoneNum, setChangePhoneNum] = useState(false)
    const [emailVerifCode, setEmailVerifCode] = useState(0)
    const [phoneVerifCode, setPhoneVerifCode] = useState(0)
    const [codeSent, setCodeSent] = useState(false);
    const [authCodeDisabled, setAuthCodeDisabled] = useState(true)
    const [phoneAuthCodeDisabled, setPhoneAuthCodeDisabled] = useState(true)
    const [getNewToken, setGetNewToken] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(0); // 120 seconds = 2 minutes
    const [savdDisabled, setSavdDisabled] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState("905");

    const [newEmailError, setNewEmailError] = useState(false);// this is for error of new email input filed
    const [newPhoneNumberError, setNewPhoneNumberError] = useState(false);
    // useEffect(() => { console.log("oisjdfois") }, [newEmailRef.current])
    const history = useNavigate();

    const linkStyle = {
        color: "#556EE6"
    }
    // start for 2min timer of auth code
    useEffect(() => {
        let interval;

        if (timer > 0 && isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer, isButtonDisabled]);
    // this is for sending auth code of new email
    const handleButtonClick = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(watch("newEmail"))) {
            setNewEmailError(true);
        } else {
            authCodeMutation.mutate()
        }
    };
    // this is for sending auth code of new phone
    const handleButtonClickPhone = () => {
        if (newPhoneNumber.length < 12 || !newPhoneNumber.startsWith("905")) {
            setNewPhoneNumberError(true);
        } else {

            if (newPhoneNumber == "") {
                setNewPhoneNumberError(true)
            } else {
                phoneAuthCodeMutation.mutate()
            }
        }
    };

    useEffect(() => {

        if (timer === 0) {
            setIsButtonDisabled(false);
        }
    }, [timer]);


    const authCodeInputRef = useRef();
    const handleVerifMail = (value) => {
        setEmailVerifCode(value);

    };
    const handleVerifphone = (value) => {
        setPhoneVerifCode(value);
    };

    const updataPassword = (data) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/auth/reset-password`
        const finalData = {
            old_password: data.oldPassword,
            new_password: data.newPassword,
            // role: "seller"
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }

    const emailPhoneVerif = ({ option, verification_code }) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/verify-change-email-phone-number`
        const finalData = {
            option: option,
            verification_code: verification_code,
            // role:"seller"
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const sendAuthCode = () => {
        setSavdDisabled(true)
        const email = document.getElementById("newemail").value
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/change-email`
        const finalData = {
            new_email: email
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const sendPhoneAuthCode = () => {
        // const number = document.getElementById("newPhoneNum").value
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/change-phone-number`
        const finalData = {
            new_phone_number: newPhoneNumber
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const passwordMutation = useMutation({
        mutationFn: updataPassword,
        onSuccess: () => {
            toast.success("İşlem Tamamlandı", { toastId: "passwordMutation" })

            setEditPersonalInformation(false)
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "passwordMutationError" })
            console.log(error);
        }
    })
    const emailPhoneVerifMutation = useMutation({
        mutationFn: emailPhoneVerif,
        onSuccess: () => {
            toast.success("İşlem Tamamlandı", { toastId: "emailPhoneVerifMutation" })
            setGetNewToken(true)
            refreshtokenMutation.mutate()
            refetch()
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "emailPhoneVerifMutationError" })
            console.log(error);
        }
    })
    //this is for changing email
    const authCodeMutation = useMutation({
        mutationFn: sendAuthCode,
        onSuccess: () => {
            toast.success("İşlem Tamamlandı", { toastId: "authCodeMutation" })
            setCodeSent(true)
            setAuthCodeDisabled(false)
            // setChangeEmail(false)
            setIsButtonDisabled(true);
            setTimer(120)
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "authCodeMutationError" });
            console.log(error);
        }
    })
    const phoneAuthCodeMutation = useMutation({
        mutationFn: sendPhoneAuthCode,
        onSuccess: () => {
            setSavdDisabled(true)
            toast.success("İşlem Tamamlandı", { toastId: 'phoneAuthCodeMutation' })
            setCodeSent(true)
            setAuthCodeDisabled(false)
            // setChangePhoneNum(false)
            setPhoneAuthCodeDisabled(false)
            setIsButtonDisabled(true);
            setTimer(120)
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "phoneAuthCodeMutationError" })
            console.log(error);
        }
    })
    //this is for validation
    const schema = Yup.object().shape({
        newPassword: Yup.string().min(12, "şifre uzunluğu en az 12 olmalıdır").matches(/(?=.*[0-9])/, "şifre en az bir rakamdan oluşmalıdır")
            .matches(/(?=.*[A-ZİÜÖŞÇĞ])/, "şifre en az bir büyük karakter içermelidir")
            .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, "şifrede en az bir özel karakter bulunmalıdır"),
        oldPassword: Yup.string().min(12, "şifre uzunluğu en az 12 olmalıdır").matches(/(?=.*[0-9])/, "şifre en az bir rakamdan oluşmalıdır")
            .matches(/(?=.*[A-ZİÜÖŞÇĞ])/, "şifre en az bir büyük karakter içermelidir")
            .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, "şifrede en az bir özel karakter bulunmalıdır"),
        repeatPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "aynı değil")
    })
    const { register, watch, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })


    const getAccountInfo = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/account-information`, { withCredentials: true })
    }
    const getRefreshToken = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/refresh-token`, { withCredentials: true })
    }

    const { isLoading, isError, data, error, refetch } = useQuery({ queryKey: ['get-account-info'], queryFn: getAccountInfo, retry: 2 })
    //this gonna happen when user changes its email. because email is stored in jwt we change the access token


    const refreshtokenMutation = useMutation({
        mutationFn: getRefreshToken,
        onSuccess: () => {
            console.log("token refreshed");
            setChangeEmail(false)
            setTimer(0)
        },
        onError: (e) => {
            console.log(e)
        }

    })


    // end of access token refresh
    if (isError) {
        if (error?.response?.data?.detail[0]?.msg === "unathorized action" || error?.response?.data?.detail[0]?.msg === "invalid jwt") {
            history("/login")
        }
        if (error?.response?.data?.detail[0]?.msg === "seller is not approved") {

            return (
                <>
                    <h1>Satıcı onaylı değil</h1>
                </>
            )
        } else {
            return (
                <>
                    <h1>hata Oluştu</h1>
                </>
            )
        }
    }

    const onPassWordSubmit = (dataa) => {

        passwordMutation.mutate(dataa)
    }
    const onEmailSubmit = (dataa) => {
        console.log("fsd");
    }


    return (
        <Container>
            {isLoading ?
                (
                    <>
                        <Skeleton paragraph={1} />
                    </>
                ) : (
                    <Row className="justify-content-between">
                        {changePass ? (
                            <>
                                <form onSubmit={handleSubmit(onPassWordSubmit)}>
                                    <Row className="">
                                        <Col md="6" xs="12">

                                            <Row className="d-flex align-items-center mb-3">
                                                <Col xs="12" sm="4">
                                                    <Label>Eski Şifre </Label>

                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3 text-start">
                                                        <Form.Control type="password" placeholder="Şifre girin" {...register("oldPassword")} />
                                                        <Form.Text style={{ color: "#FF4C4C" }} >
                                                            {errors.oldPassword?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="6" xs="12">
                                            <p style={{ whiteSpace: "pre-line", wordBreak: "break-word", textAlign: "start" }} className="d-flex flex-column align-items-start">*şifre uzunluğu en az 12 olmalıdır</p>
                                            <p style={{ whiteSpace: "pre-line", wordBreak: "break-word", textAlign: "start" }} className="d-flex flex-column align-items-start">*şifrede en az bir özel karakter bulunmalıdır</p>
                                            <p style={{ whiteSpace: "pre-line", wordBreak: "break-word", textAlign: "start" }} className="d-flex flex-column align-items-start">*şifre en az bir rakamdan oluşmalıdır</p>
                                            <p style={{ whiteSpace: "pre-line", wordBreak: "break-word", textAlign: "start" }} className="d-flex flex-column align-items-start">*şifre en az bir büyük karakter içermelidir</p>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md="6" xs="12">
                                            <Row className="d-flex align-items-center mb-3">
                                                <Col xs="12" sm="4">
                                                    <Label>Yeni Şifre </Label>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3 text-start">
                                                        <Form.Control type="password" placeholder="Şifre girin" {...register("newPassword")} />
                                                        <Form.Text style={{ color: "#FF4C4C" }}>
                                                            {errors.newPassword?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="6" xs="12">
                                            <Row className="d-flex align-items-center mb-3">
                                                <Col xs="12" sm="4">
                                                    <Label>Şifreyi tekrarla </Label>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3 text-start" >
                                                        <Form.Control type="password" placeholder="Şifre girin" {...register("repeatPassword")} />
                                                        <Form.Text style={{ color: "#FF4C4C" }}>
                                                            {errors.repeatPassword?.message}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="mb-4 mt-4 d-flex justify-content-end  ">

                                            <Button onClick={() => { setChangePass(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                            <Button type="submit" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                        </Col>
                                    </Row>
                                </form>
                            </>
                        ) :
                            (<>
                                <Col md="6" xs="12">
                                    <Row className="d-flex align-items-center mb-3">
                                        <Col xs="12" sm="4">
                                            <Label>Şifre </Label>
                                        </Col>
                                        <Col>
                                            <Input disabled type="password" placeholder={data?.data?.password} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="d-flex flex-column align-items-end" md="6" xs="12">
                                    <a style={linkStyle} onClick={() => { setChangePass(true) }}>Şifre Değiştir</a>
                                </Col>
                            </>)}
                    </Row>
                )}
            {!isLoading &&(<Row>
                {changeEmail ? (
                    <>
                        <form onSubmit={handleSubmit(onEmailSubmit)}>
                            <Row className="">
                                <Col md="6" xs="12">
                                    <Row className="d-flex align-items-center ">
                                        <Col xs="12" sm="4">
                                            <Label>Eski Email </Label>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Control disabled type="email" placeholder="E-postayı girin" value={data?.data?.email} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col md="6" xs="12">
                                    <p className="d-flex flex-column align-items-start">E-postanıza Gönderilen Onay Kodunu Girin</p>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="6" xs="12">
                                    <Row className="d-flex align-items-center ">
                                        <Col xs="12" sm="4">
                                            <Label>Yeni Email </Label>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    className={`${newEmailError ? "border-danger" : ""}`}
                                                    id="newemail"
                                                    type="email"
                                                    placeholder="E-postayı girin"
                                                    {...register("newEmail")}
                                                    onFocus={() => setNewEmailError(false)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6" xs="12">
                                    <AuthCode
                                        allowedCharacters="numeric"
                                        onChange={handleVerifMail}
                                        inputClassName="custom-auth-code-input"
                                        containerClassName="custom-auth-code-container"
                                        disabled={authCodeDisabled}
                                        ref={authCodeInputRef}

                                    />
                                </Col>

                            </Row>
                            <Row className="mt-3">
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button
                                        onClick={() => {
                                            handleButtonClick()
                                        }}
                                        disabled={isButtonDisabled}
                                        type="button"
                                        className="d-flex align-items-center btn btn-soft-info waves-effect waves-light">
                                        <i className='bx bx-check bx-sm'></i>
                                        {isButtonDisabled ? `Yeniden gönder ${timer} saniye` : 'Kodu gönder'}
                                    </Button>
                                    <Button
                                        onClick={() => { setChangeEmail(false) }}
                                        style={{ marginRight: "8px", marginLeft: "8px" }}
                                        type="button"
                                        className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light">
                                        <i className='bx bx-x bx-sm'></i>Geri
                                    </Button>



                                    <Button onClick={() => {
                                        emailPhoneVerifMutation.mutate({ option: "email", verification_code: emailVerifCode })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light" disabled={!savdDisabled}><i className='bx bx-check bx-sm' ></i>Kaydet</Button>

                                </Col>
                            </Row>
                        </form>
                    </>) : (
                    <>
                        <Col md="6" xs="12">
                            <Row className="d-flex align-items-center mb-3">
                                <Col xs="12" sm="4">
                                    <Label>Email </Label>
                                </Col>
                                <Col>
                                    <Input disabled type="email" placeholder={data?.data?.email} />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="d-flex flex-column align-items-end" md="6" xs="12">
                            <a style={linkStyle} onClick={() => { setChangeEmail(true) }}>Email Adresini Güncelle</a>
                        </Col>
                    </>)}
            </Row>)}
            {!isLoading && (<Row>
                {changePhoneNum ? (
                    <>
                        <Row className="">
                            <Col md="6" xs="12">
                                <Row className="d-flex align-items-center ">
                                    <Col xs="12" sm="4">
                                        <Label>Eski telefon numarası </Label>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Control disabled type="text" placeholder="Telefon numarasını girin" value={data.data.phone_number} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" xs="12">
                                <p className="d-flex flex-column align-items-start">Telefon Numarasına Onay Kudu Girin</p>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="6" xs="12">
                                <Row className="d-flex align-items-center ">
                                    <Col xs="12" sm="4">
                                        <Label>Yeni Telefon Numarası </Label>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                onFocus={() => setNewPhoneNumberError(false)}
                                                className={`${newPhoneNumberError ? "border-danger" : ""}`}
                                                id="newPhoneNum"
                                                type="text"
                                                placeholder="905XXXXXXXX"
                                                value={newPhoneNumber}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    if (/^\d*$/.test(value)) {
                                                        setNewPhoneNumber(value)
                                                    }
                                                }}

                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" xs="12">
                                <AuthCode
                                    allowedCharacters="numeric"
                                    onChange={handleVerifphone}
                                    inputClassName="custom-auth-code-input"
                                    containerClassName="custom-auth-code-container"
                                    disabled={phoneAuthCodeDisabled}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                <Button
                                    onClick={handleButtonClickPhone}
                                    disabled={isButtonDisabled}
                                    type="button"
                                    className="d-flex align-items-center btn btn-soft-info waves-effect waves-light">
                                    <i className='bx bx-check bx-sm'></i>
                                    {isButtonDisabled ? `Yeniden gönder ${timer} saniye` : 'Kodu gönder'}
                                </Button>
                                <Button onClick={() => { setChangePhoneNum(false) }} style={{ marginRight: "8px", marginLeft: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                <Button onClick={() => {
                                    emailPhoneVerifMutation.mutate({ option: "phone_number", verification_code: phoneVerifCode })
                                }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light" disabled={!savdDisabled}><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                            </Col>
                        </Row>
                    </>) :
                    (<>
                        <Col md="6" xs="12">
                            <Row className="d-flex align-items-center mb-3">
                                <Col xs="12" sm="4">
                                    <Label>Telefon </Label>
                                </Col>
                                <Col>
                                    <Input disabled type="text" placeholder={data?.data?.phone_number} />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="d-flex flex-column align-items-end" md="6" xs="12">
                            <a style={linkStyle} onClick={() => { setChangePhoneNum(true) }}>Telefon Numarasını Güncelle</a>
                        </Col>
                    </>
                    )}
            </Row>)}
        </Container>
    )
}

export default Account