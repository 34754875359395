import React from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Collapse,
  ModalFooter,
} from "reactstrap"
import { useState } from "react"

const Token = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [isOpen3, setIsOpen3] = useState(false)
  const [isOpen4, setIsOpen4] = useState(false)
  const [isOpen5, setIsOpen5] = useState(false)
  const [isOpen6, setIsOpen6] = useState(false)
  const [isOpen7, setIsOpen7] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggle2 = () => {
    setIsOpen2(!isOpen2)
  }
  const toggle3 = () => {
    setIsOpen3(!isOpen3)
  }
  const toggle4 = () => {
    setIsOpen4(!isOpen4)
  }
  const toggle5 = () => {
    setIsOpen5(!isOpen5)
  }
  const toggle6 = () => {
    setIsOpen6(!isOpen6)
  }
  const toggle7 = () => {
    setIsOpen7(!isOpen7)
  }
  const fontSize18 = { fontSize: "15px" }

  return (
    <div>
      <span onClick={toggleModal} style={{ ...fontSize18, cursor: "pointer",textDecoration:"underLine" }}>
        Soruları görmek için tıklayınız
      </span>
      <Modal
        style={{ maxWidth: "1000px", maxHeight: "1000px", marginTop: "80px" }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>
          TOKEN İLE İLGİLİ SIKÇA SORULAN SORULAR
        </ModalHeader>
        <ModalBody
          className="mx-3 my-3"
          style={{ overflowY: "auto", minHeight: "55vh" }}
        >
          <Card style={{ backgroundColor: "white" }}>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Token nedir ve nasıl kullanılır?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen}>
                    <p>
                    Token Butiko sistemi içinde reklam sırası alımında satıcıların ön sıralarda
gözükmesini sağlayan, paraya çevrilemeyen sadece Butiko sisteme içinde geçerli
olacak olan bir alışveriş para birimidir.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle2}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen2 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Tokenler nasıl çalışır ve neden önemlidir?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen2}>
                    <p>
                    Üyeler kendi bölgesinde Butiko uygulamasını ilk açtıkları anda karşılalarına çıkacak
ilk on sırayı görecektir. Satıcılar ise bu ilk on sırada görünebilmek adına token satın
almak zorundadır. Butiko şirketi ile yapacağı token alışverişinde fiyatları butiko şirketi
belirlemiş olup, satıcıların ise aldığı Tokenlerin adetine göre dileği sırayı alabilme
durumu olacaktır. <br/>
Tokenler ile ön sıralarda yer alan satıcılar hem satın aldığı bölgede rakiplerinden adı
daha çok duyuluacak ve satışlarını arttıracaklardır.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle3}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen3 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">Tokenlerimi nereden satın alabilirim?</h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen3}>
                    <p>
                    Butiko profil sayfasından Token bölümüne girerek hedef ve fiyat durumuna göre
                    token kampanyalarından faydalanabilir.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle4}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen4 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">
                    Tokenlerimi nasıl hesabıma aktarabilirim?
                    </h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen4}>
                    <p>
                    Satın aldığınız Tokenler otomatik hesabınıza aktrılacaktır. Satın aldığınız token
sayısında bir yanlışlık olduğunu düşünüyorsanız ve hesabınıza sayfanızı yeniledğiniz
durumda bile aktarılmadıysa lütfen Butiko ile iletişime geçiniz.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle5}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen5 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">
                    Tokenlerimi nasıl kullanabilirim?
                    </h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen5}>
                    <p>
                    Tokenlerinizi buluduğunuz konum içerisinde ve bulunduğunuz konumun çapını
büyüterek istediğiniz bölgede kullanabilirsiniz.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle6}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen6 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">
                    Tokenlerin son kullanma tarihi var mıdır?
                    </h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen6}>
                    <p>
                    Tokenlerinizin son kullanma tarihi yoktur.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ borderBottom: "1px solid #d3d3d3" }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      onClick={toggle7}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {isOpen7 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M12 20L0.74167 0.499998L23.2583 0.5L12 20Z"
                            fill="#556EE6"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 24"
                          fill="none"
                        >
                          <path
                            d="M20 12L0.499999 23.2583L0.5 0.741669L20 12Z"
                            fill="#424242"
                          />
                        </svg>
                      )}
                    </Button>
                    <h5 className="mt-2">
                    Tokenlerim paraya çevrilebilir mi?
                    </h5>
                  </div>
                  <Collapse className="ms-4 mt-1" isOpen={isOpen7}>
                    <p>
                    Tokenleriniz asla paraya çevrilemez. Para ile olan alışverişlerinizde kullanılamaz.
                    </p>
                  </Collapse>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Token
