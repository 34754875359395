import React from "react"
import { Flex, Modal } from "antd"
import yön1 from "../yön1.png"
import yön2 from "../yön2.png"
import yön3 from "../yön3.png"
import yön4 from "../yön4.png"

const HelpManangety = ({ isVisible, onClose }) => {
  return (
    <Modal
      title="İş Yönetimi Nedir ?"
      visible={isVisible}
      onOk={onClose}
      onCancel={onClose}
      width={1000}
      bodyStyle={{ maxHeight: 710, overflowY: 'auto' }} 
      footer={null}
    > 
      <div style={{display:"flex",gap:"10px", border:"1px solid #CED4DA",padding:"10px",}}>
        <div>
        <img src={yön1} alt="" width={500}/>
        </div>
        <span>
          <b>Eğer sadece ButikO da satış yapıyorsanız </b> görselde gösterilen kısım seçili olacak ve mağaza adresi kısmına ofis adresinizi girmeniz gerekmektedir.
        </span>
      </div>
      <hr/>
      <div style={{display:"flex",gap:"10px", border:"1px solid #CED4DA",padding:"10px",}}>
        <div>
        <img src={yön2} alt="" width={500} />
        </div>
        <span>
          <b>Hem ButikO da hemde mağazanız da satış yapıyorsanız </b> görselde gösterilen kısım seçili olacak ve mağaza adresi kısmına mağaza adresinizi girmeniz gerekmektedir.
        </span>
      </div>
      <hr/>
      <div style={{display:"flex",gap:"10px", border:"1px solid #CED4DA",padding:"10px",}}>
        <div>
        <img src={yön3} alt="" width={500}/>
        </div>
        <span>
          <b>Hem ButikO da hemde mağazanız da satış yapıyorsanız ama mağaza adresiniz ve ofis adresiniz farklı ise </b> görselde gösterilen kısım seçili olacak ve ilk önce mağaza adresinizi girmelisiniz daha sonra ofis adresi kısmına tıklayarak ofis adresinizi de doldurmalısınız.
        </span>
      </div>
    </Modal>
  )
}

export default HelpManangety
