import React, { useState, useRef, useEffect, useMemo } from "react"
import {
  Container,
  Col,
  Row,
  Card,
} from "reactstrap"


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import CarrierSelection from "./Components/CarrierSelection.js"
import ProcessingInfoTable from "./Components/ProcessingInfoTable.js"
// import DeliveringInfoTable from "./Components/DeliveringInfoTable.js"

import ReturningInfoTableWaiting from "./Components/ReturningInfoTableWaiting.js"
// import ReturningInfoTableDenied from "./Components/ReturningInfoTableDenied.js"
// import ReturningInfoTableAccepted from "./Components/ReturningInfoTableAccepted.js"

import ProccessingInvoices from "./Components/ProcessingInvoices.js"
// import ReturningInvoices from "./Components/ReturningInvoices.js"
// import Timeline from "./Components/Timeline.js"
import OrderInfo from "./Components/OrderInfo.js"
// import OrderSummary from "./Components/OrderSummary.js"
// import PriceTable from "./Components/PriceTable.js"
// import StatusCard from "./Components/StatusCard.js"
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { useReactToPrint } from 'react-to-print';
import { Skeleton } from 'antd';
import { FaPrint } from "react-icons/fa";
// import { Alert } from 'antd';
import { Empty, Typography, Alert } from 'antd';
// import { useProformaInvoice } from "./Hooks/useProformaInvoice.js"
// import { toast } from "react-toastify"

// import { useMutation } from "@tanstack/react-query"
const EcommerceOrderDetails = () => {
  //meta title
  const printRef = useRef();
  let [searchParams] = useSearchParams();
  document.title = "Sipariş Detayı | Butiko"
  const [orderId, setOrderId] = useState(searchParams.get('order_id'));
  // const { data: proformaData, refetch: proformaRefetch } = useProformaInvoice(orderId);
  const getReturnedOrderItems = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-returned-order-items?order_id=${orderId}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const {
    isLoading: returnIsLoading,
    isError: returnIsError,
    error: returnError,
    data: returnedOrderItemsData,
    refetch: returnedRefetch } = useQuery({ queryKey: ["get-returned-items"], queryFn: getReturnedOrderItems })
  let returnedItems
  if (returnIsLoading) {

  } else if (returnIsError) {
    console.log(returnError);
  } else {
    returnedItems = returnedOrderItemsData?.data?.order_items

  }

  const getOrderDetail = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-order-detail?order_id=${orderId}`;
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { isLoading,
    isError,
    error,
    data: orderDetailData,
    refetch } = useQuery({ queryKey: ["get-order-detail", orderId], queryFn: getOrderDetail,retry:1 })
  let orderDetail
  let orderItemsStatus
  let hasOrderedItem
  let orderItemId
  if (isLoading) {

  } else if (isError) {
    console.log(error);
  } else {
    orderDetail = orderDetailData?.data;
    orderItemId = orderDetail.order_items[0].order_item_id
    // console.log(orderItemId);
    orderItemsStatus = orderDetail?.order_items?.map(item => ({
      product_id: item.product_id,
      status: item.status,
      ordered_expires_at: item.ordered_expires_at,
      preparing_expires_at: item.preparing_expires_at
    }));
    hasOrderedItem = orderItemsStatus?.some(item => item.status === 'ordered');
  }


  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    removeAfterPrint: true
  });


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Siparişler"
            breadcrumbItem="Sipariş detayı"
          />
          {/* <h1> {orderId} </h1> */}
          <Row xs="12">
            {isLoading ? (
              <>
                {/* <div className="page-content"> */}
                  {/* <Container fluid> */}
                    <Skeleton active />
                    <Skeleton active />
                  {/* </Container> */}
                {/* </div> */}
              </>
            ) : isError ? (
              <>
                {/* <div className="page-content">
            <Container fluid>
              <Empty
                description={
                  <Typography.Text>
                    sipariş kodu yanlış...
                  </Typography.Text>
                }
              />
            </Container>
          </div> */}
              </>
            ) :
              (<>

                <Col className="mb-3" xs="12" xxl="12">

                  <Alert showIcon message="Siparişinizi onayladığınız için teşekkür ederiz. Sendeo Kargo, gönderinizi belirttiğiniz adresten alacaktır. 
İşlemleri hızlandırmak için 444 75 48 numaralı telefonu arayıp 2'yi tuşlayarak plaka kodunu (örnek İstanbul=34) ve kargo numaranızı belirtin. 
Kurye geldiğinde iletişim numarasını almayı unutmayın. Böylece, bir sonraki siparişinizde doğrudan kurye ile iletişim kurabilirsiniz." type="info" />
                </Col>
                <Col xs="12" xxl="6">
                  <ProcessingInfoTable
                    // data={[orderDetail?.shippingInfo, data?.productInformation]}
                    order_id={orderDetail?.order_id}
                    name={orderDetail?.name}
                    customer_address={orderDetail?.customer_address}
                    order_date={orderDetail?.order_date}
                    paid_amount={orderDetail?.paid_amount}
                    order_items={orderDetail?.order_items}
                    hasOrderedItem={hasOrderedItem}
                    refetch={refetch}
                    shipping_tracking_no={orderDetail?.shipping_tracking_no}
                    shipping_tracking_url={orderDetail?.shipping_tracking_url}
                    shipping_barcode={orderDetail?.shipping_barcode}
                  />
                </Col>
                <div className="col-12 co col-xxl-6 col-sm-12 " ref={printRef}>
                  <Col xs="12" xxl="12">
                    <ProccessingInvoices refetch={refetch} is_invoice_uploaded={orderDetail?.is_invoice_uploaded} invoice_link={orderDetail?.invoice_link}   id={orderDetail?.order_id} />
                    <Card>
                      <OrderInfo orderItemId={orderItemId} id={orderId}  />
                      <div className="d-flex justify-content-end p-3 pt-0">
                        <button
                          onClick={() => {
                            // getBarcodeMutation.mutate()
                            handlePrint()
                            //proformaRefetch()
                            // console.log(base);
                          }}
                          className="btn btn-primary px-5 hidden-print"
                        >
                          <FaPrint style={{ marginRight: "4px", marginBottom: "2px" }} />Yazdır
                        </button>
                      </div>
                    </Card>
                  </Col>
                </div>
              </>
              )
            }
            <div>
              {returnIsLoading ? (
                <>
                  <Skeleton active />
                </>
              ) : returnIsError ? (
                <h1>error</h1>
              ) : returnedItems?.length > 0 ? (
                <ReturningInfoTableWaiting
                  return_cancelled_tracking_no={returnedOrderItemsData?.data?.return_cancelled_tracking_no}
                  // return_cancelled_barcode={returnedOrderItemsData?.data?.return_cancelled_barcode}
                  reFetch={returnedRefetch}
                  order_items={returnedItems}
                  return_tracking_no={returnedOrderItemsData?.data?.return_tracking_no}
                  return_tracking_url={returnedOrderItemsData?.data?.return_tracking_url}
                  return_cancelled_tracking_url={returnedOrderItemsData?.data?.return_cancelled_tracking_url}
                  customer_name={returnedOrderItemsData?.data?.customer_name}
                  customer_address={returnedOrderItemsData?.data?.customer_address}
                  order_date={returnedOrderItemsData?.data?.order_date}
                />
              ) : (null)}

            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default EcommerceOrderDetails
