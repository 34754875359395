import axios from "axios";
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const DebtForm = ({ id, cvv, expiry_year, expiry_month, cc_owner, card_number }) => {
    // console.log();
    const [hiddenData, setHiddenData] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("pay", id);
        payDebtMutation.mutate()
    }
    const getHiddenFields = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/paytr/payment/get-hidden-fields-debt?order_transaction_id=${id}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const payDebtMutation = useMutation({
        mutationFn: getHiddenFields,
        onSuccess: (data) => {
            setHiddenData(data?.data)
            console.log(hiddenData);
            if (!card_number) {
                toast.warning("Lütfen bir kart seçin",{toastId:"select-card-warning"});
            } else {
                setTimeout(() => {
                    document.getElementById("debtPaymentForm").submit()
                }, 1000);
            }
        },
        onError: (error) => {
            console.log(error);
            toast.error("Bir şeyler ters gitti")
        }
    })
    return (<>
        <form method="post" id="debtPaymentForm" action="https://www.paytr.com/odeme" onSubmit={handleSubmit}>
            {/* Hidden Fields */}
            <input type="hidden" name="cc_owner" value={cc_owner} />
            <input type="hidden" name="card_number" value={card_number} />
            <input type="hidden" name="expiry_month" value={expiry_month} />
            <input type="hidden" name="expiry_year" value={expiry_year} />
            <input type="hidden" name="cvv" value={cvv} />
            {/* fsdfsdfdsfsdf */}
            <input type="hidden" name="merchant_id" value={hiddenData.merchant_id} />
            <input type="hidden" name="user_ip" value={hiddenData.user_ip} />
            <input type="hidden" name="merchant_oid" value={hiddenData.merchant_oid} />
            <input type="hidden" name="email" value={hiddenData.email} />
            <input type="hidden" name="user_phone" value={hiddenData.user_phone} />
            <input type="hidden" name="payment_type" value={hiddenData.payment_type} />
            <input type="hidden" name="currency" value={hiddenData.currency} />
            <input type="hidden" name="test_mode" value={hiddenData.test_mode} />
            <input type="hidden" name="non_3d" value={hiddenData.non_3d} />
            <input type="hidden" name="merchant_ok_url" value={hiddenData.merchant_ok_url} />
            <input type="hidden" name="merchant_fail_url" value={hiddenData.merchant_fail_url} />
            <input type="hidden" name="client_lang" value={hiddenData.client_lang} />
            <input type="hidden" name="payment_amount" value={hiddenData.payment_amount} />{/* pok*/}
            <input type="hidden" name="user_basket" value={hiddenData.user_basket} />
            <input type="hidden" name="user_address" value={hiddenData.user_address} />
            <input type="hidden" name="user_name" value={hiddenData.user_name} />
            <input type="hidden" name="installment_count" value={hiddenData.installment_count} />
            <input type="hidden" name="non3d_test_failed" value={hiddenData.non3d_test_failed} />
            <input type="hidden" name="debug_on" value={hiddenData.debug_on} />
            <input type="hidden" name="paytr_token" value={hiddenData.paytr_token} />
            {/* Visible Fields */}
            <Button
                type="submit"
            >
                Ödeme 
            </Button>
        </form>
    </>)
}
export default DebtForm