import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET ANONİM ŞİRKETİ (BUTİKO)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "55vh", overflowY: "auto", paddingLeft: "40px", paddingRight: "40px", }}>
        <h3 style={{ textAlign: "cewnter" }}>
          BUTİKO Üyeliği Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Beyanı
        </h3>{" "}
        <br />
        <p>
          6698 sayılı Kişisel Verilerin Korunması Kanunu{" "}
          <strong> ("Kanun")</strong> kapsamında, daha önce benimle paylaşılan,
          butiko.com.tr <strong> ("Site")</strong> üzerinden her zaman
          ulaşabileceğim aydınlatma metninde detaylı olarak açıklandığı şekilde
          kişisel verilerimin Veri Sorumlusu sıfatıyla BUTİKO BİLGİ TEKNOLOJİ
          PAZARLAMA TİCARET ANONİM ŞİRKETİ <strong> ("BUTİKO")</strong>{" "}
          tarafından; butiko.com.tr üyeliği kapsamında işlenmesine aşağıdaki
          rıza beyanı ile onay veriyorum.
        </p>
        <p>
          Site ve Mobil Uygulama’da çerez vb. programların kullanımına ilişkin
          çerez politikasına Site üzerinden ulaşabileceğimi biliyorum.
        </p>
        <p>
          Aydınlatma metninde detaylı olarak açıklanan pazarlama, iletişim ve
          kimlik verilerimin;
        </p>
        <ul>
          <li style={{ textDecoration: "none" }}>
            <strong>
              Kişiselleştirilmiş pazarlama, profilleme, segmentasyon:
            </strong>{" "}
            Üyelik programlarından faydalanabilmem, genel veya bana özel
            kişiselleştirilmiş kampanyalar, avantajlar, promosyonlar,
            reklamların oluşturulması, kampanya, yarışma, çekiliş ve diğer
            etkinliklerin düzenlenmesi, segmentasyon, raporlama, profilleme,
            pazarlama ve analiz çalışmalarının yapılması, Site veya diğer 3.
            taraf ortamlarda BUTİKO'ya ait reklamların ve pazarlama/iletişim
            faaliyetlerinin ( Site’deki bildirimler, pop-up gösterimi, kişiye
            özel teklifler, kullanıcı ekranlarının özelleştirilmesi, reklam,
            arama, anket vs.) yapılması, tarafıma yönelik katalog, broşür basılı
            pazarlama materyallerinin gönderilebilmesi, Site üzerindeki
            kullanıcı deneyimini iyileştirmek amaçlarıyla işlenmesine, bilişim
            teknolojileri, pazarlama/reklam faaliyetleri ya da uzmanlık
            gerektiren danışmanlık vb. konularda ürün ve hizmet desteği almak
            amacıyla BUTİKO'nun yurtiçinde bulunan tedarikçiler, iş ortakları ve
            hizmet sağlayıcılarıyla ve veri zenginleştirme/tekilleştirme
            faaliyetlerinde bulunulması amaçlarıyla paylaşılmasına,
          </li>{" "}
          <br />
          <li>
            <strong>Yurt dışına aktarım:</strong>Bilişim teknolojileri,
            pazarlama/analiz/segmentasyon/reklam faaliyetleri ya da uzmanlık
            gerektiren danışmanlık vb. konularda ürün ve hizmet desteği almak
            amacıyla BUTİKO'nun yurt dışında bulunan hizmet sağlayıcıları ve iş
            ortaklarıyla (sunucu/bakım ve geliştirme hizmeti, dijital
            pazarlama/reklam ve analiz hizmeti sağlayıcıları, felaket
            kurtarma/yedekleme hizmeti, üye/ müşteri geri bildirimi toplama
            hizmeti) ve bu suretle yurt içindeki ve/veya yurt dışındaki ilgili
            tedarikçilerin sunucularının yurt dışında olması sebebiyle kimlik,
            iletişim, görsel ve işitsel kayıtlar, müşteri işlem, finans,
            pazarlama ve işlem güvenliği verilerimin yurt dışı ile
            paylaşılmasına,
          </li>
          <br />
        </ul>
        <p>
          <strong>RIZA VERİYORUM.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  )
}

function AcikRizaMetni({ modalShow, setModalShow }) {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

export default AcikRizaMetni
