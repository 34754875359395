import React, { useState } from "react";
import { Button } from "reactstrap";
import OutOfStockPopup from "pages/Ecommerce/EcommerceProducts/Popups/OutOfStockPopup";
import PromotionsPopup from "pages/Ecommerce/EcommerceProducts/Popups/EditPopup";
import usePromotionsModalStore from "pages/Ecommerce/EcommerceProducts/productStore";
const StockAndEditButtons = ({ id }) => {
    const [discountModalIsOpen, setDiscountModalIsOpen] = useState(false);
    const [outOfStockModalIsOpen, setOutOfStockModalIsOpen] = useState(false);
    const [promoteModalIsOpen, setPromoteModalIsOpen] = useState(false);
    // const [promotionsModalIsOpen, setPromotionsModalIsOpen] = useState(false);
    const { promotionsModalIsOpen, openModal, closeModal, toggleModal } = usePromotionsModalStore();
    const [selectedProductId, setSelectedProductId] = useState(null);


    const openOutOfStockModal = (productId) => {
        setOutOfStockModalIsOpen(true);
        setSelectedProductId(productId); // Set the selected product id
        document.body.style.overflow = "hidden"; // Sayfanın kaydırılmasını engellemek için
    };

    function openPromoteModal() {
        setPromoteModalIsOpen(true);
    }

    function openPromotionsModal() {
        // setPromotionsModalIsOpen(true);
        openModal()
        console.log("poskdf");
    }

    return (
        <div className="text-end">
            <Button
                type="button"
                // color="primary"
                className="me-1"
                style={{ backgroundColor: "#F46A6A", border:"none" }}
                onClick={() => openOutOfStockModal(id)}
            >
                <i className="bx bx-x me-2" /> Stoktan Kaldır
            </Button>
            <OutOfStockPopup
                modalIsOpen={outOfStockModalIsOpen}
                setIsOpen={setOutOfStockModalIsOpen}
                productId={selectedProductId}
            />
            <Button
                type="button"
                color="success"
                className="ms-1"
                onClick={openPromotionsModal}
            >
                <i className="bx bx-edit me-2" />
                Düzenle
            </Button>
            <PromotionsPopup id={id} modalIsOpen={promotionsModalIsOpen} closeModall={closeModal} />
        </div>
    );
};

export default StockAndEditButtons;
