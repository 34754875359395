import React from 'react'
import { Tooltip } from "antd"
import { RiErrorWarningFill } from "react-icons/ri"

const Warnings = ({title}) => {
  return (
    <div>
      <Tooltip
                title={title}
                overlayClassName="custom-tooltip"
              >
                <RiErrorWarningFill style={{ fontSize: "20px" }} />
              </Tooltip>
    </div>
  )
}

export default Warnings
