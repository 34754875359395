import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    
    CardBody,
    
    
    
    
    
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";

import classnames from "classnames"

import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";


import PersonalInformation from "./Components/PersonalInformation";
import CompanyInformation from "./Components/CompanyInformation";
import Documents from "./Components/Documents";
import Settings from "./Components/Settings";

const PersonProfile = () => {

    //meta title
    document.title = "Profile | Skote - React Admin & Dashboard Template";

    const [activeTab, setactiveTab] = useState("1")

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Skote" breadcrumbItem="Profile" />

                    <Row>
                        <Col xl="9">
                            <Card className="p-3">
                                <CardTitle>Registration Dataa</CardTitle>
                                <CardBody>
                                    <Row >
                                        <Col   xs="12" xl="3">
                                            <Nav  pills vertical>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("1")
                                                        }}
                                                    >
                                                        Personal information
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("2")
                                                        }}
                                                    >
                                                        Company information
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === "3",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("3")
                                                        }}
                                                    >
                                                        Documents
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                        <Col  xs="12" xl="9">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <PersonalInformation />
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <CompanyInformation type="person" />
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <Documents type="person" />
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>


                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="3">
                            <Settings />
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(PersonProfile);
