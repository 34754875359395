export const firstPageErrorMessages = {
    firstName: "Lütfen adınızı girin.",
    lastName: "Lütfen soyadınızı girin.",
    gender: "Lütfen bir cinsiyet seçin.",
    tcNo: "Lütfen T.C. numaranızı girin.",
    incorrectTcNo: "Lütfen T.C. numaranızı 11 haneli olarak giriniz.",
    textTcNo: "Lütfen T.C. numaranızı sadece sayılardan oluşacak şekilde giriniz.",
    emailAddress: "Lütfen bir e-posta adresi girin.",
    wrongEmailAddress: "Geçerli bir e-posta adresi girin",
    password: "Şifre en az 12 karakter uzunluğunda olmalıdır",
    numberInPassword: "Şifreniz en az bir rakam içermelidir.",
    lowerTextInPassword: "Şifreniz en az bir küçük harf içermelidir.",
    upperTextInPassword: "Şifreniz en az bir büyük harf içermelidir.",
    symbolInPassword: "Şifreniz en az bir özel karakter içermelidir.",
    confirmPassword: "Şifreler uyuşmuyor",
    phoneNumber: "Telefon numarası 10 haneli olmalıdır.",
    phoneNumber2: "Telefon numarası sadece rakamlardan oluşmalıdır.",
    duplicatePhoneNumber: "Zaten sistemde olan bir telefon numarası girdiniz. Farklı bir telefon numarası giriniz.",
    duplicateTC: "Sistemde kayıtlı olan bir TC numarası girdiniz. Lütfen tekrar deneyiniz",
    duplicateEmail: "Sistemde kayıtlı olan bir e-posta girdiniz.",
    personalAddress: "Lütfen adresinizi eksiksiz bir şekilde girin.",
    exteriorDoorMsg: "Lütfen dış kapı numarası girin",
    interiorDoorInput: "Lütfen iç kapı numarası girin",
    emailForgottenSign: "Lütfen e-postanızda @ işareti olduğundan emin olun.",
    invalidPersonalAddressCity: "Lütfen şehir ve ilçe bilgilerinizin doğru olduğundan emin olunuz."
}

export const secondPageErrorMessages = {
    companyType: "Lütfen şirket türü seçin.",
    unvanMsg: "Lütfen unvan girin",
    unvanEmptyMsg: "Şahıs hesabı olmayan hesaplarda unvan belirtilmeli",
    duplicateCompanyTitleMsg: "Sistemde kayıtlı olan bir unvan girdiniz. Tekrar deneyiniz.",
    sicilMsg: "Lütfen sicil numarasını 123456-7 formatında giriniz",
    sicilEmptyMsg: "Şahıs hesabı olmayan hesaplarda sicil No belirtilmeli",
    duplicateRegistrationNumber: "Sistemde kayıtlı olan bir sicil numarası girdiniz, tekrar deneyiniz.",
    mersisMsg: "Lütfen mersis numarasını 16 haneli olarak giriniz",
    mersisEmptyMsg: "Şahıs hesabı olmayan hesaplarda mersis No belirtilmeli",
    duplicateMersisNoMsg: "Sistemde kayıtlı olan bir mersis numarası girdiniz. Tekrar deneyiniz.",
    magazaMsg: "Lütfen magaza adı giriniz",
    duplicateMagazaMsg: "Bu kullanıcı adı zaten kullanılıyor, başka bir kullanıcı adı deneyiniz.",
    adresMsg: "Lütfen adres giriniz",
    emailMsg: "Lütfen e-postanızda @ işareti olduğundan emin olunuz.",
    vergiDaireMsg: "Lütfen vergi dairesini giriniz",
    vergiNoMsg: "Lütfen vergi numarasını 10 veya 11 haneli olarak giriniz.",
    duplicateVergiNoMsg: "Sistemde kayıtlı olan bir vergi numarası girdiniz. Tekrar deneyiniz.",
    emptyTelNo: "Telefon numarası en az 1 hane içermeli.",
    wrongTelNoMsg: "Lütfen ev telefonunu başında sıfır olmadan ve aralarında boşluk olmadan 5554443322 formatında giriniz.",
    duplicateTelNoMsg: "Sistemde kayıtlı olan bir ev telefon numarası girdiniz. Tekrar deneyiniz.",
    duplicateBankIBAN: "Bu IBAN adı zaten kullanılıyor, başka bir IBAN deneyiniz.",
    kullanimMsg: "KULLANIM KOŞULLARI ve ÜYELİK SÖZLEŞMESİ kabul edilmedi.",
    kvkkMsg: "KVKK AYDINLATMA METNİ kabul edilmedi.",
    acikMsg: "AÇIK RIZA METNİ kabul edilmedi.",
    wrongKepAdress: "Kep adresi e-posta biçiminde olmalı ve '@' simgesi içermelidir.",
    wrongEmailAddress: "Lütfen geçerli bir e-posta adresi girin",
    emailAddress: "Geçerli bir KEP adresi girin",
    invalidOfficeAddressCity: "Lütfen şehir ve ilçe bilgilerinizin doğru olduğundan emin olunuz.",
    invalidStoreAddressCity: "Lütfen şehir ve ilçe bilgilerinizin doğru olduğundan emin olunuz.",
    incompleteIban: "Lütfen IBAN numaranızı eksiksiz girdiğinize emin olunuz.",
    emptyIban: "IBAN numaranızı yazdığınızdan emin olunuz.",
    incorrectIban: "Lütfen IBAN numaranızı doğru formatta yazdığınızdan emin olun: TR<24 rakam>"
}

export const addressErrorMessages = {
    city: "Lütfen şehir seçin.",
    district: "Lütfen ilçe seçin.",
    neighborhood: "Lütfen mahalle seçin.",
    street: "Lütfen sokağınızı yazın.",
    apartmentNo: "Lütfen dış kapı numaranızı yazın",
    houseNo: "Lütfen iç kapı numaranızı yazın",
    address: "Lütfen adresi tarifinizi yazın."
}
export const addressErrorMessagesStore = {
    city: "Lütfen şehir seçin.",
    district: "Lütfen ilçe seçin.",
    neighborhood: "Lütfen mahalle seçin.",
    street: "Lütfen sokağınızı yazın.",
    apartmentNo: "Lütfen dış kapı numaranızı yazın",
    houseNo: "Lütfen iç kapı numaranızı yazın",
    address: "Lütfen adresi tarifinizi yazın."
}