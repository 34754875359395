import React from "react"
import {
    CardBody,
} from "reactstrap"
import Table from 'react-bootstrap/Table';
import "../main.css"

const OrderSummary = props => {
    const tableInfo = props.brand
    return (
        <>
            <CardBody className="random">
                <Table style={{ minWidth: "100%" }}>
                    <thead>

                        <tr className="">
                            <th className="text-left " >
                                #
                            </th>
                            <th className="text-left " >
                                Ürün
                            </th>
                            <th className="text-left " >
                                Renk
                            </th>
                            <th className="text-left " >
                                Beden
                            </th>
                            <th className="text-left " >
                                Adet
                            </th>
                            <th className="text-left " >
                                Fiyat
                            </th>

                        </tr>
                    </thead>
                    <tbody>

                        {Array.isArray(tableInfo) && tableInfo.map((info, index) => (
                            <tr
                                key={index}
                                className=""
                            >
                                <td className="text-left " >
                                    {index + 1}
                                </td>
                                <td className="text-left " >
                                    {info.product_name}
                                </td>
                                <td className="text-left " >
                                    <div
                                        className="rounded-circle hidden-print"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            backgroundColor: info.color_hex,
                                            border: "2px solid #C1BDBD",
                                        }}
                                    ></div>
                                    <span className="show-print" style={{display: "none"}}>{info.color_name}</span>
                                </td>
                                <td className="text-left " >
                                    {info.size}
                                </td>
                                <td className="text-left " >
                                    {info.quantity}
                                </td>
                                <td className="text-left" >
                                    {info.price}₺
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>

        </>
    )
}

export default OrderSummary