import React, { useState } from "react"
import {
    CardTitle,
    CardBody,
} from "reactstrap"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import OrderSummary from "./OrderSummary"
import PriceTable from "./PriceTable"
import { Skeleton } from 'antd';
import Image from 'react-bootstrap/Image';
import { useProformaInvoice } from "../Hooks/useProformaInvoice"
import { Document, Page } from "react-pdf"
// import pdfFile from "../../../../assets/output.pdf"
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const OrderInfo = props => {
    
    const [id, setId] = useState(props.id);
    const [barcode, setBarcode] = useState("");
    const { isLoading, isError, error, data, refetch } = useProformaInvoice(id);
    let proformaData
    let filteredAddressParts
    if (isLoading) {

    } else if (isError) {
        console.log(error);
    } else {
        proformaData = data?.data
        // console.log(proformaData);
        const addressParts = proformaData?.shipped_to.split(',').map(part => part.trim());
        // Filter out empty parts
        filteredAddressParts = addressParts.filter(part => part !== '');
        
    }
    const getBarcode = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-barcode?order_item_id=${props.orderItemId}&option=shipping_reference_no`
        return axios.get(finalUrl, { withCredentials: true })
    }
    let base = ""
    const {isLoading:barcodeIsloading, isError: barcodeIsError,error:barcodeError, data: barcodeData}= useQuery({queryKey: ["barcode"],queryFn:getBarcode})
    if(barcodeIsloading){

    }else if(barcodeIsError){
        console.log(barcodeError);
        base="error"
    }else{
        base = barcodeData?.data?.base64_barcode
        // console.log(base);
    }
    

    return (
        <>
            {isLoading ? (
                <>
                    <Skeleton active />
                </>
            ) : isError ? (
                <>
                    <h1>Hata</h1>
                </>) : (
                <>
                    <CardTitle

                        className=" mb-3 pb-0 ps-2 text-secondary hidden-print"
                        style={{ fontWeight: "500" }}
                    >
                        Sipariş # {id}
                    </CardTitle>
                    <CardBody className="pt-0 print-card-body hidden-print">

                        <table className="w-100 ">
                            <tbody>
                                <tr className="">
                                    <th>Faturalandırılan Kişi:</th>
                                    <th >Gönderildiği Yer:</th>
                                </tr>
                                <tr className="">
                                    <td>{proformaData?.billed_name}</td>
                                    <td>{filteredAddressParts && filteredAddressParts[0]}</td>
                                </tr>
                                <tr className="">
                                    <td>{proformaData?.email}</td>
                                    <td>{filteredAddressParts && filteredAddressParts[1]}</td>
                                </tr>
                                {Array.isArray(filteredAddressParts) && filteredAddressParts.slice(2).map((item, index) => {
                                    return (
                                        <tr key={index} className="">
                                            <td></td>
                                            <td>{item}</td>
                                        </tr>
                                    )
                                })}
                                <tr className="d-flex justify-content-between w-100">
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="w-100 mt-3">
                            <tbody>

                                <tr className="d-flex justify-content-between w-100">
                                    <th></th>
                                    <th>Sipariş tarihi:</th>
                                </tr>
                                <tr className="d-flex justify-content-between w-100">
                                    <td>

                                    </td>
                                    <td>{proformaData?.order_date}</td>
                                </tr>

                            </tbody>
                        </table>
                    </CardBody>
                    {barcode && <Image style={{ display: "none", height: "15cm" }} className="show-print " src={barcode} />}
                    <OrderSummary brand={proformaData?.order_items} />
                    <PriceTable shipping={proformaData?.shipping} sub_total={proformaData?.sub_total} />
                    {base && base !="error" ? (<Document className="show-print " file={`data:application/pdf;base64,${base}`}>
                        <Page scale={2} pageNumber={1}>

                        </Page>
                    </Document>):(
                        <h1  className="show-print ">barcode bulunmadi</h1>
                    )}
                </>
            )}
        </>
    )
}

export default OrderInfo