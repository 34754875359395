import React from "react";
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import notFound from "../../assets/images/logo/404.svg"
import { Link } from "react-router-dom"
const NotFound = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    {/* Render Breadcrumb */}

                    <Row>
                        <notFound />
                        {/* <h1>not found</h1> */}
                        <img style={{ width: "100%", height: "70vh" }} src={notFound} alt="logo" />
                    </Row>
                    <Row className="">
                        <Col className="d-flex justify-content-center align-items-center">
                            <Link to="/dashboard">
                                <Button color="primary" >
                                    Ana Sayfa
                                </Button>
                            </Link>
                        </Col>
                    </Row>


                </Container>
                {/* //   )
        // } */}


            </div>
        </React.Fragment>
    )
}
export default NotFound