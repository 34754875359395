import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const RevenueBox = ({ revenueData }) => {
    // const { value, previous_period_percentage } = revenueData;
    let dataToDisplay
    if(revenueData === 0){
        dataToDisplay = "Gelir yok"
    }else{
        dataToDisplay = revenueData
    }
    return (
        <Col md="4">
            <Card className="mini-stats-wid">
                <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                <i className="bx bx-archive-in" />
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Gelir</h5>
                    </div>
                    <div className="text-muted mt-4">
                        <h4 style={dataToDisplay === "Gelir yok" ? {color: "#F1B44C"}:{}}>
                            {dataToDisplay}  
                        </h4>
                        {/* <div className="d-flex">
                            <span className="badge badge-soft-success font-size-12">
                                {previous_period_percentage}
                            </span>{" "}
                            <span className="ms-2 text-truncate">Önceki dönemden</span>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default RevenueBox;
