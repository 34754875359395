import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react"
import { toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col
} from "reactstrap"
import AddAddress from "./AddAddress";
import { useProformaInvoice } from "../Hooks/useProformaInvoice";
const ApproveOrderModal = ({ order_id, refetch, modelIsOpen, setModelIsOpen }) => {
    const [isAddAddress, setIsAddAddress] = useState(false);
    const toggleModal = () => {
        setModelIsOpen(!modelIsOpen)
    }
    const getAddress = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/addresses/get-addresses`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const { isLoading, isError, data, error, refetch: addressRefetch } = useQuery({ queryKey: ["get-address"], queryFn: getAddress })
    let addresses
    if (isLoading) {
        addresses = "loading"
    } else if (isError) {
        console.log(error);
    } else {
        addresses = data?.data
        // console.log(addresses);
    }
    const [selectedAddressId, setSelectedAddressId] = useState(null);

    const handleSelectAddress = (addressId) => {
        setSelectedAddressId(addressId);
        // console.log(addressId);
    };
    const approveOrder = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/approve-order?order_id=${order_id}&address_id=${selectedAddressId}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const {refetch :proformaRefetch} = useProformaInvoice(order_id)
    const approveOrderMutation = useMutation({
        mutationFn: approveOrder,
        onSuccess: (data) => {
            // console.log(data);
            toast.success("Sipariş onaylandı")
            toggleModal()
            refetch()
            setTimeout(() => {
                proformaRefetch()
            }, 100);

        },
        onError: (error) => {
            console.log(error);
            if (error?.response?.data?.detail[0]?.msg === "invalid order_id or no order_item with 'ordered' status") {
                toast.error("Geçersiz sipariş numarası ya da böyle bir sipariş yok")
            } else {
                toast.error("Bir şeyler yanlış gitti");

            }
            refetch()
        }
    })
    const handleRemoveAddress = (id) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/addresses/delete-address/${id}`
        return axios.delete(finalUrl, { withCredentials: true })
    }
    const deletMutation = useMutation({
        mutationFn: handleRemoveAddress,
        onSuccess: (data) => {
            addressRefetch()
        },
        onError: (error) => {
            toast.error("Bir şeyler yanlış gitti")
            console.log(error);
        }
    })
    return (
        <Modal
            style={{
                maxWidth: "1000px",
                maxHeight: "1000px",
                marginTop: "80px",
            }}
            isOpen={modelIsOpen}
            toggle={() => {
                // console.log("hi");
                if (selectedAddressId) {
                    toggleModal()
                } else {
                    toast.warning("Bir adres seçin")
                }
            }}
        >
            <ModalHeader toggle={toggleModal}>
                Teslim alma için adres seçimi{" "}
            </ModalHeader>
            <ModalBody>


                {isAddAddress ? (
                    <AddAddress addressRefetch={addressRefetch} isAddAddress={isAddAddress} setIsAddAddress={setIsAddAddress} />
                ) : (
                    <div>
                        <Row className="d-flex justify-content-between align-items-center">
                            <Col className="col-6 d-flex justify-content-start align-items-center">
                                <h2 className="">Bir Adres Seçin</h2>
                            </Col>
                            <Col className="col-6 text-end">
                                <Button onClick={() => setIsAddAddress(!isAddAddress)} color="link" >Adres Ekle</Button>
                            </Col>
                            <Col className="col-12 d-flex justify-content-start align-items-center">
                                <h6 className="">Seçtiğiniz adreste kuryeniz 2 iş günü içerisinde gönderiyi teslim almaya gelecektir.</h6>
                            </Col>
                        </Row>
                        {Array.isArray(addresses) && addresses.map((address) => (
                            <div key={address.address_id} className="card mb-3 position-relative">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="form-check d-flex justify-content-between align-items-center">
                                            <input
                                                type="radio"
                                                className="form-check-input me-3"
                                                name="address"
                                                value={address.address_id}
                                                checked={selectedAddressId === address.address_id}
                                                onClick={() => handleSelectAddress(address.address_id)}
                                                readOnly
                                                id={`address-${address.address_id}`}
                                            />
                                            <label className="form-check-label" htmlFor={`address-${address.address_id}`}>
                                                <h3>{address.name}</h3>
                                                <p>
                                                    {address.address.street}, {address.address.house_no}
                                                    {address.address.apartment_no ? `/${address.address.apartment_no}` : ''}
                                                </p>
                                                <p>
                                                    {address.address.neighborhood}, {address.address.district}, {address.address.city}
                                                    {address.post_code ? `, ${address.post_code}` : ''}
                                                </p>
                                            </label>
                                        </div>
                                        <button
                                            className="btn btn-danger btn-sm position-absolute top-0 end-0 mt-2 me-2"
                                            onClick={() => {
                                                if (address.address_type == "office") {
                                                    toast.warning("Ofis adresini silemezsiniz")
                                                } else {
                                                    deletMutation.mutate(address.address_id)
                                                }
                                            }}
                                        >
                                            Sil
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    setSelectedAddressId(null)
                    toggleModal()
                }}>
                    iptal
                </Button>
                <Button disabled={isAddAddress} onClick={() => {
                    if (selectedAddressId) {

                        approveOrderMutation.mutate()
                    } else {
                        toast.warning("Lütfen bir adres seçin")
                    }
                }} type="submit" form="form" color="primary">Onayla</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ApproveOrderModal