import React, { useState } from "react"
import {
    Col,
    Row,
    Input,
    CardTitle,
    Card,
    CardBody,
} from "reactstrap"
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { message } from 'antd';
import "./../main.css"
const ProccessingInvoices = ({ id, is_invoice_uploaded,invoice_link, refetch }) => {
    const [invoice, setInvoice] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const key = "uploadInvoice"
    const uploadInvoice = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/upload-invoice?order_id=${id}`
        const finalData = new FormData();
        finalData.append('file', invoice)
        finalData.append("order_id", id)
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const uploadInvoiceMutation = useMutation({
        mutationFn: uploadInvoice,
        onSuccess: (data) => {
            console.log(data);
            refetch()
            // toast.success(`Invoice uploaded successfully`);
        },
        onError: (error) => {
            console.log(error);
            // if(error?.response?.data?.detail[0]?.msg === "invalid order_id")
            // toast.error("error ")
        }
    })
    return (
        <>
            <Card className="hidden-print">
                <CardBody>
                    <CardTitle>Faturalar</CardTitle>
                    {is_invoice_uploaded == 1 && (
                        <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                            <h6 className="m-0">Faturanız(PDF,JPG)</h6>
                            <div className="d-flex flex-wrap justify-content-end gap-2">
                                <a download target="blank" href={invoice_link} className="btn btn-primary d-flex flex-row align-items-center gap-1">
                                    <i className="bx bx-download fs-3" />
                                    <p className="m-0">İndir</p>
                                </a>
                            </div>
                        </div>
                    )}
                    {is_invoice_uploaded == 0 &&(<div className="d-flex flex-row align-items-center justify-content-between">
                        <Row>
                            <Col lg="12" md="12" xs="12" className="no-gutters">
                                <Input
                                    id="exampleFile"
                                    name="file"
                                    type="file"
                                    accept=".pdf,.jpg,.jpeg"
                                    className="fileIput"
                                    onChange={(e) => {
                                        if (e.target.files[0] !== undefined && e.target.files[0].size < 5 * 1024 * 1024) {
                                            console.log(e.target.files[0].size);
                                            setInvoice(e.target.files[0])
                                            messageApi.open({
                                                key,
                                                type: 'loading',
                                                content: 'Faturan yükleniyor...',
                                                duration: 0,
                                            });
                                            uploadInvoiceMutation.mutateAsync().then(() => {

                                                messageApi.open({
                                                    key,
                                                    type: 'success',
                                                    content: 'Fatura başarıyla yüklendi👌',
                                                    duration: 2,
                                                });
                                            }).catch((error) => {
                                                console.log(error);
                                                messageApi.open({
                                                    key,
                                                    type: 'error',
                                                    content: 'faturan yüklenemedi',
                                                    duration: 2,
                                                });
                                            })
                                            e.target.value = ''
                                        }
                                    }}
                                />
                            </Col>
                            
                        </Row>
                    </div>)}
                    {contextHolder}
                </CardBody>
            </Card>
        </>
    )
}

export default ProccessingInvoices