import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Helpers
import * as url from 'helpers/url_helper';
import { get } from "helpers/api_helper"

// Import Toast Notifications
import { toast, ToastContainer } from "react-toastify";

// Import Query
import { useQuery } from "@tanstack/react-query";

// Import Components
import Reviews from "./Components/PageComponents/ProductDetail/Reviews";
import Specifications from "./Components/PageComponents/ProductDetail/Specifications";
import TotalPurchasesCard from "./Components/PageComponents/ProductDetail/TotalPurchasesCard";
import TotalRefundsCard from "./Components/PageComponents/ProductDetail/TotalRefundsCard";
import ProductImageGallery from "./Components/PageComponents/ProductDetail/ProductImageGallery";
import ProductVariations from "./Components/PageComponents/ProductDetail/ProductVariations";
import StockAndEditButtons from "./Components/PageComponents/ProductDetail/StockAndEditButtons";
import { IoArrowBackCircleSharp, IoCheckmarkCircle } from "react-icons/io5"
const ProductDetail = () => {
  // Meta title
  document.title = "Ürün Detayları | ButikO";

  const navigate = useNavigate();
  const { id } = useParams();

  const handleError = (error) => {
    const errorMsg = error?.response?.data?.detail?.[0]?.msg || '';

    if (errorMsg && errorMsg !== "not found") {
      toast.error("Bir Hata Oluştu", { toastId: "someError" });

      if (errorMsg === "unathorized action" || errorMsg === "invalid jwt") {
        navigate("/login");
      }
    }

    console.log(error);
  };


  const fetchData = async (apiUrl) => {
    try {
      const response = await get(apiUrl, { withCredentials: true });
      return response;
    } catch (error) {
      handleError(error);
    }
  };

  const useApi = (apiUrl) => {
    return useQuery({
      queryKey: [apiUrl],
      queryFn: () => fetchData(`${apiUrl}?product_id=${id}`),
    });
  };

  const { data: totalPurchases, isLoading: isTotalPurchasesLoading, isError: isTotalPurchasesError } = useApi(url.GET_TOTAL_PURCHASES);
  const { data: totalRefunds, isLoading: isTotalRefundsLoading, isError: isTotalRefundsError } = useApi(url.GET_TOTAL_REFUNDS);
  const { data: sizeAndVariations, isLoading: isSizeAndVariationsLoading, isError: isSizeAndVariationsError } = useApi(url.GET_SIZE_AND_VARIATIONS);
  const { data: specifications, isLoading: isSpecificationsLoading, isError: isSpecificationsError } = useApi(url.GET_SPECIFICATIONS);
  const { data: reviews, isLoading: isReviewsLoading, isError: isReviewsError } = useApi(url.GET_REVIEWS_WITH_ID);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleImage = (variationIndex, mediaIndex) => {
    setActiveTab(variationIndex); // Set active tab to the variation index
    const variationMediaString = sizeAndVariations[variationIndex]?.variation_media;
    if (variationMediaString) {
      const variationMedias = JSON.parse(variationMediaString);
      if (variationMedias && variationMedias.length > 0) {
        setSelectedImage(variationMedias[mediaIndex]);
      }
    }
  };

  useEffect(() => {
    if (sizeAndVariations && sizeAndVariations.length > 0 && JSON.parse(sizeAndVariations[0]?.variation_media).length > 0 && selectedImage === null) {
      // Set the initial selectedImage to the URL of the first variation image
      const parsedVariationMedia = JSON.parse(sizeAndVariations[0].variation_media);
      const firstItem = parsedVariationMedia[0];
      setSelectedImage(firstItem);
    }
  }, [sizeAndVariations, selectedImage]);

  return (
    <Container fluid className="page-content">
      <Breadcrumbs title="ButikO" breadcrumbItem="Ürün Detayları" />
      <ToastContainer />
      <Row>
        <Col>
          
          <Row className="mb-2">
          <Col lg="6"> <span className="title d-flex align-items-center">
            <IoArrowBackCircleSharp
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer", color: "##1A1A1D" }}
            />
          </span></Col>
            <Col lg="6"> <StockAndEditButtons id={id} /></Col>
           
          </Row>
          <Row>
            <Col xs="12" xl="6">
              <Row>
                <Col xs="12" sm="6">
                  <TotalPurchasesCard totalPurchases={totalPurchases} />
                </Col>
                <Col xs="12" sm="6">
                  <TotalRefundsCard totalRefunds={totalRefunds} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <ProductVariations sizeAndVariations={sizeAndVariations} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs="12" xl="6">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Specifications specifications={specifications} isLoading={isSpecificationsLoading} isError={isSpecificationsError} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Reviews productReviews={reviews} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

ProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
};

export default withRouter(ProductDetail);
