const ShouldGlow = (schedule) => {
    let glowing = false;
    if (schedule) {
        // console.log("true");
        const array = Object.entries(JSON.parse(schedule));
        array.forEach((item) => {

            // console.log(item[1])
            if (item[1].is_enabled) {
                if (item[1].start_date === null || item[1].end_date === null) {
                    glowing = true
                }
                // console.log(item[1]);
            } else {
            }
        }
        )
        // console.log(glowing);
    } else {
        // console.log("not true");
    }
    return glowing;
}
export default ShouldGlow