import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom"; 
import { layoutTypes } from "./constants/layout";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Notifications } from 'react-push-notification';
import { generateToken, messaging } from 'pages/Notification/firebase';
import { onMessage } from 'firebase/messaging';
import { trackPageView } from './analytics'; 

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const queryClient = new QueryClient();

  const Layout = getLayout(layoutType);
  const location = useLocation(); 

  useEffect(() => {
    generateToken()
    onMessage(messaging, (payload => {
      console.log(payload);
    }))
  }, []);

  useEffect(() => {
    trackPageView(location.pathname + location.search); 
  }, [location]);

  return (
    <React.Fragment>
      <Notifications />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/login" />}
          />
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>
                    {route.component}
                  </Layout>
                </Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
          
        </Routes>
      </QueryClientProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
