import React from "react";
import { TbBorderRadius } from "react-icons/tb";
import { Card, CardBody, Row, Col, Input, FormGroup } from "reactstrap";

const PriceBanner = ({ color, text }) => {
  return (
    <svg
      className="position-absolute"
      width="120"
      height="90"
      viewBox="0 0 84 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ top: 0, right: 0 }}
    >
      <rect width="100%" height="100%" fill="white" />
      <rect x="11.5225" y="-23" width="124" height="20" transform="rotate(35.1784 11.5225 -24)" fill={color} />
      <text
        dy="0.32em"
        textAnchor="middle"
        transform="rotate(35.1784)"
        fontSize="9"
        fontWeight="500"
        fill="white"
        x="70%"  // Adjust this value to horizontally center the text
        y="-23%"  // Adjust this value to vertically center the text
      >
        %{text} Avantaj
      </text>

    </svg>
  );
};

const PriceCard = ({ offer, isSelected, onRadioChange }) => {
  const cardStyle = {
    border: isSelected ? "2px solid #556EE6" : "2px solid #f8f8ff",
    borderRadius:"8px"
  };

  const amountStyle = { fontSize: "18px", fontWeight: "700" };
  const priceStyle = { fontSize: "16px", fontWeight: "500" };
  const perTokenStyle = { color: "#A2A3A4" };

  const bannerColor =
    offer.discount_rate === 10
      ? { color: "#34C38F" }
      : offer.discount_rate === 16
        ? { color: "#D8B604" }
        
        : offer.discount_rate === 20
          ? { color: "#556EE6" }
          : offer.discount_rate === 25
          ? { color: "#249FC6" }
          : offer.discount_rate === 28
          ? { color: "#9E0BD" }
          : offer.discount_rate === 30 
          ? { color: "#9E34C3" }
          : {};
  return (
    <React.Fragment>
      <Card style={cardStyle} onClick={() => onRadioChange(offer.offer_id)}>
        {offer.discount_rate !== 0 && (
          <PriceBanner color={bannerColor.color} text={offer.discount_rate} />
        )}

        <CardBody className="p-2 pt-4">
          <Row>
            <Col xs="1">
              <FormGroup check>
                <Input
                  type="radio"
                  onChange={()=>{}}
                  checked={isSelected}
                  value={offer.offer_id}
                  style={{ borderColor: "#767676" }}
                />
              </FormGroup>

            </Col>
            <Col xs="10">
            <Row className="mb-3">
                <Col className="d-flex align-items-center">
                  <span style={{borderBottom:"2px solid gray",fontSize:"13px"}}>{offer.banner_text}</span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="d-flex align-items-center" style={amountStyle}>
                  <span>{offer.token_amount || "N/A"}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="mb-2" style={priceStyle}>
                    {offer.token_price} ₺
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                <p className="mb-2" style={perTokenStyle}>
      Bir token {(offer.token_price / offer.token_amount).toFixed(2)} ₺
    </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PriceCard;