// CategoryFilter.js

import React, { useState } from "react"
import axios from "axios"
import { useQuery } from "react-query"
import { Label } from "reactstrap"

const CategoryFilter = ({ onCategorySelect }) => {
  const [categoryId, setCategoryId] = useState("")
  const {
    data: categoryData,
    isLoading,
    isError,
    error,
  } = useQuery("categories", async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/categories/`,
      { withCredentials: true }
    )
    return response.data
  })

  const handleCategoryChange = e => {
    const selectedValue = e.target.value
    setCategoryId(selectedValue)
    onCategorySelect(selectedValue) // Seçilen kategoriyi iletmek için onCategorySelect fonksiyonunu çağır
  }

  if (isLoading) {
    return <span>Yükleniyor...</span>
  }

  if (isError) {
    console.error(error)
    return <span>Kategorileri yüklerken bir sorun oluştu.</span>
  }

  const textFieldStyle = {
    backgroundColor: "white",
    color: "black",
    appearance: "auto",
  }
  return (
    <div className="category-filter">
      <select
        value={categoryId ? categoryId : ""}
        onChange={handleCategoryChange}
        className="form-control select2"
        style={textFieldStyle}
      >
        <option value="">Kategoriler</option>
        {categoryData.map(category => (
          <optgroup key={category.category_id} label={category.category_name}>
            <option key={category.category_id} value={category.category_id}>
              {category.category_name}
            </option>
            {category.sub_categories.map(sub => (
              <option key={sub.category_id} value={sub.category_id}>
                {sub.category_name}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  )
}

export default CategoryFilter
