import React from "react"

const SelectedProductBox = ({ product, onRemove }) => {
  const handleRemove = () => {
    onRemove(product.product_id)
  }

  return (
    <div
      style={{
        borderRadius: "8px",
        padding: "10px",
        margin: "5px 0",
        position: "relative",
      }}
      key={product.product_id}
    >
      <img
        src={product.variation_media[0]}
        alt="Product"
        style={{
          width: "100px",
          height: "100px",
          position: "relative",
          zIndex: 0,
          borderRadius: "8px",
          objectFit: "cover",
          border: "1px solid #eee",
        }}
      />
      <button
        style={{
          position: "absolute",
          top: "1px",
          right: "15px",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          padding: "0",
          zIndex: 1,
        }}
        onClick={handleRemove}
      >
        <i
          className="bx bxs-x-circle"
          style={{ fontSize: "25px", color: "#FA7E7E" }}
        ></i>
      </button>
      <div>
        <p className="text-truncate fw-bold text-center">{product.name}</p>
      </div>
    </div>
  )
}

export default SelectedProductBox
