import React from 'react';
import { Form } from 'react-bootstrap';

const YearSelect = ({ value, onChange, disabled }) => {
    return (
        <Form.Select
            value={value}
            onChange={onChange}
            disabled={disabled}
            aria-label="Year Select"
        >
            <option>Yıl</option>
            {[...Array(17)].map((_, index) => {
                const year = 2024 + index;
                return (
                    <option key={year} value={year.toString()}>
                        {year}
                    </option>
                );
            })}
        </Form.Select>
    );
};

export default YearSelect;
