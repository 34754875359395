import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

import { get, post } from "helpers/api_helper";
import * as url from "helpers/url_helper";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import VariationItem from "../Components/PopupComponents/OutOfStockPopup/VariationItem";


const OutOfStockPopup = ({ modalIsOpen, setIsOpen, productId, productName }) => {

    const notifySaved = () => toast.success("Kaydedildi!");
    const notifyFail = () => toast.error("Bir şeyler ters gitti.");
    const notifyNoItemSelected = () => toast.warn("Lütfen bir beden seçiniz.");

    const [loading, setLoading] = useState(false);

    const [stockItems, setStockItems] = useState([]);
    const [variations, setVariations] = useState([]);

    const [clickedSizes, setClickedSizes] = useState({});
    const [postOutOfStockItems, setPostOutOfStockItems] = useState({ out_of_stock_items: [] });


    const handleSizeClick = (variation, uniqueIdentifier) => {
        const size = uniqueIdentifier.split('-')[0];

        setClickedSizes(prevClickedSizes => ({
            ...prevClickedSizes,
            [uniqueIdentifier]: !prevClickedSizes[uniqueIdentifier],
        }));

        setPostOutOfStockItems(prevItems => {
            let sizeIndex = -1;
            let updatedItems;

            if (Array.isArray(prevItems.out_of_stock_items)) {
                // Handle the case when prevItems is an object with an array
                sizeIndex = prevItems.out_of_stock_items.findIndex(item => item.variation_id === variation.variation_id && item.size === size);



                if (clickedSizes[uniqueIdentifier]) {
                    // If the size is clicked again, remove it from the out-of-stock items array
                    if (sizeIndex !== -1) {
                        updatedItems = prevItems.out_of_stock_items.filter(item => item.variation_id !== variation.variation_id || item.size !== size);
                    } else {
                        updatedItems = prevItems.out_of_stock_items;
                    }
                } else {
                    // If the size is clicked, add it to the out-of-stock items array
                    if (sizeIndex === -1) {
                        updatedItems = [...prevItems.out_of_stock_items, { variation_id: variation.variation_id, size }];
                    } else {
                        updatedItems = prevItems.out_of_stock_items;
                    }
                }
            }

            return {
                out_of_stock_items: updatedItems || [],
            };
        });
    };

    const handleSaveOutOfStock = async () => {
        try {
            const postBody = postOutOfStockItems.out_of_stock_items.length > 0
                ? postOutOfStockItems
                : {};

            const response = await post(
                `${url.POST_OUT_OF_STOCK_ITEMS}`,
                postBody,
                { withCredentials: true }
            );

            // console.log(response);

            closeModal();

            notifySaved();
        } catch (error) {
            console.log(error.response);

            if (error.response.data.detail[0].msg === "Field required") {
                notifyNoItemSelected();
            } else {
                notifyFail();
            }
        }
    };

    const mainImageStyle = { width: "50%", border: "1px solid #E4E4E4", borderRadius: "8px" }

    function closeModal() {
        setIsOpen(false);
        setClickedSizes({}); // Reset clickedSizes when the modal closes
        setStockItems([])
        setVariations([]);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await get(
                    `${url.GET_OUT_OF_STOCK_ITEMS}?product_id=${productId}`,
                    { withCredentials: true }
                );

                console.log(response);

                if (Array.isArray(response)) {
                    const responseData = response.map((item) => ({
                        total_quantity: item.total_quantity,
                        variations: typeof item.variations === "string"
                            ? JSON.parse(item.variations)
                            : item.variations,
                    }));

                    setStockItems(responseData);
                    setVariations(responseData.map((item) => item.variations)); // Set variations here

                } else {
                    console.error("Response is not an array of objects");
                }

            } catch (error) {
                console.log(error.response);
            }
            setLoading(false);
        }

        if (modalIsOpen) {
            // Fetch data when the popup is opened
            fetchData();
        }

    }, [modalIsOpen])

    useEffect(() => {
        // This effect will run whenever stockItems change
        if (stockItems.length > 0) {
            // Extract variations from stockItems and set it in variations state
            const variationsData = stockItems.map((stockItem) => stockItem.variations);
            setVariations(variationsData[0]);
        }
    }, [stockItems]);

    return (
        <Modal
            isOpen={modalIsOpen}
            // onRequestClose={closeModal}
            toggle={closeModal}
            // contentLabel="Example Modal"
            style={{
                maxWidth: "60rem",
                height: "30rem", // Adjust this value to reduce the height
                marginTop: "3rem",
            }}
        >
            <ModalHeader toggle={closeModal}>
                <span style={{ fontSize: "16.2px", fontWeight: "500" }}>Stoktan Kaldır</span>
            </ModalHeader>
            <ModalBody>
                <Row className="d-flex align-items-center my-2">
                    <Col xl="2" md="6" xs="6" className="d-flex flex-column align-items-center">
                        {variations[0] && variations[0].variation_media ? (
                            <img src={variations[0].variation_media[0]} style={mainImageStyle} />
                        ) : (
                            <img  style={mainImageStyle} />
                        )}
                    </Col>
                    <Col xl="7" md="6" xs="12" className="d-flex flex-column justify-content-between">
                        <Row className="d-flex align-items-start">
                            <Col>
                                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                    {productName}
                                </span>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-end">
                            <Col>
                                <span>Ürün Miktarı: {loading ? "Yükleniyor..." : (stockItems[0] ? (stockItems[0].total_quantity) : "")}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center my-3" style={{marginLeft:"8px"}}>
                    <span>Stoğunu sıfırlamak istediğiniz bedenin üstüne tıklayınız.</span>
                </Row>
                {variations.map((variation, index) => (
                    <VariationItem
                        key={index}
                        variation={variation}
                        handleSizeClick={handleSizeClick}
                        clickedSizes={clickedSizes}
                    />
                ))}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={closeModal} className="px-5">
                    İptal Et
                </Button>
                <Button color="primary" className="px-5" onClick={handleSaveOutOfStock}>
                    Kaydet
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default OutOfStockPopup;