import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CardBody, Table, Button } from "reactstrap";
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';


const LatestTransactionError = ({ error, resetErrorBoundary }) => {



    return (
        <>

            <Card >
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                    <h4>
                        Bir şeyler ters gitti
                    </h4>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                </Card.Body>
            </Card>
        </>
    );
};

export default LatestTransactionError;
