import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
const useGetIsAuth = () => {
    let shouldOpen =false
    const getAuth = ()=>{
        // const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/payment/get_card_details`
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/is-authenticated`,{withCredentials:true})
    }
    const {data,isLoading, isError,error,refetch } = useQuery({queryKey:["get-auth"],queryFn:getAuth})
    const auth = data?.data
    if(auth && auth?.payload?.is_verified_phone_number === 1 && auth?.payload?.approval_status !== "approved" ){
        shouldOpen= true
    }
    // console.log(shouldOpen);
    
    return {auth, isLoading, isError,error,shouldOpen,refetch}
}
export default useGetIsAuth