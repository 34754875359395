import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const OrdersBox = ({ ordersData }) => {
    // const { value, previous_period_percentage } = ordersData;
    // throw new Error("order error")
    let dataToDisplay
    if(ordersData ===0){
        // console.log("josfd");
        dataToDisplay="Sipariş yok"
    }else{
        dataToDisplay = ordersData
    }
    return (
        <Col md="4">
            <Card className="mini-stats-wid">
                <CardBody>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                                <i className="bx bx-copy-alt" />
                            </span>
                        </div>
                        <h5 className="font-size-14 mb-0">Siparişler</h5>
                    </div>
                    <div className="text-muted mt-4">
                        <h4 style={dataToDisplay === "Sipariş yok" ? {color: "#F1B44C"}:{}}>
                            {dataToDisplay}
                        </h4>
                        {/* <div className="d-flex">
                            <span className="badge badge-soft-success font-size-12">
                                {previous_period_percentage}
                            </span>{" "}
                            <span className="ms-2 text-truncate">Önceki dönemden</span>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default OrdersBox;
