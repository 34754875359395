import React from "react";

const Rating = ({ rating }) => {

    const ratingColor = rating < 2 ? "bg-danger" : rating < 4 ? "bg-warning" : "bg-success"

    return (
        <span className={`${ratingColor} font-size-12 badge`} style={{ width: "43.3px" }}><i className="mdi mdi-star me-1"></i>{rating && rating}</span>
    )
};

export default Rating