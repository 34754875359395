

import React from 'react'

const Loading2 = () => {
    return (
        <React.Fragment>
            <svg width="38" height="383" viewBox="0 0 38 383" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect></rect>
                <rect x="23" width="383" height="7.99998" rx="3.99999" transform="rotate(90 23 0)" fill="#E4E4E4" />
                <rect x="23" width="233" height="7.99999" rx="3.99999" transform="rotate(90 23 0)" fill="#34C38F" />
                <circle cx="19" cy="63" r="19" transform="rotate(90 19 63)" fill="#34C38F" />
                <circle cx="19" cy="246" r="19" transform="rotate(90 19 246)" fill="#34C38F" />
                <text x="16" y="68" fontSize="18" fill="white">1</text>
                <text x="13" y="251" fontSize="18" fill="white">2</text>
            </svg>
        </React.Fragment>
    )
}

export default Loading2