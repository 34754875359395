import React from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import Filter from "./Filter"
import {
  Col,
  Container,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const EcommerceProducts = props => {
  //meta title
  document.title = "ButikO - Reklamlar"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Reklam-Token" breadcrumbItem="Reklamlar" />
          <Row>
            <Col  lg="12">
              <Filter />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
}

export default withRouter(EcommerceProducts)
