import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ToastContainer } from "react-toastify"
//import Charts
import StackedColumnChart from "./Components/StackedColumnChart";
//import action
import { getChartsData as onGetChartsData } from "../../store/actions";
// Pages Components
import WelcomeComp from "./Components/WelcomeComp";
import Earning from "./Components/earning";
import SalesAnalytics from "./Components/sales-analytics";
import TopSellingProduct from "./Components/TopSellingProduct";
import LatestTransaction from "./Components/LatestTranaction";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "@tanstack/react-query";
import PhoneVerificationPopup from "components/Common/PhoneVerificationPopup";
import OrdersBox from "./Components/Boxes/OrdersBox";
import RevenueBox from "./Components/Boxes/RevenueBox";
import AveragePriceBox from "./Components/Boxes/AveragePriceBox";
import { ErrorBoundary } from "react-error-boundary";
import { TopSellingProductErrorFallBack } from "./Components/ErrorBoundarys/TopSellingProductError";
import OrdersBoxError from "./Components/ErrorBoundarys/OrderBoxError";
import RevenueBoxError from "./Components/ErrorBoundarys/RevenueBoxError";
import AveragePriceBoxError from "./Components/ErrorBoundarys/AveragePriceBoxError";
import StackedColumnChartError from "./Components/ErrorBoundarys/StackedColumnChartError";
import LatestTransactionError from "./Components/ErrorBoundarys/LatestTranactionError";
const Dashboard = (props) => {
  // const [subscribemodal, setSubscribemodal] = useState(false);


  const [isPhoneVerificationModalOpen, setIsPhoneVerificationModalOpen] = useState(false);

  const { chartsData } = useSelector((state) => ({
    chartsData: state.Dashboard.chartsData,
  }));


  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = (pType) => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  //meta title
  document.title = "Ana Sayfa | Butiko";
  // const [page, setPage] = useState(0)
  // const GET_LATEST_TRANSACTION_PRODUCT = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/latest-transactions?page=${page}/`;


  //react query for getting store data

  const secondaryDashboardData = {
    earning_info: {
      year_overview: "[300, 100, 0, 0, 0, 100, 0, 0, 0, 0, 0, 400]",
      this_month: 400,
      last_month: 600
    },
    sales_analytics: [
      {
        total_sales: 200,
        category: "Pants"
      },
      {
        total_sales: 500,
        category: "Shirts"
      }
    ],
    product_sales: [
      { sales: "15", returns: "10", ads_sales: "75" },
      { sales: "28", returns: "10", ads_sales: "62" },
      { sales: "5", returns: "23", ads_sales: "72" },
      { sales: "10", returns: "15", ads_sales: "75" },
      { sales: "20", returns: "10", ads_sales: "70" },
      { sales: "25", returns: "20", ads_sales: "55" },
      { sales: "18", returns: "20", ads_sales: "62" },
      { sales: "30", returns: "5", ads_sales: "65" },
      { sales: "8", returns: "20", ads_sales: "72" },
      { sales: "12", returns: "25", ads_sales: "63" },
      { sales: "35", returns: "10", ads_sales: "55" },
      { sales: "22", returns: "18", ads_sales: "60" }
    ]
  }

  const defaultOrdersData = {
    value: 0,
    previous_period_percentage: 0
  };

  const defaultRevenueData = {
    value: 0,
    previous_period_percentage: 0
  };

  const defaultAveragePriceData = {
    value: 0,
    previous_period_percentage: 0
  };



  const getRevenue = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/revenue`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const getOrders = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/orders`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const getAveragePrice = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/average-price`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const getProductSales = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/product-sales`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const {
    isLoading: ProductSalesIsLoading,
    isError: ProductSalesIsError,
    error: ProductSalesError,
    data: ProductSalesData
  } = useQuery({ queryKey: ["get-product-sales"], queryFn: getProductSales });
  const {
    isLoading: averagePriceIsLoading,
    isError: averagePriceIsError,
    error: averagePriceError,
    data: averagePriceData
  } = useQuery({ queryKey: ["get-average-price"], queryFn: getAveragePrice });
  const {
    isLoading: ordersIsLoading,
    isError: ordersIsError,
    error: ordersError,
    data: ordersData
  } = useQuery({ queryKey: ["get-orders"], queryFn: getOrders })
  const {
    isLoading: revenueIsLoading,
    isError: revenueIsError,
    error: revenueError,
    data: revenueData
  } = useQuery({ queryKey: ["get-revenue"], queryFn: getRevenue })
  // --------------------------------ProductSales-------------------
  let ProductSales
  if (ProductSalesIsLoading) {
    ProductSales = "Yükleniyor..."
  } else if (ProductSalesIsError) {
    console.log(ProductSalesError);
    ProductSales = "Bir hata oluştu!"
  } else {
    ProductSales = ProductSalesData?.data
    // console.log(ProductSales);
  }

  // --------------------------------ProductSales-------------------
  // --------------------------------averagePrice-------------------
  let averagePrice
  if (averagePriceIsLoading) {
    averagePrice = "Yükleniyor..."
  } else if (averagePriceIsError) {
    console.log(averagePriceError);
    if (averagePriceError?.response?.data?.detail[0]?.msg === "seller is not approved") {
      averagePrice = "Satıcı onaylı değil"
    } else {
      averagePrice = "Bir hata oluştu!"
    }
  } else {
    averagePrice = averagePriceData?.data.average_price
  }

  // --------------------------------averagePrice-------------------
  // --------------------------------Orders-------------------
  let orders
  if (ordersIsLoading) {
    orders = "Yükleniyor..."
  } else if (ordersIsError) {
    if (ordersError?.response?.data?.detail[0]?.msg === "seller is not approved") {
      orders = "Satıcı onaylı değil"
    } else {
      orders = "Bir hata oluştu!"

    }
    console.log(ordersError);
  } else {
    // console.log(ordersData.data);
    orders = ordersData?.data.orders
  }

  // --------------------------------Orders-------------------
  // --------------------------------Revenue-------------------
  let revenue
  if (revenueIsLoading) {
    revenue = "Yükleniyor..."
  } else if (revenueIsError) {
    // console.log(revenueError);
    if (revenueError?.response?.data?.detail[0]?.msg === "seller is not approved") {
      revenue = "Satıcı onaylı değil"
    } else {
      revenue = "Bir hata oluştu."

    }
  } else {
    // console.log(revenueData.data);
    revenue = revenueData?.data?.revenue
  }
  // --------------------------------Revenue-------------------
  // throw new Error("dsf")
  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <PhoneVerificationPopup modalIsOpen={isPhoneVerificationModalOpen} setModalIsOpen={setIsPhoneVerificationModalOpen} />
        
        
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <WelcomeComp />
            <Col xl="12">
              <Row>
                <ErrorBoundary FallbackComponent={OrdersBoxError}>
                  <OrdersBox ordersData={orders} />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={RevenueBoxError}>
                  <RevenueBox revenueData={revenue} />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={AveragePriceBoxError}>
                  <AveragePriceBox averagePriceData={averagePrice} />
                </ErrorBoundary>
              </Row>


            </Col>
          </Row>
          <Row>
            {/* Kazanç */}
            {/* <Earning dataColors='["--bs-primary"]' earningData={dashboardData?.earning_info ?? secondaryDashboardData.earning_info} /> */}

            {/* Satış Analitiği */}
            {/* <SalesAnalytics dataColors='["--bs-primary", "--bs-success"]' salesAnalyticsData={dashboardData?.sales_analytics ?? secondaryDashboardData.sales_analytics} /> */}
          </Row>
          <Row className="d-flex">
            {/* Toplam Satılan Ürün */}
            <ErrorBoundary FallbackComponent={TopSellingProductErrorFallBack}>
              <TopSellingProduct dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning"]' />

            </ErrorBoundary>
            <Col style={{ flex: 1 }} xl="8">
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      {props.t("Ürün Satışları")}
                    </h4>
                    {/* <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            {props.t("Hafta")}
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            {props.t("Ay")}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            {props.t("Yıl")}
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <ErrorBoundary FallbackComponent={StackedColumnChartError}>

                    <StackedColumnChart
                      dataColors='["--bs-primary", "--bs-success", "--bs-danger","--bs-success"]'
                      periodType={periodType}
                      periodData={periodData}
                      productSalesData={ProductSales}
                    />
                  </ErrorBoundary>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* İşlem Geçmişi */}
            <ErrorBoundary FallbackComponent={LatestTransactionError}>

              <LatestTransaction />
            </ErrorBoundary>
            {/* Müşteri Aktivitesi */}
          </Row>
        </Container>
        {/* //   )
        // } */}


      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
