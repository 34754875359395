import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Table,
  Col,
} from "reactstrap"

import { useQuery, useMutation } from "@tanstack/react-query"
import "./products.css"
import NewAddPopup from "./Popups/NewAddPopup"
import AdRow from "./Components/AdRow"
import { useNavigate } from "react-router-dom"
import AdSelectFilters from "./Components/AdSelectFilters"
import AdDetailModal from "./Popups/AdDetailModal"

const Filter = () => {
  const history = useNavigate()

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const toggleDetailModal = () => {
    setDetailModalOpen(!detailModalOpen)
  }

  const [categoryId, setCategoryId] = useState(0)

  const [modalOpen, setModalOpen] = useState(false)

  const [adsData, setAdsData] = useState([])
  const [adDetailId, setAdDetailId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [selectedCityId, setSelectedCityId] = useState("") // İlçe (district) için state
  const [selectedRanking, setSelectedRank] = useState("") // Sıralama için state
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [semtId, setSemtId] = useState(0)

  const [isProducts, setIsProducts] = useState(true)
  const [nextPageHasProducts, setNextPageHasProducts] = useState(true)
  const [districtId, setDistrictId] = useState(0)
  const [search, setSearch] = useState("")

  const [filterApplied, setFilterApplied] = useState(false);

  const handleDistrictChange = e => {
    setDistrictId(e.target.value)
    setFilterApplied(true)
  }

  const handleSemtChange = e => {
    setSemtId(e.target.value)
    setFilterApplied(true)
  }
  const handleRankChange = e => {
    setSelectedRank(e.target.value)
    setFilterApplied(true)
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage => currentPage + 1)
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage => currentPage - 1)
    }
  }

  useEffect(() => {
    filterMutation.mutate()
  }, [currentPage, selectedSubCategory, semtId, selectedRanking, search])

  const handleFilterClick = () => {
    const data = {
      ...(categoryId && { category_id: categoryId }),
      ...(semtId && { semt_id: semtId }),
      ...(selectedRanking && { ranking: selectedRanking }),
      ...(search && { search: search }),
      page: currentPage,
    }
    // console.log(data);
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/search`,
      data,
      { withCredentials: true }
    )
  }
  const filterMutation = useMutation({
    mutationFn: handleFilterClick,
    onSuccess: res => {
      setErrorOccurred(false)
      const data = res.data
      setFilterApplied(false);
      if (data.length === 7) {
        setNextPageHasProducts(false)
        data.pop()
      } else {
        setNextPageHasProducts(true)
      }
      setAdsData(data)
    },
    onError: error => {
      // console.log(error);
      if (
        error.response.data.detail[0].msg === "unathorized action" ||
        error.response.data.detail[0].msg === "invalid jwt"
      ) {
        history("/login")
      }
      setFilterApplied(true)
      setErrorOccurred(true)
      // toastr.error("Bilinmeyen Bir Hata Oluştu", "")
    },
  })






  // -------------------for semt & district-------------------
  const getDistrict = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/ilce/40`, {
      withCredentials: true,
    })
  }
  const getSemt = () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/tr-addresses/semt/${districtId}`,
      { withCredentials: true }
    )
  }
  const {
    isLoading: districtIsLoading,
    isError: districtIsError,
    error: districtError,
    data: districtData,
  } = useQuery({ queryKey: ["district"], queryFn: getDistrict })
  let districtOptions
  if (districtIsLoading) {
    districtOptions = <option value="">Yükleniyor..</option>
  } else if (districtIsError) {
    if (
      
      districtError?.response?.data?.detail[0]?.msg === "unathorized action" ||
      districtError?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    // console.log(districtError);
    districtOptions = <option value="">HATA Oluştu! Lütfen te</option>
  } else {
    districtOptions = districtData?.data?.map(data => (
      <option key={data.ilce_id} value={data.ilce_id}>
        {data.ilce}
      </option>
    ))
  }
  const {
    isLoading: semtIsLoading,
    isError: semtIsError,
    error: semtError,
    data: semtData,
  } = useQuery({
    queryKey: ["semt", districtId],
    queryFn: getSemt,
    enabled: Boolean(districtId),
  })
  let semtOptions
  if (semtIsLoading) {
    districtOptions = <option value="">Yükleniyor..</option>
  } else if (semtIsError) {
    districtOptions = <option value="">HATA Oluştu! Lütfen te</option>
  } else {
    semtOptions = semtData?.data.map(data => (
      <option key={data.semt_id} value={data.semt_id}>
        {data.semt}
      </option>
    ))
  }

  return (
    <Row>
      <Row className="mb-3 d-flex  align-items-end ">
        <Col lg={12} sm={6}>
          <div className="mt-2">
            <Button
              style={{
                backgroundColor: "#34C38F",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "20px",
                fontSize: "14px",
                float: "right",
                display: "flex",
              }}
              className="mb-2"
              onClick={toggleModal}
            >
              + Yeni Reklam Oluştur
            </Button>
            <NewAddPopup filterMutation={filterMutation} modalOpen={modalOpen} setModalOpen={setModalOpen} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="3">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Filtrele</CardTitle>
              <AdSelectFilters
                districtId={districtId}
                handleDistrictChange={handleDistrictChange}
                districtOptions={districtOptions}
                handleSemtChange={handleSemtChange}
                semtOptions={semtOptions}
                handleRankChange={handleRankChange}
              />

              <Button
                color="light"
                onClick={() => {
                  setCategoryId("")
                  setSemtId("")
                  setSelectedRank("")
                  setSearch("")
                  filterMutation.mutate()
                }}
                className="clear-filter mt-5"
                disabled={!filterApplied}
              >
                Tüm Verileri Göster
              </Button>
            </CardBody>
          </Card>
          <AdDetailModal modalOpen={detailModalOpen} setModalOpen={toggleDetailModal} id={adDetailId} />
        </Col>
        <Col xs="12" md="12" lg="9">
          <Row>
            {isProducts ? (
              <Card>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr style={{ backgroundColor: "white" }}>
                        <th style={{ backgroundColor: "white" }}>#</th>
                        <th style={{ backgroundColor: "white" }}>İlçe</th>
                        <th style={{ backgroundColor: "white" }}>Sıralama</th>
                        <th style={{ backgroundColor: "white" }}>
                          Başlangıç Tarihi
                        </th>
                        <th style={{ backgroundColor: "white" }}>
                          Bitiş Tarihi
                        </th>
                        <th style={{ backgroundColor: "white" }}>Ücret</th>
                        <th style={{ backgroundColor: "white" }}>
                          Reklam Ziyareti
                        </th>
                        <th style={{ backgroundColor: "white" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorOccurred ? (
                        <tr>
                          <td colSpan="10">Reklam bulunamadı. "Reklam Oluştur" butonundan yeni reklam oluşturabilirsiniz.</td>
                        </tr>
                      ) : adsData.length > 0 ? (
                        adsData.map((ad, index) => (
                          <AdRow modalOpen={detailModalOpen} setModalOpen={toggleDetailModal} setAdDetailId={setAdDetailId} ad={ad} key={ad.promotion_id} index={index} />
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10">Reklam bulunamadı. "Reklam Oluştur" butonundan yeni reklam oluşturabilirsiniz.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <h6 className="p-3">
                    Arama kriterlerine uygun reklam bulunamadı. Filtreyi
                    temizleyip tekrar deneyiniz.{" "}
                  </h6>
                </CardBody>
              </Card>
            )}

            <Row>
              <Col className="d-flex justify-content-end">
                {currentPage > 0 && (
                  <Button className="prevButton" onClick={handlePreviousPage}>
                    Önceki
                  </Button>
                )}
                <Button
                  className="nextButton"
                  onClick={handleNextPage}
                  disabled={nextPageHasProducts}
                >
                  Sonraki
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Row>
  )
}

export default Filter
