import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import avatar1 from "../../assets/images/order/avatar-1.jpg"
import "./chat.css"
// import toastr from "toastr";
import { toast } from 'react-toastify';

import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";
import LightBox from "./LightBox";


//redux
const Chat = () => {


  // const [open, setOpen] = useState(false);

  document.title = "Sohbetler | Butiko";
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  //meta title




  const [messageBox, setMessageBox] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(1);

  const [currentUser, setCurrentUser] = useState({
    name: "Henry Wells",
    isActive: true,
  });


  const [Chat_Box_Username, setChat_Box_Username] = useState("");
  // eslint-disable-next-line no-unused-vars

  const [curMessage, setcurMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [recipientId, setRecipientId] = useState(0);
  const [messageHistory, setMessageHistory] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newMsg, setNewMsg] = useState([]);
  const [imgSrc, setImgSrc] = useState("");

  const options = { hour: 'numeric', minute: 'numeric', hour12: false };
  const getChatHistory = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/chat_history/?session_id=${currentRoom}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const {
    isError: chatHistoriIsError,
    isPending: chatHistoryIsPending,
    error: chatHistoryError,
    data: chatHistoriData,
    refetch: chatHistoryRefetch,
  } = useQuery({
    queryKey: ["get-chat-history"],
    queryFn: getChatHistory,
    enabled: false,
    retry: false
  })
  if (chatHistoryIsPending) {
  } else if (chatHistoriIsError) {
    console.log(chatHistoryError);
  } else {

    // console.log(chatHistoriData.data);
  }
  const getSellerSessions = useCallback(() => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/chat_api/seller_sessions/`
    return axios.get(finalUrl, { withCredentials: true })
  })
  const { isError, isPending, error, data, refetch: sessionsRefetch } = useQuery({
    queryKey: ["get-sessions"],
    queryFn: getSellerSessions
  })
  let sessionData
  if (isPending) {
    sessionData = "loading"
  } else if (isError) {
    console.log(error);
  } else {
    sessionData = data?.data.user_id
    // console.log(data?.data.sessions);
  }
  const [sellerId, setSellerId] = useState(0);
  const [sellerUserName, setSellerUserName] = useState("");
  //when using react query we can not use setstate but by using usememo we can do it 
  useMemo(() => {
    setUserId(data?.data?.user_id);
    setSellerId(data?.data?.sessions[0]?.seller_id)
    setSellerUserName(data?.data?.sessions[0]?.seller_shop_name)
  }, [data])


  // ------------------------------web socket---------------------------
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `wss://butikoseller.com/chat_api/sellersocket/${userId}`,
    {

      shouldReconnect: (CloseEvent) => true,
      onMessage: (e) => {
        console.log(e)
        chatHistoryRefetch()
        sessionsRefetch()
        toast.info("Yeni mesaj", { toastId: "newMsg" });
      },

    })


  useEffect(() => {
    if (lastMessage !== null) {
      chatHistoryRefetch()
      sessionsRefetch()
    }
  }, [lastMessage, setMessageHistory]);
  // --------------------------end web socket-------------------------


  const [percent, setPercent] = useState(0);
  const uploadMedia = (img) => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/media/upload_files`
    const finalData = new FormData()
    finalData.append('files', img)
    return axios.post(finalUrl, finalData, {
      withCredentials: true, onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded} b of ${total} b | ${percent}%`);
        if (percent < 100) {
          setPercent(percent)
        }
      }
    })
  }
  const [imgUrl, setImgUrl] = useState("");
  const uploadMediaMutation = useMutation({
    mutationFn: uploadMedia,
    onSuccess: (data) => {
      setImgUrl(data.data.file_name[0])
      setPercent(100)
      setTimeout(() => {
        setPercent(0)
      }, 3000);
    },
    onError: (error) => { console.log(error); }
  })
  const [imgToUpload, setImgToUpload] = useState("");
  const handleImageChange = (event) => {
    if (event.target.files[0].size > 600 && event.target.files[0].size < 10 * 1024 * 1024) {
      event.preventDefault();

      setImgToUpload(event.target.files[0])
      toast.promise(
        uploadMediaMutation.mutateAsync(event.target.files[0]),
        {
          pending: 'Yükleniyor',
          success: 'Yüklendi👌',
          error: 'Yüklenmedi'
        }
      )
      uploadMediaMutation.mutate(event.target.files[0])
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Dosya boyutu 0,5 Kilobyte ile 10mb arasında olmalıdır.")
    }
  };
  const updateSession = (id) => {
    const finalUrl = `https://butiko.com.tr/chat_api/update_status/?session_id=${id}`
    return axios.post(finalUrl, { withCredentials: true })
  }
  const updateSessionMutation = useMutation({
    mutationFn: updateSession,
    onSuccess: (e) => {
      console.log(e);
    },
    onError: (error) => {
      console.log(error);
    }
  })


  //Use For Chat Box
  const userChatOpen = (id, name, recipient) => {
    setChat_Box_Username(name);
    setCurrentRoom(id);
    setRecipientId(recipient)
    if (newMsg.includes(recipient)) {
      setNewMsg(prev => prev.filter(number => number !== recipient))
    }
    updateSessionMutation.mutate(id)
  };

  useEffect(() => {
    // This will run after the state has been updated
    chatHistoryRefetch();
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  }, [currentRoom]);

  const addMessage = (roomId, sender) => {

    if (curMessage !== "" || selectedImage !== null) {
      const test = {
        recipient: recipientId,
        message: {
          from_seller_name: sellerUserName,
          from_seller_id: sellerId,
          text: curMessage,
          ...(imgUrl && { media: imgUrl }),
        }
      }
      console.log(test);
      sendMessage(JSON.stringify(test), []);
      // chatHistoriData.data.push(test)
      setImgUrl("")
      setcurMessage("");
      setSelectedImage(null);
      setImgToUpload(null)
      chatHistoryRefetch()
    } else {
      alert("resim yok")
    }


  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Mesajlar" breadcrumbItem="Sohbetler" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div >
                    <div className="search-box chat-search-box pb-4">
                      <div className="position-relative">
                        <Input
                          // onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Ara..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <div>
                        <ul className="list-unstyled chat-list" id="recent-list">

                          <PerfectScrollbar style={{ height: "470px" }}>
                            {data?.data?.sessions?.map(chat => (
                              <li
                                key={chat?._id + chat?.status}
                                className={
                                  currentRoom === chat?._id
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    userChatOpen(
                                      chat?._id,
                                      chat?.user_first_name,
                                      chat?.user_id,
                                    );
                                  }}
                                >
                                  <div className="d-flex">

                                    <div className="align-self-center me-3">
                                      <i
                                        className={chat?.status === "" ? "mdi mdi-circle text-success font-size-10" : ""}
                                      />
                                    </div>
                                    <div className="align-self-center me-3">
                                      <img
                                        src={chat?.user_profile_picture === "null" || chat?.user_profile_picture === null ? avatar1 : chat?.user_profile_picture}
                                        className="rounded-circle avatar-xs"
                                        alt=""
                                      />
                                    </div>

                                    <div className="flex-grow-1 overflow-hidden">
                                      <h5 className="text-truncate font-size-14 mb-1">
                                        {chat.user_first_name}{" "}{chat.user_last_name}
                                      </h5>
                                      <p style={{ maxWidth: "80px" }} className="text-truncate mb-0">
                                        {chat?.recent_messages[0]?.message?.text}

                                      </p>
                                    </div>
                                    <div className="font-size-11">
                                      {/* {currentRoom === chat._id && new Date(chatHistoriData?.data[chatHistoriData?.data.length - 1]?.timestamp).toLocaleTimeString("en-US", options)} */}
                                      {chat?.recent_messages[0]?.timestamp ? new Date(chat?.recent_messages[0]?.timestamp).toLocaleString("en-US", options) : ""}
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <Row>
                            <Col md="2">
                              <div className="avatar-xs align-self-center me-3">
                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                  {Chat_Box_Username[0]}
                                </span>
                              </div>
                            </Col>
                            <Col className="align-self-center">
                              <h5 className="font-size-15 mb-1">
                                {Chat_Box_Username}
                              </h5>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">
                        <ul className="list-unstyled">
                          <PerfectScrollbar
                            style={{ height: "470px" }}
                            containerRef={ref => setMessageBox(ref)}
                          >
                            {chatHistoriData?.data.length === undefined && (
                              <h2 className=" align-items-center d-flex justify-content-center ">Lütfen Bir Sohbet Seçin</h2>
                            )}
                            {/* <Button onClick={console.log(chatHistoriData)}>dfdfs</Button> */}
                            {
                              chatHistoriData?.data.map((message, index) => {
                                const date = new Date(chatHistoriData?.data[index]?.timestamp).toLocaleDateString('en-GB');
                                const prvDate = new Date(chatHistoriData?.data[index - 1]?.timestamp).toLocaleDateString('en-GB');

                                return (
                                  <li
                                    key={"test_k" + message._id}
                                    className={
                                      message?.message?.from_seller_id
                                        ? "right"
                                        : ""
                                    }
                                  >

                                    <p style={{ textAlign: "center" }}>{date > prvDate && date}</p>
                                    <p style={{ textAlign: "center" }}>{index === 0 && date}</p>
                                    <div className="conversation-list">
                                      <div className="ctext-wrap">
                                        <div className="conversation-name">
                                          {message?.message?.from_user_name}
                                        </div>
                                        <p style={{ textAlign: "start" }}>{message?.message?.text}</p>
                                        {message?.message?.media &&
                                          <img
                                            className="chat-img"
                                            onClick={() => {
                                              setImgSrc(message?.message?.media)
                                              handleShow()
                                            }}
                                            src={message?.message?.media}
                                            alt=""
                                            width="150px"
                                          />}

                                        <p className="chat-time mb-0"> {message?.timestamp ? new Date(message?.timestamp).toLocaleTimeString('en-US', options) : new Date().toLocaleTimeString('en-US', options)}</p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              }
                              )
                            }
                            <LightBox modalOpen={show} setModalOpen={setShow} src={imgSrc} />
                          </PerfectScrollbar>
                        </ul>
                      </div>
                      {
                        selectedImage &&
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1">
                            <img src={selectedImage} alt="select img" style={{ width: "150px", height: "auto" }} />
                            {/* {percent > 0 && <ProgressBar animated now={percent} label={`${percent}%`} />} */}
                          </div>
                          <div className="flex-shrink-0">
                            <button type="button" id="close_toggle" className="btn btn-sm btn-link mt-n2 me-n3 fs-18" onClick={() => {
                              setSelectedImage(null)
                              setImgUrl("")
                            }}>
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      }
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={curMessage}
                                onChange={e => setcurMessage(e.target.value)}
                                className="form-control chat-input"
                                placeholder="Mesajı Girin..."
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    addMessage(currentRoom, currentUser.name)
                                  }
                                }}
                              />
                            </div>
                            <div className="chat-input-links">
                              <ul className="list-inline mb-0">

                                <li className="list-inline-item">
                                  <label htmlFor="imageInput" style={{ color: "#556ee6", fontSize: 16 }}>
                                    <i className="mdi mdi-file-image-outline me-1" id="Imagetooltip" />
                                    <UncontrolledTooltip placement="top" target="Imagetooltip">
                                      Images
                                    </UncontrolledTooltip>
                                  </label>
                                  <input disabled={currentRoom === 1} type="file" accept=".jpg, .png" id="imageInput" className="d-none" onChange={handleImageChange} />
                                </li>

                              </ul>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={() =>
                                addMessage(currentRoom, currentUser.name)
                              }
                              className="btn btn-primary btn-rounded chat-send w-md "
                              disabled={currentRoom === 1 || curMessage === ""}
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Gönder
                              </span>{" "}
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Chat.propTypes = {
  chats: PropTypes.array,
  // messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

export default Chat;