import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
} from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import { get, post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { toast } from "react-toastify"
import Countdown from "react-countdown"
import OTPInput from "react-otp-input"

const CircleWithIcon = () => {
  const circleStyle = {
    width: "72px",
    height: "72px",
    borderRadius: "50%",
    backgroundColor: "#EFF2F7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  const iconStyle = {
    fontSize: "40px",
    color: "white",
  }

  return (
    <div style={circleStyle}>
      <i className="bx bxs-phone text-primary" style={iconStyle}></i>
    </div>
  )
}

const PhoneVerificationPopup = ({ modalIsOpen, setModalIsOpen }) => {
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const [sent, setSent] = useState(true)
  const [error, setError] = useState("")
  const [remainingTime, setRemainingTime] = useState(0) // Add state for remaining time
  const [code, setCode] = useState("")

  const handleChange = value => {
    // Check if the entered value is a number
    if (/^\d*$/.test(value)) {
      // If it's a number, update the state
      setCode(value)
      console.log(value)
      setError("")
    } else {
      setError("Lütfen sadece sayı giriniz.")
    }
  }

  useEffect(() => {
    if (sent) {
      const intervalId = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1000)
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [sent])

  const generateSms = async () => {
    try {
      const response = await get(url.GET_GENERATE_SMS_CODE, {
        withCredentials: true,
      })
      // const response = await Promise.resolve({
      //   info: "sms sent successfully",
      // });
      console.log(response)

      if (response.info === "sms sent successfully") {
        setSent(true) // Update sent state only when SMS is successfully sent
        setRemainingTime(60000)
        toast.success("SMS Gönderildi!", { toastId: "descriptionMutation" })

        setModalIsOpen(true)
      }

      return response ? response ?? [] : []
    } catch (error) {
      console.error("Error sending sms:", error)
      throw error
    }
  }

  // const {
  //   data: generateSmsData,
  //   isLoading: isLoading,
  //   isError: isError,
  // } = useQuery({
  //   queryKey: ["generateSms"],
  //   queryFn: generateSms,
  //   onSuccess: () => {
  //     setSent(true)
  //   },
  //   onError: error => {
  //     setError("SMS gönderilemedi.")
  //   },
  // })
  useEffect(() => {
    sendSmsMutation.mutate()
  }, [])

  const sendSmsMutation = useMutation({
    mutationKey: ["generateSms"],
    mutationFn: generateSms,
    onError: error => {
      console.log(error)
      setError("SMS gönderilemedi.")
    },
  })

  const verifyCode = async () => {
    try {
      const data = {
        verification_code: code,
      }

      const response = await post(url.POST_VERIFY_SMS_CODE, data, {
        withCredentials: true,
      })
      console.log(response)

      // response.info === "phone_number verified"

      if (response.info === "phone_number verified") {
        const response = await get(url.GET_REFRESH_TOKEN, {
          withCredentials: true,
        })

        console.log(response)
      }

      return response ? response ?? [] : []
    } catch (error) {
      console.error("Error sending sms:", error)
      throw error
    }
  }

  const verifyCodeMutation = useMutation({
    mutationKey: ["verifyCode"],
    mutationFn: verifyCode,
    onSuccess: () => {
      setModalIsOpen(false)
      toast.success("Telefon numarası doğrulandı.", {
        toastId: "descriptionMutation",
      })
    },
    onError: error => {
      console.log(error)
      setError("SMS doğrulanamadı.")
      setTimeout(() => {
        setError("")
      }, [5000])
    },
  })

  const otpInputStyle = {
    width: "37px", // Adjust width as needed
    height: "43px", // Adjust height as needed
    fontSize: "20px", // Adjust font size as needed
    borderRadius: "10px", // Add border radius
    border: "1px solid #CED4DA", // Add border style
    margin: "5px", // Add margin between inputs
    textAlign: "center", // Center text horizontally
  }

  const handleSubmit = () => {
    verifyCodeMutation.mutate()
  }

  return (
    <Modal
      style={{
        maxWidth: "600px",
        maxHeight: "600px",
        marginTop: "80px",
      }}
      isOpen={modalIsOpen}
      toggle={toggleModal}
      backdrop="static" // Set backdrop to "static" to prevent closing on click outside
    >
      <ModalHeader>Telefon Doğrulama</ModalHeader>
      <ModalBody>
        <Row className="mb-4">
          <Col>
            <span style={{ fontWeight: 500 }}>
              Doğrulama (Telefon numaranızı doğrulamanız için size SMS
              göndereceğiz.)
            </span>
          </Col>
        </Row>
        <Row className="my-2">
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <CircleWithIcon />
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <span
              style={{ fontWeight: 500 }}
              className="d-flex justify-content-center align-items-center"
            >
              Telefon doğrulaması
            </span>
          </Col>
        </Row>
        <Row className="my-2 mb-4">
          <Col>
            <span className="d-flex justify-content-center align-items-center text-center">
              Lütfen telefon numaranıza gönderilen <br /> 6 haneli kodu giriniz.
            </span>
          </Col>
        </Row>
        <Row className="my-2 mb-4">
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <OTPInput
              value={code}
              onChange={handleChange}
              numInputs={6}
              renderInput={props => <input {...props} />}
              inputStyle={otpInputStyle}
              inputType="text"
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col className="d-flex flex-column justify-content-center align-items-center">
            {error && <span style={{ color: "#FF0000" }}>{error}</span>}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            {code ? (
              <Button style={{ width: "265px" }} color="success" onClick={handleSubmit}>
                Onayla
              </Button>
            ) : (<Button
              color={sent ? "secondary" : "primary"}
              disabled={sent}
              style={{ width: "265px" }}
              onClick={() => {
                sendSmsMutation.mutate()
              }}
            >
              {sent ? (
                <Countdown
                  date={Date.now() + remainingTime}
                  renderer={({ minutes, seconds }) => {
                    if (sent) {
                      const paddedMinutes = String(minutes).padStart(2, "0")
                      const paddedSeconds = String(seconds).padStart(2, "0")
                      return (
                        <span>
                          {paddedMinutes}:{paddedSeconds}
                        </span>
                      )
                    } else {
                      return <span>SMS Gönder</span>
                    }
                  }}
                  zeroPadTime={2}
                  onComplete={() => {
                    setSent(false)
                    setCode("")
                  }}
                />
              ) : (
                "SMS Gönder"
              )}
            </Button>)}
          </Col>
        </Row>
      </ModalBody>

      
    </Modal>
  )
}

export default PhoneVerificationPopup
