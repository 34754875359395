import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useQuery, useMutation } from "@tanstack/react-query";
import withRouter from "components/Common/withRouter"
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api"
import "./map.css"
import {
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import icon from "../iconButiko/11Asset 12.png"

// ! Harita Popup'ını gösteren ve eylem boylam bilgilerini ayarlamak için bileşen.
const MapModal = ({ modalOpen, setModalOpen, setLatitude, setLongitude }) => {

    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }
    const mapRef = useRef()
    const [position, setPosition] = useState({
        lat: 0,
        lng: 0
    });
    const { isLoaded } = useLoadScript({
        // googleMapsApiKey: "AIzaSyCbWOArVUIn-uRQ8S3fsvayHrep5El4ab4",
        googleMapsApiKey: "AIzaSyBv0r0C6360TDA3uM9iaPwQrTl4z8inMvw",
    })
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });

                },
                error => {
                    console.error("Error getting user's location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);
    const [center, setCenter] = useState({ lat: 41.0082, lng: 28.9784 })
    const option = useMemo(() => ({
        disableDefaultUI: true,
        clickableIcons: false,
    }), [])

    const onLoad = useCallback(map => (mapRef.current = map), [])
    const handleDBClick = (e) => {
        const LAT = e.latLng.lat()
        const LNG = e.latLng.lng()
        const pan = new google.maps.LatLng(LAT, LNG)
        mapRef.current.panTo(pan);

        setPosition({ lat: LAT, lng: LNG })
        // console.log(position);
    }

    const handleSaveClick = () => {
        setLatitude(position.lat);
        setLongitude(position.lng);
        setCenter({ lat: position.lat, lng: position.lng })
        toggleModal();
    }

    if (!isLoaded) return <div>Yükleniyor...</div>


    return (
        <>
            <Container fluid={true}>
                <Modal
                    style={{
                        maxWidth: "100rem",
                        maxHeight: "1000px",
                        marginTop: "80px",
                    }}
                    isOpen={modalOpen}
                    toggle={toggleModal}
                >
                    <ModalHeader toggle={toggleModal}>Konum Seçiniz</ModalHeader>
                    <ModalBody className="mx-3 my-3">
                        <div>
                            {/* map */}
                            <GoogleMap

                                zoom={14}
                                center={center}
                                mapContainerClassName='map-container'
                                options={option}
                                onLoad={onLoad}
                                onClick={handleDBClick}
                            >
                                {position && <Marker position={position} icon={icon} animation={google.maps.Animation.BOUNCE} />}



                            </GoogleMap>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleModal}>Kapat</Button>
                        <Button color="primary" onClick={handleSaveClick}>
                            Kaydet
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    )
}

export default withRouter(MapModal)
