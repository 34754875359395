import React, { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios"
import { useNavigate } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useQuery } from "@tanstack/react-query";
// users
import user1 from "../../../assets/images/order/avatar-1.jpg";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
// import { RiArrowDropUpLine } from "react-icons/ri";


const ProfileMenu = props => {
  const history = useNavigate()

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [profilePic, setProfilePic] = useState("");
  const getInfo = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { isLoading, isError, error, data } = useQuery({ queryKey: ["get-info"], queryFn: getInfo })
  useMemo(() => {
    setusername(data?.data?.shop_name)
    setProfilePic(data?.data?.profile_picture)
  }
    , [data])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  const logOut = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`, { withCredentials: true })
      console.log(res.data);
      history("/login")
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePic ? profilePic : user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {username} {!menu ? <RiArrowDropDownLine size={24} /> : <RiArrowDropUpLine size={24} />
}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem >

            <Link to="/profile">

              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </Link>
          </DropdownItem>
          <DropdownItem tag="a" href="/tokens">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <a onClick={logOut} to="/login" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
