import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Card, Form, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DebtForm from '../DebtForm';
import useGetCards from '../hooks/useGetCards';
import Spinner from 'react-bootstrap/Spinner';

const formatCardNumber = (number) => {
    return `**** **** **** ${number.slice(-4)}`;
};

function CardModal({ show, setShow, transactionId }) {
    const history = useNavigate()
    const [selectedCard, setSelectedCard] = useState(null);
    const [cc_owner, set_cc_owner] = useState("");
    const [card_number, set_card_number] = useState("");
    const [expiry_month, set_expiry_month] = useState("");
    const [expiry_year, set_expiry_year] = useState("");
    const [cvv, set_cvv] = useState("");
    const handleCardSelect = (cardId, card_owner_name, card_number, expiry_month, expiry_year, cvc) => {
        setSelectedCard(cardId);
        set_cc_owner(card_owner_name)
        set_card_number(card_number)
        set_expiry_month(expiry_month)
        set_expiry_year(expiry_year)
        set_cvv(cvc)
    };
    const { cards, isLoading, isError, error, refetch } = useGetCards()
    const handleClose = () => setShow(false);
    return (
        <>
            <Modal contentClassName={{ width: "500vw" }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Borç Ödemek</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="mt-4">
                        <h2 className="mb-4">Kredi Kartı Seçin</h2>
                        {isLoading ?
                            (<>
                                <Spinner animation="border" />
                            </>) :
                            isError ? (
                                <>
                                    <Card>
                                        <Card.Body>
                                            Kartlar Alınırken Bir Hata Oluştu
                                        </Card.Body>
                                    </Card>
                                </>
                            ) :
                                Array.isArray(cards) && cards.map((card) => (
                                    <Card
                                        key={card.detail_id}
                                        className={`mb-3 ${selectedCard === card.detail_id ? 'border-primary' : ''}`}
                                        onClick={() =>
                                            handleCardSelect(
                                                card.detail_id,
                                                card.card_owner_name,
                                                card.card_number,
                                                card.expiry_month,
                                                card.expiry_year,
                                                card.cvc
                                            )}
                                    >
                                        <Card.Body>
                                            <Form.Check
                                                type="radio"
                                                id={`card-${card.detail_id}`}
                                                checked={selectedCard === card.detail_id}
                                                onChange={() => {
                                                    handleCardSelect(
                                                        card.detail_id,
                                                        card.card_owner_name,
                                                        card.card_number,
                                                        card.expiry_month,
                                                        card.expiry_year,
                                                        card.cvc
                                                    )
                                                }}
                                                label={
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <strong>{card.card_owner_name}</strong>
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            {formatCardNumber(card.card_number)}
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            Son kullanma tarihi: {card.expiry_month}/{card.expiry_year}
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                        </Card.Body>
                                    </Card>
                                ))}
                        {Array.isArray(cards) && cards.length === 0 && (
                            <>
                                <Card
                                    className="mb-3 border-primary"
                                    onClick={() => { }}
                                >
                                    <Card.Body>
                                        <Card.Text>
                                            Kartınız Yok Lütfen Cüzdandan Ekleyin
                                        </Card.Text>
                                        <Button onClick={() => history("/tokens")} variant="primary">Cüzdana Git</Button>
                                    </Card.Body>
                                </Card>
                            </>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                    
                    <DebtForm
                        id={transactionId}
                        cc_owner={cc_owner}
                        card_number={card_number}
                        expiry_month={expiry_month}
                        expiry_year={expiry_year}
                        cvv={cvv}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CardModal;