// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const firebaseConfig = {//from mortezas code
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
};
//-------this is from my console


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {

    const permission = await Notification.requestPermission()
    // console.log(permission);
    if (permission === "granted") {



        const token = await getToken(
            messaging,
            { vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY }
        );
        // console.log(token);
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/users/save-device-token`
        const finalData = {
            device_token: token
        };

        try {
            const response = await axios.put(finalUrl, finalData, { withCredentials: true });
            // console.log("success", response.data);
            // Handle success - you might want to update some state here
        } catch (err) {
            console.log(err);
            // setError(err.message || 'An error occurred');
        } finally {
            // setIsLoading(false);
        }

    }

}