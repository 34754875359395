import React from "react"
import "./style.css"
const ContractPrint = ({ companyAddress, companyName }) => {
    return (
        <>
            <div className="contract-text">
                <h1 className="text-xl font-bold mb-4">SATICI İŞ ORTAKLIĞI ve İLAN SÖZLEŞMESİ</h1>

                <section className="text-start ">
                    <h2 className="text-lg font-semibold mb-2">1. TARAFLAR</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 1.1.</strong> İşbu satıcı iş ortaklığı ve ilan sözleşmesi ("Sözleşme") merkezi "Küçükbakkalköy Mah. Selvili̇ Sk. Canan Business Plaza. No: 4/20 Ataşehir/İstanbul" adresinde bulunan BUTİKO BİLGİ TEKNOLOJİ PAZARLAMA TİCARET ANONİM ŞİRKETİ ("BUTİKO") ile merkezi {companyAddress} adresinde bulunan {companyName} ("Satıcı") arasında akdedilmiştir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 1.2.</strong> BUTİKO ve Satıcı işbu Sözleşme'mde münferiden "Taraf" ve müştereken "Taraflar" olarak anılacaklardır.
                    </p>
                </section>

                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">2. SÖZLEŞME'NİN AMACI VE KAPSAMI</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 2.1.</strong> BUTİKO, butiko.com.tr alan adlı çevrimiçi elektronik ticaret platformunu, mobil uygulamalarını ve mobil web sitesini işletmekte olup tedarikçiler, aracılar, servis sağlayıcılar, üreticiler, tüketiciler, amacı ticari olan veya olmayan kurumlar arasında iletişimi ve iş süreçlerini hızlandıran, verimliliği artıran, finansal kaynaklardan tasarruf edilmesini sağlayan sanal bir pazaryerinde alışveriş yapma amacıyla tarafları bir araya getirmektedir. BUTİKO, Web Sitesi'nde yer alan herhangi bir ürün veya hizmetin satıcısı konumunda değildir ve 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca aracı hizmet sağlayıcıdır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 2.2.</strong> İşbu Sözleşme uyarınca Satıcı, BUTİKO tarafından yönetilmekte olan butiko.com.tr alan adlı elektronik ticaret platformuna üye olmak ve bu platformda kuracağı Sanal Mağara’dan satış yapmak, BUTİKO ise Satıcının kendisine ödeyeceği komisyon karşılığında sahibi olduğu elektronik ticaret platformu hizmetlerinden satıcıyı faydalandırmak istemektedir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 2.3.</strong> İşbu Sözleşme’nin amacını, Web sitesi üzerinde BUTİKO'ya da BUTİKO'nun belirlediği iş ortağı tarafından sunulan Hizmetler’den, Satıcıların yararlanmasına ilişkin şartların belirlenmesi ve bu doğrultuda Tarafların hak ve yükümlülüklerinin tespiti oluşturmaktadır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 2.4.</strong> Şüpheye mahal vermemek adına, işbu Sözleşme, yalnızca Taraflar arasında olup, BUTİKO tarafından Satıcı ’ya sunulan Hizmetler’e yönelik şekil ve şartları kapsamaktadır. Satıcılar ile Alıcılar arasındaki ilişki işbu Sözleşme’nin kapsamına girmemektedir; BUTİKO işbu ticari faaliyette sadece, Satıcı’ya Sanal Mağaza açma ve bu Sanal Mağaza’nın bulunduğu alanda ilan yayınlama hakkı vermektedir. Satıcı, Sanal Mağaza’dan gerçekleştireceği satış işlemlerinden, Alıcılara karşı 6502 sayılı Tüketicinin Korunması Hakkında Kanun başta olmak üzere sair mevzuat çerçevesinde bizzat sorumlu olacaktır.
                    </p>
                </section>
                <section className="mt-4">
                    <h2 className="text-lg font-semibold mb-2">3. TANIMLAR</h2>
                    <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg overflow-hidden">
                        <tbody>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Alıcı</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    BUTİKO ile yaptığı Üyelik Sözleşmesi kapsamında Web Sitesi'ne üye olan ve Satıcı tarafından Web sitesi üzerinde verilen ilanlarla satışa arz edilen ürün ve/veya hizmetleri satın alan üyeyi ifade eder.
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Hizmet</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-21">
                                    Alıcı ve Satıcıların işbu Sözleşme içerisinde tanımlı olan iş ve işlemlerini gerçekleştirmeleri ve ürün/hizmetlerini yayınlamaları amacıyla BUTİKO tarafından ücret karşılığında veya ücretsiz olarak Web Sitesi’nde sunulan ilan, yayınlama ve benzeri uygulamaları ifade eder.
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Kullanıcı</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Web Sitesi’ne erişimde bulunan tüm gerçek ve tüzel kişileri ifade eder.
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Sanal Mağaza</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    BUTİKO'nun Web sitesi üzerinde BUTİKO prosedür ve kurallarına uygun olarak Satıcılara tahsis etmiş olduğu ve Satıcıların bir ya da birden fazla ürün ve/veya hizmetin satışına yönelik içerik ve görsellerden oluşan ilanlarını yayınlayabilme imkânına sahip oldukları sanal alanı ifade eder.
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Satıcı</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    BUTİKO ile yaptığı işbu Sözleşme kapsamında Web Sitesi’ne üye olan ve Web sitesi üzerinde oluşturduğu hesap üzerinden yayınladığı ilanlar aracılığıyla çeşitli ürün ve/veya hizmetleri satışa arz eden tüzel/gerçek kişi üyeyi ifade eder.
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Satıcı Ofisi</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Satıcının, BUTİKO tarafından Web Sitesi’nde sunulan uygulamalardan ve Hizmetler’den yararlanabilmesi için gerekli işlemleri gerçekleştirebildiği, kendisinden talep edilen firma/kişisel bilgilerini girdiği, satış işlem detaylarını takip edebildiği, sadece Satıcı tarafından belirlenen kullanıcı adı ve şifre ile erişebildiği kendisine özel sanal sayfayı ifade eder.
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Web sitesi</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Mülkiyeti BUTİKO'ya ait olan ve BUTİKO'nun işbu Sözleşme ile belirlenen Hizmetler’i üzerinde sunmakta olduğu butiko.com.tr alan adına sahip internet sitesini, mobil uygulamalarını ve mobil siteyi ifade eder.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2 text-start">4. SATICI’NIN HAK VE YÜKÜMLÜLÜKLERİ</h2>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.1.</strong> Satıcı, “satıcı” sıfatını kazanabilmek için, öncelikle EK-1’de bulunan “Sanal Mağaza Açılışı İçin Gerekli Belge Listesi’nde yer alan belgeleri işbu Sözleşme’nin elektronik ortamda onaylanması ve Satıcı Ofisi’ne yüklenmesi sırasında tam ve eksiksiz olarak temin edecektir. Satıcı tarafından işbu belgeler eksiksiz olarak sunulmakla birlikte, BUTİKO'nun Satıcının başvurusunu sebep göstermeksizin reddetme ve/veya ek belge talep etme hakkı saklıdır. Başvurusu kabul edilen Satıcı, Web Sitesi’nde bulunan üyelik formunu, eksiksiz ve gerçeğe uygun şekilde dolduracaktır. Başvuru sırasında Satıcı tarafından gerçeğe uygun olmayan yanlış bilgiler verilmesi nedeniyle doğabilecek tüm zararlardan bizzat Satıcı sorumlu olacaktır. Satıcı, şirketi adına işlem yapmaya yetkili kişileri kayıt esnasında ilgili formda bildirecek ve bu kişilerin yapacağı her türlü işlem BUTİKO nezdinde satıcıyı temsilen yapılmış kabul edilecektir.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.2.</strong> Satıcı, Satıcı’ya ve yetkili temsilcisine ait bilgiler başta olmak üzere temin etmiş olduğu belge ve bilgilerde meydana gelebilecek her türlü değişikliği, değişikliğin meydana gelmesinden itibaren en geç 15 (on beş) gün içinde, BUTİKO'ya bildirmekle ve sistemdeki kaydını buna uygun olarak güncellemekle yükümlüdür.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.3.</strong> Satıcı, Websitesi’ne üye olduğu esnada Websitesi’nde ilan edilen kurallara ve ilgili iş içeriğine göre bir kullanıcı adı ve şifre oluşturacak; Sanal Mağaza’sına, profil sayfasına erişmek ve diğer bazı işlemleri gerçekleştirebilmek için bu bilgileri kullanacaktır. Satıcı’nın şifre ve kullanıcı adının güvenliği ve gizliliği tamamen Satıcı’nın sorumluluğundadır, bu nedenle de kendi profili üzerinden giriş yapılarak gerçekleştirilen tüm işlemlerden bizzat ve münhasıran sorumludur.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.4.</strong> Satıcı, her türlü reklam ve ilanın Websitesi’nde yayınlanmasını sağlayacak gerekli teknik altyapının oluşturulması için BUTİKO ile tam işbirliği içinde olmayı ve bunun için gerekli her türlü belgeyi temin etmeyi kabul ve taahhüt eder.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.5.</strong> Satıcı, 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca, kişisel verilerin hukuka aykırı olarak işlenmesini ve kişisel verilere hukuka aykırı olarak erişilmesini önlemek ve kişisel verilerin muhafazasını sağlamak amacıyla, uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbiri almak zorundadır ve bu önlemlerin alınması hususunda sorumludur. Satıcı ayrıca, Websitesi’nde ve Sanal Mağaza’da yayınladığı ilanlara yönelik her türlü görsel, yazılı ve sair sağlanan bilgi ve içeriklerin gerçekliğinden, güvenilirliğinden, doğruluğundan ve hukuka uygunluğundan bizzat sorumludur. Ek olarak, Satıcı, Websitesi’ni kendi veya üçüncü bir kişi adına, bir veri tabanı, kayıt veya rehber yaratmak, kontrol etmek, güncellemek, değiştirmek ve diğer üyelerin kişisel bilgilerine erişmek ve başka bir sisteme indirmek amacıyla kullanmamayı kabul beyan ve taahhüt eder. Satıcı, bu madde hükmünü ihlal etmesi halinde Sözleşme’nin Madde 7.5 ’inin uygulanacağını kabul eder. Satıcı Websitesi’nde ve Sanal Mağaza’da yayınladığı ilanlara yönelik her türlü görsel, yazılı ve sair sağlanan bilgi ve içeriklerin üçüncü kişilerin fikri mülkiyet haklarını ihlal etmesi halinde üçüncü kişilere karşı ve BUTİKO'ya karşı sorumludur.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.6.</strong> Satıcı EK-4’te bulunan "Satış ve İlan Koşulları"nda belirtilen tüm hüküm ve koşulların Sözleşme’nin esaslı unsurlarından olduğunu ve bunlara uygun faaliyet gösterdiğini ve göstereceğini kabul ve taahhüt eder.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.7.</strong> Satıcı, Websitesi’ne kendisi tarafından iletilmiş olan her türlü ilan, içerik ve görsel üzerinde her daim hak sahibi olmaya devam edecektir. Bununla birlikte Satıcı, söz konusu içeriğin, reklam ve tanıtım için hazırlanan ekran görüntüleri ve her türlü görsel ve içerikte kullanılmasına (örneğin, Sanal Mağazası’nın butiko.com.tr sitesinin reklamında ve dijital pazarlama faaliyetlerinde anlık olarak görünmesi) yönelik olarak gerekli her türlü hak ve yetkiyi, süre ve yer kısıtlaması olmaksızın ve bilabedel BUTİKO'ya tahsis ettiği kabul, taahhüt ve beyan eder.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.8.</strong> Satıcı, kendisiyle Websitesi üzerinden iletişime geçen Alıcılar’ı Websitesi dışında alışveriş yapmaya teşvik etmeyecek ve kendisine bu yönde gelen teklifleri de reddedecektir. Satıcı, hiçbir surette BUTİKO kampanya ve indirimlerini manipüle eden davranışlarda bulunmayacaktır.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.9.</strong> Satıcı, Alıcı’yla yalnızca Websitesi üzerinden sözleşme akdedecek ve sadece Websitesi üzerinden söz konusu ticari faaliyette bulunacaktır. Satıcı, Alıcı’nın Websitesi’nden ileteceği, kendisiyle her ne surette olursa olsun Websitesi dışında iletişime geçme ve/veya Alıcı’nın Websitesi dışında satın alma isteğine olumlu cevap vermeyecek, her ne surette olursa olsun Alıcılar’la Websitesi dışında herhangi bir hukuki ilişki kurmayacak, herhangi bir satış işlemi gerçekleştirmeyecektir. Satıcı BUTİKO'nun bilgisi olmaksızın her ne surette olursa olsun Alıcı’larla iletişime geçmeyecektir.
                    </p>

                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 4.10.</strong> Satıcı, Ödeme Koruma Sistemi’ne uygun davranmayı taahhüt eder. Ödeme Koruma Sistemi’ne göre Alıcı ürünü satın aldıktan sonra, ürün bedeli BUTİKO havuz hesabına aktarılır. Satıcı, ürünü belirttiği süre içerisinde Alıcı’ya ulaştırdıktan sonra, Alıcı ürünü inceleyerek siparişe onay verir. Bu onay ile ödeme BUTİKO havuz hesabından Satıcı’nın hesabına aktarılır. Satıcı, Websitesi’nde Ödeme Koruma Sistemi’ne ilişkin tüm hüküm ve şartlarına erişim sağlayabileceğini ve bunları anladığını kabul eder ve BUTİKO'yu söz konusu işlemleri gerçekleştirebilmesi için yetkilendirir. Dolayısıyla, ürün bedeli satın alma anında Satıcı adına BUTİKO tarafından Alıcı’dan tahsil edilecek ve Satıcı işbu Sözleşme’de belirtilen edimlerini tam ve gereği gibi yerine getirmesi koşuluna bağlı olarak Hizmetler’den faydalanabilecek ve Websitesi üzerinden gerçekleştireceği satış işlemi karşılığındaki ürün bedeline hak kazanacaktır.
                    </p>
                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2 text-start">5. BUTİKO'NUN HAK VE YÜKÜMLÜLÜKLERİ</h2>
                    <p className="mb-2">
                        <strong className="d fs-5 text-start"> 5.1.</strong> BUTİKO, Satıcı tarafından üyelik işlemi esnasında ve üyelik boyunca temin edilen şahsi ve ticari bilgileri güvenli bir ortamda saklayacaktır. BUTİKO, bu bilgileri Hizmetler’in tam ve gereği gibi işletilmesi için gereken uygulamaların yürütülmesi, istatistiki değerlendirmelerin yapılması amacıyla kendisinin ve iş ortaklarının faaliyetlerinin, uygulamalarının reklamı, pazarlanması, ilanı, kredibilite sorgusu ve sair amaçlarla, kullanabilir ve belirtilen sebeplerle sınırlı olmak kaydıyla üçüncü kişilere açıklanabilir. Bu anlamda Satıcı, kendine ait bilgilerin BUTİKO tarafından işbu madde hükmüne göre kullanılıp saklanmasına rıza gösterecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5 text-start"> 5.2.</strong> BUTİKO, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve 6563 sayılı Elektronik Ticaret Düzenlenmesi Hakkında Kanun kapsamında mesafeli sözleşme kurulmasına aracılık eden sıfatına ve Aracı Hizmet Sağlayıcı niteliğine sahiptir. Bu sebeple de BUTİKO'nun, Satıcı tarafından Websitesi’nde yayınlanan içerikleri kontrol etme yükümlülüğü bulunmamaktadır. BUTİKO, Websitesi üzerinden gerçekleştirilen mesajlaşmalar da dâhil olmak üzere her türlü içeriği kontrol etmek ya da bu içerik ve içeriğe konu ürün veya hizmetle ilgili hukuka aykırı bir faaliyetin veya durumun söz konusu olup olmadığını araştırmakla yükümlü olmamakla birlikte, söz konusu içerikleri dileği zaman kontrol etme ve gerekli görürse erişime kapatma ve silme hakkına sahiptir. BUTİKO ayrıca, mahkeme ve hakem heyeti de dahil olmak üzere herhangi bir yargı makamının kararını yerine getirmek için ve bu kapsamla sınırlı olmak üzere söz konusu içerikler üzerinde işlem gerçekleştirebilecektir.
                    </p>
                    <p className="mb-2 text-start">
                        <strong className="d fs-5"> 5.3.</strong> BUTİKO'nun Satıcı’nın Websitesi’nde yayınladığı herhangi bir içeriğin yürürlükteki mevzuata aykırı olduğunu tespit etmesi halinde bu içeriği derhal kaldırma hakkı her zaman saklıdır. Böyle bir durumda Satıcı her ne isim altında olursa olsun BUTİKO'dan herhangi bir tazminat talep edemez.
                    </p>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">6. MALİ HUSUSLAR</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 6.1.</strong> Satıcı, işbu Sözleşme’de belirtilen edimlerini tam ve gereği gibi yerine getirmesi koşuluna bağlı olarak Hizmetler’den faydalanabilecek ve Websitesi üzerinden gerçekleştireceği satış işlemleri karşılığındaki ücrete hak kazanacaktır. BUTİKO tarafından Satıcı’ya uygulanacak olan komisyon oranları, ödeme koşulları ve sair mali şartları EK-3’te yer alan "Finansal ve Özel Çalışma Şartları"nda belirlendiği şekilde uygulanacaktır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 6.2.</strong> Satıcı 213 Sayılı Vergi Usul Kanunu Madde 230 ve 3065 sayılı Katma Değer Vergisi Kanunu Madde 8 uyarınca satılan ürün ve hizmetle ilgili fatura veya perakende satış fişi düzenlemek zorundadır. Bu anlamda Satıcı, Alıcı’ya gönderdiği ürün ve/veya hizmeti fatura keserek teslim etmeyi kabul ve taahhüt eder.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 6.3.</strong> İşbu Sözleşme’nin Madde 7.5’i uyarınca, BUTİKO, maruz kaldığı her türlü zararı, kaybı, masrafı, gideri veya üçüncü kişilerin taleplerini takdiri tamamen kendisinde olmak üzere Satıcı’nın cari hesabından mahsup edebilir veya Satıcı’dan bizzat tahsil edebilir.
                    </p>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">7. SÖZLEŞME’NİN SÜRESİ, FESHİ VE TAZMİNAT</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.1.</strong> İşbu Sözleşme, Satıcı tarafından elektronik ortamda onaylanmakla yürürlüğe girecek olup, yürürlük tarihinden itibaren belirsiz süreli olarak geçerlidir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.2.</strong> Taraflar, herhangi bir sebebe dayanmaksızın ve tazminat ödeme yükümlülüğü altında bulunmaksızın, yazılı olarak bildirimde bulunmak kaydıyla işbu Sözleşme’yi her zaman feshedebilir. Sözleşme’nin BUTİKO tarafından bu suretle feshedilmesi halinde Satıcı, fesih tarihine kadar BUTİKO tarafından sunulan hizmete ilişkin bedelleri ödemeyi kabul ve taahhüt eder. Bu fesih yalnızca Taraflar’ın ilişkisini etkileyecek; Satıcı’nın Sanal Mağaza’sı üzerinden yapmış olduğu satışlara yönelik olarak Alıcılar’a karşı mevcut olabilecek tüm sorumlulukları devam edecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.3.</strong> Taraflar’dan birinin iflas etmesi, iflasın ertelenmesi talebinde bulunması, acze düşmesi, konkordato teklifinde bulunması, kayyum atanması, tasfiyeye girmesi veya hakkında fesih davası açılması hallerinde, Sözleşme kendiliğinden sona erecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.4.</strong> Taraflar işbu Sözleşme’nin EK-4’ünde yer alan hüküm ve şartların her birinin esaslı olduğunu ve bu hüküm ve şartlara aykırılık halinde BUTİKO'nun Sözleşme’yi derhal fesih hakkı bulunduğunu kabul eder. Bunların yanında BUTİKO Sözleşme’yi feshetme hakkını kullanmasa dahi, söz konusu aykırılık hallerinde BUTİKO Hizmetler’i geçici olarak durdurma ve askıya alma hakkına her zaman sahip olacaktır. Bu durumlarda Satıcı’nın, Alıcı’ya karşı her türlü sorumluluğu devam edecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.5.</strong>Satıcı, işbu Sözleşme’nin hükümlerinden birini ihlal etmesi veya Satıcı Ofisi’nde ilan edilen satış kurallarına uyulmaması sebebiyle BUTİKO'nun doğrudan veya dolaylı olarak uğramış olduğu her türlü hukuki/idari/cezai zararı, kaybı, masrafı, gideri ve üçüncü kişilerin taleplerini BUTİKO'nun herhangi bir makama başvurmasına gerek olmaksızın ve ilk talebi üzerine, tamamen ve derhal tazmin etmekle yükümlüdür. Şüpheye mahal bırakmamak adına, BUTİKO'nun söz konusu hakkını kullanması BUTİKO'nun Sözleşme’yi feshedip feshetmemesinden bağımsızdır ve BUTİKO Sözleşme’yi feshetmeksizin söz konusu tazminata hak kazanır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.6.</strong> Satıcı, BUTİKO aleyhine dava, tüketici şikayeti, savcılık soruşturması ve icra takibi de dahil olmak üzere herhangi bir yasal takibin başlatılması veya bankalardan, bakanlıklardan, adli veya idari makamlardan, emniyet müdürlüklerinden ve diğer tüm sair özel kuruluş ya da kamu kuruluşları tarafından herhangi bir bilgi veya belgenin talep edilmesi halinde BUTİKO'nun kendisini savunması veya talep edilen bilgi ya da belgeleri sunması için gereken tüm bilgi ve belgeleri derhal temin edecektir. Satıcı tarafından, talep edilen süre içinde BUTİKO'ya temin edilmeyen bilgi ve belgeler nedeniyle doğacak her türlü zarar ve/veya ceza, Satıcı’ya yansıtılacaktır. Ayrıca, bu tutarlar, Satıcı’ya ait tüm mağazalardan tahsil edilebilecektir. Ayrıca, BUTİKO'ya herhangi bir icra dairesinden ya da benzeri bir makamdan Satıcı aleyhine düzenlenmiş bir haciz ihbarnamesi tebliğ edilmesi, BUTİKO'nun Satıcı’nın dolandırıcılık ya da sahtecilik teşkil edebilecek bir davranışının söz konusu olabileceğine veya Sanal Mağaza’da sahte ürün satışının yapıldığına ilişkin makul bir şüphe duyması veya Alıcılar’ın ya da üçüncü bir kişinin mağduriyetini önlemek ya da azaltmak amacını taşıyan diğer makul sebeplerle, BUTİKO, Satıcı’nın BUTİKO hesabındaki bakiyeyi, haciz ihbarnamesinin mevcut olması halinde belirtilen miktarla sınırlı olmak üzere; diğer hallerde ise söz konusu ihlal ya da mağduriyeti karşılayabilecek makul bir miktarı 120 (yüz yirmi) günlük bir süre için ve Satıcı’ya haber vermeksizin bloke edebilir.
                    </p>
                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">8. SÖZLEŞME DEĞİŞİKLİKLERİ VE BİLDİRİMLER</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 8.1.</strong> Satıcı, işbu Sözleşme’yi elektronik ortamda onaylayarak, Sözleşme’ye taraf ve Sözleşme’nin hükümleriyle bağlı olacaktır. BUTİKO gerekli olduğuna kanaat getirdiği takdirde işbu Sözleşme’nin hüküm ve şartlarını tek taraflı olarak uygun göreceği herhangi bir zamanda değiştirme hakkına sahiptir. Şüpheye mahal vermemek adına, BUTİKO belirli hizmetlerin kısmen ya da tamamen ücretli hale gelmesine ilişkin olanlar da dahil olmak üzere Sözleşme’ye eklemeler yapma hakkına da sahiptir.
                    </p>
                    <p className="mb-2 font-semibold"><strong className="d fs-5"> 8.2.</strong> Söz konusu Sözleşme değişiklikleri BUTİKO tarafından:</p>
                    <ul className="list-none pl-6 mb-4">
                        <li className="mb-2">
                            <span className="font-medium">(i)</span> Satıcı'nın BUTİKO'ya Websitesi'ne üye olurken bildirdiği adrese e-posta gönderilmesi,
                        </li>
                        <li className="mb-2">
                            <span className="font-medium">(ii)</span> Satıcı Ofisi ekranında duyurulması veya
                        </li>
                        <li className="mb-2">
                            <span className="font-medium">(iii)</span> Websitesi'nde yayınlanması
                        </li>
                    </ul>
                    <p>
                        yoluyla yapılabilir. İşbu Sözleşme'nin değişen hükümleri, ilan edildikleri tarihte geçerlilik kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 8.3.</strong> Şüpheye mahal vermemek adına, BUTİKO Sözleşme’nin imza tarihinden sonra meydana gelen mevzuat değişiklikleri gereği yapmak zorunda olduğu Sözleşme değişikliklerini Satıcı’ya bildirmekle yükümlü değildir. Ayrıca Websitesi üzerinde sunulacak olan Hizmetler’in ve BUTİKO tarafından uygulanan kampanyaların detayları, ürünlerin sergilenme süreleri, ek imkânlar ve teknik özellikler BUTİKO tarafından önceden habersiz olarak değiştirilebilecektir. BUTİKO tarafından yapılan değişiklikler yayınlanma tarihinde yürürlüğe girecek ve bağlayıcı olacaktır, Satıcı işbu değişikleri Websitesi’nden takip etmek ve değişikliklerin gereklerini yerine getirmekle yükümlüdür.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 8.4.</strong> Satıcı, BUTİKO tarafından herhangi bir ön bilgilendirme yapılmasını beklemeksizin BUTİKO'nun Websitesi üzerinden duyurduğu ve uyguladığı kampanya ve indirimleri takip etmek, kampanya kapsamına dahil edilen ürünlerle ilgili olarak tüm kampanya koşullarını uygulamak ve tüm kampanya koşullarına vakıf olarak kendisine gelen Alıcı taleplerini kampanyaya uygun olarak yönetmekle yükümlüdür.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 8.5.</strong> Taraflar, Satıcı’nın Websitesi’ne üye olurken bildirdiği e-posta adresi vasıtasıyla veya fiziki posta adreslerine iadeli taahhütlü posta ile gönderilecek bildirimler aracılığıyla iletişim kuracaklardır. Satıcı’nın Websitesi’ne kayıtlı adresine e-posta gönderimi yazılı iletişim olarak kabul edilecek olup, Satıcı’nın e-posta adresini düzenli olarak kontrol etme ve yükümlülüğü olacaktır. Satıcı’nın bildirmiş olduğu adres ve/veya e-posta adreslerinin değişmesi durumunda, değişikliğin yazılı olarak derhal diğer Taraf’a bildirilmediği takdirde son adres ve/veya e-posta adreslerine yapılan bildirimler geçerli ve bağlayıcı kabul edilecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 8.6.</strong> Karşı Taraf’ın temerrüdüne, Sözleşme’nin feshine ve Sözleşme’den dönmeye ilişkin bildirimler hakkında da işbu Sözleşme’nin Madde 8.5’i uygulanacaktır.
                    </p>
                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">9. GİZLİLİK</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 9.1.</strong> Taraflar’ın işbu Sözleşme’nin ifası sırasında birbirleri hakkında edindikleri her türlü ticari, finansal, patent ve know-how, bilgi, buluş, iş, metot, telif hakkı, marka, müşteri bilgileri ve sair tüm bilgiler “Gizli Bilgi” olarak kabul edilecek ve işbu Sözleşme süresince ve Sözleşme’nin sona ermesini takip eden 3 (üç) yıl boyunca Gizli Bilgi olarak değerlendirilecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 9.2.</strong> Taraflar, Gizli bilgileri gizli tutacak ve alıcı Taraf’ın, ifşa eden Taraf’ın önceden yazılı onayını alması veya söz konusu ifşaatın uygulanan kanunlar veya bir mahkeme emri tarafından gerekli kılınması halleri haricinde, herhangi bir üçüncü tarafa ifşa etmeyeceklerdir. BUTİKO'nun Gizli Bilgileri üyeler arasında çıkan uyuşmazlıklarda diğer Kullanıcıların yasal haklarını kullanabilmeleri amacıyla ve sadece bu kapsamlı sınırlı olmak üzere uyuşmazlığa taraf olabilecek diğer Kullanıcılara iletebilme hakkı saklıdır.
                    </p>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">10. FİKRİ MÜLKİYET HAKLARI</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 10.1.</strong> BUTİKO markası ve logosu, BUTİKO mobil uygulamasının ve Websitesi’nin tasarımı, yazılımı, alan adı ve bunlara ilişkin olarak BUTİKO tarafından oluşturulan her türlü marka, tasarım, logo, ticari takdim şekli, slogan ve diğer tüm içeriğin her türlü fikri mülkiyet hakkı ile BUTİKO'nun mülkiyetindedir. Satıcı, BUTİKO'nun veya bağlı şirketlerinin mülkiyetine tabi fikri mülkiyet haklarını BUTİKO'nun izni olmaksızın kullanamaz, paylaşamaz, dağıtamaz, sergileyemez, çoğaltamaz ve bunlardan türemiş çalışmalar yapamaz. Satıcı, BUTİKO mobil uygulamasının ve Websitesi’nin bütünü veya bir kısmı başka bir internet sitesinde izinsiz olarak kullanılamaz.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 10.2.</strong> Satıcı, BUTİKO mobil uygulaması ve Websitesi aracılığıyla gerçekleştirdiği tüm faaliyetlerinde BUTİKO'nun ve üçüncü kişilerin fikri mülkiyet haklarını ihlal etmeyeceğini taahhüt etmektedir. BUTİKO, üçüncü kişilerin veya BUTİKO'nun fikri mülkiyet haklarını ihlal edecek nitelikte faaliyet gösteren Satıcı’nın sözleşmesini feshetme hakkını saklı tutmaktadır. Satıcı, bu madde hükmünün ihlal etmesi halinde Sözleşme’nin Madde 7.5’inin uygulanacağını kabul eder.
                    </p>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">11. MÜCBİR SEBEP</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 11.1.</strong> Ayaklanma, ambargo, devlet müdahalesi, isyan, işgal, savaş, seferberlik, grev, lokavt, iş eylemleri veya boykotlar dahil olmak üzere işçi-işveren anlaşmazlıkları, siber saldırı, iletişim sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları ve bu sebeple meydana gelebilecek arızalar, elektrik kesintisi, yangın, patlama, fırtına, sel, deprem, göç, salgın veya diğer bir doğal felaket veya Taraflar’dan birinin kontrolü dışında gerçekleşen, kusurundan kaynaklanmayan ve makul olarak öngörülemeyecek diğer olaylar ("Mücbir Sebep") Taraflar’dan herhangi birinin ("Etkilenen Taraf") bu Sözleşme’den doğan yükümlülüklerini ifa etmesini engeller veya geciktirirse, Etkilenen Taraf, diğer Taraf’a yazılı olarak derhal bu Mücbir Sebep’i, bununla ilgili tüm detayları ve delilleri ve bu durumun muhtemel süresini bildirmekle yükümlüdür. Etkilenen Taraf Mücbir Sebep ’in sonuçlarını engellemek veya hafifletmek için makul çabayı göstermekle yükümlüdür.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 11.2.</strong> Etkilenen Taraf, bu maddede yer alan yükümlülüklerine uymuş olması kaydıyla, ifası Mücbir Sebep sonucunda engellenen veya geciken yükümlülüklerinden dolayı sorumlu tutulamaz ve bu durum işbu Sözleşme’nin bir ihlali olarak kabul edilmeyerek, ifa için gerekli olan süre, söz konusu gecikmenin süresine eşit bir süre ile uzatılır.
                    </p>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">12. UYGULANACAK HUKUK VE UYUŞMAZLIKLARIN ÇÖZÜMÜ</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 12.1.</strong> İşbu Sözleşme münhasıran Türkiye Cumhuriyeti kanunlarına tabi olacaktır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 12.2.</strong> İşbu Sözleşme ‘den kaynaklanan veya işbu Sözleşme ile bağlantılı olan her türlü ihtilaf, İstanbul Anadolu Mahkemeleri ve İcra Müdürlükleri’nin münhasır yargı yetkisinde olacaktır.
                    </p>

                </section>
                <section className="mt-4 text-start">
                    <h2 className="text-lg font-semibold mb-2">13. MUHTELİF HÜKÜMLER</h2>
                    <p className="mb-2">
                        <strong className="d fs-5"> 13.1.</strong> Delil sözleşmesi. Satıcı, işbu Sözleşme ’den doğabilecek ihtilaflarda Tarafların resmi defter ve ticari kayıtları ile BUTİKO'nun veri tabanında, sunucularında tutulan e-arşiv kayıtlarının, elektronik bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil edeceğini ve bu maddenin 6100 sayılı Hukuk Muhakemeleri Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 13.2.</strong> Tarafların İlişkisi. İşbu Sözleşme’nin hiçbir hükmü, Taraflar veya onların külli veya cüz’i halefleri arasında bir acentelik, bayilik, distribütörlük, ortaklık veya ortak girişim ilişkisi kurulduğu şeklinde yorumlanamaz.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 13.3.</strong> Sözleşme’nin Bütünlüğü. İşbu Sözleşme’nin ekleri, işbu Sözleşme’nin ayrılmaz bir parçasını teşkil eder ve işbu Sözleşmeyle birlikte göz önüne alınır ve yorumlanır. İşbu Sözleşme, Taraflar arasındaki anlaşmanın tamamını oluşturmakta ve işbu Sözleşme’nin konusuna ilişkin olarak Taraflar arasındaki sözlü veya yazılı önceki tüm taslakları, anlaşmaları, düzenlemeleri ve mutabakatın ilga etmektedir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 13.4.</strong> Bölünebilirlik. İşbu Sözleşme’nin herhangi bir hükmünün yetkili herhangi bir mahkeme, tahkim heyeti veya idari makam tarafından tümüyle veya kısmen geçersiz veya uygulanamaz olduğu veya makul olmadığına karar verilmesi halinde, söz konusu geçersizlik, uygulanamazdık veya makul olmama ölçüsünde işbu Sözleşme bölünebilir olarak kabul edilecek ve diğer hükümler tümüyle yürürlükte kalmaya devam edecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 13.5.</strong> Sözleşme’nin Devri. Satıcı, BUTİKO'nun önceden yazılı onayını almaksızın işbu Sözleşme ’deki haklarını veya yükümlülüklerini tümüyle veya kısmen temlik edemeyecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 13.6.</strong> Tadil ve Feragat. Taraflardan birinin Sözleşme’mde kendisine verilen herhangi bir hakkı kullanmaması ya da icra etmemesi, söz konusu haktan feragat ettiği anlamına gelmeyecek veya söz konusu hakkın daha sonra kullanılmasını ya da icra edilmesini engellemeyecektir.
                    </p>
                    <p className="mb-2">
                        13 (on üç) madde ve 4 (dört) ekten ibaret bu Sözleşme, Taraflarca her bir hükmü okunarak, Satıcı tarafından elektronik ortamda onaylanmak suretiyle akdedilerek yürürlüğe girmiştir.
                    </p>
                    {/* <div className="required-documents p-4 bg-gray-100 rounded-lg"> */}
                    <h2 className="text-xl font-bold mb-4">EKLER:</h2>
                    <ul className="list-disc pl-5 mb-6">
                        <li>EK-1: Sanal Mağaza Açılış Gerekli Belge Listesi</li>
                        <li>EK-2: Yasaklı Ürünler Listesi</li>
                        <li>EK-3: Finansal ve Özel Çalışma Şartları</li>
                        <li>EK-4: Satış İlan ve Koşulları</li>
                    </ul>

                    <h3 className="text-lg font-semibold mb-3">EK-1</h3>
                    <h4 className="text-md font-semibold mb-4">Sanal Mağaza Açılışı İçin Gerekli Belge Listesi</h4>

                    {/* <div className="mb-6"> */}
                    <h5 className="font-semibold mb-2">Sermaye şirketi iseniz (Anonim ve Limited):</h5>
                    <ol className="list-decimal pl-5">
                        <li>Vergi levhası fotokopisi</li>
                        <li>Şirket kuruluş ticaret sicil gazetesi fotokopisi</li>
                        <li>İmza sirküleri fotokopisi</li>
                        <li>İlgili ticaret sicilinden alınacak güncel faaliyet belgesinin aslı</li>
                    </ol>
                    {/* </div> */}

                    {/* <div> */}
                    <h5 className="font-semibold mb-2">Şahıs şirketi, tacir veya adi ortaklık iseniz:</h5>
                    <ol className="list-decimal pl-5">
                        <li>Ortaklık sözleşmesi</li>
                        <li>Nüfus cüzdanı fotokopisi</li>
                        <li>Faaliyet belgesi</li>
                        <li>İmza sirküleri</li>
                        <li>Vergi levhası</li>
                    </ol>
                    <p className="mb-2">
                        BUTİKO yukarıda sayılan belgelere ek olarak, ayrıca belge talep edilebilecek ve üyelik kabul kararını nihai olarak verme yetkisine sahip olacaktır.
                    </p>
                    <h3 className="text-lg font-semibold ">EK-2</h3>
                    <h6 className="">Yasaklı Ürünler Listesi</h6>
                    <ol className="list-decimal pl-5">
                        <li>Airbag ve ekipmanları</li>
                        <li>Alım-satımı devlet iznine tabi olan ürünler</li>
                        <li>Alkollü içecekler</li>
                        <li>Askeri teçhizat</li>
                        <li>Ateşli silahlar ve bıçaklar</li>
                        <li>Canlı hayvan</li>
                        <li>Cinsel aktiviteye ilişkin ürünler</li>
                        <li>Çalıntı mallar, seri numarası çıkarılmış ürünler</li>
                        <li>Deneme Boyu / numune (tester) parfüm ve sair kozmetik ürünleri</li>
                        <li>Diğer kurum, kuruluş ve e-ticaret platformlarına ait hediye çeki ya da indirim kuponları</li>
                        <li>Dinleme cihazları</li>
                        <li>Emniyet kemeri adaptörü</li>
                        <li>Hisse senedi, tahvil, bono</li>
                        <li>İlaç testlerini geçmeye yönelik yardımcı maddeler</li>
                        <li>İnsan ve diğer canlı organları</li>
                        <li>Kaçak ve ithalatı yasak ürünler</li>
                        <li>Kişilik hakları</li>
                        <li>Kopya ve bandrolsüz ürünler</li>
                        <li>Kültür ve tabiat varlıkları</li>
                        <li>Maymuncuk ve kilit açıcılar</li>
                        <li>Mucizevi tedavi ürün veya hizmetleri, falcılık ve büyücülük ve sair hizmetler</li>
                        <li>Pornografik içerikli malzemeler</li>
                        <li>Promosyon ürünleri</li>
                        <li>Radar dedektörleri</li>
                        <li>Reçeteli ilaçlar, lensler ve gıda takviyeleri</li>
                        <li>Resmi kıyafetler ve üniformalar</li>
                        <li>Sahte veya replika ürünler</li>
                        <li>Sosyal ağ ve diğer internet üyelik hesapları</li>
                        <li>Şans oyunları biletleri</li>
                        <li>Taşıtların yönetim sistemine müdahale amacıyla kullanılan cihaz ve teçhizat</li>
                        <li>Telif hakkını haiz ürünler</li>
                        <li>Telsiz ve komünikasyon cihazları</li>
                        <li>Toplu elektronik posta adresleri ve listeleri</li>
                        <li>Tütün mamulleri ve elektronik sigaralar</li>
                        <li>TV dekoderleri ve şifreli yayın çözücüler</li>
                        <li>Uyuşturucu maddeler</li>
                        <li>Ürün özelliği taşımayan satışlar</li>
                        <li>Ürün vasfına sahip olmayan listelemeler</li>
                        <li>Web ortamında saldırma ve program kırma yazılımları</li>
                        <li>Yanıcı ve patlayıcı maddeler</li>
                        <li>Yasaklı hayvan türleri</li>
                        <li>Yasaklı yayınlar</li>
                    </ol>
                    <h3 className="text-lg font-semibold mt-5">EK-3</h3>
                    <h6 className="">Finansal ve Özel Çalışma Şartları</h6>
                    <h6 className="">Komisyon Oranları</h6>
                    {/* </div> */}
                    {/* </div> */}
                    <table className="mb-2 mt-3 min-w-full bg-white border border-gray-300 shadow-sm rounded-lg overflow-hidden">
                        <tbody>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Kategori</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Ürün Grubu
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Komisyon Oranı (%)
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Pazarlama Hizmet Bedeli Oranı (%)
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">Kadın Giyim</td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Tekstil
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    5%
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    1%
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <p className="mb-2 mt-3">
                        Satıcı, mücbir sebep halleri dışında ve aksi belirtilmediği sürece ürünü BUTİKO'nun herhangi bir suretle duyurduğu / bildirdiği sürede Alıcı’ya ulaştırılmak üzere kargoya vermek durumundadır. Satıcı’nın ürünleri teslim edememesi halinde sipariş içerisindeki iptal ederek teslim etmediği ürünlerin net satış bedelinin toplamına karşılık gelen aşağıdaki tabloda belirtilen ceza tutarlarını ödeyecektir. BUTİKO işbu madde kararlaştırılan cezai şart tutarını Satıcı’nın herhangi bir alacağından mahsup etme hakkına sahiptir.
                    </p>
                    <table className=" mb-2 mt-3min-w-full bg-white border border-gray-300 shadow-sm rounded-lg overflow-hidden">
                        <tbody>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    Tedarik Edilemeyen Ürünlerin Toplam Tutarı

                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Cezai Şart Tutarı
                                </td>

                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    0 - 99,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    15 TL
                                </td>
                            </tr>

                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    100 - 499,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    25 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    500 - 999,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    50 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    1000 - 1999,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    100 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    2000 - 4999,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    200 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    5000 TL ve üzeri
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    300 TL
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <p className="mb-2 mt-3">
                        Satıcı’nın ürünleri gereği gibi teslim edememesi durumunda (kusurlu, eksik, ayıplı, hasarlı ve benzeri) sipariş içerisindeki gereği gibi teslim etmediği ürünlerin net satış bedelinin toplamına karşılık gelen aşağıdaki tabloda belirtilen ceza tutarlarını ödeyecektir. BUTİKO işbu madde kararlaştırılan cezai şart tutarını Satıcı’nın herhangi bir alacağından mahsup etme hakkına sahiptir.
                    </p>
                    <table className="mb-2 mt-3 min-w-full bg-white border border-gray-300 shadow-sm rounded-lg overflow-hidden">
                        <tbody>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    Gönderi İçerisinde Eksik/Kusurlu Gönderilen Ürünlerin Toplam Tutarı

                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    Cezai Şart Tutarı
                                </td>

                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    0 - 99,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    15 TL
                                </td>
                            </tr>

                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    100 - 499,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    25 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    500 - 999,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    50 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    1000 - 1999,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    100 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    2000 - 4999,99 TL
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    200 TL
                                </td>
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="py-4 px-4 font-semibold text-gray-800 border-1 text-start">
                                    5000 TL ve üzeri
                                </td>
                                <td className="px-4 py-4 text-gray-600 border-1 text-start ms-2">
                                    300 TL
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <h3 className="text-lg font-semibold mt-5">EK-4</h3>
                    <h6 className="">Satış ve İlan Koşulları</h6>
                    <p className="mb-2">
                        <strong className="d fs-5"> 1.</strong> Satıcı, Sanal Mağazası’nda ve ilanlarda sergilediği ürün ve hizmetlerin satışının ve teşhirinin yürürlükteki mevzuat doğrultusunda herhangi bir hukuka aykırılık yaratmayacağını, herhangi aldatıcı, yanlış veya eksik bilgi içermediğini, hak ihlaline sebep olmayacağını, söz konusu ilan ve içeriklerin ilgili olduğu ürün ve hizmetlerin internet üzerinde yayınlanması, satışa arz edilmesi ve satılması için tüm hak, yetki ve sorumluluğun kendisinde olduğunu kabul, beyan ve taahhüt etmektedir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 2.</strong> Satıcı, Sanal Mağaza’sında kesinlikle taklit/sahte, izin gerektiren ve/veya izni olmayan ürün teşhir etmeyecek ve satmayacaktır. Ayrıca Satıcı, EK-2’de yer alan "Yasaklı Ürünler Listesi"nin içeriğini sürekli olarak takip edecek ve bu listede yer alan ürünlerin teşhirini ve satışını yapmayacaktır. Satıcı’nın EK-2’de yer alan ürünlerin teşhirini veya satışını yapması halinde, bu fillerin hukuka aykırılığından dolayı BUTİKO'ya yöneltilebilecek talepler veya üçüncü kişilerin marka hakları dahil olmak üzere fikri ve sınai mülkiyet haklarının ihlallerinden kaynaklanabilecek taleplerin tek muhatabı ve sorumlusu Satıcı olacaktır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 3.</strong> Satıcı, Websitesi üzerinde gerçekleştirdiği ilan ve satış işlemlerinde; işbu Sözleşme’nin hükümlerine, Websitesi’nde belirtilen tüm şart ve koşullara ve yürürlükteki mevzuata, ahlak kurallarına ve kamu düzenine uygun olarak hareket etmeyi taahhüt eder. Satıcı’nın bu hüküm aksine yapacağı tüm işlem ve eylemlerde hukuki ve cezai sorumluluk kendisine ait olacaktır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 4.</strong> Satıcı, BUTİKO'nun önceden yazılı rızası alınmaksızın Websitesi’nde yer alan içeriğin bütününü veya bir kısmını ticari amaçlarla kopyalamayacak, işlemeyecek, kullanmayacak veya umuma arz etmeyecektir. Satıcı ayrıca, BUTİKO'ya veya Alıcılar’a spam veya zincirleme e-posta göndermeyecektir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 5.</strong> Satıcı, Websitesi’nde yürüttüğü tüm faaliyetlerini Websitesi’ne teknik olarak hiçbir surette zarar vermeyecek şekilde yürütmekle yükümlüdür. Satıcı, Websitesi’ne sağlayacağı tüm bilgi, içerik, materyal ve sair içeriğin sisteme zarar verecek her türlü program, virüs, yazılım, lisansız ürün, truva atı, vb. gibi içermemesi için gerekli koruyucu yazılımları ve lisanslı ürünleri kullanmak da dâhil olmak üzere gerekli her türlü tedbiri aldığını, bu madde hükmünün uygulanması amacıyla BUTİKO tarafından talep edilen önlemleri derhal almayı kabul ve taahhüt eder.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 6.</strong> Satıcı, Alıcılar’la Websitesi’nden yapılacak satışlarda akdedilecek mesafeli satış sözleşmelerinde satıcı taraf olduğunu, BUTİKO'nun işbu mesafeli satış sözleşmesi ilişkisinde taraf olmadığını, Alıcılar’a karşı yürürlükteki tüketici hukuku mevzuatı ve sair mevzuat kapsamında her anlamda bizzat sorumlu olduğunu kabul ve beyan eder. Bu bağlamda Satıcı, Sanal Mağazası’nda sergilediği ve sattığı tüm ürünlerin kalitesinden, mevzuata uygunluğundan, garanti belgesi verilmesinden, faturalandırılmasından ve sair diğer gerekli belgenin tesliminden ve satış sonrası gereksinim duyulan servis vs. hizmetlerinden ve ürünlerin süresinde teslim edilmesinden bizzat ve münhasıran sorumlu olacak ve Alıcı istek ve taleplerine mümkün olan en kısa sürede dönüş yapacaktır.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 7.</strong> Satıcı, Sanal Mağaza aracılığıyla Websitesi üzerinde yayınladığı tüm ilan ve içeriklerle bağlıdır ve bunlardan kaynaklanan tüm taahhütlerinden sorumlu olacaktır. BUTİKO, Satıcı’nın faaliyetlerinin işbu Sözleşme koşullarına ve Websitesi’nde beyan edilen politika, kural ve şartlara uymaması, mevzuata aykırılık teşkil etmesi, hukuki, teknik ve özellikle bilgi güvenliği anlamında risk oluşturduğunun tespit edilmesi, üçüncü kişilerin şahsi ve ticari haklarına halel getirici mahiyette olması sebebiyle, Satıcı’ya herhangi bir ön ihtarda bulunmadan ilgili ilanların yayınını geçici veya sürekli olarak durdurabilir, üyeliği askıya alabilir veya üyeliğe son verebilir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 8.</strong> Satıcı, satışa sunduğu ürün ve hizmetleri, başta 5464 sayılı Banka Kartları ve Kredi Kartları Kanunu ve 6502 sayılı Tüketicinin Korunması Hakkında Kanun ile ilgili yönetmelikleri olmak üzere ilgili mevzuat hükümlerini dikkate alarak uygun ve doğru kategoride listelemekle, ürün veya hizmet ile ilgisi olmayan kelimeleri kullanmamakla, mevzuata aykırı ürün içerikleri oluşturmamakla yükümlüdür. BUTİKO tarafından Satıcı’nın herhangi bir ürün veya hizmet hatalı kategorilendirdiğinin veya Mal/Hizmet ile ilgili olarak yanıltıcı bilgi ve/veya içerikler yayınladığının tespit edilmesi halinde, BUTİKO ilgili ürün veya hizmeti Websitesi’nden kaldırma hakkına sahiptir.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 9.</strong> Satıcı, faaliyetlerini iyiniyet ve dürüstlük kurallarına uyarak ve basiretli bir tacir gibi gerçekleştirmeyi, BUTİKO'nun iç işleyişine ve online operasyonlarına zarar veren faaliyetlerden kaçınmayı, bu faaliyetlerle ilgili uyuşmazlıklarda BUTİKO'nun kendisini denetim hakkının bulunduğunu peşinen kabul ve taahhüt eder.
                    </p>
                    <p className="mb-2">
                        <strong className="d fs-5"> 10.</strong> Satıcı, BUTİKO markası başta olmak üzere BUTİKO'nun ve iştiraklerinin sahip olduğu marka ve sair fikri ve sınai mülkiyete konu ürünleri ile veya ticaret unvanlarını Satıcı veya Sanal Mağaza ismi olarak veya üçüncü bir kişinin fikri mülkiyet hakları ve kişilik hakları dahil olmak üzere herhangi bir hakkını ihlal edecek şekilde kullanamaz.
                    </p>
                </section>
            </div>
        </>
    )
}
export default ContractPrint