import React, { useMemo } from "react";
import { useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Label,
    Input
} from "reactstrap";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import AddressInput from "./AddressInput";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Skeleton } from 'antd';
const CompanyInformation = ({ type }) => {
    const [shopNameError, setShopNameError] = useState(false);
    const [ibanError, setIbanError] = useState(false);
    const history = useNavigate();

    function handleCountUpdate() {
        setCount(prevCount => prevCount + 1);
    }

    const getCompanyInfo = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/company-information`, { withCredentials: true })
    }
    const [render, setRender] = useState(false);
    const [editStoreName, setEditStoreName] = useState(false);
    const [storeNameForUpdate, setStoreNameForUpdate] = useState("");

    const [editKepAddress, setEditKepAddress] = useState(false);
    const [editKepAddressForUpdate, setEditKepAddressForUpdate] = useState("");

    const [editBankName, setEditBankName] = useState(false);
    const [editBankNameForUpdate, setEditBankNameForUpdate] = useState("");

    const [editBankBranch, setEditBankBranch] = useState(false);
    const [editBankBranchForUpdate, setEditBankBranchForUpdate] = useState("");

    const [editTel, setEditTel] = useState(false);
    const [editTelForUpdate, setEditTelForUpdate] = useState("");

    const [editBankIban, setEditBankIban] = useState(false);
    const [editBankIbanForUpdate, setEditBankIbanForUpdate] = useState("");

    const [editEmail, setEditEmail] = useState(false);
    const [editEmailForUpdate, setEditEmailForUpdate] = useState("");

    const [count, setCount] = useState(0);


    const updateCompanyInfo = ({ option, value, setFalse }) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/update-company-info`
        setFalse(false)
        const finalData = {
            option: option,
            value: value
        }

        return axios.put(finalUrl, finalData, { withCredentials: true })
    }
    const companyInfoMutation = useMutation({//
        mutationFn: updateCompanyInfo,
        onSuccess: () => {
            refetch()
            toast.success("İşlem Tamamlandı", { toastId: "companyInfoMutation" })
            setRender(!render)
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            } else if (error.response.data.detail[0].msg === "String should have at least 1 characters") {
                toast.error("Giriş en az 1 karakterden oluşmalıdır", { toastId: "lenghtError" })
            } else if (error.response.data.detail[0].msg == "Value error, company_bank_IBAN must match following pattern: TR<24 digit>") {
                toast.error("Iban yanlış", { toastId: "ibanError" })
                setIbanError(true)
            } else if (error.response.data.detail[0].msg == "company_bank_IBAN duplication") {
                setIbanError(true)
                toast.error("Sistemde böyle bir iban var", { toastId: "ibanDuplicationError" })
            } else if (error.response.data.detail[0].msg == "shop_name duplication") {
                setShopNameError(true)
                toast.error("Sistemde böyle bir Mağaza adı var", { toastId: "ibanDuplicationError" })
            } else {

                toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "companyInfoError" })
            }
            console.log(error);
            refetch()
        }
    })
    const { isLoading, isError, data, error, isSuccess, refetch } = useQuery({ queryKey: ['get-company-info', render], queryFn: getCompanyInfo, retry: 2 })
    useMemo(() => {
        setStoreNameForUpdate(data?.data?.shop_name)
        setEditKepAddressForUpdate(data?.data?.company_kep_address)
        setEditBankNameForUpdate(data?.data?.company_bank_name)
        setEditBankBranchForUpdate(data?.data?.company_bank_branch)
        setEditTelForUpdate(data?.data?.company_landline)
        setEditBankIbanForUpdate(data?.data?.company_bank_IBAN)
        setEditEmailForUpdate(data?.data?.company_email)

    }, [data])
    if (isLoading) {
        return <Skeleton />
    } else if (isError) {
        if (error?.response?.data?.detail[0]?.msg === "unathorized action" || error?.response?.data?.detail[0]?.msg === "invalid jwt") {
            history("/")
        }
        if (error?.response?.data?.detail[0]?.msg === "seller is not approved") {

            return (
                <>
                    <h1>Satıcı onaylı değil</h1>
                </>
            )
        } else {
            return (
                <>
                    <h1>hata Oluştu</h1>
                </>
            )
        }
    }

    return (
        <Container>
            <Row>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        <Col xs="12" sm="4">
                            <Label>Şirket Tipi </Label>
                        </Col>
                        <Col>
                            <Input disabled value={data?.data?.company_type.replace(/_/g, ' ') || ''} type="text" placeholder="tipi" />
                        </Col>
                    </Row>
                </Col>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        <Col xs="12" sm="4">
                            <Label>Unvan: </Label>
                        </Col>
                        <Col>
                            <Input disabled value={data?.data?.company_title || ''} type="text" placeholder="unvan" />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {type === "company" ? (
                <div>
                    <Row>
                        <Col md="6" xs="12">
                            <Row className="d-flex align-items-center mb-3">
                                <Col xs="12" sm="4">
                                    <Label>sicil numarası </Label>
                                </Col>
                                <Col>
                                    <Input disabled value={data?.data?.company_registration_no || ''} type="text" placeholder="sicil numarasi" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6" xs="12">
                            <Row className="d-flex align-items-center mb-3">
                                <Col xs="12" sm="4">
                                    <Label>Mersis numarası </Label>
                                </Col>
                                <Col>
                                    <Input disabled value={data?.data?.company_mersis_no || ''} type="text" placeholder="mersis" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            ) : (null)}
            <Row>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">

                        {editStoreName ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Mağaza adı </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8" >
                                    <Input onChange={(e) => {
                                        setStoreNameForUpdate(e.target.value)
                                    }} value={storeNameForUpdate} type="text" placeholder={storeNameForUpdate} />
                                </Col>

                                <Col xxl="12" className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => {

                                        setEditStoreName(false)
                                    }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "shop_name", value: storeNameForUpdate, setFalse: setEditStoreName })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (<>
                            <Col xs="12" sm="4">
                                <Label>Mağaza adı </Label>
                            </Col>
                            <Col>
                                <InputGroup >
                                    <Form.Control
                                        className={`${shopNameError && "border-danger"}`}
                                        placeholder="Mağaza adı"
                                        disabled
                                        value={data?.data?.shop_name || ''}
                                    />
                                    <Button className={`${shopNameError && "border-danger"}`} onClick={() => {
                                        setEditStoreName(true)
                                        setShopNameError(false)
                                        console.log(storeNameForUpdate);
                                    }} variant="outline-secondary" id="button-addon2">
                                        Düzenle
                                    </Button>
                                </InputGroup>
                            </Col></>
                        )}
                    </Row>
                </Col>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        <Col xs="12" sm="4">
                            <Label>vergi dairesi </Label>
                        </Col>
                        <Col>
                            <Input disabled value={data?.data?.company_tax_office || ''} type="text" placeholder="vergi dairesi" />
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Row>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        {editKepAddress ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Kep adresi </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8">
                                    <Input value={editKepAddressForUpdate} onChange={(e) => {
                                        setEditKepAddressForUpdate(e.target.value)
                                    }} type="text" placeholder="kep adresi" />
                                </Col>
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => { setEditKepAddress(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "company_kep_address", value: editKepAddressForUpdate, setFalse: setEditKepAddress })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Kep adresi </Label>
                                </Col>
                                <Col>
                                    <InputGroup className="">
                                        <Form.Control
                                            placeholder="Kep adresi"
                                            disabled
                                            value={data?.data?.company_kep_address || ''}
                                        />
                                        <Button onClick={() => {
                                            setEditKepAddress(true)
                                        }} variant="outline-secondary" id="button-addon2">
                                            Düzenle
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        <Col xs="12" sm="4">
                            <Label>vergi numarasi </Label>
                        </Col>
                        <Col>
                            <Input disabled value={data?.data?.company_tax_no || ''} type="text" placeholder="vergi numarasi" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        {editBankName ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Banka adı </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8">
                                    <Input value={editBankNameForUpdate} onChange={(e) => {
                                        setEditBankNameForUpdate(e.target.value)
                                    }} type="text" placeholder="Banka adı" />
                                </Col>
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => { setEditBankName(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "company_bank_name", value: editBankNameForUpdate, setFalse: setEditBankName })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Banka adı </Label>
                                </Col>
                                <Col>
                                    <InputGroup className="">
                                        <Form.Control
                                            placeholder="Banka adı"
                                            disabled
                                            value={data?.data?.company_bank_name || ''}
                                        />
                                        <Button onClick={() => {
                                            setEditBankName(true)
                                        }} variant="outline-secondary" id="button-addon2">
                                            Düzenle
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        {editBankBranch ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Banka subesi </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8">
                                    <Input value={editBankBranchForUpdate} onChange={(e) => {
                                        setEditBankBranchForUpdate(e.target.value)
                                    }} type="text" placeholder="Banka subesi" />
                                </Col>
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => { setEditBankBranch(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "company_bank_branch", value: editBankBranchForUpdate, setFalse: setEditBankBranch })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Banka subesi </Label>
                                </Col>
                                <Col>
                                    <InputGroup className="">
                                        <Form.Control
                                            placeholder="Banka subesi"
                                            disabled
                                            value={data?.data?.company_bank_branch || ''}
                                        />
                                        <Button onClick={() => {
                                            setEditBankBranch(true)
                                        }} variant="outline-secondary" id="button-addon2">
                                            Düzenle
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        {editTel ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Sabit Tel </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8">
                                    <Input value={editTelForUpdate} onChange={(e) => {
                                        setEditTelForUpdate(e.target.value)
                                    }} type="text" placeholder="Sabit Tel" />
                                </Col>
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => { setEditTel(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "company_landline", value: editTelForUpdate, setFalse: setEditTel })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Sabit Tel </Label>
                                </Col>
                                <Col>
                                    <InputGroup className="">
                                        <Form.Control
                                            placeholder="Sabit Tel"
                                            disabled
                                            value={data?.data?.company_landline || ''}
                                        />
                                        <Button onClick={() => {
                                            setEditTel(true)
                                        }} variant="outline-secondary" id="button-addon2">
                                            Düzenle
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        {editBankIban ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Banka IBAN </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8">
                                    <Input value={editBankIbanForUpdate} onChange={(e) => {
                                        setEditBankIbanForUpdate(e.target.value)
                                    }} type="text" placeholder="Banka IBAN" />
                                </Col>
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => { setEditBankIban(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "company_bank_IBAN", value: editBankIbanForUpdate, setFalse: setEditBankIban })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Banka IBAN </Label>
                                </Col>
                                <Col>
                                    <InputGroup className="">
                                        <Form.Control
                                            placeholder="Banka IBAN"
                                            disabled
                                            className={`${ibanError && "border-danger"}`}
                                            value={data?.data?.company_bank_IBAN || ''}
                                        />
                                        <Button className={`${ibanError && "border-danger"}`} onClick={() => {
                                            setEditBankIban(true)
                                            setIbanError(false)
                                        }} variant="outline-secondary" id="button-addon2">
                                            Düzenle
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md="6" xs="12">
                    <Row className="d-flex align-items-center mb-3">
                        {editEmail ? (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Email </Label>
                                </Col>
                                <Col xxl="8" md="8" sm="8">
                                    <Input value={editEmailForUpdate} onChange={(e) => {
                                        setEditEmailForUpdate(e.target.value)
                                    }} type="text" placeholder="Email" />
                                </Col>
                                <Col className="mb-4 mt-4 d-flex justify-content-end  ">
                                    <Button onClick={() => { setEditEmail(false) }} style={{ marginRight: "8px" }} type="button" className="d-flex align-items-center btn btn-soft-secondary waves-effect waves-light"><i className='bx bx-x bx-sm'></i>Geri</Button>
                                    <Button onClick={() => {
                                        companyInfoMutation.mutate({ option: "company_email", value: editEmailForUpdate, setFalse: setEditEmail })
                                    }} type="button" className="d-flex align-items-center btn btn-soft-success waves-effect waves-light"><i className='bx bx-check bx-sm'></i>Kaydet</Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs="12" sm="4">
                                    <Label>Email: </Label>
                                </Col>
                                <Col>
                                    <InputGroup className="">
                                        <Form.Control
                                            placeholder="Email"
                                            disabled
                                            type="email"
                                            value={data?.data?.company_email || ''}
                                        />
                                        <Button onClick={() => {
                                            setEditEmail(true)
                                        }} variant="outline-secondary" id="button-addon2">
                                            Düzenle
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col lg="10">
                    <span style={{ textAlign: "start" }} className="inputTitle">İş yöntemi</span>
                    <Row style={{ marginTop: "10px" }}>
                        <Col lg="5">
                            <div className="form-check form-radio-primary mb-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="businessMethod"
                                    id="businessRadio1"
                                    value="onlineAndInStore"
                                    checked={data?.data?.business_method === "online-and-in-store" ? true : false}
                                    onChange={() => { }}
                                    onClick={() => {
                                        toast.info("Değiştirmek için destek ekibiyle iletişime geçin", { toastId: "contactSupport" })
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="businessRadio1"
                                >
                                    Online ve mağaza içi alışveriş
                                </label>
                            </div>
                            <div className="form-check form-radio-primary mb-3">
                                <input
                                    className="form-check-input "
                                    type="radio"
                                    name="businessMethod"
                                    id="businessRadio2"
                                    value="online"
                                    checked={data?.data?.business_method !== "online-and-in-store" ? true : false}
                                    onChange={() => { }}
                                    onClick={() => {
                                        toast.info("Değiştirmek için destek ekibiyle iletişime geçin", { toastId: "contactSupport" })
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="businessRadio2"
                                >
                                    Yalnızca Butiko aracılığıyla online
                                </label>
                            </div>
                        </Col>
                        {data?.data?.business_method === "online-and-in-store" && data?.data?.is_multiple_address ? (<Col lg="5">
                            <div className="form-check form-check-primary">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckcolor5"
                                    checked={data?.data?.is_multiple_address}
                                    onChange={() => { }}
                                    onClick={() => {
                                        toast.info("Değiştirmek için destek ekibiyle iletişime geçin", { toastId: "contactSupport" })
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="customCheckcolor5"
                                >
                                    Ofis ve mağaza için farklı adresler
                                </label>
                            </div>
                        </Col>) : (null)}
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <AddressInput id={data?.data?.office_address_id} type="office" address={data?.data?.office_address} />
                </Col>
            </Row>

            {data?.data?.is_multiple_address &&
                <Row className="mt-2">
                    <Col>
                        <AddressInput id={data?.data?.store_address_id} onUpdate={refetch} type="store" address={data?.data?.store_address} />
                    </Col>
                </Row>
            }

        </Container>
    )
}

export default CompanyInformation