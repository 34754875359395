import React, { useState } from "react"
import { Button, Col, Row } from "reactstrap"
import "../../Register.css"
import ErrorDisplay from "../hataGöster/ErrorDisplay";
import { useErrorContext } from "../../ErrorContext";
import { secondPageErrorMessages } from "../hataMesajlari/errorMessages";
const bankaKodlari = {
  TR: {
    "0001": "TC Merkez Bankası",
    "00004": "İller Bankası",
    "00010": "Ziraat Bankası",
    "00012": "Halk Bankası",
    "00014": "Sınai Kalkınma Bankası",
    "00015": "Vakıflar Bankası",
    "00016": "Eximbank",
    "00017": "Kalkınma Bankası",
    "00029": "Birleşik Fon Bankası",
    "00032": "Türk Ekonomi Bankası (TEB)",
    "00046": "Akbank",
    "00059": "Şekerbank",
    "00062": "Garanti Bankası",
    "00064": "İş Bankası",
    "00067": "Yapı ve Kredi Bankası",
    "00091": "Arap Türk Bankası",
    "00092": "Citibank",
    "00094": "Bank Mellat",
    "00096": "Turkish Bank",
    "00098": "JPMorgan Chase Bank",
    "00099": "ING Bank",
    "00100": "Adabank",
    "00103": "Fibabanka",
    "00108": "Turkland Bank",
    "00109": "ICBC Turkey Bank",
    "00111": "Finansbank",
    "00115": "Deutsche Bank",
    "00116": "Pasha Yatırım Bankası",
    "00121": "Standard Chartered Yatırım Bankası",
    "00122": "Societe Generale",
    "00123": "HSBC",
    "00124": "Alternatif Bank",
    "00125": "Burgan Bank",
    "00129": "Merrill Lynch Yatırım Bank",
    "00132": "Takasbank",
    "00134": "Denizbank",
    "00135": "Anadolu Bank",
    "00137": "Rabobank",
    "00138": "Dilerbank",
    "00139": "GSD Bank",
    "00141": "Nurol Yatırım Bankası",
    "00142": "Bankpozitif Kredi ve Kalkınma Bankası",
    "00143": "Aktif Yatırım Bankası",
    "00146": "Odea Bank",
    "00147": "Bank of Tokyo-Mitsubishi UFJ Turkey",
    "00148": "Intesa Sanpaolo S.p.A",
    "00203": "Albaraka Türk Katılım Bankası",
    "00205": "Kuveyt Türk Katılım Bankası",
    "00206": "Türkiye Finans Katılım Bankası",
    "00209": "Ziraat Katılım Bankası",
    "00210": "Vakıf Katılım Bankası",
    "00806": "Merkezi Kayıt Kuruluşu",
  },
}

// ! IBAN yazma ve banka isminin otomatik olarak gösterilmesi için bir bileşen.
function Bank({ iban, setIban, name, setName, branch, setBranch }) {

  const { errors, showError, hideError, clearErrors } = useErrorContext()


  const handleIbanChange = (e) => {
    let girilenIban = e.target.value;
    
    // Sadece sayıları kabul et
    girilenIban = girilenIban.replace(/[^0-9]/g, "");
  
    // Eğer kullanıcı ilk karakter olarak harf yazmak isterse 'TR' ekleyelim
    if (/^\d/.test(girilenIban)) {
      girilenIban = "TR" + girilenIban;
    }
  
    // IBAN uzunluğunu 26 karakter ile sınırlayın
    girilenIban = girilenIban.substring(0, 26);
  
    setIban(girilenIban);
  
    const ulkeKodu = girilenIban.substring(0, 2);
    const bankaKodu = girilenIban.substring(4, 9);
  
    if (girilenIban.length === 26) {
      hideError("iban");
    }
  
    if (ulkeKodu in bankaKodlari && bankaKodu in bankaKodlari[ulkeKodu]) {
      setName(bankaKodlari[ulkeKodu][bankaKodu]);
    } else {
      setName("Banka bulunamadı");
    }
  };
  

  const handleIbanBlur = (e) => {
    const girilenIban = e.target.value;
    let duzenlenmisIban = girilenIban.replace(/\s+/g, "");

    if (duzenlenmisIban.length !== 26) {
      showError("iban", secondPageErrorMessages.incompleteIban);
    } else {
      hideError("iban")
    }
  }

  // Method to handle changes in the Bank Name input field
  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };


  return (
    <>
      <Row>
        <Col xs="12">
          <input
            type="text"
            placeholder="IBAN girin"
            className="form-control"
            value={iban}
            onChange={handleIbanChange}
            onBlur={handleIbanBlur}
            style={{ padding: "20px" }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col lg="12" >
          <span className="inputTitle d-flex align-items-start">
            Banka adı &nbsp;
            {/* <span style={{ opacity: "0.5" }}>
              (isteğe bağlı)
            </span> */}
          </span>
          <div>
            <input
              type="text"
              className="form-control form-text"
              style={{ padding: "20px", marginTop:"-8px" }}
              value={name}
              placeholder="Banka Adı"
              readOnly
            />
          </div>
        </Col>

       
      </Row>
      <Row>
        <Col>
          <ErrorDisplay error={errors.iban} />
        </Col>
      </Row>
    </>
  )
}

export default Bank
