import React from "react"
import withRouter from "components/Common/withRouter"
import {

  Container,
  Modal,
  ModalBody,
  
} from "reactstrap"

import Image from 'react-bootstrap/Image';

const LightBox = ({ modalOpen, setModalOpen, src }) => {
  

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <Container fluid={true}>
        <Modal
        style={{
            maxWidth: "50vw",
            maxHeight: "1200px",
            marginTop: "80px",
          }}
          
          isOpen={modalOpen}
          toggle={toggleModal}
        >
          <ModalBody className="mx-3 my-3">
          <Image src={src} fluid />
          </ModalBody>
          
        </Modal>
      </Container>
    </>
  )
}

export default withRouter(LightBox)
