import React, { useState, useMemo, useRef, useEffect } from "react"
import { Button, Col, Row } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import axios from "axios"
import { toast } from 'react-toastify';
import Form from "react-bootstrap/Form"
import { useNavigate } from "react-router-dom"

const StoreDescription = ({
    aboutStoreData,
    disableEdit,
    setDisableEdit,
    refetch,
    descriptionVal,
}) => {
    const history = useNavigate()
    const [editPart2, setEditPart2] = useState(false)
    const [description, setDescription] = useState("")
    useEffect(() => {
        if (descriptionVal !== undefined) {
            setDescription(descriptionVal);
        }
    }, [descriptionVal]);
    const handleEditPart2 = () => {
        setDisableEdit(true)
        setEditPart2(true)
    }
    useMemo(() => {
        setDescription(aboutStoreData?.data?.description)
    }, [aboutStoreData])
    const putEditDescription = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-description`
        const finalData = { description: `${description}` }
        return axios.put(finalUrl, finalData, { withCredentials: true })
    }
    const descriptionMutation = useMutation({
        mutationFn: putEditDescription,
        onSuccess: () => {
            toast.success("Mağaza Açıklamanız Düzenlenmiştir.", { toastId: "descriptionMutation" })
            refetch()
            setEditPart2(false)
            setDisableEdit(false)
        },
        onError: error => {
            if (
                error?.response?.data?.detail[0]?.msg === "unathorized action" ||
                error?.response?.data?.detail[0]?.msg === "invalid jwt"
            ) {
                history("/login")
            }
            console.log(error);
            toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "descriptionMutationError" })
            setDisableEdit(false)
        },
    })
    return (
        <>
            <Row className={`${aboutStoreData?.description || editPart2 ? "" : ""} align-items-center justify-content-center mt-3 mb-2`}>
                <Col className="">
                    <Row>
                        <Col className="col-12">
                            <strong style={aboutStoreData?.description ? {} : { color: "rgba(248, 45, 45, 1)" }} className="mt-3 mb-2">Açıklama:</strong>
                        </Col>
                    </Row>
                </Col>

                {editPart2 ? (
                    <>
                        <Col className=" text-nowrap d-flex align-items-center justify-content-end text-end ">
                            <Button
                                className="btn btn-soft-secondary d-flex align-items-center border-0 me-2 "
                                onClick={() => {
                                    setEditPart2(false)
                                    setDisableEdit(false)
                                }}
                            >
                                <i className="bx bx-x bx-xs"></i>
                                iptal
                            </Button>

                            <Button
                                className="btn btn-soft-success d-flex align-items-center border-0 "
                                onClick={() => {
                                    descriptionMutation.mutate()
                                }}
                            >
                                <i className="bx bx-check bx-xs d-flex align-items-center"></i>
                                Kaydet
                            </Button>
                        </Col>
                    </>
                ) : (
                    <Col className=" d-flex align-items-center justify-content-end text-end ">
                        <button
                            className="btn btn-outline-primary d-flex align-items-center border-0 "
                            onClick={handleEditPart2}
                            disabled={disableEdit}
                        >
                            <i className="bx bxs-edit-alt bx-xs"></i>
                            Düzenle
                        </button>
                    </Col>
                )}
            </Row>
            {editPart2 ? (
                <Form.Group
                    className="mb-3"
                >
                    <Form.Control
                        as="textarea"
                        rows={5}
                        style={{ background: "#F3F3F9", }}
                        id="forDescription"
                        onChange={e => {
                            setDescription(e.target.value)
                        }}
                        value={description}
                    />
                </Form.Group>
            ) : (
                <span
                    className={`mb-3 ${aboutStoreData?.description ? 'text-muted' : 'text-danger'}`}
                >
                    {aboutStoreData?.description
                        ? aboutStoreData.description.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                {index < aboutStoreData.description.split('\n').length - 1 && <br />}
                            </React.Fragment>
                        ))
                        : 'Lütfen mağazanız için bir açıklama girin'}
                </span>
            )}
        </>
    )
}
export default StoreDescription