import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Input, Label, Row } from "reactstrap";
import logodark from "../../assets/images/logo/logo-dark.png";
import logolight from "../../assets/images/logo/logo-light.png";
import CarouselPage from "./CarouselPage";
import axios from "axios";

const Recoverpw2 = () => {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState(Array(6).fill("")); // 6 haneli doğrulama kodu
  const [showVerification, setShowVerification] = useState(false); // Doğrulama kutularını göstermek için bir state

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!showVerification) {
        // Doğrulama kodu gönderildikten sonra alt kısımda doğrulama kutularını göstermek için state'i güncelleyin
        setShowVerification(true);

        // Başarılı ise kullanıcıyı bilgilendir
        alert("Şifre sıfırlama isteği başarıyla gönderildi!");
      } else {
        // Doğrulama kodunu gönderdikten sonra API'ye göndermek için hazırlık yap
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/auth/verify-code`,
          {
            verification_code: verificationCode.join(""),
            email: email,
            role: "seller"
          },
          { withCredentials: true }
        );

        console.log("Response:", response);

        // Başarılı ise kullanıcıyı bilgilendir
        alert("Doğrulama kodu başarıyla gönderildi!");
      }

    } catch (error) {
      console.error("Şifre sıfırlama isteği sırasında bir hata oluştu:", error);
      alert("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    }
  };

  // Meta başlık
  document.title = "Recover Password 2 | Skote - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Şifreyi Sıfırla</h5>
                      </div>

                      <div className="mt-4">
                        <div className="alert alert-success text-center mb-4" role="alert">
                          {showVerification ? "Mailinize gelen kodu giriniz" : "Şifrenizi sıfırlamak için mail adresinizi girin!"}
                        </div>

                        <Form className="form-horizontal" onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Mail gir"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          {!showVerification && (
                            <div className="text-end">
                              <button className="btn btn-primary w-md" type="submit">
                                Gönder
                              </button>
                            </div>
                          )}
                        </Form>

                        {/* Gönderildikten sonra doğrulama kutularını göster */}
                        {showVerification && (
                          <React.Fragment>
                            <div className="mt-4 d-flex justify-content-center">
                              {verificationCode.map((digit, index) => (
                                <input
                                  key={index}
                                  className="form-control m-1 text-center"
                                  maxLength={1}
                                  style={{ width: "30px" }}
                                  value={digit}
                                  onChange={(e) => {
                                    const newVerificationCode = [...verificationCode];
                                    newVerificationCode[index] = e.target.value;
                                    setVerificationCode(newVerificationCode);
                                  }}
                                />
                              ))}
                            </div>
                            <div className="mt-4 text-center">
                              <button className="btn btn-primary w-md" type="submit">
                                Kodu Gönder
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        <div className="mt-5 text-center">
                          <p>
                            Remember It?{" "}
                            <Link to="pages-login-2" className="fw-medium text-primary">
                              Sign In here
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Recoverpw2;
