import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo/logo-dark.png";
import logolight from "../../assets/images/logo/logo-light.png";
import CarouselPage from "./CarouselPage";
import { useMutation } from "@tanstack/react-query";
import { post } from "helpers/api_helper";
import * as url from "../../helpers/url_helper"
import toastr from "toastr"
const ForgetPassword2 = () => {

  //meta title
  document.title = "Şifremi Unuttum | ButikO";

  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const sendEmail = async () => {
    const data = {
      option: "email",
      email: validation.values.email || "",
      role: "seller"
    }

    const response = await post(url.POST_FORGOT_PASSWORD, data)

    console.log(response);

    return response
  }

  const forgetPasswordMutation = useMutation({
    mutationFn: sendEmail,
    onError: error => {
      console.log(error);

      if (error?.response?.data?.detail?.[0]?.ctx?.reason === "The email address is not valid. It must have exactly one @-sign.") {
        toastr.error("Lütfen geçerli bir e-posta giriniz.")
      } else {
        toastr.error("Beklenmedik bir hata oluştu.")
      }

    },
    onSuccess: () => {
      toastr.success("E-posta Gönderildi!")
      navigate("/auth-two-step-verification-2", { state: { email: validation.values.email } })
    }

  })
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Şifrenizi Sıfırlayın</h5>
                        <p className="text-muted">Butiko ile Şifrenizi Sıfırlayın
                        </p>
                      </div>

                      <div className="mt-4">

                        <div className="alert alert-success text-center mb-4" role="alert">
                        Şifrenizi yenilemeniz için, ButikO Satıcı Paneli’ne kayıtlı e-posta adresinize ihtiyacımız var.
                        </div>

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">E-posta</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="E-postanızı girin"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              style={{ backgroundColor: "#5770E6", border:"none" }}
                              type="submit"
                              onClick={() => {
                                forgetPasswordMutation.mutate();
                              }}
                            >
                              Kodu Gönder
                            </Button>
                          </div>

                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Hatırladınız mı ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Buradan giriş yapın{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} ButikO {" "}
                        <i className="mdi mdi-heart text-danger"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword2;
