import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Col, Card, CardBody, Table } from "reactstrap";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const TopSellingProduct = (props) => {


  const monthNumber = new Date().getMonth() + 1;
  // console.log(typeof (monthNumber));
  const [selectedMonthId, setSelectedMonthId] = useState(monthNumber);

  const colors = ["#34C38F", "#556EE6", "#F46A6A"]

  let options = {

    plotOptions: {

      radialBar: {
        dataLabels: {
          name: {
            fontSize: "220px",
            fontWeight: "700",

          },
          value: {
            fontSize: "0px",
            fontWeight: "800",
          },
          total: {
            show: true,
            label: "",
            fontSize: "12px",

            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              // console.log(w)
              return `${w.globals.series[0]}%`
            },
          },
        },
      },
    },


  }

  const getTopSellingProduct = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/dashboard/top-selling-products?month=${selectedMonthId}`
    return axios.get(finalUrl, { withCredentials: true })
  }
  const { isLoading, isError, isSuccess, error, data } = useQuery({ queryKey: ["top-selling", selectedMonthId], queryFn: getTopSellingProduct, retry: 1 });
  let TopSellingProduct
  if (isLoading) {

  } else if (isError) {
    console.log(error);
  } else {
    // console.log(data?.data);
    TopSellingProduct = data?.data
  }




  return (
    <React.Fragment>
      <Col className="d-flex " xl="4">
        <Card style={{ padding: "15px", width: "100%" }}>
          <CardBody>
            <div className="row justify-content-between d-flex">
              <h4 className="col card-title mb-4">En Çok Satan Ürün</h4>
              <div className="col float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={selectedMonthId}
                    onChange={(e) => setSelectedMonthId(e.target.value)}
                  >
                    <option value="1">Ocak</option>
                    <option value="2">Şubat</option>
                    <option value="3">Mart</option>
                    <option value="4">Nisan</option>
                    <option value="5">Mayıs</option>
                    <option value="6">Haziran</option>
                    <option value="7">Temmuz</option>
                    <option value="8">Ağustos</option>
                    <option value="9">Eylül</option>
                    <option value="10">Ekim</option>
                    <option value="11">Kasım</option>
                    <option value="12">Aralık</option>
                  </select>

                  <label className="input-group-text">Ay</label>
                </div>
              </div>
            </div>
            <div style={{ minHeight: "100px" }} className="text-muted text-center">
              {isLoading ? (
                <span className="top-selling-p ">
                  <Spinner animation="border" variant="secondary" />
                </span>
              ) :
                isSuccess && !TopSellingProduct.info ? (
                  <>
                    <p className="mb-2">{TopSellingProduct[0]?.name}</p>
                    <h4>TL {TopSellingProduct[0]?.revenue}</h4>
                    {/* <p className="mt-4 mb-0">
                      <span className="badge badge-soft-success font-size-11 me-2">
                        {TopSellingProduct[0].previous_period_percentage}%
                        <i className="mdi mdi-arrow-up" />
                      </span>{" "}
                      Önceki dönemden
                    </p> */}
                    <div style={{ overflow: "auto" }} className=" mt-4  ">
                      <Table className=" align-middle table-responsive mb-0">
                        <tbody>
                          {TopSellingProduct?.map((data, key) => {
                            let dynamicOptions = {

                              ...options,
                              colors: [colors[key % colors.length]],
                              plotOptions: {
                                ...options.plotOptions,
                                radialBar: {
                                  ...options.plotOptions.radialBar,
                                  dataLabels: {
                                    ...options.plotOptions.radialBar.dataLabels,
                                    total: {
                                      ...options.plotOptions.radialBar.dataLabels.total,
                                      label: `${data.sales_ratio}%`
                                    }
                                  }
                                }
                              }
                            };
                            return (
                              <tr className="" style={{ borderBottom: "3px solid #EFF2F7" }} key={key}>
                                <td style={{ backgroundColor: "white", width: "33%", display: "flex", alignItems: "center", borderBottom: "none" }}>
                                  <Link to={`/ecommerce-product-detail/${data?.product_id}`}>
                                    <Image style={{ marginRight: "8px", objectFit: "cover", borderRadius: "8px" }} height={100} width={90} src={data?.variation_media[0]} rounded />
                                  </Link>
                                  <div className="product-names" >
                                    <h5 className="font-size-14 text-start text-truncate mb-1">{data?.name}</h5>
                                    <h5 className="font-size-11 text-start text-muted text-truncate mb-1">{data?.category_name}</h5>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: "white", width: "33%" }}>
                                  {/* Insert content for the second column if needed */}
                                </td>
                                <td style={{ backgroundColor: "white", width: "33%", minWidth: "130px" }}>
                                  <ReactApexChart
                                    options={dynamicOptions}
                                    series={[data?.sales_ratio]}
                                    type="radialBar"
                                    height="130"

                                    className="apex-charts"
                                  />
                                </td>
                              </tr>

                            );
                          })}
                        </tbody>
                      </Table>

                    </div>
                  </>
                ) : (
                  <>
                    <p className="top-selling-p " >Veri Bulunamadı</p>
                  </>
                )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TopSellingProduct;
