import { create } from 'zustand';
import axios from 'axios';

const useAuthStore = create((set) => ({

    getRefreshToken: async () => {
        // const shouldRefresToken = localStorage.getItem("shouldRefresTokenDoc");
        // if (shouldRefresToken !== "updated") {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/refresh-token`, { withCredentials: true });
            localStorage.setItem("shouldRefresTokenDoc", "updated");
            return response.data;
        } catch (error) {
            console.error("Error refreshing token:", error);
            throw error;
        }
    }

    // },

    // You can add more authentication-related functions here

}
)
);

export default useAuthStore;