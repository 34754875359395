const ColorHex = (colorName) => {
    console.log(colorName.replace(/\s/g, ''));
    let colors = [
        { color_name: "mavi", color_hex: "#0000FF" }, // Blue
        { color_name: "yeşil", color_hex: "#008000" }, // Green
        { color_name: "kırmızı", color_hex: "#FF0000" }, // Red
        { color_name: "siyah", color_hex: "#000000" }, // Black
        { color_name: "beyaz", color_hex: "#FFFFFF" }, // White
        { color_name: "gri", color_hex: "#808080" }, // Gray
        { color_name: "sarı", color_hex: "#FFFF00" }, // Yellow
        { color_name: "pembe", color_hex: "#FFC0CB" }, // Pink
        { color_name: "mor", color_hex: "#800080" }, // Purple
        { color_name: "turuncu", color_hex: "#FFA500" }, // Orange

        { color_name: "kahverengi", color_hex: "#A52A2A" }, // Brown
        { color_name: "camgöbeği", color_hex: "#00FFFF" }, // Cyan
        { color_name: "macenta", color_hex: "#FF00FF" }, // Magenta
        { color_name: "teal", color_hex: "#008080" }, // (Need assistance)
        { color_name: "altın", color_hex: "#FFD700" }, // Gold
        { color_name: "gümüş", color_hex: "#C0C0C0" }, // Silver
        { color_name: "lacivert", color_hex: "#000080" }, // Navy
        { color_name: "bej", color_hex: "#F5F5DC" }, // Beige
        { color_name: "mercan", color_hex: "#FF7F50" }, // Coral
        { color_name: "eflatun", color_hex: "#EE82EE" }, // Violet

        { color_name: "zeytin yeşili", color_hex: "#808000" }, // Olive
        { color_name: "limon yeşili", color_hex: "#00FF00" }, // Lime
        { color_name: "bordo", color_hex: "#800000" }, // Maroon
        { color_name: "turkuaz", color_hex: "#40E0D0" }, // Turquoise
        { color_name: "lavanta", color_hex: "#E6E6FA" }, // Lavender
        { color_name: "gökyüzü mavisi", color_hex: "#87CEEB" }, // Sky blue
        { color_name: "somon", color_hex: "#FA8072" }, // Salmon
        { color_name: "nane", color_hex: "#F0FFF0" }, // Mint
        { color_name: "gül", color_hex: "#FF007F" }, // Rose
        { color_name: "çivit mavisi", color_hex: "#4B0082" }, // Indigo

        { color_name: "kızıl", color_hex: "#DC143C" }, // Crimson
        { color_name: "amber", color_hex: "#FFBF00" }, // Amber 
        { color_name: "şeftali", color_hex: "#FFE5B4" }, // Peach 
        { color_name: "leylak", color_hex: "#C8A2C8" }, // Lilac
        { color_name: "açık mavi", color_hex: "#ADD8E6" }, // Light Blue
        { color_name: "füme", color_hex: "#708090" }, // Smoky Gray
        { color_name: "koyu yeşil", color_hex: "#006400" }, // Dark Green
        { color_name: "hardal sarısı", color_hex: "#FFDB58" }, // Mustard Yellow
        { color_name: "petrol mavisi", color_hex: "#536878" }, // Teal Blue
        { color_name: "erik moru", color_hex: "#CC8899" },  // Plum

        { color_name: "kar", color_hex: "#FFFAFA" },  // snow
        { color_name: "açık pembe", color_hex: "#FFB6C1" },  // lightpink
        { color_name: "ahududu", color_hex: "#872657" },  // raspberry
        { color_name: "koyu pembe", color_hex: "#FF1493" },  // deeppink
        { color_name: "orkide", color_hex: "#DA70D6" },  // orchid
        { color_name: "menekşe", color_hex: "#EE82EE" },  // violet
        { color_name: "fuşya", color_hex: "#FF00FF" },  // fuchsia
        { color_name: "çivit", color_hex: "#4B0082" },  // indigo
        { color_name: "kobalt", color_hex: "#3D59AB" },  // cobalt
        { color_name: "gökyüzü mavisi", color_hex: "#87CEEB" },  // skyblue
        { color_name: "camgöbeği", color_hex: "#00FFFF" },  // cyan
        { color_name: "ilkbahar yesili", color_hex: "#00FF7F" },  // springgreen
        { color_name: "zümrüt yeşili", color_hex: "#00C957" },  // emeraldgreen
        { color_name: "soluk yeşil", color_hex: "#98FB98" },  // palegreen
        { color_name: "açık yeşil", color_hex: "#90EE90" },  // lightgreen
        { color_name: "limon", color_hex: "#00FF00" },  // limon
        { color_name: "sarı yeşil", color_hex: "#9ACD32" },  // yellowgreen
        { color_name: "fildişi", color_hex: "#FFFFF0" },  // ivory
        { color_name: "zeytin", color_hex: "#808000" },  // olive
        { color_name: "haki", color_hex: "#F0E68C" },  // khaki
        { color_name: "tan", color_hex: "#D2B48C" },  // tan
        { color_name: "bisküvi", color_hex: "#FFE4C4" },  // bisque
        { color_name: "kavun", color_hex: "#E3A869" },  // melon
        { color_name: "havuç", color_hex: "#ED9121" },  // carrot
        { color_name: "keten", color_hex: "#FAF0E6" },  // linen
        { color_name: "şeftali", color_hex: "#FFDAB9" },  // peachpuff
        { color_name: "deniz kabuğu", color_hex: "#FFF5EE" },  // seashell
        { color_name: "çikolata", color_hex: "#D2691E" },  // chocolate
        { color_name: "sienna", color_hex: "#A0522D" },  // sienna
        { color_name: "sepya", color_hex: "#5E2612" },  // sepia
        { color_name: "domates", color_hex: "#FF6347" },  // tomato
        ];
    const findColor = colors.find(color => color.color_name.replace(/\s/g, '') === colorName.toLowerCase().trim().replace(/\s/g, ''));

    // const hex_code = `#${colorName}`
    if (findColor === undefined) {
        return "#000000";
    }
    return findColor.color_hex
}
export default ColorHex