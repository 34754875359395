import React from 'react';
import { FcMoneyTransfer } from 'react-icons/fc';
import { Row, Col } from 'reactstrap';
import Breadcrumbs from "components/Common/Breadcrumb"
import Kazanc from "pages/sozlesmeler/kazanc"
import { TbShoppingBagPlus } from "react-icons/tb";
import { GrMoney } from "react-icons/gr";
import { AiFillSecurityScan } from "react-icons/ai";
import { BiSolidImageAdd } from "react-icons/bi";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { TbUserEdit } from "react-icons/tb";
import { RiUserSettingsFill } from "react-icons/ri";
import UrunKargolama from 'pages/sozlesmeler/urunKargolama';
import UrunSatisi from 'pages/sozlesmeler/urunSatisi';
import UrunEkleme from 'pages/sozlesmeler/urunEkleme';
import Guvenlik from 'pages/sozlesmeler/guvenlik';
import Token from 'pages/sozlesmeler/token';
import HesapAyarlari from 'pages/sozlesmeler/hesapAyarları';
import KullaniciBilgileri from 'pages/sozlesmeler/kullaniciBilgileri';
import { TbTruckDelivery } from "react-icons/tb";
import Kargo from 'pages/sozlesmeler/kargo';
const Faq = () => {
  const cardStyle = {
    width: '23rem',
    height:"17rem",
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  };

  const iconStyle = {
    fontSize: '55px',
  };

  const cardBodyStyle = {
    padding: '20px',
  };

  const cardTitleStyle = {
    fontSize: '1.25rem',
    marginBottom: '10px',
    textAlign:"center"
  };

  const cardTextStyle = {
    marginBottom: '15px',
    textAlign:"center"
  };

  const pageContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const rowStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop:"20px"
  };
  const breadcrumbContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '20px',
  };
  return (
    <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="SIKÇA SORULAN SORULAR" />
      <Row style={rowStyle}>
        <Col lg="3" md="6" sm="12">
          <div className="card" style={cardStyle} > 
            <div className="icon-container" style={iconContainerStyle}>
              <TbShoppingBagPlus style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Ürün Satışı
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Ürün Satışı ile ilgili merak ettiğiniz sorular
              </p>
              <UrunSatisi backgroundColor="white" />
            </div>
          </div>
        </Col>
        <Col lg="3" md="6" sm="12">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <FcMoneyTransfer style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
                Kazancım
              </h5>
              <p className="card-text" style={cardTextStyle}>
                Kazancınız ile ilgili merak ettiğiniz sorular
              </p>
              <Kazanc backgroundColor="white" />
            </div>
          </div>
        </Col>
        <Col lg="3" md="6" sm="12">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <GrMoney   style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Token
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Token ile ilgili merak ettiğiniz sorular
              </p>
              <Token backgroundColor="white" />
            </div>
          </div>
        </Col>
      </Row>

      <Row style={rowStyle}>
      <Col lg="3">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <BiSolidImageAdd   style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Ürün Ekleme
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Ürün Ekleme ile ilgili merak ettiğiniz sorular
              </p>
              <UrunEkleme backgroundColor="white" />
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <MdOutlineProductionQuantityLimits   style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Ürün Kargolama
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Ürün Kargolama ile ilgili merak ettiğiniz sorular
              </p>
              <UrunKargolama backgroundColor="white" />
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <AiFillSecurityScan  style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Güvenlik ve Kurallar
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Güvenlik ve Kurallar ile ilgili merak ettiğiniz sorular
              </p>
              <Guvenlik backgroundColor="white" />
            </div>
          </div>
        </Col>
      </Row>


      <Row style={rowStyle}>
      <Col lg="3">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <RiUserSettingsFill    style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Hesap Ayarları
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Hesap Ayarları ile ilgili merak ettiğiniz sorular
              </p>
              <HesapAyarlari  backgroundColor="white" />
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <TbUserEdit    style={iconStyle} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Kullanıcı Bilgileri Düzenleme
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Kullanıcı Bilgileri Düzenleme ile ilgili merak ettiğiniz sorular
              </p>
              <KullaniciBilgileri backgroundColor="white" />
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="card" style={cardStyle}>
            <div className="icon-container" style={iconContainerStyle}>
              <TbTruckDelivery     style={{marginTop:"8px", fontSize:"55px"}} />
            </div>
            <div className="card-body" style={cardBodyStyle}>
              <h5 className="card-title" style={cardTitleStyle}>
              Kargo
              </h5>
              <p className="card-text" style={cardTextStyle}>
              Kargo Bilgileri Düzenleme ile ilgili merak ettiğiniz sorular
              </p>
              <Kargo backgroundColor="white" />
            </div>
          </div>
        </Col>

      </Row>
    </div>
  );
};

export default Faq;
