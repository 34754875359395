import React from "react";
import Form from "react-bootstrap/Form";
import { Label } from "reactstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const LabelWrapper = ({ labelText, className, children }) => (
    <div>
        <Label style={{ fontSize: "14px", fontWeight: "500" }}>{labelText}</Label>
        <div className={className}>{children}</div>
    </div>
);

const AdSelectFilters = ({
    districtId,
    handleDistrictChange,
    districtOptions,
    handleSemtChange,
    semtOptions,
    handleRankChange
}) => {

    const rankingData = [
        { label: "Birinci Sıra", value: 1 },
        { label: "İkinci Sıra", value: 2 },
        { label: "Üçüncü Sıra", value: 3 },
        { label: "Dördüncü Sıra", value: 4 },
        { label: "Beşinci Sıra", value: 5 },
        { label: "Altıncı Sıra", value: 6 },
        { label: "Yedinci Sıra", value: 7 },
        { label: "Sekizinci Sıra", value: 8 },
        { label: "Dokuzuncu Sıra", value: 9 },
        { label: "Onuncu Sıra", value: 10 },
    ];

    return (
        <div>
            <Row>
                <Col className="col-6">
                    <LabelWrapper labelText="İlçe" className="mb-3">
                        <Form.Select
                            value={districtId}
                            onChange={handleDistrictChange}
                            aria-label="Default select example"
                        >
                            <option value="">İlçe Seçin</option>
                            {districtOptions}
                        </Form.Select>
                    </LabelWrapper>
                </Col>
                <Col className="col-6">
                    <LabelWrapper labelText="Semt" className="mb-3">
                        <Form.Select
                            onChange={handleSemtChange}
                            aria-label="Default select example"
                        >
                            <option value="">Semt Seçin</option>
                            {semtOptions}
                        </Form.Select>
                    </LabelWrapper>
                </Col>

            </Row>

            <LabelWrapper labelText="Sıralama" >
                <Form.Select onChange={handleRankChange}>
                    <option value="">Sıra Seçin</option>
                    {rankingData.map(rank => (
                        <option key={rank.value} value={rank.value}>{rank.label}</option>
                    ))}
                </Form.Select>
            </LabelWrapper>
        </div>
    );
};

export default AdSelectFilters;
