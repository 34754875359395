import React, { useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {

  Row,
  Col,
} from "reactstrap"
import { Form } from "react-bootstrap"
import axios from "axios"
import { useMutation } from "@tanstack/react-query";
import { addDays } from "@fullcalendar/react"
import { useNavigate } from "react-router-dom";
import "./products.css"
function Calendar(props) {
  const history = useNavigate();


  const today = new Date();
  const maxDays = new Date(today.setDate(today.getDate() + 60));
  // const month = new Date().getMonth() +1
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [reservedDays, setReservedDays] = useState([]);
  const semt = props.semt
  const rank = props.rank
  // if (semt && rank) {
    // isDisabled = false
  // }
  const getExcludeDates = () => {
    const finalData = {
      semt_id: semt,
      ranking: rank,
      month: month
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/ads/get-reserved-days`, finalData, { withCredentials: true })
  }

  const excludeDatesMutation = useMutation({
    mutationFn: getExcludeDates,
    onSuccess: (data) => {
      console.log(data.data);
      let day = []
      data.data.forEach(element => {
        let date = new Date(element.start_date)
        const stDate = new Date(date.setDate(date.getDate() - 1));
        day.push({ start: stDate, end: new Date(element.end_date) })
        // day.push(new Date(element.end_date))
      });
      setReservedDays(day);
      // console.log(reservedDays);
    },
    onError: (error) => {
      console.log(error);
      if ( error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
        history("/login")
      }
    }
  })

  return (
    <>
      <Row>
        <Col xs={12} sm={6}>

          <Form.Group className="mb-3 mt-2" >
            <Form.Label style={{ fontSize: "14px", fontWeight: "500", }}>Başlama Tarihi</Form.Label>
            {/* <Form.Control onChange={e => setStartDate(e.target.value)} type="date" /> */}
            <DatePicker
              className="w-100 date-input"
              selected={props.startDate}
              onChange={(date) => {
                props.setStartDate(date)
                console.log(date);
              }}
              selectsStart
              startDate={props.startDate}
              endDate={props.endDate}
              onMonthChange={(date) => {
                setMonth(date.getMonth() + 1);
                excludeDatesMutation.mutate()
              }}
              minDate={addDays(new Date(), 1)}
              excludeDateIntervals={reservedDays}
              maxDate={maxDays}
              onCalendarOpen={(e) => {
                excludeDatesMutation.mutate()
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>

          <Form.Group className="mb-3 mt-2" >
            <Form.Label style={{ fontSize: "14px", fontWeight: "500", }}>Bitiş Tarihi</Form.Label>
            {/* <Form.Control onChange={e => setEndDate(e.target.value)} type="date" /> */}
            <DatePicker
              className="w-100 date-input"
              selected={props.endDate}
              onChange={(date) => props.setEndDate(date)}
              selectsEnd
              startDate={props.startDate}
              endDate={props.endDate}
              minDate={props.startDate}
              excludeDateIntervals={reservedDays}
              onMonthChange={(date) => {
                console.log(date);
              }}
              placeholderText="Bitiş Tarihi"
              maxDate={maxDays}
            />

          </Form.Group>

        </Col>
      </Row>
    </>



  )
}

export default Calendar
