import React, { useState, useEffect } from "react"
import { Container, Button, Card, CardBody, Row, Col } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import accessToken from "helpers/jwt-token-access/accessToken"
import axios from "axios"
import "./reviews.css"
import { useParams } from "react-router-dom";
const ReviewDetails = () => {
  document.title = "Review Details | Skote - React Admin & Dashboard Template"

  const [message, setMessage] = useState("")
  const [messageSent, setMessageSent] = useState(false)

  const handleSendMessage = () => {
    setMessageSent(true)
  }
  const { reviewId } = useParams();
  const parsedReviewId = parseInt(reviewId, 10); 
  const imageStyle = { width: "100%", height: "auto", maxWidth: "100%" }

  const [currentPage, setCurrentPage] = useState(0)
  const [productsData, setProductsData] = useState([])
  const [errorOccurred, setErrorOccurred] = useState(false)
  const calculateTimeAgo = dateString => {
    const currentDate = new Date()
    const reviewDate = new Date(dateString)

    const timeDifference = currentDate - reviewDate
    const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60))

    if (hoursAgo < 24) {
      return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`
    } else {
      const daysAgo = Math.floor(hoursAgo / 24)
      return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`
    }
  }

  useEffect(() => {
    getProducts(parsedReviewId); // parsedReviewId'yi kullanarak ürünleri getir
  }, [currentPage, parsedReviewId]); 

  const getProducts = () => {
    const headers = {
      Authorization: accessToken,
    }

    const config = {
      headers: headers,
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/reviews/detail?review_id=${reviewId}`,
        config
      )
      .then(response => {
        const responseData = response.data.map(item => ({
          ...item,
          variation_media: JSON.parse(item.variation_media),
          comment_images: JSON.parse(item.comment_images),
        }))

        setProductsData(responseData)
      })
      .catch(error => {
        console.error("Veri alınırken bir hata oluştu: ", error.response.data)
        setErrorOccurred(true)
      })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Review Details" />
          <Row>
            <Col>
              <Card className="p-2">
                <CardBody>
                  <Row className="d-flex align-items-center">
                    {productsData.map((product, key) => (
                      <Col
                        xs="9"
                        sm="6"
                        md="3"
                        className="d-flex flex-column align-items-center"
                        key={"product_" + key}
                      >
                        <img src={product.comment_images} style={imageStyle} />
                        <div>
                          <span
                            className="d-flex"
                            style={{
                              color: "#343A40",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {product.product_name}
                          </span>
                        </div>
                      </Col>
                    ))}
                    <Col md="9">
                      <Row>
                        {productsData.map((product, key) => (
                          <Col xl="1" className="py-2" key={"product_" + key}>
                            <div className="align-self-center me-3">
                              <img
                                src={product.user_profile_picture}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>
                          </Col>
                        ))}

                        <Col xl="11">
                          <Row>
                            {productsData.map((product, key) => (
                              <Col key={"product_" + key}>
                                <span style={{ fontSize: "15px" }}>
                                  {product.username}
                                </span>
                              </Col>
                            ))}
                            {productsData.map((product, key) => (
                              <Col
                                className="d-flex justify-content-end"
                                key={"product_" + key}
                              >
                                <div
                                  className="p-1 me-2"
                                  style={{
                                    backgroundColor: "#34C38F",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <i
                                    className="bx bxs-star pe-1"
                                    style={{ color: "#FFF" }}
                                  ></i>
                                  <span style={{ color: "#FFF" }}>
                                    {product.product_rating}
                                  </span>
                                </div>
                                <div className="d-flex-inline align-items-start">
                                  <i className="bx bx-calendar text-primary font-size-20"></i>
                                  <span
                                    style={{
                                      color: "#74788D",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      margin: "5px",
                                    }}
                                  >
                                    {calculateTimeAgo(product.reviewed_at)}
                                  </span>
                                </div>
                              </Col>
                            ))}
                          </Row>
                          <Row>
                            {productsData.map((product, key) => (
                              <Col key={"product_" + key}>
                                <p style={{ fontWeight: "400" }}>
                                  {product.comment}
                                </p>
                              </Col>
                            ))}
                          </Row>
                          <Row className="mt-4">
                            {productsData.map((product, key) =>
                              product.variation_media.map((media, mediaKey) => (
                                <Col
                                  xs="12"
                                  sm="6"
                                  md="3"
                                  className="py-1"
                                  key={`product_${key}_media_${mediaKey}`}
                                >
                                  <div className="align-self-center me-3">
                                    <img
                                      src={media}
                                      className="reviewImg"
                                      alt=""
                                    />
                                  </div>
                                </Col>
                              ))
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="p-2">
                <CardBody>
                  {messageSent ? (
                    <div>
                      <div className="d-flex justify-content-between">
                        <span
                          className="d-flex my-1"
                          style={{ fontSize: "15px", fontWeight: "500" }}
                        >
                          Your response
                        </span>
                        <div>
                          <i className="bx bx-calendar text-primary font-size-20"></i>
                          <span
                            style={{
                              color: "#74788D",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            5 hrs ago
                          </span>
                        </div>
                      </div>

                      <span
                        className="d-flex my-1"
                        style={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        Written by: Recep Börekci
                      </span>
                      <p className="mt-3">{message}</p>
                    </div>
                  ) : (
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control chat-input"
                            placeholder="Enter Message..."
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          type="button"
                          color="primary"
                          className="btn btn-primary btn-rounded chat-send w-md"
                          onClick={handleSendMessage}
                        >
                          <span className="d-none d-sm-inline-block me-2">
                            Send
                          </span>{" "}
                          <i className="mdi mdi-send" />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ReviewDetails
