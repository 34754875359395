import React from "react";
import { Table } from "reactstrap";

export const DefinitionTable = () => {

    const tableStyle = { width: "4.5e+2pt", borderCollapse: "collapse", border: "1pt solid black" }
    const tableRow1Data1Style = {
        width: "106.5pt",
        border: "1pt solid black",
        backgroundColor: "#CCD4DD",
        padding: "3pt",
        verticalAlign: "top"
    }

    const tableRowData2Style = {
        width: "345.75pt",
        border: "1pt solid black",
        backgroundColor: "#CCD4DD",
        padding: "3pt",
        height: "32.25pt",
        verticalAlign: "top"
    }

    const tableParagraphStyle = {
        margin: "0in",
        lineHeight: "115%",
        fontSize: 15,
        marginTop: "10.0pt",
        marginRight: "0in",
        marginBottom: "10.0pt",
        marginLeft: "4.0pt",
        textAlign: "justify"
    }

    const tableTextStyle = { fontSize: 16, lineHeight: "115%", color: "black" }

    return (
        <Table
            responsive
            style={tableStyle}
        >
            <tbody>
                <tr >
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    BUTİKO
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={{ fontSize: 16, lineHeight: "115%", color: "black" }}>
                                Kullanıcılar'ın Android ve Apple Store vasıtasıyla indirmek
                                suretiyle üye olduğu ve sipariş verebildiği BUTİKO'ya ait mobil
                                uygulama ve internet sitesini ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Platform
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                butiko.com.tr internet sitesi ve mobil uygulamasını ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Tedarikçi
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                BUTİKO ile yaptığı sözleşme kapsamında Platform’a üye olan ve
                                oluşturduğu hesap üzerinden yayınladığı ilanlar aracılığıyla
                                çeşitli ürün ve/veya hizmetleri satışa arz eden tüzel/gerçek kişi
                                üyeyi ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Kullanıcı(lar)
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                BUTİKO üzerinden Kullanım Koşulları'nı kabul etmek suretiyle üye
                                olan kişileri ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Ürün(ler)&nbsp;
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                BUTİKO’da bulunan, Kullanıcı'nın mevcut seçenekler arasında
                                seçtiği ve sipariş verdiği, &nbsp;Tedarikçi’nin de Kullanıcı’ya
                                sağladığı ürünleri ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Kart Saklama
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                Kullanıcı'nın ödeme yaparken, kredi/banka kartı bilgilerini
                                BUTİKO'ya girmesi üzerine, bu bilgilerin daha sonraki
                                kullanımlarda ödeme yapılması için saklanmasını ifade eder.&nbsp;
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Mesafeli Satış
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                Kullanıcı’nın, BUTİKO üzerinde bulunan ürünü satın almak istemesi
                                üzerine, Tedarikçi ve kullanıcı arasında kurulan mesafeli satış
                                sözleşmesini ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Sipariş
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                Kullanıcı'nın, BUTİKO üzerinden Ürün(ler) sipariş etmesini ifade
                                eder.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        style={tableRow1Data1Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <strong>
                                <span
                                    style={tableTextStyle}
                                >
                                    Koşullar
                                </span>
                            </strong>
                        </p>
                    </td>
                    <td
                        style={tableRowData2Style}
                    >
                        <p
                            style={tableParagraphStyle}
                        >
                            <span style={tableTextStyle}>
                                İşbu kullanım koşullarını ifade eder.
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}