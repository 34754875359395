import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap"
import { formatDistanceToNow, isYesterday, isToday } from 'date-fns';
import trLocale from 'date-fns/locale/tr';
import avatar from "../../../../../../assets/images/order/avatar-1.jpg"

const Reviews = ({
  reviews
}) => {

  const timestamp = 1694461735; // Replace this with your timestamp
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

  let formattedRelativeTime;

  if (isToday(date)) {
    // If the date is today, display hours ago
    formattedRelativeTime = formatDistanceToNow(date, { addSuffix: true, locale: trLocale });
  } else if (isYesterday(date)) {
    // If the date is yesterday, display yesterday
    formattedRelativeTime = 'dün';
  } else {
    // Otherwise, display the full date
    formattedRelativeTime = date.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  return (
    <div>
      <h5 className="mb-4">Değerlendirmeler:</h5>
      {reviews ? reviews.map((review, index) => (
        <div key={index}>
          <Row>
            <Col xs="2">
              <div className="align-self-center m-3">
                {review.customer_profile_picture && review.customer_profile_picture !== "null" ? (
                  <img
                    src={review.customer_profile_picture}
                    className="avatar-xs rounded-circle"
                    alt=""
                  />
                ) : (
                  <img
                    src={avatar}
                    className="avatar-xs rounded-circle"
                    alt=""
                  />
                )}
              </div>
            </Col>
            <Col xs="8">
              <Row>
                <div className="flex-grow-1">
                  <p
                    className="text-muted fw-medium m-0"
                    style={{ color: "#495057", fontSize: 14 }}
                  >
                    {review.customer_username}
                  </p>
                </div>
              </Row>
              <Row>
                <p className="m-0">{review.review_content}</p>
              </Row>
            </Col>
          </Row>
          <Row className="ps-3">
            <p
              className="m-0  p-0 ps-2"
              style={{ color: "#687E95", fontSize: 11.2 }}
            >
              {formattedRelativeTime}
            </p>
          </Row>
        </div>
      )) : (
        <span>
          Bu ürün için bir değerlendirme bulunamamıştır.
        </span>
      )}

    </div>
  )
};


Reviews.propTypes = {
  comments: PropTypes.array,
  reply: PropTypes.any,
  productId: PropTypes.any,
  onClickReply: PropTypes.func,
  onCancelReply: PropTypes.func,
  onAddReply: PropTypes.func,
  onAddComment: PropTypes.func,
};

export default Reviews;