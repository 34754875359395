import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Button,

  Col,
  Container,
  Input,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { get, post } from "helpers/api_helper";
import * as url from "helpers/url_helper";

import { useQuery, QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import ReviewCard from "./Components/ReviewCard";
import ReviewDetails from "./ReviewDetails";

import { CiFilter } from "react-icons/ci";
import useReviewStore from "./store";

const queryClient = new QueryClient();

const ReviewQuery = () => {

  //meta title
  document.title = "Yorumlar | ButikO";

  const navigate = useNavigate();

  const {
    reviews, setReviews, currentPage, errorMessage, selectedReviewId, isAnswered, isNextButtonDisabled,
    setCurrentPage, setErrorMessage, setSelectedReviewId, setIsAnswered, setIsNextButtonDisabled,
  } = useReviewStore((state) => ({
    reviews: state.reviews,
    setReviews: state.setReviews,
    currentPage: state.currentPage,
    errorMessage: state.errorMessage,
    dates: state.dates,
    selectedReviewId: state.selectedReviewId,
    isAnswered: state.isAnswered,
    isNextButtonDisabled: state.isNextButtonDisabled,
    setCurrentPage: state.setCurrentPage,
    setErrorMessage: state.setErrorMessage,
    setSelectedReviewId: state.setSelectedReviewId,
    setIsAnswered: state.setIsAnswered,
    setIsNextButtonDisabled: state.setIsNextButtonDisabled,
  }));

  const getReviewsData = async () => {
    try {
      const response = await get(
        `${url.GET_REVIEWS}/?page=${currentPage}`,
        { withCredentials: true }
      );

      console.log(response);

      return response;
    } catch (error) {
      console.error("Error fetching reviews:", error);

      if (
        error.response?.data?.detail?.[0]?.msg === "unathorized action" ||
        error.response?.data?.detail?.[0]?.msg === "invalid jwt"
      ) {
        navigate("/login");
      }

      if (error.response?.status === 404) {
        setErrorMessage("Yorum Bulunamadı.");
      } else {
        setErrorMessage("Bilinmeyen bir hata meydana geldi");
      }

      throw error; // Rethrow the error so that React Query can handle it
    }
  }

  const { isLoading, isError, error, data, refetch } = useQuery({
    queryKey: ['reviewsData', currentPage],
    queryFn: getReviewsData,
  });

  useEffect(() => {
    if (data) {
      setReviews(data)
    }
  }, [data, setReviews])

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setIsAnswered(value === "" ? "" : parseInt(value));
  };

  // useEffect(() => {
  //   console.log("Is loading:", isLoading);
  //   console.log("Is error:", isError);
  //   console.log("Error:", error);
  //   console.log(reviews);
  //   console.log(errorMessage);
  // }, [isLoading, isError, error, reviews, errorMessage]);

  const checkIfNextButtonDisabled = (reviews) => {
    return reviews && reviews.length < 7;
  };

  useEffect(() => {
    setIsNextButtonDisabled(checkIfNextButtonDisabled(reviews));
  }, [reviews]);

  const filteredData = reviews?.filter(review => isAnswered === '' || review.is_answered == isAnswered);
  const isDataEmpty = !reviews || reviews.length === 0;
  const showNoReviewsAlert = isDataEmpty || errorMessage === "Yorum Bulunamadı.";

  return (
    <React.Fragment>
      <div className="page-content" style={{
        minHeight: "100vh", /* Set the minimum height of the page content to 100% of the viewport height */
        overflow: "auto", /* Enable vertical scrolling if the content overflows the viewport */
      }}>
        <Container fluid>
          <Breadcrumbs title="ButikO" breadcrumbItem="Yorumlar" />
          <>
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 5, order: 1 }}>
                <Row className="d-flex align-items-center pb-3">
                  <Col xs="auto">
                    <Row>
                      <Col xs="auto">
                        <CiFilter />
                      </Col>
                      <Col xs="auto">
                        <span style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px"
                        }}>Filtrele</span>
                      </Col>
                    </Row>


                  </Col>
                  <Col>
                    <Input type="select" value={isAnswered} onChange={handleFilterChange} disabled={showNoReviewsAlert}>
                      <option value="">Hepsi</option>
                      <option value={1}>Cevaplandı</option>
                      <option value={0}>Cevaplanmadı</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  {
                    showNoReviewsAlert ? (
                      <div className="alert" style={{backgroundColor:"#EFF2F7"}}>
                        Şu anda görüntülenecek yorum bulunmamaktadır.
                      </div>
                    ) :
                      isLoading ? (
                        <div>Yükleniyor...</div>
                      ) : errorMessage && errorMessage.length !== 0 ? (
                        <div className="alert alert-danger">{errorMessage}</div> // Render the error message
                      ) : (<>
                        {filteredData?.length === 0 ? (
                          <div className="alert alert-warning">Gösterilecek yorum yok.</div>
                        ) : (
                          filteredData.map((review, index) => (
                            <Col xs="12" key={index}>
                              <ReviewCard
                                review={review}
                                onClicked={() => {
                                  setSelectedReviewId(review.review_id);
                                }}
                              />
                            </Col>
                          ))
                        )}
                      </>)
                  }


                </Row>
                <Row className="justify-content-end align-items-center">
                  {currentPage > 0 && !isError && (
                    <Col xs="6" sm="4" >
                      <Button
                        color="tertiary"
                        onClick={handlePreviousPage}
                        disabled={showNoReviewsAlert}
                        className="w-100 btn btn-outline-secondary"
                      >
                        Önceki
                      </Button>
                    </Col>
                  )}
                  {!isError && (
                    <Col xs="6" sm="4">
                      <Button
                        color="tertiary"
                        onClick={handleNextPage}
                        disabled={isNextButtonDisabled || showNoReviewsAlert}
                        className={`w-100 btn btn-outline-secondary`}
                      >
                        Sonraki
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 7, order: 2 }}>
                <Row>
                  <ReviewDetails />
                </Row>
              </Col>
            </Row>
          </>



        </Container>
      </div >
    </React.Fragment >
  );
};

const Reviews = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReviewQuery />
    </QueryClientProvider>
  )
}
export default Reviews;
