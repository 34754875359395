import React from "react";

const ColorCircle = ({ color }) => {
  return (
    <div
      style={{
        width: "20px", 
        height: "20px", 
        borderRadius: "50%", 
        backgroundColor: color, 
        margin:"6px",
        boxShadow: "2px 2px 3px 0px #cfcfcf"
      }}
    ></div>
  );
};

export default ColorCircle;
