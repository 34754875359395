import React, { useState, useEffect } from "react";

const steps = [
  {
    title: "Ürün Seçimi ",
    description:
      "Reklam vermek istediğiniz ürünü ya da ürünleri belirleyin ve hedef kitlenize ulaşın.",
  },
  {
    title: "Birden çok ürün mü seçtiniz ?",
    description:
      " Ürünlerinizin gösterim sırasını belirleyin ve kampanyanızı optimize edin.",
  },
  {
    title: "Reklamınızı ne zaman ve nerede vermek istersiniz ?",
    description:
      "İlçe, semt ve tarih seçenekleriyle hedef kitlenizi doğru yerde ve zamanda yakalayın.",
  },
];

const Timeline = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDisabled(window.innerWidth < 1000);
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isDisabled) {
    return <div></div>;
  }

  return (
    <div style={styles.timelineContainer}>
      {steps.map((step, index) => (
        <div key={index} style={styles.stepContainer}>
          <div style={styles.circle}>{index + 1}</div>
          <div style={styles.content}>
            <h5>{step.title}</h5>
            <p>{step.description}</p>
          </div>
          {index < steps.length - 1 && <div style={styles.connector}></div>}
        </div>
      ))}
    </div>
  );
};

const styles = {
  timelineContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "20px 0",
  },
  stepContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    textAlign: "left",
    width: "32%",
    marginRight: "20px",
  },
  circle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#007bff",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
  },
  content: {
    textAlign: "left",
  },
  connector: {
    position: "absolute",
    top: "50%",
    left: "100%",
    width: "100%",
    height: "2px",
    backgroundColor: "#007bff",
    zIndex: "-1",
  },
};

export default Timeline;
