import React, { useMemo, useRef } from "react"
import { Container, Input, Label } from "reactstrap"
import { Button, Col, Row, CardTitle, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import { useQuery, useMutation } from "@tanstack/react-query"
import "react-datepicker/dist/react-datepicker.css"
import ListGroup from "react-bootstrap/ListGroup"
//Import Breadcrumb
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useState } from "react"
import { Skeleton } from 'antd';

import "./AboutStore.css"
import EditOpenColseTime from "./components/editOpenCloseTime"
import EditEcommerceLinks from "./components/editEcommerceLinks"
import EditSocialLinks from "./components/editSocialLinks"
import EcommerceLinks from "./components/ecommerceLinks"
import SocialLinks from "./components/socialLinks"
import accessToken from "helpers/jwt-token-access/accessToken"
import axios from "axios"
import { GET_ABOUT_STORE } from "helpers/url_helper"
import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import storeDefaultImg from "../../assets/images/logo/storeDefaultImg.png"
import storeLogoImage from "../../assets/images/logo/storeLogoImage.png"
import { ErrorBoundary } from "react-error-boundary";
import ShouldGlow from "./components/shouldGlow"
import { message } from 'antd';
import StoreDescription from "./components/StoreDescription"
const LineColumnArea = ({ data }) => {
  const lineColumnAreaColors = getChartColorsArray(
    '["--bs-danger","--bs-primary"]'
  )
  const series = data
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: lineColumnAreaColors,

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    // labels: [
    //   "01/01/2003",
    //   "02/01/2003",
    //   "03/01/2003",
    //   "04/01/2003",
    //   "05/01/2003",
    //   "06/01/2003",
    //   "07/01/2003",
    //   "08/01/2003",
    //   "09/01/2003",
    //   "10/01/2003",
    //   "11/01/2003",
    // ],
    markers: {
      size: 0,
    },
    legend: {
      offsetY: 11,
    },
    xaxis: {
      categories: [
        "Oca",
        "Şub",
        "Mar",
        "Nis",
        "May",
        "Haz",
        "Tem",
        "Ağu",
        "Eyl",
        "Eki",
        "Kas",
        "Ara",
      ],
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + ""
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
    />
  )
}

const AboutStore = () => {
  document.title = "Magaza Profili"
  const history = useNavigate()
  const toastRef = useRef(null)
  const [editPart1, setEditPart1] = useState(false)
  // const [editPart2, setEditPart2] = useState(false)
  const [editPart3, setEditPart3] = useState(false) //this is for social links
  const [editPart4, setEditPart4] = useState(false) // this is for ecommerce links
  const [editPart5, setEditPart5] = useState(false) //this is for open and close times

  const [storeName, setStoreName] = useState("")
  const [description, setDescription] = useState("")
  const [coverPic, setCoverPic] = useState("")
  const [profilePic, setProfilePic] = useState("")
  const [shouldGlow, setShouldGlow] = useState(false);


  const [disableEdit, setDisableEdit] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const key = "loading"
  const putEditStoreName = () => {
    const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-shop-name?shop_name=${storeName}`
    const finalData = { shop_name: `${storeName}` }
    return axios.put(finalUrl, finalData, { withCredentials: true })
  }
  const putEditCoverPic = () => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-cover-picture`
    const finalData = new FormData()
    finalData.append("cover_picture", coverPic)


    return axios.put(finalUrl, finalData, { withCredentials: true })
  }

  const putEditProfilePic = () => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-profile-picture`
    const finalData = new FormData()
    finalData.append("profile_picture", profilePic)

    return axios.put(finalUrl, finalData, { withCredentials: true })
  }

  const putEditHide = phoneOrAddress => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/hide-address-phone`
    const finalData = {
      option: phoneOrAddress,
    }

    return axios.put(finalUrl, finalData, { withCredentials: true })
  }

  const putEditSocialLinks = () => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-social_links`
    const website = document.getElementById("website").value || null
    const instagram = document.getElementById("instagram").value || null
    const x = document.getElementById("x").value || null
    const youtbube = document.getElementById("youtube").value || null
    const facebook = document.getElementById("facebook").value || null
    const tiktok = document.getElementById("tiktok").value || null
    const whatsapp = document.getElementById("whatsapp").value || null
    const telegram = document.getElementById("telegram").value || null
    const pinterest = document.getElementById("pinterest").value || null
    const linkedin = document.getElementById("linkedin").value || null
    const googlemaps = document.getElementById("googlemaps").value || null
    const isLinkValid = link => {
      let inputValue = link
      const baseURL = "https://"
      if (link) {
        // Check if the input value already contains the country code
        if (!inputValue.startsWith(baseURL)) {
          // If not, prepend to the input value
          inputValue = baseURL + inputValue
          return inputValue
        } else {
          return link
        }
      } else {
        return null
      }
    }
    const finalData = {
      website: isLinkValid(website),
      instagram: isLinkValid(instagram),
      x: isLinkValid(x),
      youtube: isLinkValid(youtbube),
      facebook: isLinkValid(facebook),
      tiktok: isLinkValid(tiktok),
      whatsapp: isLinkValid(whatsapp),
      telegram: isLinkValid(telegram),
      pinterest: isLinkValid(pinterest),
      linkedin: isLinkValid(linkedin),
      google_maps: isLinkValid(googlemaps),
    }


    return axios.put(finalUrl, finalData, { withCredentials: true })
  }

  const putEditSchedule = () => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-store-schedule`

    const mondaySwitch = document.getElementById("mondaySwitch").checked
    const mondayOpen = document.getElementById("mondayOpen").value
    const mondayClose = document.getElementById("mondayClose").value

    const tuesdaySwitch = document.getElementById("tuesdaySwitch").checked
    const tuesdayOpen = document.getElementById("tuesdayOpen").value
    const tuesdayClose = document.getElementById("tuesdayClose").value

    const wednesdaySwitch = document.getElementById("wednesdaySwitch").checked
    const wednesdayOpen = document.getElementById("wednesdayOpen").value
    const wednesdayClose = document.getElementById("wednesdayClose").value

    const thursdaySwitch = document.getElementById("thursdaySwitch").checked
    const thursdayOpen = document.getElementById("thursdayOpen").value
    const thursdayClose = document.getElementById("thursdayClose").value

    const fridaySwitch = document.getElementById("fridaySwitch").checked
    const fridayOpen = document.getElementById("fridayOpen").value
    const fridayClose = document.getElementById("fridayClose").value

    const saturdaySwitch = document.getElementById("saturdaySwitch").checked
    const saturdayOpen = document.getElementById("saturdayOpen").value
    const saturdayClose = document.getElementById("saturdayClose").value

    const sundaySwitch = document.getElementById("sundaySwitch").checked
    const sundayOpen = document.getElementById("sundayOpen").value
    const sundayClose = document.getElementById("sundayClose").value

    const finalData = {
      monday: {
        is_enabled: mondaySwitch,
        start_date: mondayOpen,
        end_date: mondayClose,
      },
      tuesday: {
        is_enabled: tuesdaySwitch,
        start_date: tuesdayOpen,
        end_date: tuesdayClose,
      },
      wednesday: {
        is_enabled: wednesdaySwitch,
        start_date: wednesdayOpen,
        end_date: wednesdayClose,
      },
      thursday: {
        is_enabled: thursdaySwitch,
        start_date: thursdayOpen,
        end_date: thursdayClose,
      },
      friday: {
        is_enabled: fridaySwitch,
        start_date: fridayOpen,
        end_date: fridayClose,
      },
      saturday: {
        is_enabled: saturdaySwitch,
        start_date: saturdayOpen,
        end_date: saturdayClose,
      },
      sunday: {
        is_enabled: sundaySwitch,
        start_date: sundayOpen,
        end_date: sundayClose,
      },
    }
    console.log(finalData)


    return axios.put(finalUrl, finalData, { withCredentials: true })
  }

  const putEditEcommerceLinks = () => {
    const finalUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/about-store/edit-e-commerce-links`
    const n11 = document.getElementById("n11").value || null
    const trendyol = document.getElementById("trendyol").value || null
    const etsy = document.getElementById("etsy").value || null
    const shopify = document.getElementById("shopify").value || null
    const hepsiburada = document.getElementById("hepsiburada").value || null
    const aliexpress = document.getElementById("aliexpress").value || null
    const alibaba = document.getElementById("alibaba").value || null
    const ciceksepeti = document.getElementById("ciceksepeti").value || null
    const amazon = document.getElementById("amazon").value || null
    const dolap = document.getElementById("dolap").value || null
    const shopier = document.getElementById("shopier").value || null
    const azall = document.getElementById("azall").value || null

    const isLinkValid = link => {
      let inputValue = link
      const baseURL = "https://"
      if (link) {
        // Check if the input value already contains the country code
        if (!inputValue.startsWith(baseURL)) {
          // If not, prepend the country code to the input value
          inputValue = baseURL + inputValue
          return inputValue
        } else {
          return link
        }
      } else {
        return null
      }
    }
    const finalData = {
      az: isLinkValid(azall),
      n11: isLinkValid(n11),
      trendyol: isLinkValid(trendyol),
      etsy: isLinkValid(etsy),
      shopify: isLinkValid(shopify),
      hepsiburada: isLinkValid(hepsiburada),
      alibaba: isLinkValid(alibaba),
      aliexpress: isLinkValid(aliexpress),
      cicek_sepeti: isLinkValid(ciceksepeti),
      amazon: isLinkValid(amazon),
      dolap: isLinkValid(dolap),
      shopier: isLinkValid(shopier),
    }
    console.log(finalData)
    const newHeder = {
      Authorization: accessToken,
    }
    const newConfig = {
      headers: newHeder,
    }
    return axios.put(finalUrl, finalData, { withCredentials: true })
  }



  // react query for ecommerce links
  const eCommerceLinksMutation = useMutation({
    mutationFn: putEditEcommerceLinks,
    onSuccess: () => {
      toast.success("E-ticaret Bağlantınız Eklenmiştir.", { toastId: "eCommerceLinksMutation" })
      refetch()
      setEditPart4(false)
      setDisableEdit(false)
    },
    onError: error => {
      toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "eCommerceLinksMutationError" })
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      }
      setDisableEdit(false)
      console.log(error)
    },
  })

  // react query for schedule
  const scheduleMutation = useMutation({
    mutationFn: putEditSchedule,
    onSuccess: () => {
      toast.success("Çalışma Saatleriniz Düzenlenmiştir.", { toastId: "scheduleMutation" })
      refetch()
      setEditPart5(false)
      setDisableEdit(false)
    },
    onError: error => {
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      }
      toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "scheduleMutationError" })
      console.log(error)
      setDisableEdit(false)
    },
  })

  // react query for socila links
  const socialLinksMutation = useMutation({
    mutationFn: putEditSocialLinks,
    onSuccess: () => {
      toast.success("Sosyal Bağlantınız Eklenmiştir.", { toastId: "socialLinksMutation" })
      refetch()
      setEditPart3(false)
      setDisableEdit(false)
    },
    onError: error => {
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      }
      toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "socialLinksMutationError" })
      setDisableEdit(false)
    },
  })

  // react query hide address or phone number
  const hidemutation = useMutation({
    mutationFn: putEditHide,
    onSuccess: () => {
      toast.success("İşlem Tamamlandı", { toastId: "hidemutation" })
      refetch()
      setEditPart1(false)
      setDisableEdit(false)
    },
    onError: error => {
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      }
      toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "hidemutationError" })
      setDisableEdit(false)
    },
  })

  //react query put profile pic
  const profilePicmutation = useMutation({
    mutationFn: putEditProfilePic,
    onSuccess: () => {
      toast.update(toastRef.current, { render: "Yüklendi👌", type: "success", isLoading: false, autoClose: 1000 })
      refetch()
      setEditPart1(false)
      setDisableEdit(false)
    },
    onError: error => {
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      } else if (error?.response?.data?.detail[0]?.msg === "unsupported mime type") {
        toast.update(toastRef.current, { render: "Desteklenmeyen dosya türü, jpg veya png olmali", type: "error", isLoading: false, autoClose: 2000 })
      } else if (error.response.data.detail[0].msg === "file size above maxiumum") {
        toast.update(toastRef.current, { render: "Maksimum dosya boyutu: 5Mb", type: "error", isLoading: false, autoClose: 2000 })
      } else {

        toast.update(toastRef.current, { render: "Yüklenmedi", type: "error", isLoading: false, autoClose: 2000 })
      }

      setDisableEdit(false)
    },
  })

  //react query put cover pic
  const coverPicmutation = useMutation({
    mutationFn: putEditCoverPic,
    onSuccess: () => {

      toast.update(toastRef.current, { render: "Yüklendi👌", type: "success", isLoading: false, autoClose: 1000 })
      refetch()
      setEditPart1(false)
      setDisableEdit(false)
    },
    onError: error => {
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      } else if (error?.response?.data?.detail[0]?.msg === "unsupported mime type") {
        toast.update(toastRef.current, { render: "Desteklenmeyen dosya türü, jpg veya png olmali", type: "error", isLoading: false, autoClose: 2000 })
      } else if (error.response.data.detail[0].msg === "file size above maxiumum") {
        toast.update(toastRef.current, { render: "Maksimum dosya boyutu: 5Mb", type: "error", isLoading: false, autoClose: 2000 })
      } else {

        toast.update(toastRef.current, { render: "Yüklenmedi", type: "error", isLoading: false, autoClose: 2000 })
      }

      setDisableEdit(false)
    },
  })
  // react query put store name
  const mutation = useMutation({
    mutationFn: putEditStoreName,
    onSuccess: () => {
      toast.success("Mağaza Adı Değiştirildi.", { toastId: "storeName" })
      refetch()
      setEditPart1(false)
      setDisableEdit(false)
    },
    onError: error => {
      if (
        error?.response?.data?.detail[0]?.msg === "unathorized action" ||
        error?.response?.data?.detail[0]?.msg === "invalid jwt"
      ) {
        history("/login")
      }
      toast.error("Bilinmeyen Bir Hata Oluştu", { toastId: "storeNameError" })
      setDisableEdit(false)
    },
  })

  //for edit part
  const handleEditPart1 = () => {
    setDisableEdit(true)
    setEditPart1(true)
  }

  const handleEditPart3 = () => {
    setDisableEdit(true)
    setEditPart3(true)
  }
  const handleEditPart4 = () => {
    setDisableEdit(true)
    setEditPart4(true)
  }
  const handleEditPart5 = () => {
    setDisableEdit(true)
    setEditPart5(true)
  }
  //end for edit part


  //react query for getting store data
  const getStoreData = () => {
    return axios.get(GET_ABOUT_STORE, { withCredentials: true })
  }
  const {
    isLoading,
    isError,
    data: aboutSData,
    error,
    refetch,
  } = useQuery({ queryKey: ["get-about-store"], queryFn: getStoreData })
  if (isLoading) {
    // return <h1>loading..</h1>
  }
  if (isError) {
    // toastr.error("Lütfen Giriş Yapın")
    console.log(error)
    if (
      error?.response?.data?.detail[0]?.msg === "unathorized action" ||
      error?.response?.data?.detail[0]?.msg === "invalid jwt"
    ) {
      history("/login")
    }
    if (error?.response?.data?.detail[0]?.msg === "seller is not approved") {
      toast.info("Satıcı onaylı değil", { toastId: "notApprovedAlert" })
    }
    // return <h1>error..</h1>
  }
  useMemo(() => {
    setShouldGlow(ShouldGlow(aboutSData?.data?.store_schedule))
    setDescription(aboutSData?.data?.description)
  }, [aboutSData])
  //end react query
  const aboutStoreData = aboutSData?.data
  // console.log(aboutStoreData);
  const aboutStoreViews = aboutSData?.data?.views
  //pars social links
  let socialLinks;
  if (aboutSData && aboutSData.data && aboutSData.data.social_links) {
    try {
      socialLinks = JSON.parse(aboutSData.data.social_links);
    } catch (error) {
      console.error("Error parsing social links JSON:", error);
    }
  }
  // pars ecommerce Links
  let eCommerceLinks;
  if (aboutSData && aboutSData.data && aboutSData.data.social_links) {
    try {
      eCommerceLinks = JSON.parse(aboutSData.data.e_commerce_links);

    } catch (error) {
      console.error("Error parsing ecommerce links JSON:", error);
    }
  }
  //pars store schedule
  let schedule;

  if (aboutSData && aboutSData.data && aboutSData.data.store_schedule) {
    try {
      schedule = JSON.parse(aboutSData?.data?.store_schedule);

    } catch (error) {
      console.error("Error parsing ecommerce links JSON:", error);
    }
  }

  const randomUUID = crypto.randomUUID() //random thing for rendering profile and cover pic (make react not to cache pic)

  //start for views chart

  const avgM1 = aboutStoreViews?.average_visits?.average_month_1.toFixed(0)
  const avgM2 = aboutStoreViews?.average_visits?.average_month_2.toFixed(0)
  const avgM3 = aboutStoreViews?.average_visits?.average_month_3.toFixed(0)
  const avgM4 = aboutStoreViews?.average_visits?.average_month_4.toFixed(0)
  const avgM5 = aboutStoreViews?.average_visits?.average_month_5.toFixed(0)
  const avgM6 = aboutStoreViews?.average_visits?.average_month_6.toFixed(0)
  const avgM7 = aboutStoreViews?.average_visits?.average_month_7.toFixed(0)
  const avgM8 = aboutStoreViews?.average_visits?.average_month_8.toFixed(0)
  const avgM9 = aboutStoreViews?.average_visits?.average_month_9.toFixed(0)
  const avgM10 = aboutStoreViews?.average_visits?.average_month_10.toFixed(0)
  const avgM11 = aboutStoreViews?.average_visits?.average_month_11.toFixed(0)
  const avgM12 = aboutStoreViews?.average_visits?.average_month_12.toFixed(0)

  const storeVisitM1 = aboutStoreViews?.your_visits?.visits_in_month_1.toFixed(0)
  const storeVisitM2 = aboutStoreViews?.your_visits?.visits_in_month_2.toFixed(0)
  const storeVisitM3 = aboutStoreViews?.your_visits?.visits_in_month_3.toFixed(0)
  const storeVisitM4 = aboutStoreViews?.your_visits?.visits_in_month_4.toFixed(0)
  const storeVisitM5 = aboutStoreViews?.your_visits?.visits_in_month_5.toFixed(0)
  const storeVisitM6 = aboutStoreViews?.your_visits?.visits_in_month_6.toFixed(0)
  const storeVisitM7 = aboutStoreViews?.your_visits?.visits_in_month_7.toFixed(0)
  const storeVisitM8 = aboutStoreViews?.your_visits?.visits_in_month_8.toFixed(0)
  const storeVisitM9 = aboutStoreViews?.your_visits?.visits_in_month_9.toFixed(0)
  const storeVisitM10 = aboutStoreViews?.your_visits?.visits_in_month_10.toFixed(0)
  const storeVisitM11 = aboutStoreViews?.your_visits?.visits_in_month_11.toFixed(0)
  const storeVisitM12 = aboutStoreViews?.your_visits?.visits_in_month_12.toFixed(0)
  const table = [
    {
      name: "Senin mağazan",
      type: "column",
      data: [
        storeVisitM1,
        storeVisitM2,
        storeVisitM3,
        storeVisitM4,
        storeVisitM5,
        storeVisitM6,
        storeVisitM7,
        storeVisitM8,
        storeVisitM9,
        storeVisitM10,
        storeVisitM11,
        storeVisitM12,
      ],
    },
    {
      name: "Ortalama",
      type: "area",
      data: [
        avgM1,
        avgM2,
        avgM3,
        avgM4,
        avgM5,
        avgM6,
        avgM7,
        avgM8,
        avgM9,
        avgM10,
        avgM11,
        avgM12,
      ],
    },
  ]
  //end for views chart

  const greyBackG = {
    backgroundColor: "#A6A6A6",
    border: "none",
  }


  // console.log(aboutSData?.data?.store_schedule && JSON.parse(aboutSData?.data?.store_schedule));
  return (
    <>
      <ErrorBoundary fallback={<div>Bir şeyler yanlış gitti</div>}>

        <div className="page-content">
          {isLoading ? (
            <>
              <Container fluid={true}>
                <Skeleton.Image className=" me-3" style={{ width: "60vw" }} active />
                <Skeleton.Image style={{ width: "20vw" }} active />
                <br />
                <Skeleton.Input className="mt-3 me-3" style={{ width: "60vw", height: "300px" }} active />
                <Skeleton.Input className="mt-3" style={{ width: "20vw", height: "300px" }} active />
              </Container>
            </>
          ) : (
            <Container fluid={true}>
              <Breadcrumbs title="E-ticaret" breadcrumbItem="Magaza Profili" />
              {/* write Html code or structure */}
              <Card style={{ backgroundColor: "#F8F8FB", padding: "0" }}>
                <CardBody style={{ backgroundColor: "#F8F8FB", padding: "0" }}>
                  <Row>
                    <Col xl={8} lg={12} md={12} sm={12}>
                      <Row>
                        <Col md={12}>
                          <Card style={{ borderRadius: "20px 20px 5px 5px" }} className="profile-card">
                            <div className="container">
                              <div className="about-store-img-container">
                                <img
                                  src={
                                    aboutStoreData?.cover_picture
                                      ? `${aboutStoreData?.cover_picture}?${randomUUID}`
                                      : storeDefaultImg
                                  }
                                  className="img-fluid about-store-img"
                                  alt="Store Image"
                                />
                              </div>
                              {editPart1 ? (
                                <Row className="edit-input justify-content-start ">
                                  <Col className="col-xxxl-2 text-nowrap bg-gradient " md={2}>
                                    <div style={{ textAlign: "center" }}>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          color: "white",
                                        }}
                                      >
                                        Kapak resmi:
                                      </span>
                                      <div style={{ color: "white", fontSize: "12px", color: "#556EE6" }}>
                                        (1500*400px)
                                      </div>
                                    </div>
                                  </Col>

                                  <Col className="col-xxxl-5" md={5}>
                                    <Input
                                      className="form-control"
                                      type="file"
                                      accept=".jpg, .png"
                                      id="formFile"
                                      onChange={e => {
                                        setCoverPic(e.target.files[0])
                                      }}
                                    />
                                  </Col>
                                  <Col className="col-xxxl-5 col-md-5 text-nowrap mt-2 text-end d-flex align-items-center justify-content-end ">
                                    <Button
                                      className="btn btn-secondary border-0 me-2 d-flex align-items-center "
                                      onClick={() => {
                                        setEditPart1(false)
                                        setDisableEdit(false)
                                      }}
                                    >
                                      <i className="bx bx-x bx-xs"></i>
                                      İptal
                                    </Button>

                                    <Button
                                      className="btn btn-success border-0 d-flex align-items-center "
                                      onClick={() => {
                                        messageApi.open({
                                          key,
                                          type: 'loading',
                                          content: 'Resim yükleniyor...',
                                          duration: 0,
                                        });
                                        coverPicmutation.mutateAsync().then(() => {
                                          messageApi.open({
                                            key,
                                            type: 'success',
                                            content: 'Mağaza kapak fotoğrafınız başarıyla yüklendi.',
                                            duration: 2,
                                          });
                                        }).catch((err) => {
                                          const msg = err?.response?.data?.detail[0]?.msg;
                                          let content
                                          if (msg === 'file size above maxiumum') {
                                            content = 'Dosya boyutu en fazla 5MB olmalıdır.'
                                          } else if (msg === "unsupported mime type") {
                                            content = 'Bu tür dosya desteklenmiyor, jpg veya png olmali'
                                          } else {
                                            content = 'Bir hata oluştu'
                                          }
                                          messageApi.open({
                                            key,
                                            type: 'error',
                                            content: content,
                                            duration: 3,
                                          });
                                        })

                                      }}
                                    >
                                      <i className="bx bx-check bx-xs"></i>
                                      Kaydet
                                    </Button>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col md={6} xs={6}>
                                    <div className="bottom-left p-2">
                                      <i className=" bx bxs-shopping-bag bx-sm pe-1 "></i>
                                      Ürünler:{" "}
                                      <strong>
                                        <span className="ms-2">
                                          {aboutStoreData
                                            ? aboutStoreData.products
                                            : ""}
                                        </span>
                                      </strong>
                                    </div>
                                  </Col>
                                  <Col md={6} xs={6}>
                                    <div className="bottom-right p-2">
                                      <i className="bx bxs-user-voice bx-sm pe-1 "></i>
                                      Takipçiler:
                                      <strong>
                                        <span className="ms-2">
                                          {aboutStoreData
                                            ? aboutStoreData.followers
                                            : ""}
                                        </span>
                                      </strong>
                                    </div>
                                    {/* </Col>
                              <Col className="d-flex align-items-center " md={4} xs={4}> */}
                                    <div className="bottom-more-right p-2 ">
                                      <i className="bx bxs-star bx-sm me-1 "></i>
                                      Poan:
                                      <strong>
                                        <span className="ms-2">
                                          {aboutStoreData
                                            ? aboutStoreData.rating.toFixed(2)
                                            : ""}
                                        </span>
                                      </strong>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>

                            <CardBody>
                              <Row className="d-flex align-items-center justify-content-between">
                                <Col className="col-12 ">
                                  <Row className=" align-items-center justify-content-start">
                                    <Col className="col-2 d-flex align-items-center justify-content-between">
                                      <div className="profile-container">
                                        <img
                                          src={
                                            aboutStoreData?.profile_picture
                                              ? `${aboutStoreData?.profile_picture}?${randomUUID}`
                                              : storeLogoImage
                                          }
                                          alt="Your Profile Picture"
                                          className=""
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-10">
                                      <Row className="d-flex align-items-center justify-content-between">
                                        {!editPart1 ? (
                                          <>
                                            <Row className="d-flex align-items-center justify-content-between">
                                              <Col className="col-7">
                                                <CardTitle className="mt-0 d-inline-block fs-2">
                                                  {aboutStoreData
                                                    ? aboutStoreData.shop_name
                                                    : ""}
                                                </CardTitle>
                                              </Col>
                                              <Col className="col-5 d-flex align-items-center justify-content-end ms-0 ps-0 ">
                                                <button
                                                  className="btn btn-outline-primary d-flex align-items-center border-0 "
                                                  onClick={handleEditPart1}
                                                  disabled={disableEdit}
                                                >
                                                  <i className="bx bxs-edit-alt bx-xs"></i>
                                                  Düzenle
                                                </button>
                                              </Col>
                                            </Row>
                                          </>
                                        ) : (
                                          <>
                                            <Col className="col-2 d-flex align-item-center">
                                              <Label className=" d-inline-block fw-bolder ">
                                                Profil logo <span style={{ color: "#556EE6" }} className="opacity-75 ">(800*800px)</span>
                                              </Label>

                                            </Col>
                                            <Col className="col-5 mt-3 mb-3">
                                              <Input
                                                className="form-control"
                                                type="file"
                                                accept=".jpg, .png"
                                                id="formFile"
                                                onChange={e => {
                                                  setProfilePic(e.target.files[0])
                                                }}
                                              />
                                            </Col>
                                            <Col className="col-4  mb-3 mt-3 text-nowrap  d-flex align-items-center justify-content-end text-end ">
                                              <Button
                                                className="btn btn-soft-secondary d-flex align-items-center border-0 me-2"
                                                onClick={() => {
                                                  setEditPart1(false)
                                                  setDisableEdit(false)
                                                }}
                                              >
                                                <i className="bx bx-x bx-xs"></i>
                                                İptal
                                              </Button>

                                              <Button
                                                className="btn btn-soft-success d-flex align-items-center border-0 "
                                                onClick={() => {
                                                  // profilePicmutation.mutate()
                                                  messageApi.open({
                                                    key,
                                                    type: 'loading',
                                                    content: 'Resim yükleniyor...',
                                                    duration: 0,
                                                  });
                                                  profilePicmutation.mutateAsync().then(() => {
                                                    messageApi.open({
                                                      key,
                                                      type: 'success',
                                                      content: 'Logonuz yüklenmiştir.',
                                                      duration: 2,
                                                    });
                                                  }).catch((err) => {
                                                    const msg = err?.response?.data?.detail[0]?.msg;
                                                    let content
                                                    if (msg === 'file size above maxiumum') {
                                                      content = 'Dosya boyutu en fazla 5MB olmalıdır.'
                                                    } else if (msg === "unsupported mime type") {
                                                      content = 'Bu tür dosya desteklenmiyor, jpg veya png olmali'
                                                    } else {
                                                      content = 'Bir hata oluştu'
                                                    }
                                                    messageApi.open({
                                                      key,
                                                      type: 'error',
                                                      content: content,
                                                      duration: 3,
                                                    });
                                                  })
                                                  // toastRef.current = toast.info("Resim yükleniyor...", { toastId: "loading", autoClose: 5000 });

                                                }}
                                              >
                                                <i className="bx bx-check bx-xs"></i>
                                                Kaydet
                                              </Button>
                                            </Col>
                                          </>
                                        )}
                                      </Row>

                                      <Row>
                                        <Col>
                                          <Row className="d-flex align-item-center  justify-content-between">
                                            {editPart1 ? (
                                              <>
                                                <Col className="col-2">
                                                  <Label className=" d-inline-block fw-bolder ">
                                                    Magza Tipi:
                                                  </Label>
                                                </Col>
                                                <Col className="col-4">
                                                  <input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={
                                                      aboutStoreData.business_method ===
                                                        "only-online"
                                                        ? "çevrimiçi "
                                                        : "çevrimiçi ve mağazada "
                                                    }
                                                    disabled
                                                  />
                                                </Col>
                                                <Col className="col-5">
                                                  <span>
                                                    değiştirmek için destek ile
                                                    iletişime geçin
                                                  </span>
                                                </Col>
                                              </>
                                            ) : (
                                              <Col
                                                className="col-lg-3 col-sm-8 break-word"
                                                style={{
                                                  color: "#34C38F",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                <span
                                                  className="break-word"
                                                  style={
                                                    aboutStoreData?.business_method ===
                                                      "only-online"
                                                      ? {
                                                        color: "#34C38F",
                                                      }
                                                      : {
                                                        color: "#556EE6",
                                                      }
                                                  }
                                                >
                                                  {aboutStoreData?.business_method ===
                                                    "only-online"
                                                    ? "online "
                                                    : "online ve mağaza"}
                                                </span>
                                              </Col>
                                            )}

                                            {editPart1 ? (
                                              <>
                                                {/* <Row> */}
                                                <Col className="col-2 d-flex align-item-center mt-4">
                                                  <Label className=" d-inline-block fw-bolder ">
                                                    Magza adi:
                                                  </Label>
                                                </Col>
                                                <Col className="col-5 mt-3 mb-3 d-flex justify-content-end">
                                                  <Input
                                                    className="form-control"
                                                    style={{
                                                      background: "#F3F3F9",
                                                    }}
                                                    type="text"
                                                    onChange={e => {
                                                      setStoreName(e.target.value)
                                                    }}
                                                    defaultValue={
                                                      aboutStoreData
                                                        ? aboutStoreData.shop_name
                                                        : ""
                                                    }
                                                  />
                                                </Col>
                                                <Col className="col-4 mb-3 mt-3 text-nowrap d-flex align-items-center justify-content-end text-end ">
                                                  <Button
                                                    className="btn btn-soft-secondary d-flex align-items-center border-0 me-2 "
                                                    onClick={() => {
                                                      setEditPart1(false)
                                                      setDisableEdit(false)
                                                    }}
                                                  >
                                                    <i className="bx bx-x bx-xs"></i>
                                                    İptal
                                                  </Button>

                                                  <Button
                                                    className="btn btn-soft-success d-flex align-items-center border-0 "
                                                    onClick={() => {
                                                      mutation.mutate()
                                                    }}
                                                  >
                                                    <i className="bx bx-check bx-xs"></i>
                                                    Kaydet
                                                  </Button>
                                                </Col>
                                                {/* </Row> */}
                                              </>
                                            ) : (
                                              <>
                                                <Col className="col-4 hd justify-content-end d-flex m-0  p-0">
                                                  <div className=" form-check form-switch  mb-3 d-inline-block ">
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="customSwitch1"
                                                    >
                                                      Telefonu Gizle
                                                    </label>
                                                    <input
                                                      readOnly
                                                      type="checkbox"
                                                      style={
                                                        aboutStoreData?.is_phone_number_hidden ===
                                                          1
                                                          ? null
                                                          : greyBackG
                                                      }
                                                      className="form-check-input"
                                                      onClick={() => {
                                                        hidemutation.mutate("phone")
                                                      }}
                                                      id="customSwitch1"
                                                      checked={
                                                        aboutStoreData?.is_phone_number_hidden ===
                                                          1
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                                {aboutStoreData?.business_method ===
                                                  "only-online" &&
                                                  editPart1 ? null : (
                                                  <>
                                                    <Col className="col-4 hd d-inline-block ">
                                                      <div className="form-check form-switch  mb-3 ">
                                                        <input
                                                          readOnly
                                                          style={
                                                            aboutStoreData?.is_address_hidden ===
                                                              1
                                                              ? null
                                                              : greyBackG
                                                          }
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          id="customSwitch2"
                                                          onClick={() => {
                                                            if (aboutStoreData.business_method === "only-online") {
                                                              toast.info("Mağazanızda sadece online satış yapıldığı için herhangi bir fiziksel mağaza adresi gösterilemez. Dilerseniz mağaza statünüzü destek talebi oluşturarak değiştirebilirsiniz.", { toastId: "addresInfo" })
                                                            } else {

                                                              hidemutation.mutate("address")
                                                            }
                                                          }}
                                                          checked={
                                                            aboutStoreData?.is_address_hidden ===
                                                              1
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="customSwitch2"
                                                        >
                                                          Adresi Gizle
                                                        </label>
                                                      </div>
                                                    </Col>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row>
                                {aboutStoreData?.is_phone_number_hidden === 1 && aboutStoreData?.is_address_hidden === 1 ?
                                  (null) :
                                  (
                                    <div
                                      style={{
                                        borderTop: "3px solid #EFF2F7",
                                        marginTop: "1rem",
                                        height: "0",
                                        width: "100%",
                                      }}
                                    ></div>
                                  )}
                                {aboutStoreData?.is_phone_number_hidden === 0 && (
                                  <>
                                    <Row className=" align-items-center justify-content-center">
                                      <Col className="d-flex justify-content-between p-2 ">
                                        <span className="mb-1 mt-2 d-inline-block  ">
                                          {aboutStoreData?.is_phone_number_hidden ===
                                            0 && aboutStoreData.landline ? (
                                            <strong><BsFillTelephoneFill /> Telefon: </strong>
                                          ) : null}
                                          {aboutStoreData?.is_phone_number_hidden ===
                                            0 && aboutStoreData.landline
                                            ? aboutStoreData.landline
                                            : null}
                                        </span>
                                        {aboutStoreData?.is_phone_number_hidden === 0 ? (
                                          <span className="d-flex align-items-center mb-1 mt-2 d-inline-block pb-0 fw-light">
                                            Telefon ve Adres profil sayfasından değiştir
                                          </span>
                                        ) : null}
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                {aboutStoreData?.business_method !==
                                  "only-online" ? (
                                  <Row className=" align-items-center justify-content-center mt-3 mb-2">
                                    <Col className="d-flex justify-content-between ">
                                      <span style={{ display: 'block', wordBreak: 'break-all' }} className="mb-1 d-inline-block ">
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address ? (
                                          <strong><MdLocationOn style={{ marginBottom: "2px" }} /> Adres: </strong>
                                        ) : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "city"
                                          ] !== null
                                          ? ` ${JSON.parse(aboutStoreData?.address)[
                                          "city"
                                          ]
                                          }/`
                                          : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "district"
                                          ] !== null
                                          ? ` ${JSON.parse(aboutStoreData?.address)[
                                          "district"
                                          ]
                                          }/`
                                          : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "street"
                                          ] !== null
                                          ? ` ${JSON.parse(aboutStoreData?.address)[
                                          "street"
                                          ]
                                          }/`
                                          : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "neighborhood"
                                          ] !== null
                                          ? ` ${JSON.parse(aboutStoreData?.address)[
                                          "neighborhood"
                                          ]
                                          }/`
                                          : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "house_no"
                                          ] !== null
                                          ? `${JSON.parse(aboutStoreData?.address)[
                                          "house_no"
                                          ]
                                          }/`
                                          : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "apartment_no"
                                          ] !== null
                                          ? `${JSON.parse(aboutStoreData?.address)[
                                          "apartment_no"
                                          ]
                                          }/`
                                          : ""}
                                        {aboutStoreData?.is_address_hidden === 0 &&
                                          aboutStoreData?.address &&
                                          JSON.parse(aboutStoreData?.address)[
                                          "address"
                                          ] !== null
                                          ? `${JSON.parse(aboutStoreData?.address)[
                                          "address"
                                          ]
                                          }`
                                          : ""}
                                      </span>
                                      {aboutStoreData?.is_address_hidden === 0 &&
                                        aboutStoreData?.is_phone_number_hidden ===
                                        1 ? (
                                        <span className="d-flex align-items-center mb-1 d-inline-block  pb-0 fw-light">
                                          profil sayfasından değiştir
                                        </span>
                                      ) : null}
                                    </Col>
                                  </Row>
                                ) : null}
                                {/* {aboutStoreData?.is_address_hidden === 1 && aboutStoreData?.is_phone_number_hidden === 1 ? (
                                  ( */}
                                <div className="hr"></div>
                                {/* //   )
                                // ) : null} */}
                                <StoreDescription
                                  aboutStoreData={aboutStoreData}
                                  refetch={refetch}
                                  // editPart2={editPart2}
                                  // handleEditPart2={handleEditPart2}
                                  disableEdit={disableEdit}
                                  // setEditPart2={setEditPart2}
                                  setDisableEdit={setDisableEdit}
                                  descriptionVal={description}

                                />

                              </Row>
                              <div className="hr"></div>

                              <Row className="">
                                <Row className=" d-flex align-items-center justify-content-center mt-3 mb-2">
                                  <Col>
                                    <strong className="mb-0">
                                      Sosyal Bağlantılar:
                                    </strong>
                                  </Col>
                                  {editPart3 ? (
                                    <>
                                      <Col className="text-nowrap d-flex align-items-center justify-content-end text-end ">
                                        <a
                                          className="btn btn-soft-secondary d-flex align-items-center border-0 me-2 "
                                          onClick={() => {
                                            setEditPart3(false)
                                            setDisableEdit(false)
                                          }}
                                        >
                                          <i className="bx bx-x bx-xs"></i>
                                          İptal
                                        </a>

                                        <Button
                                          className="btn btn-soft-success d-flex align-items-center border-0 "
                                          onClick={() => {
                                            socialLinksMutation.mutate()
                                          }}
                                        >
                                          <i className="bx bx-check bx-xs"></i>
                                          Kaydet
                                        </Button>
                                      </Col>
                                    </>
                                  ) : (
                                    <Col className="d-flex align-items-center justify-content-end text-end">
                                      <button
                                        className="btn btn-outline-primary d-flex align-items-center border-0 "
                                        onClick={handleEditPart3}
                                        disabled={disableEdit}
                                      >
                                        <i className="bx bxs-edit-alt bx-xs"></i>
                                        Düzenle
                                      </button>
                                    </Col>
                                  )}
                                </Row>

                                <Row>
                                  {editPart3 ? (
                                    <>
                                      <ErrorBoundary fallback={<div>Bir şeyler yanlış gitti</div>}>

                                        <EditSocialLinks
                                          x={socialLinks["x"]}
                                          tiktok={socialLinks["tiktok"]}
                                          facebook={socialLinks["facebook"]}
                                          website={socialLinks["website"]}
                                          youtube={socialLinks["youtube"]}
                                          instagram={socialLinks["instagram"]}
                                          linkedin={socialLinks["linkedin"]}
                                          telegram={socialLinks["telegram"]}
                                          whatsapp={socialLinks["whatsapp"]}
                                          pinterest={socialLinks["pinterest"]}
                                          google_maps={socialLinks["google_maps"]}
                                        />
                                      </ErrorBoundary>
                                    </>
                                  ) : (
                                    <>
                                      <ErrorBoundary fallback={<div>Bir şeyler yanlış gitti</div>}>

                                        <SocialLinks
                                          x={socialLinks?.x ? socialLinks["x"] : null}
                                          tiktok={socialLinks?.tiktok}
                                          facebook={socialLinks?.facebook}
                                          website={socialLinks?.website}
                                          youtube={socialLinks?.youtube}
                                          instagram={socialLinks?.instagram}
                                          linkedin={socialLinks?.linkedin}
                                          telegram={socialLinks?.telegram}
                                          whatsapp={socialLinks?.whatsapp}
                                          pinterest={socialLinks?.pinterest}
                                          google_maps={socialLinks?.google_maps}
                                        />
                                      </ErrorBoundary>
                                    </>
                                  )}
                                </Row>
                              </Row>

                              <div className="hr"></div>
                              <Row className="">
                                <Row className=" d-flex align-items-center justify-content-center mt-3 mb-2">
                                  <Col>
                                    <div>
                                      <strong className="mb-0">
                                        E-ticaret Bağlantıları:
                                      </strong>
                                    </div>
                                  </Col>
                                  {editPart4 ? (
                                    <>
                                      <Col className="text-nowrap d-flex align-items-center justify-content-end text-end ">
                                        <a
                                          className="btn btn-soft-secondary d-flex align-items-center border-0 me-2 "
                                          onClick={() => {
                                            setEditPart4(false)
                                            setDisableEdit(false)
                                          }}
                                        >
                                          <i className="bx bx-x bx-xs"></i>
                                          İptal
                                        </a>

                                        <a
                                          className="btn btn-soft-success d-flex align-items-center border-0 "
                                          onClick={() => {
                                            eCommerceLinksMutation.mutate()
                                          }}
                                        >
                                          <i className="bx bx-check bx-xs"></i>
                                          Kaydet
                                        </a>
                                      </Col>
                                    </>
                                  ) : (
                                    <Col className="d-flex align-items-center justify-content-end text-end ">
                                      <button
                                        className="btn btn-outline-primary border-0 "
                                        onClick={handleEditPart4}
                                        disabled={disableEdit}
                                      >
                                        <i className="bx bxs-edit-alt bx-xs"></i>
                                        Düzenle
                                      </button>
                                    </Col>
                                  )}
                                </Row>
                                <Row className="d-flex flex-row align-items-center flex-wrap gap-4 mb-3">
                                  {editPart4 ? (
                                    <>
                                      <ErrorBoundary fallback={<div>Bir şeyler yanlış gitti</div>}>

                                        <EditEcommerceLinks
                                          az={eCommerceLinks?.az}
                                          n11={eCommerceLinks?.n11}
                                          etsy={eCommerceLinks?.etsy}
                                          dolap={eCommerceLinks?.dolap}
                                          amazon={eCommerceLinks?.amazon}
                                          alibaba={eCommerceLinks?.alibaba}
                                          shopier={eCommerceLinks?.shopier}
                                          shopify={eCommerceLinks?.shopify}
                                          trendyol={eCommerceLinks?.trendyol}
                                          aliexpress={eCommerceLinks?.aliexpress}
                                          hepsiburada={eCommerceLinks?.hepsiburada}
                                          cicek_sepeti={eCommerceLinks?.cicek_sepeti}
                                        />
                                      </ErrorBoundary>
                                    </>
                                  ) : (
                                    <>
                                      <ErrorBoundary fallback={<div>Bir şeyler yanlış gitti</div>}>

                                        <EcommerceLinks
                                          az={eCommerceLinks?.az}
                                          n11={eCommerceLinks?.n11}
                                          etsy={eCommerceLinks?.etsy}
                                          dolap={eCommerceLinks?.dolap}
                                          amazon={eCommerceLinks?.amazon}
                                          alibaba={eCommerceLinks?.alibaba}
                                          shopier={eCommerceLinks?.shopier}
                                          shopify={eCommerceLinks?.shopify}
                                          trendyol={eCommerceLinks?.trendyol}
                                          aliexpress={eCommerceLinks?.aliexpress}
                                          hepsiburada={eCommerceLinks?.hepsiburada}
                                          cicek_sepeti={eCommerceLinks?.cicek_sepeti}
                                        />
                                      </ErrorBoundary>
                                    </>
                                  )}
                                </Row>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={4} lg={12} md={12} sm={12}>
                      <Col className="card mb-5">
                        <h5>Ortalama Görüntüleme Karşılaştırması</h5>
                        {aboutStoreViews ? <LineColumnArea data={table} /> : null}
                      </Col>
                      <Col>
                        <Card className={shouldGlow && aboutStoreData?.business_method === "online-and-in-store" ? 'glow' : ""}>
                          <CardTitle>
                            <Row className=" d-flex align-items-center justify-content-center">
                              <Col>Çalışma Saatleri</Col>
                              {editPart5 ? (
                                <>
                                  <Col className="text-nowrap d-flex align-items-center justify-content-end text-end ">
                                    <a
                                      style={{ border: "none", marginRight: "8px" }}
                                      className="btn btn-soft-secondary d-flex align-items-center"
                                      onClick={() => {
                                        setEditPart5(false)
                                        setDisableEdit(false)
                                      }}
                                    >
                                      <i className="bx bx-x bx-xs"></i>
                                      İptal
                                    </a>

                                    <a
                                      className="btn btn-soft-success d-flex align-items-center border-0 "
                                      onClick={() => {
                                        scheduleMutation.mutate()
                                      }}
                                    >
                                      <i className="bx bx-check bx-xs"></i>
                                      Kaydet
                                    </a>
                                  </Col>
                                </>
                              ) : (
                                <Col className="d-flex align-items-center justify-content-end text-end ">
                                  <button
                                    className="btn btn-outline-primary d-flex align-items-center border-0 "
                                    onClick={handleEditPart5}
                                    disabled={disableEdit}
                                  >
                                    <i className="bx bxs-edit-alt bx-xs"></i>
                                    Düzenle
                                  </button>
                                </Col>
                              )}
                            </Row>
                          </CardTitle>
                          <ListGroup variant="flush">
                            {editPart5 ? (
                              <>
                                <ErrorBoundary fallback={<div>Bir şeyler yanlış gitti</div>}>

                                  <EditOpenColseTime
                                    monday={schedule?.monday}
                                    friday={schedule?.friday}
                                    sunday={schedule?.sunday}
                                    tuesday={schedule?.tuesday}
                                    saturday={schedule?.saturday}
                                    thursday={schedule?.thursday}
                                    wednesday={schedule?.wednesday}
                                  />
                                </ErrorBoundary>
                              </>
                            ) : (
                              <>
                                {schedule ? (
                                  schedule.monday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Pazartesi
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>

                                            {schedule["monday"]["start_date"]} - {" "}
                                            {schedule["monday"]["end_date"]}
                                          </>

                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Pazartesi
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}


                                {schedule ? (
                                  schedule.tuesday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>Salı</span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>

                                            {schedule["tuesday"]["start_date"]} - {" "}
                                            {schedule["tuesday"]["end_date"]}
                                          </>

                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Salı
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}

                                {schedule ? (
                                  schedule.wednesday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>Çarşamba</span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>
                                            {schedule["wednesday"]["start_date"]} -{" "}
                                            {schedule["wednesday"]["end_date"]}

                                          </>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Çarşamba
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}
                                {schedule ? (
                                  schedule.thursday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>Perşembe</span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>
                                            {schedule["thursday"]["start_date"]} -{" "}
                                            {schedule["thursday"]["end_date"]}

                                          </>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Perşembe
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}
                                {schedule ? (
                                  schedule.friday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>Cuma</span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>
                                            {schedule["friday"]["start_date"]} -{" "}
                                            {schedule["friday"]["end_date"]}

                                          </>

                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Cuma
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}
                                {schedule ? (
                                  schedule.saturday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>Cumartesi</span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>
                                            {schedule["saturday"]["start_date"]} -{" "}
                                            {schedule["saturday"]["end_date"]}
                                          </>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Cumartesi
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}
                                {schedule ? (
                                  schedule.sunday.is_enabled ? (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>Pazar</span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <>
                                            {schedule["sunday"]["start_date"]} -{" "}
                                            {schedule["sunday"]["end_date"]}
                                          </>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  ) : (
                                    <ListGroup.Item>
                                      <div className="item-content">
                                        <span style={{ fontFamily: "Poppins" }}>
                                          Pazar
                                        </span>{" "}
                                        {aboutStoreData?.business_method === "only-online" ? (
                                          <span style={{ color: "#34C38F" }}>Online</span>
                                        ) : (
                                          <span className="kapali">Kapalı</span>
                                        )}
                                      </div>
                                    </ListGroup.Item>
                                  )
                                ) : null}
                              </>
                            )}
                          </ListGroup>
                        </Card>
                      </Col>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {contextHolder}
            </Container>
          )}
        </div>
      </ErrorBoundary>
    </>
  )
}

export default withRouter(AboutStore)
