import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Select, Input } from "antd"
import Button from "react-bootstrap/Button"
import { PiSortAscending, PiSortDescending } from "react-icons/pi"
import { Col, Row } from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddCollection from "./components/AddCollection"
import { PiMagicWandBold } from "react-icons/pi"
import { IoTrashSharp } from "react-icons/io5"
import { toast } from "react-toastify"
import { Modal } from "react-bootstrap"
import "./collection.css"

function Collection() {
  document.title = "Siparişler | Butiko"

  const [desc, setDesc] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const [sortBy, setsortBy] = useState("items")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isNextPage, setIsNextPage] = useState(false)
  const [collections, setCollections] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedCollectionId, setSelectedCollectionId] = useState(null)

  useEffect(() => {
    fetchData()
  }, [currentPage, searchValue, desc, sortBy])

  const fetchData = () => {
    const requestData = {
      page: currentPage,
      filter: sortBy,
      desc: desc,
    }
    axios
    if (searchValue.trim() !== "") {
      requestData.search = searchValue
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/search`,
        requestData,
        { withCredentials: true }
      )
      .then(response => {
        const responseData = response.data.collection_data.map(item => ({
          id: item.collection_id,
          name: item.collection_name,
          items: item.items,
          media: JSON.parse(item.variation_media),
          likes:
            response.data.likes.find(
              like => like.collection_id === item.collection_id
            )?.likes || 0,
        }))
        let deepCopy
        if (responseData.length > 12) {
          deepCopy = cloneDeep(responseData)
          setIsNextPage(true)
          setCollections(deepCopy)
        } else {
          setIsNextPage(false)
          setCollections(responseData)
        }
        setLoading(false)
        setError(null)
        if (responseData.length === 13) {
          deepCopy = cloneDeep(responseData)
          deepCopy.pop()
          setIsNextPage(true)
          setCollections(deepCopy)
        } else {
          setIsNextPage(false)
          setCollections(responseData)
        }
        setLoading(false)
        setError(null)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        setError("Ürün bulunamadı")
      })
  }
  const handleDeleteClick = collectionId => {
    setSelectedCollectionId(collectionId)
    setShowDeleteModal(true)
  }

  const confirmDelete = () => {
    if (selectedCollectionId !== null) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/collections/update-collection?collection_id=${selectedCollectionId}`,
          {
            collection_name: "",
            product_ids: [],
          },
          { withCredentials: true }
        )
        .then(response => {
          toast.success("Koleksiyon Başarıyla Silindi.", {
            toastId: "descriptionMutation",
          })
          fetchData()
        })
        .catch(error => {
          console.error("Error deleting collection:", error)
          toast.error(
            "Koleksiyon silinirken hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
            { toastId: "descriptionMutationError" }
          )
        })
        .finally(() => {
          setShowDeleteModal(false)
          setSelectedCollectionId(null)
        })
    }
  }

  const showPaginationButtons = collections.length > 12
  const handleChange = value => {
    setsortBy(value)
  }

  const goToPreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 0))
  }

  const goToNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const refreshCollections = () => {
    fetchData() // Refresh collections
  }
  const handleSearchChange = e => {
    setSearchValue(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Ürünler" breadcrumbItem="Koleksiyonlar" />
          <Row>
            <Row className="mb-2 align-items-center" style={{ margin: "3px" }}>
              {/* Sorting Section */}
              <Col
                xs={12}
                md={6}
                lg={4}
                className="mb-2 d-flex align-items-center"
              >
                <div className="d-flex">
                  <Button
                    onClick={() => setDesc(true)}
                    style={{ borderRadius: "8px 0px 0px 8px" }}
                    variant={`${desc ? "primary" : "light"}`}
                  >
                    <PiSortAscending size={24} />
                  </Button>
                  <Button
                    onClick={() => setDesc(false)}
                    style={{ borderRadius: "0px 8px 8px 0px" }}
                    variant={`${!desc ? "primary" : "light"}`}
                  >
                    <PiSortDescending size={24} />
                  </Button>
                </div>
                <span className="ms-3 me-2">Sıralama:</span>
                <Select
                  defaultValue="items"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    marginRight: "20px",
                  }}
                  size="middle"
                  onChange={handleChange}
                  options={[
                    { value: "items", label: "Ürüne Göre Sırala" },
                    { value: "likes", label: "Beğenmeye Göre Sırala" },
                  ]}
                />
              </Col>

              {/* Search Box and Add Collection Button */}
              <Col
                xs={12}
                md={6}
                lg={8}
                className="d-flex align-items-center justify-content-end"
              >
                <div className="d-flex w-100 justify-content-end">
                  <div
                    className="search-box me-2"
                    style={{ width: "100%", maxWidth: "400px" }}
                  >
                    <div
                      className="position-relative"
                      style={{
                        border: "1px solid #d3d3d3",
                        borderRadius: "15px",
                      }}
                    >
                      <Input
                        type="text"
                        className="form-control border-0 searchInput"
                        placeholder="Arama yapın..."
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                  <Button
                    type="button"
                    className="btn addCollections"
                    style={{
                      borderRadius: "30px",
                      background: "#34C38F",
                      border: "none",
                      padding: "13px",
                      whiteSpace: "nowrap", // Prevents text overflow on small screens
                    }}
                    onClick={toggleModal}
                  >
                    + Yeni Koleksiyon Ekle
                  </Button>
                </div>
                <AddCollection
                  refreshCollections={refreshCollections}
                  toggleModal={toggleModal}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                />
              </Col>
            </Row>

            {error ? (
              <div className="text-center">KOLEKSIYON bulunamadı</div>
            ) : (
              <>
                <Row className="no-gutters">
                  {collections.map((collection, key) => (
                    <Col
                      xl="3"
                      md="6"
                      sm="6"
                      xs="12"
                      key={"_row_" + key}
                      style={{
                        backgroundColor: "white",
                        margin: "5px",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="d-flex">
                        <Row>
                          <Col lg="6" sm="3" md="12">
                            {" "}
                            <div className="collectionsImageContainer">
                              <Link to={`/edit-collections/${collection.id}`}>
                                <img
                                  alt=""
                                  src={collection?.media[0]}
                                  style={{
                                    top: 12,
                                    left: 15,
                                    zIndex: "0",
                                  }}
                                  className="collectionsImage position-absolute "
                                />
                              </Link>
                              {collection.media[1] ? (
                                <img
                                  alt=""
                                  style={{ zIndex: "2" }}
                                  src={collection.media[1]}
                                  className="collectionsImage"
                                />
                              ) : (
                                <img
                                  alt=""
                                  style={{
                                    zIndex: "2",
                                    objectFit: "cover",
                                  }}
                                  src={collection.media[0]}
                                  className="collectionsImage"
                                />
                              )}
                            </div>
                          </Col>
                          <Col lg="6" sm="3" md="12">
                            <div className="collectionsDesc flex-grow-1">
                              <div className="collectionsName">
                                {collection.name}
                              </div>
                              <div className="collectionsItem">
                                {collection.items} Ürün
                              </div>
                              <div className="collectionsLike">
                                {collection.likes} Beğenme
                              </div>
                              <Row lg={12}>
                                <Col lg={5}>
                                  <Button
                                    color="white"
                                    style={{
                                      backgroundColor: "white",
                                      border: "none",
                                    }}
                                  >
                                    <Link
                                      to={`/edit-collections/${collection.id}`}
                                    >
                                      <PiMagicWandBold
                                        style={{
                                          fontSize: "20px",
                                          backgroundColor: "white",
                                        }}
                                      />
                                      <span>Düzenle</span>
                                    </Link>
                                  </Button>
                                </Col>
                                <Col lg={5}>
                                  <Button
                                    color="white"
                                    onClick={() =>
                                      handleDeleteClick(collection.id)
                                    }
                                    style={{
                                      backgroundColor: "white",
                                      border: "none",
                                    }}
                                  >
                                    <IoTrashSharp
                                      style={{
                                        color: "#F46A6A",
                                        fontSize: "20px",
                                      }}
                                    />
                                    <span style={{ color: "#F46A6A" }}>
                                      Sil
                                    </span>
                                  </Button>
                                  <Modal
                                    show={showDeleteModal}
                                    onHide={() => setShowDeleteModal(false)}
                                    centered
                                  >
                                    <Modal.Body>
                                      <Row
                                        style={{
                                          marginBottom: "20px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          shapeRendering="geometricPrecision"
                                          textRendering="geometricPrecision"
                                          imageRendering="optimizeQuality"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          viewBox="0 0 512 512"
                                          width="150"
                                          height="150"
                                        >
                                          <path
                                            fill="#A82C1F"
                                            fillRule="nonzero"
                                            d="M256 0c70.686 0 134.69 28.658 181.016 74.984C483.342 121.31 512 185.314 512 256c0 70.686-28.658 134.69-74.984 181.016C390.69 483.342 326.686 512 256 512c-70.686 0-134.69-28.658-181.016-74.984C28.658 390.69 0 326.686 0 256c0-70.686 28.658-134.69 74.984-181.016C121.31 28.658 185.314 0 256 0z"
                                          />
                                          <circle
                                            fill="#FF5043"
                                            cx="256"
                                            cy="256"
                                            r="226.536"
                                          />
                                          <path
                                            fill="#fff"
                                            fillRule="nonzero"
                                            d="M275.546 302.281c-.88 22.063-38.246 22.092-39.099-.007-3.779-37.804-13.444-127.553-13.136-163.074.312-10.946 9.383-17.426 20.99-19.898 3.578-.765 7.512-1.136 11.476-1.132 3.987.007 7.932.4 11.514 1.165 11.989 2.554 21.402 9.301 21.398 20.444l-.044 1.117-13.099 161.385zm-19.55 39.211c14.453 0 26.168 11.717 26.168 26.171 0 14.453-11.715 26.167-26.168 26.167s-26.171-11.714-26.171-26.167c0-14.454 11.718-26.171 26.171-26.171z"
                                          />
                                        </svg>
                                      </Row>

                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        Koleksiyonu Silmek İstediğinize Emin
                                        Misiniz?
                                      </div>
                                    </Modal.Body>

                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        onClick={() =>
                                          setShowDeleteModal(false)
                                        }
                                      >
                                        İptal
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={confirmDelete}
                                      >
                                        Sil
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
                {showPaginationButtons && (
                  <div className="mt-3 mb-3  d-flex justify-content-end">
                    <Button
                      disabled={currentPage === 0}
                      onClick={goToPreviousPage}
                      className="me-2"
                      style={{
                        backgroundColor: "white",
                        borderColor: "#CED4DA",
                        color: "black",
                        fontSize: "15px",
                        width: "120px",
                        height: "37px",
                      }}
                    >
                      Geri
                    </Button>
                    <Button
                      onClick={goToNextPage}
                      disabled={!isNextPage}
                      className="nextButtons"
                      style={{
                        backgroundColor: "white",
                        borderColor: "#CED4DA",
                        color: "black",
                        fontSize: "15px",
                        width: "120px",
                        height: "37px",
                      }}
                    >
                      İleri
                    </Button>
                  </div>
                )}
              </>
            )}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Collection
